import { PayloadAction, createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import ApiCall from '../helper/ApiCall';
import { startAppListening } from '../middleware/listenerMiddleware';
import sortByKey from '../helper/sortByKey';

export enum CartLoading {
  Idle = 'idle',
  Pending = 'pending',
  Success = 'succeeded',
  Error = 'failed'
}
export enum CartCurrency {
  EUR = 'EUR',
  GBP = 'GBP',
  USD = 'USD',
  DKK = 'DKK'
}

export interface CartConfigState {
  currency: CartCurrency;
  sourceCountry: string;
}
export type CartItemIdParameters = {
  type: ProductTypes;
  bookId?: number | string;
  serviceId?: number | string;
};

export type PricingObject = {
  quantity: number;
  price: number;
};

export interface Product {
  bookId?: number | string;
  serviceId?: number | string;
  type: ProductTypes;
  title: string;
  image?: string;
  pricing: PricingObject[];
}

export enum ProductTypes {
  PrivatePrintbook = 'PRIVATEPRINTBOOK',
  Printbook = 'PRINTBOOK',
  Ebook = 'EBOOK',
  Isbn = 'ISBN',
  IsbnDigital = 'ISBNDIGITAL',
  BusinessCards = 'BUSCARDS',
  Service = 'SERVICE',
  Shipping = 'SHIPPING'
}

export interface CartServerState {
  id?: string | number;
  config: CartConfigState;
  items: CartItem[];
  discount?: Record<string, string>;
}

export interface CartStateIdle {
  loading: CartLoading.Idle;
  error?: string;
  id?: string | number;
  config?: CartConfigState;
  items?: CartItem[];
  discount?: Record<string, string>;
}

export interface CartStateLoading {
  loading: CartLoading.Pending;
  error?: string;
  id?: string | number;
  config?: CartConfigState;
  items?: CartItem[];
  discount?: Record<string, string>;
}

export interface CartStateSucceeded {
  loading: CartLoading.Success;
  id?: string | number;
  config: CartConfigState;
  items: CartItem[];
  discount?: Record<string, string>;
}
export interface CartStateFailed {
  loading: CartLoading.Error;
  error: string;
  id?: string | number;
  config?: CartConfigState;
  items?: CartItem[];
  discount?: Record<string, string>;
}
/*
export interface CartState {
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  config: CartConfigState;
  items: CartItem[];
  discount?: Record<string, string>;
}
*/
export type CartState = CartStateIdle | CartStateLoading | CartStateSucceeded | CartStateFailed;

export interface CartItem extends Omit<Product, 'title'> {
  id: string;
  quantity: number;
  bookSpecs?: CartItemBookSpecs | CartItemEbookSpecs;
  isbnSpecs?: CartItemIsbnSpecs;
  serviceSpecs?: CartItemServiceSpecs;
  buscardsSpecs?: CartItemBuscardsSpecs;
}

export interface CartItemPrintBook
  extends Omit<CartItem, 'isbnSpecs' | 'serviceSpecs' | 'buscardsSpecs'> {
  type: ProductTypes.Printbook | ProductTypes.PrivatePrintbook;
  bookSpecs: CartItemBookSpecs;
}

export interface CartItemEbook
  extends Omit<CartItem, 'isbnSpecs' | 'serviceSpecs' | 'buscardsSpecs'> {
  type: ProductTypes.Ebook;
  bookSpecs: CartItemEbookSpecs;
}

export interface CartItemIsbn
  extends Omit<CartItem, 'bookSpecs' | 'serviceSpecs' | 'buscardsSpecs'> {
  type: ProductTypes.Isbn | ProductTypes.IsbnDigital;
  isbnSpecs: CartItemIsbnSpecs;
}

export interface CartItemBuscards
  extends Omit<CartItem, 'bookSpecs' | 'isbnSpecs' | 'serviceSpecs'> {
  type: ProductTypes.Isbn | ProductTypes.IsbnDigital;
  buscardsSpecs: CartItemBuscardsSpecs;
}

export interface CartItemBookSpecs {
  title: string;
  subtitle?: string;
  binding: string;
  size: {
    id: string;
    shortLocalized: string;
    x: number;
    y: number;
  };
  isbn?: string;
  lastEdited: string;
  color: string;
  imageUrl: string;
  suggestedSku: string;
}

export interface CartItemEbookSpecs extends Omit<CartItemBookSpecs, 'size' | 'color'> {}

export interface CartItemIsbnSpecs {
  bookTitle: string;
  suggestedSku: string;
}

export interface CartItemServiceSpecs {
  serviceName: string;
  bookTitle?: string;
  suggestedSku: string;
}

export interface CartItemBuscardsSpecs {
  bookTitle: string;
  suggestedSku: string;
}

export function generateCartItemIdFromProduct(product: Product): string {
  return [product.type, product?.bookId, product?.serviceId].join('-');
}
export function generateCartItemId(params: CartItemIdParameters): string {
  return [params.type, params?.bookId, params?.serviceId].join('-');
}

export const productTypeIsBook = (type: ProductTypes) => {
  if (
    type === ProductTypes.Printbook ||
    type === ProductTypes.PrivatePrintbook ||
    type === ProductTypes.Ebook
  ) {
    return true;
  } else {
    return false;
  }
};

export const productTypeIsPrintBook = (type: ProductTypes) => {
  if (type === ProductTypes.Printbook || type === ProductTypes.PrivatePrintbook) {
    return true;
  } else {
    return false;
  }
};

export const productTypeIsEBook = (type: ProductTypes) => {
  if (type === ProductTypes.Ebook) {
    return true;
  } else {
    return false;
  }
};

export const productTypeIsIsbn = (type: ProductTypes) => {
  if (type === ProductTypes.Isbn || type === ProductTypes.IsbnDigital) {
    return true;
  } else {
    return false;
  }
};

export const productTypeIsBuscards = (type: ProductTypes) => {
  if (type === ProductTypes.BusinessCards) {
    return true;
  } else {
    return false;
  }
};

const initialState: CartStateIdle = {
  config: { currency: CartCurrency.EUR, sourceCountry: 'NL' },
  loading: CartLoading.Idle,
  items: []
};

const api = new ApiCall();

export const fetchCart = createAsyncThunk('cart/fetchCart', async () => {
  const response = await api.getData('checkoutAPI/cart');
  return response.data as CartServerState;
  // note: forcing type of return is important
});

export const cartSlice = createSlice({
  name: 'cart',
  initialState: initialState as CartState,
  reducers: {
    increaseQuantity: (state, action) => {
      const item = state.items?.find((item) => item.id === action.payload);
      if (item && productTypeIsPrintBook(item.type)) {
        item.quantity++;
      }
    },
    decreaseQuantity: (state, action) => {
      if (state.items) {
        const item = state.items.find((item) => item.id === action.payload);

        if (item) {
          const newQuantity = item.quantity - 1;
          if (newQuantity === 0) {
            item.quantity = 1;
          } else {
            item.quantity = newQuantity;
          }
        }
      }
    },
    changeQuantity: (state, action: PayloadAction<{ id: string; quantity: number }>) => {
      if (state.items) {
        let newQuantity = action.payload.quantity;
        if (newQuantity === 0) {
          newQuantity = 1;
        }
        const item = state.items.find((item) => item.id === action.payload.id);
        // only print books can have a quantity > 1
        if (item) {
          if (productTypeIsPrintBook(item.type)) {
            item.quantity = newQuantity;
          } else if (item.type === ProductTypes.BusinessCards) {
            const validQuantities = [50, 100, 200, 500];
            if (validQuantities.find((el) => el === newQuantity)) {
              item.quantity = newQuantity;
            }
          } else if (newQuantity === 1) {
            item.quantity = newQuantity;
          }
        }
      }
    },
    deleteItem: (state, action: PayloadAction<string>) => {
      if (state.items) {
        state.items = state.items.filter((item) => item.id !== action.payload);
      }
    },
    deleteItems: (state, action: PayloadAction<string[]>) => {
      if (state.items && action.payload.length > 0) {
        state.items = state.items.filter((item) => !action.payload.includes(item.id));
      }
    },
    addItem: (state, action: PayloadAction<CartItem>) => {
      const foundItem = state.items?.find((item) => item.id === action.payload.id);
      if (foundItem) {
        if (productTypeIsPrintBook(action.payload.type)) {
          foundItem.quantity += action.payload.quantity;
        }
      } else {
        state.items?.push(action.payload);
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCart.pending, (state, action) => {
      state.loading = CartLoading.Pending;
    });
    builder.addCase(fetchCart.fulfilled, (state, action) => {
      return (state = { ...action.payload, loading: CartLoading.Success });
    });
    builder.addCase(fetchCart.rejected, (state, action) => {
      return (state = {
        error: action.error.message ? action.error.message : 'UNKNOWN_ERROR',
        loading: CartLoading.Error
      });
    });
  }
});

export const getPricePerUnitForQuantity = (
  priceArray: PricingObject[],
  quantity: number
): number => {
  const pricingArray = [...priceArray];
  const sortedPricingArray: Array<PricingObject> = sortByKey(pricingArray, 'quantity', 'desc');
  const correctPriceObject = sortedPricingArray.find((el, index) => {
    return el.quantity <= quantity;
  })!;
  return correctPriceObject.price;
};

export const getPricePerUnitForCartItem = (item: CartItem, specificQuantity?: number): number => {
  if (specificQuantity) {
    return getPricePerUnitForQuantity(item.pricing, specificQuantity);
  }
  return getPricePerUnitForQuantity(item.pricing, item.quantity);
};

export const {
  increaseQuantity,
  decreaseQuantity,
  changeQuantity,
  deleteItem,
  deleteItems,
  addItem
} = cartSlice.actions;

let cartApiTimer: NodeJS.Timeout;
let syncStatus: string;
startAppListening({
  matcher: isAnyOf(deleteItem, changeQuantity, increaseQuantity, decreaseQuantity, deleteItems),
  effect: async (action, listenerApi) => {
    clearTimeout(cartApiTimer);
    const state = listenerApi.getState();
    //console.log('Cart entry added: ', action);
    //console.log('New state: ', state.cart);
    syncStatus = 'PENDING';
    //console.log('syncStatus: ', syncStatus);
    cartApiTimer = setTimeout(() => {
      // the timeout effect should only push something to the server if the cart was actually loaded from the server, otherwise it might become chaos
      if (state.cart.loading === 'succeeded') {
        const cartToSync: CartServerState = {
          config: state.cart.config,
          items: state.cart.items,
          discount: state.cart.discount
        };

        // we don't care about the response of this api call
        api.putData('checkoutAPI/cart', cartToSync);
        // console.log('async logica na timeout', cartToSync);
        // console.log(JSON.stringify(cartToSync));
        // update cart to server
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        syncStatus = 'OK';
        // console.log('syncStatus: ', syncStatus);
      }
    }, 2000);
  }
});

export default cartSlice.reducer;
