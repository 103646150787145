import { useEffect } from 'react';
import { useGetUserProfileQuery, useRequestCheckoutSpecificationMutation } from '../api/apiSlice';
import {
  CheckoutState,
  CheckoutTotalAmounts,
  CheckoutVatSpecification,
  getPriceForCheckoutItem,
  setCartId,
  setInitial,
  setShippingMethod,
  setTotalAmounts,
  setVATSpecification
} from './checkoutSlice';
import {
  useAppDispatch,
  useAppSelector,
  useGetCartQuery,
  useGetCountry,
  useGetVATRates
} from '../hooks';
import { CustomerType } from '../user';
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import CheckoutDetails from './CheckoutDetails';
import { EGridColType, EGridTypes, GridCol, GridContainer } from 'mbs-common';
import { CartLoading, ProductTypes } from '../Cart/cartSlice';
import CheckoutOverview from './CheckoutOverview';
import customerIsEUCompany from '../helper/customerIsEUCompany';
import { VATRateReturnType } from '../helper/VATRate';
import OrderSidebar from './OrderSidebar';

export default function Checkout() {
  //const [ getUserProfileData ] = useLazyGetUserProfileQuery();
  const { data: UserProfileData, isSuccess, isLoading, isError } = useGetUserProfileQuery();
  const dispatch = useAppDispatch();
  const checkout = useAppSelector((state) => state.checkout);
  const {
    email,
    customerType,
    shippingAddress,
    billingAddressSameAsShipping,
    selectedShippingMethod,
    specification
  } = checkout;
  const billingAddress = billingAddressSameAsShipping ? shippingAddress : checkout.billingAddress;
  const { data: cart, isLoading: isCartLoading, isSuccess: isCartSuccess } = useGetCartQuery(); // we get the cart using the hook, because if it's not loaded in local state, we want to fetch it from the server
  const routeobject = useRouteMatch();
  const i18nCountry = useGetCountry();
  const history = useHistory();
  const { data: VATRatesRatio, isSuccess: VATRatesRatioIsSuccess } = useGetVATRates(
    specification ? specification.productionCountry : i18nCountry,
    shippingAddress?.l,
    billingAddress?.l,
    customerIsEUCompany(customerType, billingAddress?.l),
    VATRateReturnType.Ratio
  );
  let { url, path } = routeobject;
  

  const [requestCheckoutSpecification] = useRequestCheckoutSpecificationMutation({
    fixedCacheKey: 'RequestCheckoutSpec'
  });

  // set initial state for checkout component when the checkout page is reopened
  useEffect(() => {
    let payload: CheckoutState | null = null;
    if (isSuccess) {
      payload = {
        email: UserProfileData.email,
        ...(UserProfileData.customerType === CustomerType.PRIVATE
          ? { customerType: CustomerType.PRIVATE }
          : {
              customerType: UserProfileData.customerType
            }),
        ...(UserProfileData.billingAddressSameAsShipping
          ? { billingAddressSameAsShipping: true }
          : {
              billingAddressSameAsShipping: false,
              billingAddress: UserProfileData.billingAddress
            }),
        ...(UserProfileData.shippingAddress
          ? { shippingAddress: UserProfileData.shippingAddress }
          : undefined)
      };
    }
    dispatch(setInitial(payload));
  }, [UserProfileData, dispatch, isSuccess]);

  // fetches checkout specification from server when shippingaddress or cart changes
  useEffect(() => {
    if (cart && cart.loading === CartLoading.Success && shippingAddress) {
      if (cart.items.length > 0) {
        requestCheckoutSpecification({ cart, shippingAddress: shippingAddress });
      }
    }
  }, [cart, shippingAddress, requestCheckoutSpecification]);

  useEffect(() => {
    if (cart && cart.loading === CartLoading.Success) {
      if (cart.items.length === 0) {
        history.push('/cart');
      } else if (cart.id) {
        dispatch(setCartId(cart.id));
      }
    }
  }, [history, cart, dispatch]);

  // set totalamounts and vatspecificationfor the current checkout situation
  useEffect(() => {
    if (email && shippingAddress && specification && VATRatesRatioIsSuccess) {
      // now set totalamounts and vatspecification in redux checkout state
      const totalAmountsPayload: CheckoutTotalAmounts = {
        productTotalExclVat: 0,
        productVat: 0,
        shippingTotalExclVat: 0,
        shippingVat: 0,
        totalInclVat: 0
      };
      const vatPayload: CheckoutVatSpecification = {
        items: {},
        shipping: {}
      };

      specification.items.forEach((item) => {
        const vatRate = VATRatesRatio[item.type];
        const priceExclVat = getPriceForCheckoutItem(item.pricing, item.quantity);
        vatPayload.items[item.id] = { priceExcl: priceExclVat, vatRate };
        // recalculate total values for the checkout
        const totalItemCostsExclVat = priceExclVat * item.quantity;
        const totalItemVat = totalItemCostsExclVat * vatRate;
        totalAmountsPayload.productTotalExclVat += totalItemCostsExclVat;
        totalAmountsPayload.productVat += totalItemVat;
        totalAmountsPayload.totalInclVat += totalItemCostsExclVat + totalItemVat;
      });

      specification.shippingMethods.forEach((item) => {
        const vatRate = VATRatesRatio[ProductTypes.Shipping];
        const shippingAmount = item.value;
        vatPayload.shipping[item.id] = { priceExcl: shippingAmount, vatRate };

        if (selectedShippingMethod && item.id === selectedShippingMethod) {
          const shippingVat = item.value * vatRate;
          totalAmountsPayload.shippingTotalExclVat += shippingAmount;
          totalAmountsPayload.shippingVat += shippingVat;
          totalAmountsPayload.totalInclVat += shippingAmount + shippingVat;
        }
      });

      totalAmountsPayload.productTotalExclVat = Math.round(totalAmountsPayload.productTotalExclVat);
      totalAmountsPayload.productVat = Math.round(totalAmountsPayload.productVat);
      totalAmountsPayload.shippingTotalExclVat = Math.round(
        totalAmountsPayload.shippingTotalExclVat
      );
      totalAmountsPayload.shippingVat = Math.round(totalAmountsPayload.shippingVat);
      totalAmountsPayload.totalInclVat = Math.round(totalAmountsPayload.totalInclVat);
      dispatch(setTotalAmounts(totalAmountsPayload));
      dispatch(setVATSpecification(vatPayload));
    }
  }, [
    email,
    shippingAddress,
    specification,
    selectedShippingMethod,
    VATRatesRatioIsSuccess,
    VATRatesRatio,
    dispatch
  ]);

  // const handleSetShipping = () => {
  //   const value = 'default';
  //   dispatch(setShippingMethod(value));
  // };

  // const handleSetInitialNull = () => {
  //   dispatch(setInitial(null));
  // };

  // const handleSetInitialEmail = () => {
  //   dispatch(
  //     setInitial({
  //       email: 'martijn@test.nl',
  //       customerType: CustomerType.PRIVATE,
  //       billingAddressSameAsShipping: true
  //     })
  //   );
  // };

  return (
    <>
      <GridContainer type={EGridTypes.Center}>
        <GridCol
          type={[EGridColType.Default12, EGridColType.Md8, EGridColType.Xl9, EGridColType.Xxl12]}
        >
          <Switch>
            <Route exact path={`${path}`} children={<CheckoutDetails />} />
            <Route path={`${path}/overview`} children={<CheckoutOverview />} />
            <Redirect to={`${path}`} />
          </Switch>
        </GridCol>
        <GridCol
          type={[EGridColType.Default12, EGridColType.Md4, EGridColType.Xl3, EGridColType.Xxl4]}
        >
          <OrderSidebar />
        </GridCol>
      </GridContainer>
    </>
  );
}
