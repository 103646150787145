import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { VATRatesObject } from '../helper/VATRate';
import { UserAddress, UserProfile } from '../user';
import { CartItem, CartServerState, CartStateSucceeded } from '../Cart/cartSlice';
import {
  CheckoutSpecification,
  CheckoutState,
  DeliverytimesData,
  EbookDownloadlinksData,
  PaymentSession
} from '../Checkout/checkoutSlice';
// import { RemittanceState } from '../Admin/NewOrder/Remittance';

export type CartVerificationResponse =
  | { status: 'OK' }
  | { status: 'ERROR'; errors: Record<string, string> };

export type PostalcodecheckResponse = {
  s: string;
  p: string;
  c: string;
  l: string;
};

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: window.location.origin + '/site' }),
  endpoints: (builder) => ({
    getVATRates: builder.query<Record<string, VATRatesObject>, void>({
      query: () => 'checkoutAPI/vatrates'
    }),
    getUserProfile: builder.query<UserProfile, void>({
      query: () => 'userAPI/userProfile'
    }),
    verifyCartBeforeCheckout: builder.mutation<CartVerificationResponse, CartServerState>({
      query: (cart) => ({
        url: 'checkoutAPI/verifyCartBeforeCheckout',
        method: 'POST',
        body: cart
      })
    }),
    requestCheckoutSpecification: builder.mutation<
      CheckoutSpecification,
      { cart: CartStateSucceeded; shippingAddress: UserAddress }
    >({
      query: ({ cart, shippingAddress }) => ({
        url: 'checkoutAPI/getSpecification',
        method: 'POST',
        body: { cart, shippingAddress }
      }),
      async onCacheEntryAdded(arg, { dispatch, cacheDataLoaded }) {
        await cacheDataLoaded;
      }
    }),
    postalcodecheck: builder.query<
      PostalcodecheckResponse,
      { l: string; p: string; nr: string; nra?: string }
    >({
      query: ({ l, p, nr, nra }) =>
        `userAPI/postalcodecheck/l/${l}/p/${p}/nr/${nr}${nra ? `/nra/${nra}` : ''}`
    }),
    preparePaymentSession: builder.mutation<PaymentSession, CheckoutState>({
      query: (checkoutState) => ({
        url: 'checkoutAPI/preparePayment',
        method: 'POST',
        body: { ...checkoutState }
      })
    }),
    deliverytimes: builder.query<DeliverytimesData, string[]>({
      query: (skus) => `checkoutAPI/deliverytimes/skus/${skus.join(',')}`
    }),
    ebookDownloadlinks: builder.query<EbookDownloadlinksData, (string | number)[]>({
      query: (ids) => `checkoutAPI/ebookDownloadlinks/ids/${ids.join(',')}`
    }),
    adminGetUserProfileByEmail: builder.query<UserProfile, string>({
      query: (email) => `adminUserAPI/userProfile/email/${encodeURIComponent(email)}`
    }),
    adminGetCartProduct: builder.query<
      CartItem,
      { type: string; bookId: string; serviceId?: string; quantity: number }
    >({
      query: ({ type, bookId, serviceId, quantity }) =>
        `adminOrderAPI/getCartProduct/type/${type}/bookId/${bookId}/quantity/${quantity}${
          serviceId ? `/serviceId/${serviceId}` : ``
        }`
    }),
    // adminCreateOrder: builder.mutation<
    //   { orderId: string; status: string },
    //   { checkoutState: CheckoutState; remittance: RemittanceState }
    // >({
    //   query: ({ checkoutState, remittance }) => ({
    //     url: 'adminOrderAPI/order',
    //     method: 'POST',
    //     body: { ...checkoutState, remittance }
    //   })
    // })
  })
});

export const {
  useGetVATRatesQuery,
  useGetUserProfileQuery,
  useLazyGetUserProfileQuery,
  useRequestCheckoutSpecificationMutation,
  useVerifyCartBeforeCheckoutMutation,
  usePostalcodecheckQuery,
  useLazyPostalcodecheckQuery,
  usePreparePaymentSessionMutation,
  useDeliverytimesQuery,
  useLazyDeliverytimesQuery,
  useEbookDownloadlinksQuery,
  useLazyEbookDownloadlinksQuery,
  useLazyAdminGetUserProfileByEmailQuery,
  useLazyAdminGetCartProductQuery,
  // useAdminCreateOrderMutation
} = apiSlice;
