import { Controller, UseFormReturn } from 'react-hook-form';
import { UserAddress } from '../../../../user';
import { CountryOptionType, IsoCountryCodes } from '../../../../helper/addressSupport';
import sortByKey from '../../../../helper/sortByKey';
import { useIntl } from 'react-intl';
import { EGridColType, FormItem, GridCol, MBSInput, MBSSelect } from 'mbs-common';
import GenericAddress from '../../../AddressInput/GenericAddress';
import NLAddress from '../../../AddressInput/NLAddress';

export default function PrivateClient({ methods }: { methods: UseFormReturn<UserAddress, any> }) {
  const intl = useIntl();
  const countryOptions: CountryOptionType[] = sortByKey(
    IsoCountryCodes.map((val) => ({
      value: val,
      label: intl.formatMessage({ id: `Country-${val}`, defaultMessage: val })
    })),
    'label'
  );

  const {
    resetField,
    watch,
    register,
    formState: { errors }
  } = methods;

  const handleChangeCountry = () => {
    resetField('p');
    resetField('nr');
    resetField('nra');
    resetField('s');
    resetField('s2');
    resetField('c');
    resetField('r');
  };

  const selectedCountry = watch('l', '');

  return (
    <>
      <GridCol type={[EGridColType.Default12]}>
        <FormItem
          label={intl.formatMessage({
            id: 'Checkout-Address-Input-L',
            defaultMessage: 'Country'
          })}
        >
          <Controller
            control={methods.control}
            name="l"
            render={({ field: { onChange, value, name, ref } }) => (
              <MBSSelect
                options={countryOptions}
                isSearchable={true}
                menuPortalTarget={document.body}
                value={countryOptions.find((c) => c.value === value)}
                onChange={(val) => {
                  if (val) {
                    onChange(val.value);
                    handleChangeCountry();
                  }
                }}
              />
            )}
          />
        </FormItem>
      </GridCol>
      {selectedCountry === 'NL' ? (
        <NLAddress methods={methods} />
      ) : (
        <GenericAddress methods={methods} selectedCountry={selectedCountry} />
      )}
      <GridCol type={[EGridColType.Default6, EGridColType.DefaultRowEnd]}>
        <FormItem
          label={intl.formatMessage({
            id: 'Checkout-Address-Input-T',
            defaultMessage: 'Phone number'
          })}
        >
          <MBSInput
            type="tel"
            maxLength={20}
            {...register('t')}
            error={errors.t?.message && intl.formatMessage({ id: errors.t?.message })}
          />
        </FormItem>
      </GridCol>
      <GridCol type={[EGridColType.Default12]}>
        <FormItem
          label={intl.formatMessage({
            id: 'Checkout-Address-Input-Cp',
            defaultMessage: 'Company name'
          })}
        >
          <MBSInput
            {...register('cp')}
            error={errors.cp?.message && intl.formatMessage({ id: errors.cp?.message })}
          />
        </FormItem>
      </GridCol>
    </>
  );
}
