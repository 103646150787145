import { useIntl } from 'react-intl';
import ProductItem from '.';
import { CartItemBookSpecs } from '../../../../Cart/cartSlice';

export default function PrintBookItem({
  bookSpecs,
  quantity,
  id
}: {
  bookSpecs: CartItemBookSpecs;
  quantity: number;
  id: string;
}) {
  const intl = useIntl();
  const theImage = <img src={bookSpecs.imageUrl} alt={bookSpecs.title} />;
  const specifications = bookSpecs.isbn
    ? intl.formatMessage(
        { id: 'Bookspec-ISBN-isbn', defaultMessage: 'ISBN: {isbn}' },
        { isbn: bookSpecs.isbn }
      )
    : `${intl.formatMessage({ id: `binding-${bookSpecs.binding}` })} - ${intl.formatMessage({
        id: bookSpecs.color === 'fc' ? `interiorcolor-short-fc` : `interiorcolor-short-bw`
      })}`;
  return (
    <ProductItem
      title={bookSpecs.title}
      subtitle={bookSpecs.subtitle}
      image={theImage}
      quantity={quantity}
      specifications={specifications}
    />
  );
}