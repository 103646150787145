import React from 'react';

function Searchbox(props) {

	return(
		<div className="cw-search-container">
			<div className="cw-search">
				<input type="text" className="input-white app-input-hassymbolafter app-input-hassymbolsearch" onChange={props.onChange} placeholder={props.translations.placeholderSearch} /><div className="app-input-symbolblock app-input-symbolblock-lesslight app-input-symbolblock-search app-input-symbolblock-after">&nbsp;</div>
			</div>
		</div>
	)
}

export default Searchbox;