import React from 'react';

function PoweredbyComponent(props) {
	return (
	<section className="cw-poweredby-container">
		<a href={window.location.origin} target="_blank" rel="noopener noreferrer">{props.text}</a>
	</section>
	);
}

export default PoweredbyComponent;