import { useParams } from 'react-router-dom';
import useLocalStorageState from 'use-local-storage-state';
import { CheckoutState } from '../../checkoutSlice';
import {
  productTypeIsBuscards,
  productTypeIsEBook,
  productTypeIsIsbn,
  productTypeIsPrintBook
} from '../../../Cart/cartSlice';
import { CheckoutResultRouteParams } from '..';
import {
  Block,
  BlockContent,
  BlockTitle,
  SectionIntroduction,
  NumberedList,
  Loading,
  SVGGraphic
} from 'mbs-common';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLazyDeliverytimesQuery, useLazyEbookDownloadlinksQuery } from '../../../api/apiSlice';
import { useEffect } from 'react';
import { useGetLanguage } from '../../../hooks';

export default function CheckoutSuccess() {
  let { id: orderId } = useParams<CheckoutResultRouteParams>();
  const [localorder, setLocalOrder] = useLocalStorageState<CheckoutState>(`order-${orderId}`);
  const [triggerDeliverytimes, { data, isSuccess, isLoading }] = useLazyDeliverytimesQuery();

  const [
    triggerEbooklinks,
    { data: ebookLinks, isSuccess: isSuccessEbookLinks, isLoading: isLoadingEbookLinks }
  ] = useLazyEbookDownloadlinksQuery();
  const intl = useIntl();
  const language = useGetLanguage();
  let printbookOrdered = false;
  let ebookOrdered = false;
  let isbnOrdered = false;
  let buscardsOrdered = false;
  let isbnBookId: string | number | undefined = undefined;

  if (localorder && localorder.specification) {
    localorder.specification.items.forEach((item) => {
      if (productTypeIsPrintBook(item.type)) {
        printbookOrdered = true;
      } else if (productTypeIsEBook(item.type)) {
        ebookOrdered = true;
      } else if (productTypeIsIsbn(item.type)) {
        isbnOrdered = true;
        isbnBookId = item.bookId;
      } else if (productTypeIsBuscards(item.type)) {
        buscardsOrdered = true;
      }
    });
  }

  const addWeekDaysToToday = (weekdays: number) => {
    const date = new Date();
    let daysToAdd = weekdays;
    while (daysToAdd > 0) {
      date.setDate(date.getDate() + 1);
      // Skip weekends
      if (date.getDay() !== 0 && date.getDay() !== 6) {
        daysToAdd--;
      }
    }
    return date;
  };

  useEffect(() => {
    if (localorder && localorder.specification) {
      const printskus = localorder.specification.items
        .map((item) => {
          if (productTypeIsPrintBook(item.type)) {
            return item.sku;
          }
          return false;
        })
        .filter((value) => value !== false) as string[];

      triggerDeliverytimes(printskus);

      const ebookIds = localorder.specification.items
        .map((item) => {
          if (productTypeIsEBook(item.type)) {
            return item.bookId;
          }
          return false;
        })
        .filter((value) => value !== false) as (string | number)[];

      triggerEbooklinks(ebookIds);
    }
  }, [localorder, triggerDeliverytimes, triggerEbooklinks]);

  return (
    <>
      <SectionIntroduction>
        <SVGGraphic name="Bookbuddy/Printer" className="introsvg" />
        <h2>
          <FormattedMessage
            id="CheckoutSuccess-title-Thankyou"
            defaultMessage="Thank you for your purchase!"
          />
        </h2>
        <p>
          <FormattedMessage
            id="CheckoutSuccess-YourOrderNoIs"
            defaultMessage="Your order number is: <b>{orderId}</b>"
            values={{
              orderId: orderId,
              b: (chunks: React.ReactElement) => <strong className="extrabold">{chunks}</strong>
            }}
          />
        </p>
        {printbookOrdered || ebookOrdered || isbnOrdered || buscardsOrdered ? (
          <p>
            <FormattedMessage
              id="CheckoutSuccess-IntroWhatToExpect"
              defaultMessage="You have just placed an order with us. Depending on what you  have ordered, you can read what to expect below."
            />
          </p>
        ) : (
          ''
        )}
      </SectionIntroduction>
      <Block type="columnedflex">
        {printbookOrdered && (
          <div className="app-grid-flex-1w">
            <BlockTitle
              text={intl.formatMessage({
                id: 'CheckoutSuccess-title-PaperbackHardcover',
                defaultMessage: 'Paperback / hardcover'
              })}
            />
            <BlockContent>
              <NumberedList>
                <li>
                  <strong className="extrabold">
                    <FormattedMessage
                      id="CheckoutSuccess-li1-OrderConfEmail"
                      defaultMessage="You will receive confirmation via e-mail"
                    />
                  </strong>
                  <br />
                  <span className="font-content-subtext">
                    <FormattedMessage
                      id="CheckoutSuccess-li1-OrderNumberIs"
                      defaultMessage="Your order number is: <b>{orderId}</b>."
                      values={{
                        orderId: orderId,
                        b: (chunks: React.ReactElement) => (
                          <strong className="extrabold">{chunks}</strong>
                        )
                      }}
                    />{' '}
                    <FormattedMessage
                      id="CheckoutSuccess-li1-OrderConfSentTo"
                      defaultMessage="We will send an order confirmation to {email}. Note: the e-mail could end up in your spam box."
                      values={{ email: localorder?.email }}
                    />
                  </span>
                </li>
                <li>
                  <strong className="extrabold">
                    <FormattedMessage
                      id="CheckoutSuccess-PaperbackHardcover-li2-WeMakePOD"
                      defaultMessage="We are making your book using Print on Demand"
                    />
                  </strong>
                  <br />
                  <span className="font-content-subtext">
                    <FormattedMessage
                      id="CheckoutSuccess-PaperbackHardcover-li2-WePrintRightAway"
                      defaultMessage="We are printing your order right away. Keep in mind that this will take a few days."
                    />
                  </span>
                </li>
                <li>
                  <strong className="extrabold">
                    <FormattedMessage
                      id="CheckoutSuccess-PaperbackHardcover-li3-OrderWillShipped"
                      defaultMessage="Your order will be shipped"
                    />
                  </strong>
                  <br />
                  <span className="font-content-subtext">
                    <FormattedMessage
                      id="CheckoutSuccess-PaperbackHardcover-li3-ShipConfEMail"
                      defaultMessage="You will receive a shipping confirmation with a tracking link via e-mail."
                    />
                  </span>
                </li>
              </NumberedList>
            </BlockContent>
            <BlockContent blockColor="darkgrey">
              <div style={{ textAlign: 'center' }}>
                {isSuccess && data ? (
                  <>
                    <strong className="extrabold">
                      <FormattedMessage
                        id="CheckoutSuccess-PaperbackHardcover-ExpectedShippingDate"
                        defaultMessage="Expected shipping date"
                      />
                    </strong>
                    <br />
                    <FormattedMessage
                      id="CheckoutSuccess-PaperbackHardcover-ExpectedShippingDate-BetweenDates"
                      defaultMessage="Between {startdate} and {enddate}"
                      values={{
                        startdate: addWeekDaysToToday(data.minDays).toLocaleDateString(language, {
                          month: 'long',
                          day: 'numeric'
                        }),
                        enddate: addWeekDaysToToday(data.maxDays).toLocaleDateString(language, {
                          month: 'long',
                          day: 'numeric'
                        })
                      }}
                    />
                  </>
                ) : isLoading ? (
                  <Loading container={true} size="20px" />
                ) : (
                  <></>
                )}
              </div>
            </BlockContent>
          </div>
        )}
        {ebookOrdered && (
          <div className="app-grid-flex-1w">
            <BlockTitle
              text={intl.formatMessage({
                id: 'CheckoutSuccess-title-Ebook',
                defaultMessage: 'eBook'
              })}
            />
            <BlockContent>
              <NumberedList>
                <li>
                  <strong className="extrabold">
                    <FormattedMessage
                      id="CheckoutSuccess-li1-OrderConfEmail"
                      defaultMessage="You will receive confirmation via e-mail"
                    />
                  </strong>
                  <br />
                  <span className="font-content-subtext">
                    <FormattedMessage
                      id="CheckoutSuccess-li1-OrderNumberIs"
                      defaultMessage="Your order number is: <b>{orderId}</b>."
                      values={{
                        orderId: orderId,
                        b: (chunks: React.ReactElement) => (
                          <strong className="extrabold">{chunks}</strong>
                        )
                      }}
                    />{' '}
                    <FormattedMessage
                      id="CheckoutSuccess-li1-OrderConfSentTo"
                      defaultMessage="We will send an order confirmation to {email}. Note: the e-mail could end up in your spam box."
                      values={{ email: localorder?.email }}
                    />
                  </span>
                </li>
                <li>
                  <strong className="extrabold">
                    <FormattedMessage
                      id="CheckoutSuccess-Ebook-li2-dlLinkEmail"
                      defaultMessage="Your download link is in the confirmation e-mail"
                    />
                  </strong>
                  <br />
                  <span className="font-content-subtext">
                    <FormattedMessage
                      id="CheckoutSuccess-Ebook-li2-dlLinkEmailOrClickBelow"
                      defaultMessage="The link to download your e-book is in the confirmation e-mail or you can click the link below."
                    />
                  </span>
                </li>
              </NumberedList>
            </BlockContent>
            <BlockContent blockColor="darkgrey">
              <div style={{ textAlign: 'center' }}>
                {isSuccessEbookLinks && ebookLinks ? (
                  <>
                    <strong className="extrabold">
                      <FormattedMessage
                        id="CheckoutSuccess-Ebook-DownloadYourEbook"
                        defaultMessage="Download your e-book"
                      />
                    </strong>
                    <br />
                    {ebookLinks.map((item) => (
                      <div>
                        <a href={item.url}>{item.label}</a>
                      </div>
                    ))}
                  </>
                ) : isLoadingEbookLinks ? (
                  <Loading container={true} size="20px" />
                ) : (
                  <></>
                )}
              </div>
            </BlockContent>
          </div>
        )}
        {isbnOrdered && (
          <div className="app-grid-flex-1w">
            <BlockTitle
              text={intl.formatMessage({
                id: 'CheckoutSuccess-title-Isbn',
                defaultMessage: 'ISBN'
              })}
            />
            <BlockContent>
              <NumberedList>
                <li>
                  <strong className="extrabold">
                    <FormattedMessage
                      id="CheckoutSuccess-li1-OrderConfEmail"
                      defaultMessage="You will receive confirmation via e-mail"
                    />
                  </strong>
                  <br />
                  <span className="font-content-subtext">
                    <FormattedMessage
                      id="CheckoutSuccess-li1-OrderNumberIs"
                      defaultMessage="Your order number is: <b>{orderId}</b>."
                      values={{
                        orderId: orderId,
                        b: (chunks: React.ReactElement) => (
                          <strong className="extrabold">{chunks}</strong>
                        )
                      }}
                    />{' '}
                    <FormattedMessage
                      id="CheckoutSuccess-li1-OrderConfSentTo"
                      defaultMessage="We will send an order confirmation to {email}. Note: the e-mail could end up in your spam box."
                      values={{ email: localorder?.email }}
                    />
                  </span>
                </li>
                <li>
                  <strong className="extrabold">
                    <FormattedMessage
                      id="CheckoutSuccess-Isbn-li2-IsbnReady"
                      defaultMessage="Your ISBN is ready to go"
                    />
                  </strong>
                  <br />
                  <span className="font-content-subtext">
                    <FormattedMessage
                      id="CheckoutSuccess-Isbn-li2-IsbnReadyInStep"
                      defaultMessage="You can find the ISBN in the step ISBN of your book. We automatically add the ISBN to the back of your cover."
                    />
                  </span>
                </li>
              </NumberedList>
            </BlockContent>
            <BlockContent blockColor="darkgrey">
              {isbnBookId && (
                <div style={{ textAlign: 'center' }}>
                  <strong className="extrabold">
                    <FormattedMessage
                      id="CheckoutSuccess-Isbn-ContinueWithBook"
                      defaultMessage="Continue with your book"
                    />
                  </strong>
                  <br />
                  <a href={`/site/book/index/id/${isbnBookId}`}>
                    <FormattedMessage
                      id="CheckoutSuccess-Isbn-ContinueWithBookClickHere"
                      defaultMessage="Click here to continue working on your book"
                    />
                  </a>
                </div>
              )}
            </BlockContent>
          </div>
        )}
        {buscardsOrdered && (
          <div className="app-grid-flex-1w">
            <BlockTitle
              text={intl.formatMessage({
                id: 'CheckoutSuccess-title-Buscards',
                defaultMessage: 'Business cards'
              })}
            />
            <BlockContent>
              <NumberedList>
                <li>
                  <strong className="extrabold">
                    <FormattedMessage
                      id="CheckoutSuccess-li1-OrderConfEmail"
                      defaultMessage="You will receive confirmation via e-mail"
                    />
                  </strong>
                  <br />
                  <span className="font-content-subtext">
                    <FormattedMessage
                      id="CheckoutSuccess-li1-OrderNumberIs"
                      defaultMessage="Your order number is: <b>{orderId}</b>."
                      values={{
                        orderId: orderId,
                        b: (chunks: React.ReactElement) => (
                          <strong className="extrabold">{chunks}</strong>
                        )
                      }}
                    />{' '}
                    <FormattedMessage
                      id="CheckoutSuccess-li1-OrderConfSentTo"
                      defaultMessage="We will send an order confirmation to {email}. Note: the e-mail could end up in your spam box."
                      values={{ email: localorder?.email }}
                    />
                  </span>
                </li>
                <li>
                  <strong className="extrabold">
                    <FormattedMessage
                      id="CheckoutSuccess-Buscards-li2-WeMakeCards"
                      defaultMessage="We are making your business cards"
                    />
                  </strong>
                  <br />
                  <span className="font-content-subtext">
                    <FormattedMessage
                      id="CheckoutSuccess-Buscards-li2-WePrintRightAway"
                      defaultMessage="We are printing your business cards right away. It will take approximately one week to deliver them."
                    />
                    <br />
                    <FormattedMessage
                      id="CheckoutSuccess-Buscards-li2-NoShipmentMail"
                      defaultMessage="Note: you will not receive a separate shipping confirmation for the business cards."
                    />
                  </span>
                </li>
              </NumberedList>
            </BlockContent>
          </div>
        )}
      </Block>
    </>
  );
}
