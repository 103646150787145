import { IsoEUCountryCodes } from './addressSupport';

function isEUCountry(country?: string): boolean {
  const euCountries: string[] = IsoEUCountryCodes;

  if (country === undefined) {
    return false;
  }
  if (euCountries.includes(country)) {
    return true;
  }
  return false;
}

export default isEUCountry;
