import React from 'react';

function AboutAuthor(props) {
	return(
		<div className="cw-aboutauthor-container">
			<h2>{props.translations.titleAboutme}</h2>
			<p>{props.biography}</p>
		</div>
	);
}
export default AboutAuthor;