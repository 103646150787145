import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import Keycloak from 'keycloak-js';
import KeycloakApiCall from '../helper/KeycloakApiCall';
import { AppDispatch } from '../store';

type twoFASliceState = { isLoading: boolean; isLoaded?: boolean; enabled?: boolean; error?: string };

const initialState: twoFASliceState = { isLoading: false, isLoaded: false };

export const twoFASlice = createSlice({
  name: 'twoFA',
  initialState,
  reducers: {
    loadRequest: (state, action: PayloadAction<undefined>) => {
      state.isLoading = true;
      state.isLoaded = false;
      state.error = undefined;
      state.enabled = undefined;
    },
    loadSuccess: (state, action: PayloadAction<boolean>) => {
      state.isLoading = false;
      state.isLoaded = true;
      state.enabled = action.payload;
    },
    loadError: (state, action: PayloadAction<{ error: string }>) => {
      state.error = action.payload.error;
      state.enabled = undefined;
      state.isLoading = false;
    }
  }
});

export const { loadRequest, loadSuccess, loadError } = twoFASlice.actions;

export const getTwoFAStatus = (keycloak: Keycloak, initialized: boolean) => async (
  dispatch: AppDispatch
) => {
  dispatch(loadRequest());

  const api = new KeycloakApiCall(keycloak, initialized);
  api
    .getData('/isOtpEnabled')
    .then((response: AxiosResponse) => {
      console.log(response);
      dispatch(loadSuccess(response.data));
    })
    .catch((error: AxiosError) => {
      dispatch(loadError({ error: error.message }));
    });
};

export default twoFASlice.reducer;
