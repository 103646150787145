import { configureStore } from '@reduxjs/toolkit';
import loggerMiddleware from './middleware/logger';
import reducer from './rootReducer';
import { apiSlice } from './api/apiSlice';
import { listenerMiddleware } from './middleware/listenerMiddleware';

export default function configureAppStore(preloadedState: any | undefined) {
  const store = configureStore({
    reducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().prepend(listenerMiddleware.middleware).concat(apiSlice.middleware)
//    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(loggerMiddleware)
  });

  return store;
}

const preloadedState = window.__PRELOADED_STATE__;
delete window.__PRELOADED_STATE__;
export const store = configureAppStore(preloadedState);
//export const storeDispatch = store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
