const messages = {
  'Promotion': 'Promotion',
  'binding-1': 'Hardcover',
  'binding-3': 'Paperback',
  'binding-6': 'E-book',
  'binding-7': 'PDF',
  'interiorcolor-bw': 'Black/white',
  'interiorcolor-fc': 'Colour',
  'interiorcolor-short-bw': 'B/W',
  'interiorcolor-short-fc': 'Color',
  'Interior color': 'Colour interior',
  'Papertype-short': 'Paper',
  'Papertype-short-1': 'Cream',
  'Papertype-short-2': 'Cream',
  'Papertype-short-3': 'White',
  'Papertype-short-4': 'White',
  'Papertype-short-5': 'Semi-gloss',
  'Papertype-short-6': 'Semi-gloss',
  'Papertype-short-7': 'White',
  'Papertype-short-8': 'White glossy',
  'Papertype-short-9': 'Tinted',
  'Papertype-short-10': '150 grams',
  'Papertype-short-12': 'White',
  'Amountpages-short': 'Pages',
  'Amountcolorpages-short': 'Color pages',
  'Help-howdoesitwork-question': 'How does it work?',
  'ISBN': 'ISBN',
  'Author website': 'Author website',
  'Author webshop': 'Author webshop',
  'authorwebsite-notEnabled': 'Not enabled - {link}',
  'authorwebsite-notEnabled-enablenow': 'Enable now',
  'Link to book page': 'Link to book page',
  'button-downloadcover': 'Download cover',
  'button-downloadexcerpt': 'Download excerpt',
  'button-copybooklink': 'Copy book link',
  'button-copyannouncementtext': 'Copy announcement',
  'button-openinstagram': 'Open Instagram App',
  'button-addtoshoppingcart': 'Add to shopping cart',
  'button-orderbooks': 'Order books',
  'button-registerbook': 'Register book',
  'Channels-availability': 'Availability',
  'Channelname': 'Store',
  'Price': 'Price',
  'Your margin': 'Your margin',
  'Bookspec-ISBN-isbn': 'ISBN: {isbn}',
  'Bookspec-LastEdited-date': 'Last edited: {date}',

  'What do you have to do?': 'What do you have to do?',
  
  // form labels
  'label-announcementtext': 'Announcement',
  'label-instagram-addbooklinktobio': 'Add the link to your book to your Instagram bio',
  'label-instagram-downloadcover': 'Download your cover image for your post',
  'label-instagram-openinstagram': 'Open Instagram',
  'label-instagram-chooseanimage': 'Choose an image',
  'label-attachbooklinktomessage': 'Automatically add the link to your book to the message',

  // zod validation errors
  'ZodValidationError-EmailRequired': 'Email address is required',
  'ZodValidationError-EmailNotValidEmail': 'Please enter a valid email address',
  'ZodValidationError-AddressFnRequired': 'First name is required',
  'ZodValidationError-AddressFnLongerThan30': 'First name cannot be longer than 30 characters',
  'ZodValidationError-AddressLnRequired': 'Last name is required',
  'ZodValidationError-AddressLnLongerThan30': 'Last name cannot be longer than 30 characters',
  'ZodValidationError-AddressSRequired': 'Street and building number is required',
  'ZodValidationError-AddressSLongerThan50': 'Street and building number cannot be longer than 50 characters',
  'ZodValidationError-AddressS2LongerThan50': 'Second address line cannot be longer than 50 characters',
  'ZodValidationError-AddressNrMustBeNumber': 'Building number must be a number',
  'ZodValidationError-AddressPRequired': 'Postal code is required',
  'ZodValidationError-AddressPLongerThan20': 'Postal code cannot be longer than 20 characters',
  'ZodValidationError-AddressCRequired': 'City is required',
  'ZodValidationError-AddressCLongerThan35': 'City cannot be longer than 35 characters',
  'ZodValidationError-AddressRLongerThan35': 'Region cannot be longer than 35 characters',
  'ZodValidationError-AdressLRequired': 'Country is required',
  'ZodValidationError-AddressCountryNotTwoLetterCode': 'Country must be an ISO 2 letter code',
  'ZodValidationError-AddressCpLongerThan35': 'Company name cannot be longer than 35 characters',
  'ZodValidationError-AddressTLongerThan20': 'Phone number cannot be longer than 20 characters',
  'ZodValidationError-AddressFullNameLongerThan40': 'Full name cannot be longer than 40 characters',
  'ZodValidationError-AddressPostalCodeIncorrect': 'Postal code is incorrect',
  'ZodValidationError-AddressS2SameAsS': 'Second address line cannot be equal to the previous line',
  'ZodValidationError-AddressVRequiredForCanarias': 'Tax number is required for the Canary Islands',

  // guide popup
  'wikiguidepopup-title-selectaguide': 'Select a guide',

  // errors
  'error-booknotpublished': 'You can’t promote this book, because it’s not published. Go to ‘my books’ to edit this book.',
  'error-booknotavailable': 'The book you’re trying to open doesn’t exist. Go to ‘my books’ and select a book.',
  'error-booknotauthorized': 'You are not authorized to access this page. If this message is incorrect, please contact support.',
  'error-bookunknownerror': 'An unexpected problem occurred while loading this page. Please refresh the page to try again. If the problem persists, please contact support and mention book id: {bookid}',

  // promo popup
  'promoapp-publishedpopup-title': 'Congrats, you’re a published author!',
  'promoapp-publishedpopup-paragraph1': 'You did it! Your book is now published, so take a moment or ten to celebrate! Tell your friends, treat yourself, and bask in your accomplishment for a while.',
  'promoapp-publishedpopup-paragraph2': 'Now that your book is for sale, it’s time for the next step on your author’s journey: promoting your book. Naturally, we’re here to help you with that. Our promotion steps will walk you through some of the most effective ways to spread the word about your book.',
  'promoapp-publishedpopup-startbutton': 'Start promoting your book',

  // promo categories
  'promoapp-havetodo-title': 'Actions you need to take',
  'promoapp-shoulddo-title': 'Actions we recommend that you take',
  'promoapp-coulddo-title': 'Actions that’ll give you a helpful boost',
  'blocktitle-thingsyouneedtopromote': 'Things you’ll need to promote your book',

  // promo overview
  'promoapp-overview-intro-p1': 'Writing a book is a great achievement in itself, but chances are you also want to sell it to people. In order to do that, you’re going to need to promote it. For many authors, this is where the true challenge begins. It requires quite a bit of work and dedication, but you’ll start to see the results quicker than you might think. To help get you started, we’ve compiled a selection of tips, guides, and tools to make the promotion of your book easier and more effective.',

  // list of all promotool descriptions
  'promotool-authorwebsite': 'Author webshop',
  'promotool-description-authorwebsite': 'The most profitable way to sell your books',
  'promotool-whatsapp': 'WhatsApp',
  'promotool-description-whatsapp': 'Sell your book directly to your friends and have them spread the word',
  'promotool-socialmedia': 'Social media',
  'promotool-description-socialmedia': 'Set up your social media pages to drive sales and gain loyal readers',
  'promotool-instagram': 'Instagram',
  'promotool-description-instagram': 'Promote your book using stunning visuals',
  'promotool-facebook': 'Facebook',
  'promotool-description-facebook': 'Connect with readers, drive sales by joining groups and posting interesting content',
  'promotool-twitter': 'Twitter',
  'promotool-description-twitter': 'Use your way with words to promote and sell your books',
  'promotool-linkedin': 'LinkedIn',
  'promotool-description-linkedin': 'Document your author journey to inspire your professional network',
  'promotool-libraries': 'Libraries',
  'promotool-description-libraries': 'Cultivate a larger readership by getting your book into libraries',
  'promotool-nbdbiblion': 'NBD Biblion',
  'promotool-description-nbdbiblion': 'Get NBD to review your book and get it in the libraries',
  'promotool-nationallibrary': 'National library',
  'promotool-description-nationallibrary': 'Immortalize your work by depositing a copy of your book',
  'promotool-launchparty': 'Book launch party',
  'promotool-description-launchparty': 'No publication is complete without a book launch party!',
  'promotool-bookstores': 'Bookstores',
  'promotool-description-bookstores': 'Get your book stocked in local shops to stimulate sales',
  'promotool-pressrelease': 'Press release',
  'promotool-description-pressrelease': 'Getting press coverage will work wonders for your sales',
  'promotool-businesscards': 'Business cards',
  'promotool-description-businesscards': 'Make an impression with your own custom-made business cards',

  // launch party
  'launchparty-intro-p1': 'A book launch is an integral step in the process of promoting your book. Not only is it a great way to draw attention to the fact that your book is published, but it’s also a really nice way to mark the occasion and celebrate a great achievement with friends and family. You can make your book launch event as big or small as you like; whatever aligns with the goals you’ve set for yourself. We’ve made a guide for both a small, informal book launch and a bigger, more formal launch for you to consult below - see which type matches your personal ambitions the best.',
  'launchparty-bookdrinks-title': 'Informal book launch',
  'launchparty-bookdrinks-intro': 'If you want to keep things simple and start small, you can make your book launch event a more intimate event than is traditional. It doesn’t have to be a big thing at a rented location with guest speakers and the local press attending. Rather, you can simply have friends, family, and acquaintances come over to join you in celebrating the milestone you’ve reached. Think of it as a party for you and your book; it’s an easy and relatively inexpensive way to draw attention to you being a published author and selling your first books. Here are a few pointers if you’re planning on throwing a book launch party.',
  'launchparty-bookdrinks-whenandwho-title': 'Who to invite and when?',
  'launchparty-bookdrinks-whenandwho-paragraph1': 'Make a list of all the people you want to invite. Your friends, family, neighbours, and people who’ve contributed to your book in some way are always good choices here. Set a date, create some sort of group chat and send out invitations! Make sure your messages are upbeat and welcoming, and be sure to include a picture of your book!',
  'launchparty-bookdrinks-orderbooks-title': 'Order books',
  'launchparty-bookdrinks-orderbooks-paragraph1': 'When you’ve sent out invitations in the chat, you’ll probably have a pretty good idea of how many people are coming pretty soon. Assume that every guest will want to buy a copy or two, and order enough books ahead of time, so you don’t need to worry about them being delivered in time for your book launch.',
  'launchparty-bookdrinks-agenda-title': 'Program',
  'launchparty-bookdrinks-agenda-paragraph1': 'While the idea is to make this an informal, spontaneous event, it never hurts to prepare a little in order to make it a memorable experience and get your guests in the mood to buy your book. Ask yourself what you want to say, and whether you want to propose a toast. Do you want to present the first copy of your book to someone special as a gift? Perhaps you’d like to tell everyone about your experience self-publishing a book? Make a short list of points you’d like to cover at the event.',
  'launchparty-bookdrinks-foodandhouse-title': 'Refreshments',
  'launchparty-bookdrinks-foodandhouse-paragraph1': 'Make sure to provide plenty of snacks and drinks for your guests; it’s important that people can enjoy themselves and feel comfortable. Remember to include alcohol-free beverages and keep potential dietary restrictions in mind. Also, make sure your home is spotless, and arrange your books in a prominent position; they should always be visible. It’s also a good idea to have someone taking pictures, as they’ll be greatly appreciated by guests and make great promotional material for your book after the event.',
  'launchparty-bookdrinks-enjoy-title': 'Enjoy!',
  'launchparty-bookdrinks-enjoy-paragraph1': 'Last but absolutely not least, make sure to enjoy your book launch party! People are here to celebrate a great achievement with you, so bask in the attention a little! You’ll need to determine beforehand whether you want to give each guest your book as a gift, or ask them to buy their copies themselves. While the latter option may sound a bit odd, it is an absolutely normal request, and your guests will understand. The people attending are close to you and will want to support you after all!',
  'launchparty-bookpresentation-title': 'Book launch',
  'launchparty-bookpresentation-intro': 'A formal book launch is simply a more ambitious way to go about hosting a book launch party. While you can still host it at home, you might want to consider renting a locale instead; something like a café or a bookshop is always a good choice. Furthermore, you’re going to want to invite the local press and perhaps even social media influencers who are active in your area. This raises the stakes a bit, so you’ll want to plan ahead and have a solid program in place. Here are some things you should think about when planning your book launch event.',
  'launchparty-guestlist-title': 'Determine who to invite',
  'launchparty-guestlist-paragraph1': 'A good place to start is writing a guest list. Obvious choices are friends, family, anyone who has contributed to your book in some way, and perhaps colleagues or other people in your professional network. You can usually count on people being excited to celebrate this milestone with you, so don’t feel shy about sending out invitations! The reason it’s a good idea to start with a guest list is that it makes determining a suitable location to host the event that much easier.',
  'launchparty-findalocation-title': 'Find a location and set a date',
  'launchparty-findalocation-paragraph1': 'There are loads of locations well-suited to a book launch event. You can choose to host it in the comfort of your own home, in your favourite café, or at a local bookshop. The latter is our personal favourite, as we find that being surrounded by books really enhances the atmosphere of a book launch event. Try securing your location cheaply (or for free) and involve the proprietor in your planning so that they know what to expect.',
  'launchparty-sendinvites-title': 'Send out the invitations',
  'launchparty-sendinvites-paragraph1': 'While a physical invitation isn’t necessarily the norm these days, that in itself could be a reason to try it out. It can lend the event a certain legitimacy, and it allows you to do fun stuff like make invitations with your book cover on them. Of course, you can also choose to send out invitations via email or text. The advantage of this is that it’s free and saves you time. Don’t forget to ask for RSVPs; you’ll need to know how many people are coming.',
  'launchparty-createagenda-title': 'Program',
  'launchparty-createagenda-paragraph1': 'You ought to plan the main events of the book launch ahead of time. We’re big proponents of making your book launch event your own, but there are certain staples that we recommend you include:',
  'launchparty-createagenda-list1': 'Start off with a welcome drink and let guests mingle until everyone has arrived. Some music would be a good addition here as well.',
  'launchparty-createagenda-list2': 'Once all the guests have arrived, have a friend or other recognizable person welcome everyone.',
  'launchparty-createagenda-list3': 'A Q&A session where your guests can ask you about things like why you chose to write a book, what it’s about, where you got your inspiration from and whether you have any fun anecdotes about the process.',
  'launchparty-createagenda-list4': 'Reading a passage from your book out loud to your guests',
  'launchparty-createagenda-list5': 'Sign the books that people buy',
  'launchparty-createagenda-list6': 'Snacks and socialising',
  'launchparty-contactmedia-title': 'Media',
  'launchparty-contactmedia-paragraph1': 'We strongly recommend that you try to get in touch with local media outlets or perhaps even relevant social media influencers based in your area. Ask them if they’d be interested to cover your book launch, and invite them to the event. You’d be surprised at how often this works, so don’t be shy to give it a go!',
  'launchparty-refreshments-title': 'Arrange snacks and drinks',
  'launchparty-refreshments-paragraph1': 'Our advice here is to keep it simple. Go for crowd pleasers and keep the selection limited. Make sure to get the ok from the owner of your locale beforehand. If you’re hosting in a place that doesn’t normally serve food and drinks, they might have rules about that sort of thing, and if you’re hosting in a place that does, they will want to serve their own food and drinks.',
  'launchparty-orderbooks-title': 'Books',
  'launchparty-orderbooks-paragraph1': 'Make sure that you have enough copies of your book at hand. Your guests can then buy a copy during the event, and you can sign it for them on the spot to make the experience more personal. Keep in mind that if you’re hosting your book launch at a bookshop, the owner will want a cut of the book sales. Make sure to negotiate the specifics of this beforehand.',
  'launchparty-photos-title': 'Photos and videos',
  'launchparty-photos-paragraph1': 'Not only is your book launch event a good way to raise awareness and generate some sales, but it can also create future marketing material for you. That’s why it’s important that you arrange for someone to take pictures and/or record the event. This doesn’t have to be a professional photographer; you probably have a friend who’s decent with a camera. You can then select some of the material to post on social media to promote and legitimise your book.',
  'launchparty-followup-title': 'Follow-up',
  'launchparty-followup-paragraph1': 'Make sure to thank your guests for coming, a couple of days after the event at the latest. Let them know you appreciate their support, and share the photos and videos from the event. You can also share these with people that didn’t make it, and even include a link to where they can buy your book. If you manage to get any local media outlets or influencers to attend or cover the event, be sure to spread that around as well, and interact with them on social media afterwards.',

  // bookstores
  'bookstores-intro-p1': 'Brick-and-mortar bookshops are still a powerful sales channel for books, despite the ever-increasing dominance of online shopping. However, as they’ve been on the decline for a while, bookshops tend to be wary of stocking books by unknown authors, as it’s usually considered to be an unnecessary risk. Nevertheless, we do recommend getting in touch with local bookshops to inquire whether they’d be willing to stock your book. Having your book available in a physical shop still results in a hefty legitimacy boost, and makes for a great marketing opportunity.',
  'bookstores-intro-p2': 'If you do manage to get a bookshop to stock your book, they will want a cut of the profits if and when your book gets sold. This will generally be around 20 – 40 percent. Furthermore, they will most likely only agree to stock your book risk-free, meaning they’ll reserve the right to return any unsold copies to you. We’ve created a checklist which will give you an overview of things to keep in mind when approaching and negotiating with bookstores.',
  'bookstores-checklist-title': 'Bookshop checklist',
  'bookstores-selectbookstores-title': 'Choose the right bookshops',
  'bookstores-selectbookstores-p1': 'The first step is to determine which bookshops you want to approach. We recommend doing some research on what’s available near you, making a list, and approaching them one at a time.',
  'bookstores-contactbookstore-title': 'Contact the bookshops',
  'bookstores-contactbookstore-p1': 'Once you’ve determined which bookshops you’d like to sell your book, it’s time to contact them and set up an introductory meeting.',
  'bookstores-contactbookstore-p2': 'Here are our tips for your first meeting:',
  'bookstores-contactbookstore-list1': 'Tell them a bit about yourself',
  'bookstores-contactbookstore-list2': 'Tell them about your book: what it’s about, why you wrote it, and what your target audience is',
  'bookstores-contactbookstore-list3': 'Bring a copy of your book and leave it with them. The proprietor will undoubtedly want to examine the book before stocking it',
  'bookstores-agreement-title': 'Draw up some agreements with the bookshop',
  'bookstores-agreement-p1': 'If the bookshop is prepared to stock your book, you ought to have some agreements in place with regards to several things:',
  'bookstores-agreement-whobuysbooks': 'Who is paying for the books?',
  'bookstores-agreement-buy-option1': 'Option 1: You pay for the books and the bookshop sells them on your behalf. This is going to be the most attractive option for the bookshop, as they won’t be running any financial risk. You can buy the book using the integrated feature on our platform and agree on a profit margin with the bookshop. Don’t forget to agree on when you’re going to get paid and how you’re going to be informed of sales.',
  'bookstores-agreement-buy-option2': 'Option 2: The bookshop buys the books from you. This is the easiest solution; you can both automatically collect your respective profit margins. If you go for this option, make sure you’ve set the reach to “National” in the pricing step on the platform.',
  'bookstores-agreement-howlongavailable': 'How long will the book be available for sale in the bookshop?',
  'bookstores-agreement-positioninstore': 'Where will the book be placed/displayed?',
  'bookstores-beactive-title': 'Be active',
  'bookstores-beactive-p1': 'Once your book is available in the shop, it’s imperative that you inform people about it! Here are a few ways to do this:',
  'bookstores-beactive-list1': 'A festive way to do it would be to host your book launch party in the bookshop itself. See our guide on book launches for more info.',
  'bookstores-beactive-list2': 'Announce the fact that your book is available in this particular shop on social media and/or your website.',
  'bookstores-beactive-list3': 'Make appearances at the bookshop and announce these to your readers in order to generate attention and stimulate sales.',

  // press release
  'pressrelease-intro-p1': 'A press release is an official statement from you in your capacity as an author to media outlets. In the publishing world, press releases are mostly used to announce the publication of a book. As a self-published author, you probably won’t have the contacts that a major publishing house has. Don’t let this stop you though. You can still reach out to local media outlets; local papers and news sites are always on the lookout for content, and you’d be surprised how interested they can be in an up-and-coming author.',
  'pressrelease-intro-p2': 'Our platform generates an automatic press release for you based on the information you fill in during the various publication steps. Relevant information such as the cover, blurb, ISBN, price and link to the store is included.',
  'pressrelease-intro-p3': 'If you want to maximise the chance for a review, send a copy of your book along with the press release.',
  'pressrelease-identifytarget-title' : 'Review your target audience',
  'pressrelease-identifytarget-p1': 'It’s important that you keep your target audience in mind when selecting recipients for your press release. It wouldn’t make sense to send a press release to a media outlet with a completely different target audience than yourself.',
  'pressrelease-identifytarget-p2': 'While we’ve been talking about local media up until this point, you shouldn’t limit yourself unnecessarily. You might also consider looking up websites that cover the same topics that you do in your book. The more specific, the better.',
  'pressrelease-collectcontact-title': 'Gather contact details',
  'pressrelease-collectcontact-p1': 'Time for the most boring part of the process; making a structured list of all the people you need to contact. A good way to go about it is making a spreadsheet containing the names and addresses of promising outlets, along with the name, email and phone number of a contact at said outlet. Try to get the contact details of specific people whenever possible, rather than generic emails and company phone numbers. Depending on how big your list is, you might also want to add columns that allow you to keep track of who you’ve contacted and what their response was.',
  'pressrelease-sendpressrelease-title': 'Send the press release',
  'pressrelease-sendpressrelease-p1': 'When sending the press release via email, make sure to keep the text short, persuasive and to the point. Don’t reuse text from the press release, but try to make it interesting enough that your contact will be tempted to learn more. The primary goal is to make the contact open the press release. Make sure to send the press release as a PDF. You can also attach an excerpt from your book as a PDF.',
  'pressrelease-sendpressrelease-p2': 'For high priority outlets, you might want to consider sending a print copy of your book. It’s a relatively small investment that can make a huge difference. Lastly, be sure to indicate that you’re available for interviews in your email.',
  'pressrelease-downloadbutton': 'Download press release',
  'pressrelease-followup-title': 'Follow-up',
  'pressrelease-followup-p1': 'Don’t expect people to publish your press release or write something about your book automatically. Always follow-up to check if they have done or are planning to do something with your press release. Consider resending your press release after a few weeks if you don’t get a reply.',
  'pressrelease-sharelinks-title': 'Share the resulting media links',
  'pressrelease-sharelinks-p1': 'Every time your book is mentioned in the media you need to share this on social media. It’s free promotion for your book, and the more people see it, the higher the chance that someone will buy your book.',

  // NBD Biblion
  'nbdbiblion-copyandpastetext': 'Click here to copy the text above',

  // Business cards
  'businesscards-intro-p1': 'Promotional materials are a great way to complement your marketing strategy. It can add a tangible, personal touch that a lot of people can appreciate in the digital age. That’s why we provide you with the option to print your own business cards, on which you can place a picture of your book along with info on where to buy it. The business cards are printed in full colour on sturdy 400 gram cardboard. Your order will be delivered to you within two weeks.',
  'businesscards-blocktitle-order': 'Order business cards',
  'businesscards-preview-label-front': 'Front',
  'businesscards-preview-label-back': 'Back',
  'businesscards-numberofcards-label': 'Number of business cards',
  'businesscards-selectnumberofcards-item-label': '{number} cards - {price}',

  // whatsapp
  'whatsapp-intro-p1': 'WhatsApp, or whichever messaging app you’re using, is probably where you’re going to get your first sales. The people you reach via Whatsapp make up your own personal network, and will therefore be the ones most likely to support you as an author. Be sure to involve them in the book creation process before you spring the news of the publication and ask them for the ultimate favour of buying your book though! You could, for example, show them your cover ideas and ask for their opinions, or brainstorm the title with them. You could even send them the blurb ahead of time to get them excited.',
  'whatsapp-intro-p2': 'It might not seem like a big deal if your friends and family buy your book, but they could potentially be your biggest ambassadors. Word of mouth is king, so don’t neglect this promotion method!',
  'whatsapp-promoteviawhatsapp': 'Promote your book via WhatsApp',
  'whatsapp-button-sendviawhatsapp': 'Send via WhatsApp',
  'whatsapp-week1-title': 'Week 1 - Announcement',
  'whatsapp-week1-message': 'Hey everyone, just wanted to let you know that the book I’ve been blabbering about for so long is now finally published! I’m super excited about it and would really appreciate you spreading the word if you know anyone who might be interested in it!\n\nIf you’re interested in it yourself, or if you need a present for a book-lover, you can get your hands on a copy here:\n\n',
  'whatsapp-week2-title': 'Week 2 - (informal) book launch party',
  'whatsapp-week2-message': 'Hey again, and thanks to everyone who has already bought my book, you guys are fantastic!\n\nI just wanted to let you all know that I’m throwing a book launch party! You are of course all invited. There’ll be snacks, drinks, a short reading of an excerpt by yours truly! I’ll even sign your books for you if you like ;).\n\nIt’ll be super fun and I’d be honoured if you came!',
  'whatsapp-week3-title': 'Week 3 - Thanks for coming!',
  'whatsapp-week3-message': 'Hey everyone! Thanks to everyone who came to my book launch party, it was amazing to see you all there! Here are some great pics I took during the event.\n\nI’d also like to thank everyone who bought a copy, it really means a lot to me! If you couldn’t make it and still want to get your hands on a copy of my book, you can do that right here:',
  'whatsapp-week4-title': 'Week 4 - Review request',
  'whatsapp-week4-message': 'Hey!\n\nIf I could ask for a tiny favour, it would be a massive help to me if you could write a short review of the book on Goodreads? It would do wonders for the visibility of the book online and help me reach more people. I’d be more than happy to pay you back with a coffee one of these days!',
  'whatsapp-week4-goodreadswarning': 'Note: before you send this message, please make sure you’ve already added your book to Goodreads!',
  'whatsapp-tips-title': 'Tips',
  'whatsapp-tips-groups-label': 'Create dedicated Whatsapp groups',
  'whatsapp-tips-groups': 'Chances are that you have a varied list of contacts on Whatsapp. It can be a good idea to create several groups and send specially crafted messages to each of these groups that match your usual level of familiarity.',
  'whatsapp-tips-readtheroom-label': 'Read the room',
  'whatsapp-tips-readtheroom': 'The people you’re talking to on Whatsapp are likely the people you’re closest to, which means they’re probably going to be your most loyal readers and customers. Consider nurturing that by not sending constant reminders to buy your book, but rather thank the people who do buy it. This is usually a more effective way to motivate anyone lagging behind.',
  'whatsapp-tips-askspread-label': 'Ask people to spread the word',
  'whatsapp-tips-askspread': 'Word of mouth is one of the most powerful ways to raise awareness of your book. It’s also one of the hardest. Therefore, take advantage of your Whatsapp groups and have friends and family spread the word within their own social circles.',
  'whatsapp-tips-participation-label': 'Create a sense of participation',
  'whatsapp-tips-participation': 'People on Whatsapp are likely going to be your closest and most loyal readers and customers. Nurture this by letting them know how much you appreciate them buying your book and spreading the word. Invite them to your book launch party and other book-related events, share pictures of these events in the group. Remind them of how important they are to your success.',

  // social media
  'socialmedia-intro-p1': 'As you’re undoubtedly aware, social media is one of the most important promotional tools available today. As a self-published author, being able to communicate directly with readers interested in the particular niche that you’re writing in is invaluable. We’ve compiled some tips and tools for several social media channels below, with more to come.',
  'socialmedia-tips-title': 'Tips',
  'socialmedia-tips-beconsistent-label': 'Be consistent',
  'socialmedia-tips-beconsistent': 'As a writer you have a unique voice, a particular way in which you share your thoughts, and this should saturate your communication on social media as well. It’s important to share your thoughts actively and eloquently, and project your voice consistently in a way your audience will recognize.',
  'socialmedia-tips-beshareable-label': 'Be shareable',
  'socialmedia-tips-beshareable': 'Even though you should develop posting habits on social media and do your own thing, it’s also important to create content that can be easily shared by your followers. The more shares your posts get, the more attention your account will get.',
  'socialmedia-tips-berelatable-label': 'Be relatable',
  'socialmedia-tips-berelatable': 'Naturally, you’re going to want to share content relating to your book often. Nevertheless, it’s also a good idea to branch out. Try giving people a peek into your personal life as well, and share relatable moments. This makes you more than just a salesperson. You should also engage with other people’s content; like, comment, and share.',
  'socialmedia-tips-hashtaglover-label': 'Be a hashtag-lover',
  'socialmedia-tips-hashtaglover': 'Hashtags are still extremely important. Don’t use generic hashtags; make the effort to find and implement some that will help you find the right people, and vice versa. Do some research on the top hashtags in e.g. the writing community before posting. Of course you shouldn’t hashtag everything. Just focus on the content that you really want to broadcast. Not every post needs a hashtag.',
  'socialmedia-tips-beflexible-label': 'Be flexible',
  'socialmedia-tips-beflexible': 'Every social media platform is different. Instagram is all about images. LinkedIn is all about networking. Facebook is all about sharing. Twitter is all about words. It is important that your content is not copy-pasted; you have to adapt to the style and norms of each platform. Your voice should remain the same, but the way you project it should be flexible.',
  'socialmedia-tips-beanalytical-label': 'Be analytical',
  'socialmedia-tips-beanalytical': 'Every social media platform provides analytics, make use of that! You should pay attention to what kind of audience you’re reaching, the best times to post, and the liking and following trends. Your social media platforms will provide you with that information as soon as you change your profile to a business account.',

  // Instagram
  'instagram-intro-p1': 'Instagram is practically a must for any author these days. The Instagram book scene, bookstagram, is a huge community full of potential readers. Building a strong Instagram presence can be rather difficult, as it requires you to produce high-quality visual content on a regular basis.',
  'instagram-intro-p2': 'There are three main things you should prioritize when on Instagram; building an aesthetically pleasing feed, using relevant and varied hashtags (we’ve added some to the templates, but feel free to change them or add more), and posting stories.',
  'instagram-promoteoninstagram': 'Promote your book on Instagram',
  'instagram-switchtophone': 'Switch to your phone',
  'instagram-switchtophone-description': 'You’ll have to use your phone to post Instagram content. You can scan the QR code below to go to the mobile version of this page. Most smartphones will scan QR codes automatically if you open the native camera app and point it at the code. If you can’t scan the QR code, you can also log in to the platform on your phone.',
  'instagram-setupinsta-title': 'Set up instagram',
  'instagram-week1-title': 'Week 1 - Announcement',
  'instagram-week1-message': 'Here it is at last: the first printed copy of my new book, {title} - this is such a huge milestone for me and I couldn’t be more excited to share it with you! If you want to support me, the book is available now for {price}, link in the bio!\n\n{keywords} #selfpub #bookworms #published #booklaunch',
  'instagram-week2-title': 'Week 2 - First sales post',
  'instagram-week2-chooseanimage-description': 'When creating the visual for your first sales post, it’s important to make it personal; that’s what your Instagram followers want to see. Try to include your face, your book, and preferably both. For example, you could post a photo of you standing in front of a bookstore, holding a copy of your book.',
  'instagram-week2-message': 'I’m really excited to have sold my first 5 books! A huge thank you to everyone who bought a copy; I can’t tell you how much I appreciate your support! I hope you’ll all forgive me for the shameless self-promotion I’m about to do, but if you think the idea of reading a book that I’ve written with my own two hands sounds even remotely interesting, consider heading over to my webshop and checking it out. You can read the blurb, admire the cover, and maybe even get your own copy? The link is in the bio!\n\n#selfpublishing #writerslife',
  'instagram-week3-title': 'Week 3 - Giveaway post',
  'instagram-week3-chooseanimage-description': 'When creating the visual for your giveaway, it makes sense to show what exactly it is you’re giving away. In our example below, we’re giving away 5 signed copies, and in that case, you might take a picture of 5 copies of your book, with one laying open to display your signature. Make sure that your lighting is on point, so the books look as good as they possibly can.',
  'instagram-week3-message': 'It’s been a crazy couple of weeks since my book {title} came out, and I’m overwhelmed by the incredibly positive response it’s gotten so far, not to mention the support that I’ve received personally. Thank you all for your kind words!\nIf you haven’t managed to get your hands on the book yet, then what comes next might interest you; I’m going to give away 5 copies to my followers! The books will all be signed, so in case I happen to get really famous, you’ll have something cool on your shelf ;)\n\nAll you have to do is like this post and tag a friend who you think might also be interested in the book! I’ll select 5 comments at random and announce the winners in my stories next week!\n\n#giveaway #bookstagram #indieauthor',
  'instagram-week4-title': 'Week 4 - Review request',
  'instagram-week4-chooseanimage-description': 'When asking for reviews, you’re depending on the goodwill of your followers, so it’s important to connect with them through your visual. A candid photo of yourself holding your book out towards the camera is a simple way to do it. If you want to get quirky you can put a few books in a basket and place a piece of cardboard with the words “Review me” in front of it. You could also opt for making a story when requesting reviews, instead of just a post; this option will likely reach more people.',
  'instagram-week4-message': 'Hey everyone, I’m here to ask you a favour!\n\nIf you’ve read my book, please please please write a short review about it on Goodreads! It would be incredibly helpful to boost its visibility online and reach more people. Even if you don’t have time to write an actual review, just a rating would still be great. I’d really appreciate it.\n\nAlso, thank you so much to everyone who has bought my book, you have no idea how much it means to me!\n\n#review #selfpub #bookstragram #indieauthor',
  'instagram-tips-title': 'Tips',
  'instagram-tips-visuals-label': 'All about visuals',
  'instagram-tips-visuals': 'As you’re probably aware, Instagram is all about the visuals. This might seem like a challenge for you as an author trying to promote your book, but it doesn’t have to be a disadvantage. Some cornerstones of your Instagram content should be the cover of your book, behind the scenes photos of your writing process, and some pictures of your day-to-day life as an author.',
  'instagram-tips-quotes-label': 'Quotes',
  'instagram-tips-quotes': 'To stimulate the curiosity of your audience, an often-successful tactic is sharing quotes from your book! Even though Instagram is very visual, editing photos with a phrase on them is always a good way to share your writing.',
  'instagram-tips-interact-label': 'Interact',
  'instagram-tips-interact': 'Your focus should not only be on promotion but also engaging with your audience. The more interaction, the more attention your account will receive. It is essential to share your reader’s posts on your stories, or comment on their posts. Keep the community engaged.',
  'instagram-tips-bookstagram-label': 'Bookstagram',
  'instagram-tips-bookstagram': 'There’s a huge community of book readers on Instagram that all come together under the umbrella term “Bookstagram”. This online community shares photographs of the books they are reading and what they plan to read, and they’re extremely influential. You as an author should reach out and follow these bookstagrammers, with the ultimate aim of having them share your book on their profile. You should aim at accounts with 1k-20k followers to start with, because their engagement is often high and is easier for you to start a conversation. Just remember to create personalized messages!',
  'instagram-tips-addlinks-label': 'Add links',
  'instagram-tips-addlinks': 'It is important to add a link in your bio leading to a webpage where your book is for sale. You can add the link to our store, or your author website, and people on your Instagram can purchase your book with a couple of clicks!',
  'instagram-tips-balance-label': 'Create a balance',
  'instagram-tips-balance': 'Keep in mind that Instagram prioritizes engagement over content sharing. So it is not necessary to post every single day, however it is good to aim towards posting 2-3 days a week. Also, using Instagram Stories to share less edited photos, with more day-to-day content can make engagement grow and get Instagram’s attention. ',
  'instagram-tips-contests-label': 'Run contests/giveaways',
  'instagram-tips-contests': 'There’s nothing that people love more than getting free stuff. Creating a giveaway is the perfect way to draw people’s attention to you and to grow your audience. You can offer a signed copy of your book and ask people to follow and comment in order to participate in the giveaway!',
  'instagram-tips-scheduleposts-label': 'Schedule your posts',
  'instagram-tips-scheduleposts': 'Being an author is hard work, and you’ll often find yourself pressed for time when you need to create and upload new content. A good strategy is to use various online tools to schedule your posts. By doing so, you can create several posts at once and schedule them for the coming weeks. If you create a business account on Facebook, you gain access to Creator Studio, which lets you create and schedule posts on both Facebook and Instagram. This is a good place to start.',

  // facebook
  'facebook-copyandpastetext': 'Click here to copy the text above. Next, paste this text in the status field after clicking the share button.',
  'facebook-button-shareonfb': 'Share on Facebook',
  'facebook-intro-p1': 'The key to making the most out of your marketing efforts on Facebook is maximising your engagement. To this end, try to get as many likes and comments as possible, and find relevant groups to join and be active in.',
  'facebook-promoteviafacebook': 'Promote your book on Facebook',
  'facebook-week1-title': 'Week 1 - Announcement',
  'facebook-week1-message': 'Today’s the day! I am now officially a published author, it’s been a long road and a lot of hard work, but having my book in my hands really makes it all worth it. In case you haven’t been following my progress and are interested in what this book is all about, here’s the blurb:\n\n{shortdesc}\n\nIf you want to learn more, the book is available now at:',
  'facebook-week2-title': 'Week 2 - First sales post',
  'facebook-week2-message': 'I’m really excited to have sold my first 5 books! A massive thank you to everyone who bought a copy, it means the world to me. I hope you’ll all forgive me for the shameless self-promotion I’m about to do, but if you think the idea of reading a book that I’ve written with my own two hands sounds even remotely interesting, consider heading over to my website and checking it out. You can read the blurb, admire the cover, and maybe even get your own copy?',
  'facebook-week3-title': 'Week 3 - Giveaway',
  'facebook-week3-message': 'Being an author has been great so far; everyone has been incredibly supportive and enthusiastic, and I really couldn’t be happier. I never imagined my book would be received this well, and to celebrate, I’m organising a giveaway! So, if you haven’t gotten your hands on a copy yet, like and share this post, and leave a comment describing the plot of your favourite book in as few words as possible. I’ll then pick the five best comments and send the commenters a signed copy of {title}.',
  'facebook-week4-title': 'Week 4 - Review request',
  'facebook-week4-message': 'Thank you so much to everyone who bought my book; you guys are fantastic! I’m back to ask you for another tiny favour though - it would be a massive help to me if you would go to Goodreads and write a review for my book! Even just a rating would be tremendous, and if you aren’t or don’t want to be on Goodreads, a review on any platform or in any store would also be greatly appreciated. Thanks in advance!',
  'facebook-tips-title': 'Tips',
  'facebook-tips-authorpage-label': 'Author’s page',
  'facebook-tips-authorpage': 'Creating pages to develop specific content on Facebook is important. By creating an author’s page, your audience can get to know you better and get the most recent information about your book. ',
  'facebook-tips-engagement-label': 'Engagement',
  'facebook-tips-engagement': 'Facebook’s algorithm is all about engagement. There’s no guarantee that followers and friends will see your posts, so the best way to maximise the number of impressions your content gets is by encouraging engagement. The more likes and comments you have, the higher the chance that Facebook will display your posts to others. If done correctly, you’ll get a snowball effect, where the first few likes and comments stimulate further engagement, making your posts more visible, inviting even more engagement!',
  'facebook-tips-sellyourbook-label': 'Selling your book',
  'facebook-tips-sellyourbook': 'It is important to link directly to the sales channels where your book is available, such as our store or your website for example. It’s important to facilitate your audience’s access both to your book and to information about it and yourself.',
  'facebook-tips-promoteothers-label': 'Promote other books',
  'facebook-tips-promoteothers': 'This advice may seem counterproductive but it is actually a commonly-used strategy. The more you interact with other authors, the more they will help you out in turn. It’s always good to share reviews and promote some books to be a part of an active community.',
  'facebook-tips-sharewriting-label': 'Share your writing',
  'facebook-tips-sharewriting': 'For your audience to get to know you as an author, it is important to share some of your writing, so: create posts including some quotes or passages from your book! Teaser quotes are important to motivate people’s interest in buying your book and following your author’s journey.',
  'facebook-tips-startagroup-label': 'Start a group or participate in one',
  'facebook-tips-startagroup': 'Groups are a central aspect of Facebook. A lot of people enjoy being part of smaller communities in the big world of Facebook. You as an author can find relevant groups within your genre, which are excellent places to share more about yourself and your book. Alternatively, you can even create a group from scratch to slowly build up your own Facebook community!',

  // Twitter
  'twitter-intro-p1': 'Twitter is the place for people who can make their words count, so as a writer, you should feel right at home.',
  'twitter-intro-p2': 'To make Twitter work for you, you need to tweet and, perhaps even more importantly, retweet often. Keep an eye on hashtags that are relevant to books, publishing, your particular genre, and even your personal interests. Make sure to contribute with substance to any discourses you engage with. The sales potential of Twitter is far less direct than that of e.g. Instagram, but it can nevertheless be useful in gaining a following and, by extension, ambassadors for your book.',
  'twitter-promoteviatwitter': 'Promote your book on Twitter',
  'twitter-button-shareviatwitter': 'Share on Twitter',
  'twitter-charcount': 'You have used {chars} out 280 characters',
  'twitter-week1-title': 'Week 1 - Announcement',
  'twitter-week1-message': 'My book, {title} is finally out! Head over to my website to order a copy of your own, or read some sample chapters to get a feel for the book first! #indieauthor',
  'twitter-week2-title': 'Week 2 - First sales post',
  'twitter-week2-message': 'It’s happening! First 5 books are sold; thank you so much to everyone who bought a copy! If anyone else is interested in my book, {title} you can get it on my website.',
  'twitter-week3-title': 'Week 3 - Giveaway',
  'twitter-week3-message': 'Attention #bookworms! It’s time for a #giveaway! If you want to win a signed copy of my book, follow me, like and retweet this tweet, and leave a comment explaining why you’d like a copy of the book!',
  'twitter-week4-title': 'Week 4 - Review request',
  'twitter-week4-message': 'Attention all readers! If you’ve read my book, {title} and enjoyed it, please consider going over to Goodreads and leaving a review! Not only would I love to read your opinions, but it does wonders for the book’s visibility! #selfpub',
  'twitter-tips-title': 'Tips',
  'twitter-tips-sharequotes-label': 'Share quotes',
  'twitter-tips-sharequotes': 'As you’re undoubtedly familiar with Twitter, we won’t go too deep into the basics. As it is a platform focused on concise, text-based messages, it’s very well-suited to writers. One obvious way to make use of Twitter is for sharing quotes from your book. Try to show your audience who you are as a writer.',
  'twitter-tips-interaction-label': 'Interaction above all',
  'twitter-tips-interaction': 'Twitter is not only about sharing your own ideas but also participating in ongoing discussions; probably even more so when starting out. Twitter creates the possibility to interact with potential readers, other authors and of course your own fans. The more interaction, the better!',
  'twitter-tips-balance-label': 'Balance',
  'twitter-tips-balance': 'With book sales being your main goal, it’s easy to get lost in the constant marketing of your book. However, it is important to show a personal, and even better, a funny side of yourself. People don’t want to constantly read tweets that boil down to“buy my book”; they also want to hear your stories and get a glimpse of your life!',
  'twitter-tips-pinapost-label': 'Pin a tweet',
  'twitter-tips-pinapost': 'If you’re posting on Twitter regularly and are worried specific information about your book might get lost or overlooked as a result, a good idea would be to pin a tweet! You can pin an engaging tweet that links to buying your book and that everyone can see once they go into your profile. Then you just have to get them there.',
  'twitter-tips-bio-label': 'Bio',
  'twitter-tips-bio': 'A lot of your time on Twitter is solely focused on tweeting, but don’t neglect your bio! It will be visible to everyone, so it’s a good place to squeeze in a few words describing yourself and including the title of your book. Make yourself look interesting!',

  // LinkedIn
  'linkedin-intro-p1': 'LinkedIn has more potential than you’d think for self-published authors. For one thing, it’s practically tailor-made for those who have written non-fiction. Even if you’ve written a fiction book however, you can still find a supportive audience on LinkedIn. It’s a platform well-suited to sharing your writing and publishing journey from what we like to call an “authorpreneurial” perspective. Frame your book as a project and talk about the challenges and rewards involved in working on it. Don’t forget to be human about it though!',
  'linkedin-intro-p2': 'Posting on LinkedIn will require a bit more preparatory work than on other platforms. You’re going to want to avoid being too salesy and instead focus on sharing knowledge and experiences. Writing an article, for example, will require some effort, but it will be much better received on the platform than an unabashedly sales-focused post.',
  'linkedin-promotevialinkedin': 'Promote your book on LinkedIn',
  'linkedin-copyandpastetext': 'Click here to copy the text above. Next, paste this text in the status field after clicking the share button.',
  'linkedin-button-shareonlinkedin': 'Share on LinkedIn',
  'linkedin-week1-title': 'Week 1 - Announcement',
  'linkedin-week1-message': 'I’m immensely proud to announce that my book, {title} is finally published! Becoming an author has always been a dream of mine, and it’s been an incredible experience to actually realize that dream for myself. If you’ve got any feedback on the book or want to discuss the contents in depth, I’d love to connect with you and exchange thoughts! For those who are interested, Here’s a link where you can learn more about it!\n\n#authorpreneur #publishing #books',
  'linkedin-week2-title': 'Week 2 - Book launch',
  'linkedin-week2-message': 'I recently threw my first ever book launch event and it was an absolute blast! Seeing everyone gathered to celebrate the publication of my book with me was both incredibly exciting and extremely humbling. A heartfelt thank you to all who attended, and for those who couldn’t make it, here are some nice photos from the event!\n\n#booklaunch #selfpublished #author',
  'linkedin-week3-title': 'Week 3 - Workshop',
  'linkedin-week3-message': 'My book {title} has been selling really well lately, and I’ve been noticing a keen interest in not just the subject matter, but in the process of writing and self-publishing itself. This gave me the idea to organize a free workshop to discuss writing, self-publishing and authorpreneurship with you all!  I think it’d be extremely rewarding to exchange ideas and share insights with one another. Let me know if you’d be interested!\n\n#workshop #authorpreneur',
  'linkedin-week4-title': 'Week 4 - Giveaway',
  'linkedin-week4-message': 'Publishing my book was one of the most challenging things I’ve ever done, but it really paid off! I had never imagined that the book would ever end up selling this well, and I want to celebrate that by giving away 5 copies! If you want to participate in the giveaway, just react to this post and leave a comment describing why you think the subject of my book is important today! The 5 most inspiring comments will get a copy of {title}!\n\n#authorpreneur #selfpublishing #giveaway',
  'linkedin-tips-title': 'Tips',
  'linkedin-tips-finishprofile-label': 'Finish your profile... but really finish it',
  'linkedin-tips-finishprofile': 'This may seem obvious, but a lot of people postpone completing all steps of their Linkedin profiles indefinitely. Not finishing your profile means there are fewer ways for people to find or identify with you.',
  'linkedin-tips-connect-label': 'Connect',
  'linkedin-tips-connect': 'Linkedin is all about networking; the platform is based on creating connections with people. You need to make sure you add other authors, potential readers and so on. Creating content for a small network will get you nowhere.',
  'linkedin-tips-publisharticles-label': 'Publish articles',
  'linkedin-tips-publisharticles': 'Linkedin offers the possibility of posting articles and it is extremely useful for an aspiring author! You can use this platform to share pieces of your novel, or perhaps write articles in which you share tips and experiences that other aspiring authors might enjoy. People tend to engage and read a lot on Linkedin, and articles are perfect for an author that has something to say! Additionally, articles are a great way to funnel traffic towards your website.',
  'linkedin-tips-jobposition-label': 'Make your book a job position',
  'linkedin-tips-jobposition': 'You can put your book as its own job! The title would refer to your name and the company would be the name of your book. In this way, people can clearly see your book on your Linkedin profile. You can add the dates you’ve worked on your novel along with a nice description. This is also a neat little way of announcing potential sequels.',
  'linkedin-tips-behuman-label': 'Be human',
  'linkedin-tips-behuman': 'Since Linkedin is primarily known for being a business platform, people often think they need to be formal when posting there. However, if you are an author trying to sell your book, you will earn points by developing a personalized profile, and you should focus on that! Don’t try to be something you’re not. People on LinkedIn go crazy for stories of personal growth and overcoming challenges, so use that to your advantage.',

  // Libraries
  'libraries-intro-p1': 'Every serious writer ought to have their book in the library. It’s one of the cornerstones of a book publication. In fact, most national libraries are entitled to a copy; this is know as a legal deposit. Formalities aside however, depositing your book in the library is just a great way to legitimize yourself as an author. It also makes for a neat marketing opportunity, and if you can get enough people to request your book at their local libraries, you might even end up getting some copies sold!',

  // Upload zone
  'uploadzone-default-title': 'Upload',
  'uploadzone-default-title-progress': 'Uploading your manuscript',
  'uploadzone-filerequirements': 'File requirements: max. {filesize}, formats: {filetypes}',
  'uploadzone-button-selectfile': 'Select a file',
  'uploadzone-ordragfilehere': 'or drag and drop your file here',
  'uploadzone-dropfiletoupload': 'Drop your file here to upload it',

  // Book problem translations
  'bookproblem-link-moreinfo': 'More information',
  'bookproblem-stepScriptSizeModifiedUploadAgain': 'You’ve changed the format of your book. Please reupload your manuscript file.',
  'bookproblem-stepScriptColorModifiedUploadAgain': 'You’ve changed the colour settings of your manuscript. Please reupload your manuscript file.',
  'bookproblem-stepScriptBindingModifiedUploadAgain': 'You’ve changed your book type. Please reupload your manuscript file.',
  'bookproblem-stepScriptReaddirectionModifiedUploadAgain': 'You’ve changed the language of your book to one with a different writing directionality. Please reupload your manuscript so that the directionality corresponds with your chosen language.',
  'bookproblem-stepScriptManuscriptConversionFailed': 'We were unable to convert your file. This is probably because your file is corrupted. Try repairing and reuploading your file.',
  'bookproblem-stepScriptManuscriptColorpagesNotCalculated': 'We were unable to calculate the number of colour pages in your book correctly. Please contact us to help resolve this issue, or upload a new version of your manuscript file.',
  'bookproblem-stepScriptManuscriptConversionTechnicalError': 'A technical error occurred when converting your manuscript. Error code: {lasterror}',
  'bookproblem-stepScriptFontsNotEmbedded': 'There are fonts in this document that aren’t embedded correctly. See our guide on creating a PDF for more information.',
  'bookproblem-stepScriptEbookConversionHasErrors': 'We detected several errors when converting your eBook. You need to fix these issues before you can publish your book.',
  'bookproblem-printerConstraintNumberpagesLessThanMinimum': 'Your book does not have enough pages. Please make sure your book contains at least {min} pages (the current page count is {pagecount}).',
  'bookproblem-printerConstraintNumberpagesMoreThanMaximum': 'Your book has too many pages. Please make sure that your book contains at most {max} pages (the current page count is {pagecount}).',
  'bookproblem-printerConstraintNumberpagesMoreThanCoverwidth': 'Your book contains too many pages for your chosen format and paper type. Make sure your book contains at most {max} pages, or choose another paper type and/or a narrower format.',

  // Bookstep: manuscript
  'bookstep-Manuscript': 'Manuscript',
  'bookstep-Manuscript-intro-p1-1': 'A well-written and professionally formatted manuscript is essential. That’s why it’s important to take your time in this step. It’s much less hassle to get it right the first time than to have to come back and redo it later. We’ll be right there with you to provide support during the writing process. Feel free to make use of our services if you’d like a professional to take a look at your work. If your manuscript is already finished, you can simply upload it as a Word document or PDF. After uploading, we’ll convert the file to a print-ready PDF. Make sure to check this file thoroughly, and always order a proof copy before publishing. There are few things as annoying as finding typos and other errors in your published book.',
  'bookstep-Manuscript-intro-p1-3': 'A well-written and professionally formatted manuscript is essential. That’s why it’s important to take your time in this step. It’s much less hassle to get it right the first time than to have to come back and redo it later. We’ll be right there with you to provide support during the writing process. Feel free to make use of our services if you’d like a professional to take a look at your work. If your manuscript is already finished, you can simply upload it as a Word document or PDF. After uploading, we’ll convert the file to a print-ready PDF. Make sure to check this file thoroughly, and always order a proof copy before publishing. There are few things as annoying as finding typos and other errors in your published book.',
  'bookstep-Manuscript-intro-p1-6': 'A well-written and professionally formatted manuscript is essential. That’s why it’s important to take your time in this step. It’s much less hassle to get it right the first time than to have to come back and redo it later. We’ll be right there with you to provide support during the writing process. You can upload either a Word document or an ePub file when creating an eBook. Our platform will process your uploaded file (and convert it to an ePub if necessary), and create a version that’s ready to be published. Check this publish-ready file thoroughly before continuing. If you’re planning on uploading a Word document, it’s important to use ePub-friendly formatting, otherwise the resulting eBook might not be readable. If you want to upload an ePub, we recommend using Calibre to create it.',
  'bookstep-Manuscript-intro-p1-7': 'A well-written and professionally formatted manuscript is essential. That’s why it’s important to take your time in this step. We’ll be right there with you to provide support during the writing process. You can upload your finished manuscript directly to our platform as a PDF. After you’ve uploaded your manuscript file, we’ll display a preview of the final version. Check this preview file thoroughly! There are few things as annoying as finding typos and other errors in your published book.',
  'bookstep-Manuscript-button-nextstep': 'Next step',
  'bookstep-Manuscript-resources-toolstoimprovescript': 'Manuscript support',
  'bookstep-Manuscript-resources-wordtemplate': 'Word template and settings',
  'bookstep-Manuscript-resources-wordtemplate-description': 'Settings and templates for Word',
  'bookstep-Manuscript-resources-bookspecs': 'Specifications for professionals',
  'bookstep-Manuscript-resources-bookspecs-description': 'Settings for InDesign, QuarkXPress, etc.',
  'bookstep-Manuscript-resources-bookformatting': 'Format your book',
  'bookstep-Manuscript-resources-bookformatting-description': 'A short guide on how to best format your book',
  'bookstep-Manuscript-resources-services-needhelp': 'Need help?',
  'bookstep-Manuscript-resources-services-needhelp-description': 'Information about our paid services',
  'bookstep-Manuscript-UploadBlock-title-0': 'My manuscript',
  'bookstep-Manuscript-UploadBlock-title-1': 'One moment please, we’re converting your manuscript',
  'bookstep-Manuscript-UploadBlock-title-2': 'Verify your manuscript',
  'bookstep-Manuscript-uploadblock-stillwriting-title': 'I’m still writing',
  'bookstep-Manuscript-uploadblock-stillwriting-description': 'till working on your manuscript? If you need some inspiration, you might want to check out some of our handy tips, or have a look at the various publishing services we offer.',
  'bookstep-Manuscript-uploadblock-stillwriting-button': 'Writing',
  'bookstep-Manuscript-uploadblock-stillformatting-title': 'Formatting',
  'bookstep-Manuscript-uploadblock-stillformatting-description': 'Still need to format your manuscript? Use our templates or check out our formatting services to make sure your manuscript is as good as it can be.',
  'bookstep-Manuscript-uploadblock-stillformatting-button': 'Formatting',
  'bookstep-Manuscript-uploadblock-conversioninprogress-title': 'One moment please, we’re verifying and converting your manuscript',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-1': 'Check your print preview',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-1-desc': 'We have converted your manuscript to a print-ready PDF. Your book will be printed as shown in this document, so check it thoroughly! You can upload a new version as often as you like.',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-1-previewbutton': 'Check print file',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-3': 'Check your print preview',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-3-desc': 'We have converted your manuscript to a print-ready PDF. Your book will be printed as shown in this document, so check it thoroughly! You can upload a new version as often as you like.',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-3-previewbutton': 'Check print file',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-6': 'Check your eBook',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-6-desc': 'We’ve converted your file into an eBook. This is the final version of your manuscript, which readers will receive when purchasing your book, so make sure to check it thoroughly! You can upload a new version of your manuscript as often as you like.',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-6-previewbutton': 'Check your eBook',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-7': 'Check your PDF eBook',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-7-desc': 'We’ve converted your manuscript to a PDF. This is the final version of your manuscript, which readers will receive when purchasing your book, so make sure to check it thoroughly! You can upload a new version as often as you like. ',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-7-previewbutton': 'Check your PDF eBook',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-uploadnewbutton': 'Upload new file',
  'bookstep-Manuscript-tips-title': 'Tips for a good manuscript',
  'bookstep-Manuscript-tips-print-sizeinterior-label': 'Interior formatting / specs',
  'bookstep-Manuscript-tips-print-sizeinterior-word': 'If you’re using Word, we recommend downloading one of our manuscript templates by clicking the button above. This template is created especially for the book type you’ve selected, meaning you won’t have to worry about formatting and can just fill in your text. This is the easiest way to ensure that the layout in your book matches that of your manuscript.',
  'bookstep-Manuscript-tips-print-sizeinterior-indesign': 'If you’re using InDesign or another professional formatting program, click on the button labeled “Book Specifications”. There you’ll find all the information you need to format the perfect manuscript.',
  'bookstep-Manuscript-tips-print-images-label': 'How to add images to your manuscript',
  'bookstep-Manuscript-tips-print-images': 'You can of course add images to your manuscript if you wish. An image with a resolution of 250 dpi is sufficient to ensure high quality printed images. Simply place images wherever you want them to appear in your manuscript (mind the bleed if you’re placing images near the edges). If you want to make a spread, i.e. an image that covers two pages, you can cut the image in half and place the leftmost section on an even page, and the rightmost half on the following uneven page.',
  'bookstep-Manuscript-tips-print-colophon-label': 'Colophon page',
  'bookstep-Manuscript-tips-print-colophon-p1': 'It’s customary that your book begins with a title page and a colophon page. Generally speaking, the order is as follows:',
  'bookstep-Manuscript-tips-print-colophon-firstpage': 'First page: Displays the title of the book',
  'bookstep-Manuscript-tips-print-colophon-secondpage': 'Second page: Empty',
  'bookstep-Manuscript-tips-print-colophon-thirdpage': 'Third page: Title of the book, followed by the name of the author',
  'bookstep-Manuscript-tips-print-colophon-fourthpage': 'Fourth page: This is the colophon page, which should include the author name, the ISBN (if you have one), the name of the cover designer, the copyright statement, the year of publication, and published via: {publicbrand}',
  'bookstep-Manuscript-tips-print-colophon-p2': 'These pages are collectively known as the ‘front matter’ of your book. The pages listed above are not numbered. This means your page numbering will start at page 5. Our manuscript templates are already set up in accordance with this standard.',
  'bookstep-Manuscript-tips-print-formattinginterior-label': 'Formatting your manuscript',
  'bookstep-Manuscript-tips-print-formattinginterior': 'Without a well-formatted manuscript, your book loses value. In order to help you avoid this and miss out on readers, we’ve written a thorough guide on how to format your manuscript properly.',
  'bookstep-Manuscript-tips-print-formattinginterior-link': 'How to format your manuscript',
  'bookstep-Manuscript-tips-print-minimumpagecount-label': 'Minimum number of pages',
  'bookstep-Manuscript-tips-print-minimumpagecount': 'In order for us to print quality books for you, they need to contain a certain number of pages. The number of pages required varies depending on the type of book being printed; specifically, the cover type, paper type, and dimensions influence the minimum number of pages. For instance, a paperback has a lower page requirement than a hardback. This requirement will vary depending on what paper type is being used, as they all have different grammage. In the first step of the book creation process, where you choose your specs, we’ll display the minimum number of pages required for your selected book.',
  'bookstep-Manuscript-tips-print-pageorientation-label': 'Page orientation',
  'bookstep-Manuscript-tips-print-pageorientation': 'While this may sound trivial or obvious, it’s worth mentioning: the first page of your manuscript is always on the right. Even though the inside of your cover, called the endsheet, looks like a (blank) page, it does not count as one. Pages on the left are always even-numbered. As such, when two pages are adjacent to each other, there will always be an even page on the left with a higher, uneven page number on the right. This is handy to keep in mind if you’re making a photo book and want a spread. Keep in mind that we don’t add page numbers for you - it’s something you need to take care of before uploading your manuscript. Our manuscript templates however, do include page numbers, so if page numbering is giving you trouble, try downloading one of those.',
  'bookstep-Manuscript-tips-epub-epubexplain-label': 'About ePubs',
  'bookstep-Manuscript-tips-epub-epubexplain': 'An eBook file (called an ePub) might work a bit differently than you’d expect. For instance, an ePub has no set page numbers. Instead, it is ‘reflowable’, meaning that the text will adapt to the size of the screen it’s being read on. On a smaller screen, sentences will be shortened and the total number of pages will increase. The reverse will happen on a device with a larger screen. In short, the layout of an eBook is dependent on the device used by the reader.',
  'bookstep-Manuscript-tips-epub-usingword-label': 'When using Word',
  'bookstep-Manuscript-tips-epub-usingword': 'You can create ePubs using Word, but there are a few rules you should stick to when doing so, to make sure they work properly. First off: when you want to add a page break, do not simply hit the enter key! If you do, they’ll show up as blank lines in your ePub, meaning readers will see huge gaps in the text. We recommend reading our guide on making an ePub using Word before getting started. You can read it by clicking ‘Format Manuscript’ at the top of this page.',
  'bookstep-Manuscript-tips-epub-images-label': 'Adding images',
  'bookstep-Manuscript-tips-epub-images': 'Want to add images to your eBook? No problem! Just keep in mind that if you’re creating your ePub in Word, you need to make sure that your images are ‘in line with text’ and not with ‘text wrapping’. You can check and change this using the ‘Position’ option under the picture formatting tab in Word. ',
  'bookstep-Manuscript-tips-epub-cover-label': 'Including your cover in the ePub vs making it separately',
  'bookstep-Manuscript-tips-epub-cover': 'When uploading an ePub file, you have the option to include a cover, though this isn’t compulsory. Assuming that your cover meets our specifications, we’ll incorporate it automatically and display it in the cover design step. If you’re uploading your manuscript as a Word file, this isn’t possible, and you’ll have to upload or create your cover in the ‘cover design’ step.',
  'bookstep-Manuscript-tips-epub-calibre-label': 'Calibre: making a perfect ePub',
  'bookstep-Manuscript-tips-epub-calibre': 'If you want to make your own ePub from scratch, we recommend using Calibre. It’s a user-friendly program that allows you to make an ePub file. You can even import Word documents and use them as a basis for your ePub. Check out our guides on how to make an ePub using Calibre for more information.',
  'bookstep-Manuscript-tips-epub-checkepub-label': 'Check your ePub!',
  'bookstep-Manuscript-tips-epub-checkepub': 'The ePub file we create after you upload your manuscript is the final version, meaning it’s what your readers will be receiving when buying your eBook. That’s why it’s crucial that you check this file thoroughly. Use reliable ePub software to do so, such as Calibre, Adobe Digital Editions, Aldiko (for Android) or Apple Books (for iOS). You can also use an e-reader (e.g. a Kindle) for this.',

  'bookstep-Manuscript-popup-bookspecs-bookspecs': 'Book specifications',
  'bookstep-Manuscript-popup-bookspecs-selectboard-word': 'Microsoft Word',
  'bookstep-Manuscript-popup-bookspecs-selectboard-word-description': 'Specifications for Word',
  'bookstep-Manuscript-popup-bookspecs-selectboard-prof': 'Professional layout applications',
  'bookstep-Manuscript-popup-bookspecs-selectboard-prof-description': 'Like inDesign or QuarkXPress',
  'bookstep-Manuscript-popup-bookspecs-manuscriptspecs': 'Manuscript specifications',
  'bookstep-Manuscript-popup-bookspecs-pagesize': 'Dimensions (excl. bleed): {sizes}',
  'bookstep-Manuscript-popup-bookspecs-pagesize-inclbleed': 'Dimensions incl. bleed: {sizes}',
  'bookstep-Manuscript-popup-bookspecs-pagesize-nospecificreq': 'no requirements',
  'bookstep-Manuscript-popup-bookspecs-bleed': 'Bleed: {bleeds}',
  'bookstep-Manuscript-popup-bookspecs-bleed-nobleedreq': 'no bleed necessary',
  'bookstep-Manuscript-popup-bookspecs-bleed-top': 'top',
  'bookstep-Manuscript-popup-bookspecs-bleed-bottom': 'bottom',
  'bookstep-Manuscript-popup-bookspecs-bleed-outside': 'outside',
  'bookstep-Manuscript-popup-bookspecs-bleed-inside': 'inside',
  'bookstep-Manuscript-popup-bookspecs-nopagenumheadfoot': 'Do not add page numbers, headers or footers.',
  'bookstep-Manuscript-popup-bookspecs-imagesinline': 'Make sure to keep images ‘in line with text’. Do not using floating images.',
  'bookstep-Manuscript-popup-bookspecs-uploadspecs': 'Upload specifications',
  'bookstep-Manuscript-popup-bookspecs-fileformats': 'File formats: {formats}',
  'bookstep-Manuscript-popup-bookspecs-noprintmarks': 'No print, trim or bleed marks',
  'bookstep-Manuscript-popup-bookspecs-nospreads': 'Do not use spreads',
  'bookstep-Manuscript-popup-bookspecs-imagesdpi': 'Images: min. {dpinum} dpi',
  'bookstep-Manuscript-popup-bookspecs-word-wordtemplate': 'Word template',
  'bookstep-Manuscript-popup-bookspecs-word-wordtemplate-p1': 'When formatting your manuscript in Microsoft Word, we strongly recommend using our template. This template is tailor-made for your book and already has the right formatting settings. Download the template by clicking the button below.',
  'bookstep-Manuscript-popup-bookspecs-word-downloadtemplate': 'Download template',
  'bookstep-Manuscript-popup-bookspecs-word-therightsizemargins': 'Setting the right margins and page size ',
  'bookstep-Manuscript-popup-bookspecs-word-therightsizemargins-p1': 'If you’re using our template, you won’t have to worry about margins or bleed. The correct settings have already been applied to the document.',
  'bookstep-Manuscript-popup-bookspecs-word-therightsizemargins-p2': 'If you’re not using our template, you’ll need to make sure the page size in your manuscript document matches the dimensions of your chosen book format. If it doesn’t, we’ll automatically scale the page size of your uploaded file to match these dimensions. This can lead to changes in the layout of your book. Additionally, you’ll need to mind the bleed and add it to your margins.',
  'bookstep-Manuscript-popup-bookspecs-word-exampleimage-representsbleed': 'represents bleed',
  'bookstep-Manuscript-popup-bookspecs-word-whatisbleed': 'What is bleed?',
  'bookstep-Manuscript-popup-bookspecs-word-whatisbleed-p1': 'To prevent unwanted white space on the edge of the pages when they’re being cut, it’s necessary to make the background colour or image slightly larger than the actual page size. This extra bit of margin is what we call “bleed”. If you don’t have any colours or images that reach the edge of the page, ignoring the bleed will not lead to any unintended white space, but it can lead to slight differences in the formatting of your book, as we may need to automatically scale up your document to add this bleed anyway. You’ll be able to see any changes in the print preview after you’ve uploaded your manuscript file.',
  'bookstep-Manuscript-popup-bookspecs-exampleimage-leftpage-odd': 'Left page (odd)',
  'bookstep-Manuscript-popup-bookspecs-exampleimage-leftpage-even': 'Left (even)',
  'bookstep-Manuscript-popup-bookspecs-exampleimage-rightpage-odd': 'Right page (odd)',
  'bookstep-Manuscript-popup-bookspecs-exampleimage-rightpage-even': 'Right page (even)',
  'bookstep-Manuscript-popup-services-needhelp': 'Need help?',
  'bookstep-Manuscript-popup-services-doyouneedsupport': 'Need help with your book?',
  'bookstep-Manuscript-popup-services-weofferpaidservices': 'We have a range of affordable editing services available should you need help making the perfect book. Our team of professionals can help you with proofreading, spell-checking, editing, layout and formatting, creating a professional ePub file, and more.',
  'bookstep-Manuscript-popup-services-areyouinterested': 'Contact us for more information by clicking the button below. We’ll get in touch with you within 2 business days.',
  'bookstep-Manuscript-popup-services-contactme': 'I’m interested',
  'bookstep-Manuscript-popup-services-requestsent': 'Inquiry received',
  'bookstep-Manuscript-popup-services-wehavesentrequest': 'We’ve received your inquiry',
  'bookstep-Manuscript-popup-services-wewillcontactyousoon': 'We’ve received your request for more information about our editing services. Our team will contact you with more information within 2 business days.',
  
  'bookstep-Manuscript-guidepopup-wordtips': 'Tips for Microsoft Word',
  'bookstep-Manuscript-guidepopup-wordtips-description': 'How to create a great book using Microsoft Word',
  'bookstep-Manuscript-guidepopup-pdffontsincl': 'Create a PDF',
  'bookstep-Manuscript-guidepopup-pdffontsincl-description': 'A short guide on how to create a good PDF file',
  'bookstep-Manuscript-guidepopup-createepub': 'Create an ePub',
  'bookstep-Manuscript-guidepopup-createepub-description': 'A short guide on how to create a good ePub file',
  'bookstep-Manuscript-guidepopup-colorpages': 'Colour pages guide',
  'bookstep-Manuscript-guidepopup-colorpages-description': 'Our tips on how to properly manage pages with colour',

  // Bookstep: Writing
  'bookstep-Writing': 'Writing',
  'bookstep-Writing-intro-p1': 'Writing a book is one of the most fun and rewarding things you can attempt, but it can also get pretty difficult at times. Even though we can’t write your book for you, we can support you during the process. Whether you’re writing fiction or nonfiction, it is imperative that you structure your writing process. Most writers who don’t end up starting off strong, only to get stuck further down the line. So do yourself a favour: make a plan and stick to it. Not sure how? Check out our guide.',
  'bookstep-Writing-helpwithwriting-title': 'Help with writing',
  'bookstep-Writing-createwritingplan-title': 'Writing guide',
  'bookstep-Writing-writeblurb-title': 'Write your blurb',
  'bookstep-Writing-writeblurb-p1': 'We recommend starting by writing your blurb. This way, you’ll be forced to consider the big picture. You’re doubtlessly going to write more than one version of your blurb. Share them with others and ask for feedback.',
  'bookstep-Writing-writeblurb-p2': 'While a blurb isn’t very long, the process of distilling the essence of your book into a few sentences is an invaluable exercise. It’s going to help you create a solid writing plan, and act as a foundation for the writing process itself. Furthermore, the blurb can make or break a sale, as it’s one of the main factors readers take into consideration when deciding whether to give your book a shot or not.',
  'bookstep-Writing-writeblurb-howtowriteblurb': 'How to write a good blurb',
  'bookstep-Writing-writeblurb-howtowriteblurb-p1': 'Much like anything else, a good way to learn how to write a good blurb is by studying existing blurbs. This will give you a clearer idea of what the best practices for a blurb within your chosen genre are. No matter what kind of book you’re writing though, there are some general criteria that every blurb should fulfill:',
  'bookstep-Writing-writeblurb-howtowriteblurb-list1': 'A captivating first sentence: You need to intrigue the reader from the start. This can be achieved by showcasing a particularly promising quote, posing a compelling question, or offering a solution to a problem.',
  'bookstep-Writing-writeblurb-howtowriteblurb-list2': 'A summary: You want to give the reader an outline of the story without giving away any major plot points.',
  'bookstep-Writing-writeblurb-howtowriteblurb-list3': 'A promise: The reader wants to know what they can expect from your book. If you’re writing fiction, build up suspense or tease some drama. If you’re writing nonfiction, offer solutions to problems or knowledge of a subject.',
  'bookstep-Writing-writeblurb-howtowriteblurb-list4': 'About the author: Particularly relevant for nonfiction. Make sure to include information about yourself that’s relevant to the book; real-life experiences, other books you’ve written, etc.',
  'bookstep-Writing-writeblurb-howtowriteblurb-list5': 'Author photo: This is optional, but many who choose to include one get it wrong. Make sure you use a photo with a high enough resolution (300 dpi), otherwise you risk making your book look unprofessional.',
  'bookstep-Writing-createplan-title': 'Create a writing plan',
  'bookstep-Writing-createplan-p1': 'Writing, like all creative processes, can not always be forced. Nevertheless, we strongly advise you to create a writing plan. Divide this plan into two parts:',
  'bookstep-Writing-createplan-list1': 'Planning: When are you going to write? How long is each writing session going to be? These choices are up to you. Some people will get up early to get some writing done, whereas others are night owls who can only write once they’ve completed other tasks. Create an outline for your book, divide it into chapters and make a plan for each chapter. Don’t feel like you need to start from the beginning either - jump in at any point in the story, as long as it gets you writing.',
  'bookstep-Writing-createplan-list2': 'Plot: work out the broad strokes of your story first; which characters are you going to introduce and how are they going to develop? How are you going to work your way to the major plot points? The most popular formula for a storyline is probably ‘the hero’s journey’. This is true even for nonfiction books. People tend to like following a protagonist as they overcome obstacles and grow as people.',
  'bookstep-Writing-writefeedbackrewrite-title': 'Write, write, feedback, rewrite',
  'bookstep-Writing-writefeedbackrewrite-p1': 'If you’ve succeeded in creating an outline of your plot, it’s time to fill in the blanks. This is where the actual writing of your book comes in. Don’t let yourself be discouraged; you don’t have to slog your way through chapter by chapter. You can just as easily start in the middle, or at the end, or wherever you’re currently most inspired to write. This method tends to be far more productive than forcing yourself to write everything in order.',
  'bookstep-Writing-writefeedbackrewrite-p2': 'As soon as you’ve finished a few chapters, have friends and/or family read your work and provide honest feedback. Make sure you pick proofreaders that won’t shy away from constructive criticism; flattery will do you no good here. There are a few things you should keep in mind with regards to your writing:',
  'bookstep-Writing-writefeedbackrewrite-list1': 'Are you going to be writing from a first person or third person perspective?',
  'bookstep-Writing-writefeedbackrewrite-list2': 'Present tense or past tense?',
  'bookstep-Writing-writefeedbackrewrite-list3': 'Consistency is key',
  'bookstep-Writing-writefeedbackrewrite-list4': 'Show, don’t tell',
  'bookstep-Writing-writefeedbackrewrite-list5': 'Build up the tension and atmosphere to draw your readers in',
  'bookstep-Writing-feedbackediting-title': 'Feedback and editing',
  'bookstep-Writing-feedbackediting-p1': 'Writing is an art form, and artists tend to be insecure about their own work. Don’t let self-doubt discourage you though, it’s a normal part of the process. Use that feeling instead by asking your proofreaders for constructive criticism; your story will be better for it.',
  'bookstep-Writing-feedbackediting-p2': 'As soon as your manuscript is completed, have as many people as possible proofread it, perhaps in exchange for a coffee. Keep in mind that most people still prefer reading on paper, so it might be a good idea to print out some copies of your manuscript for the proofreaders that do. Most people feel flattered when asked to proofread, and will be happy to help.',
  'bookstep-Writing-feedbackediting-p3': 'It’s important to make sure that your manuscript is free of spelling and grammatical errors. Nothing undermines a reader’s impression of your writing like a spelling mistake. Depending on your ambitions and means, you may want to consider our editing or quick scan services. An expert will then read either your whole manuscript or part of it, and give you feedback on the style, consistency, plot, character development, etc.',
  'bookstep-Writing-nextstep': 'Next step: Formatting',
  'bookstep-Writing-uploadnow': 'Upload now',
  'bookstep-Writing-tips-title': 'Writing tips',
  'bookstep-Writing-tips-titlesubtitle-label': 'Title and subtitle',
  'bookstep-Writing-tips-titlesubtitle': 'Do you already have a title and subtitle in mind for your book? In that case you can skip this, but most people tend to have trouble deciding on a title. We recommend simply writing down all your title ideas in a brainstorm session. You’ll likely quickly single out the main contenders, or even an outright winner, when you have all the options in front of you. We recommend showing the top three title candidates to your friends or social media followers and asking them which title they prefer. Ultimately, people will take three main things into account when determining whether they want to buy your book: the front cover (including the title), the blurb, and the preview, so it’s worth getting the popular opinion on these aspects of your book.',
  'bookstep-Writing-tips-blurb-label': 'Blurb',
  'bookstep-Writing-tips-blurb': 'If you manage to pique a reader’s interest with your front cover, chances are they’ll have a look at the blurb next. This is the key moment in your customers’ decision making process, and will make or break a sale. Whether your book is fiction or nonfiction, there are a few things your prospective readers will be interested in: is the text appealing, is a problem the book is going to solve, or is there an interesting question it poses that arouses their curiosity? The blurb needs to leave the reader wanting more. If you succeed in this, chances are good that your book will be bought. So have people proofread your blurb and ask them for feedback; would they buy your book based on the blurb?',
  'bookstep-Writing-tips-howdoyoufeelabouit-label': 'Get feedback',
  'bookstep-Writing-tips-howdoyoufeelabouit': 'Have people proofread your work early on. Your manuscript doesn’t have to be finished for you to get valuable feedback on it. Whether you have people read a single page or several chapters, ask for honest feedback. Generally, people will enjoy helping you out and getting to read your work. You could also post a chapter on a social media platform of your choice and ask for feedback that way. This latter method lets you kill two birds with one stone: you get feedback and promote your book simultaneously.',
  'bookstep-Writing-tips-marketing-label': 'Marketing',
  'bookstep-Writing-tips-marketing': '‘Marketing? I haven’t even finished my book yet!’. Exactly. This is the moment to start thinking about how you’re going to market your book. At this early stage, you can start small by stirring up some interest. Share your writer’s journey on social media, talk about how it’s going, what difficulties you’re facing. Share your cover on social media when it’s ready. Post excerpts of your finished chapters. It’s likely you’ll get loads of encouragement. These are just some ideas that you could include in your marketing plan. Don’t neglect this; it’s how you build up a network of followers and ambassadors, which will be invaluable when trying to get your book noticed once it’s published.',
  'bookstep-Writing-tips-support-label': 'Support',
  'bookstep-Writing-tips-support': 'Are you stuck and need help, or do you just want some professional feedback on your manuscript? We offer several affordable service packages, allowing you to send your manuscript to a professional writer, editor, or coach, and have them improve your manuscript. You can opt for a quick scan, a complete review, spell checks, editing, and even workshops. If you’re really serious about your book, make sure to involve your network and/or hire one of our editors to help you make the best possible book.',
  'bookstep-Writing-tips-dontgiveup-label': 'Don’t give up!',
  'bookstep-Writing-tips-dontgiveup': 'Practically every author runs into a roadblock every once in a while; disillusionment, writer’s block, you name it. Don’t give up! If needed, take a break and go do something else for a bit. Go for a walk, talk to people, distract yourself in other ways. Your motivation will come back soon enough. If you’re looking for more concrete advice during the writing process, we have a book for you: ‘That’s How You Write a Book’ is a writing guide that we’ve published in collaboration with writing coach Esther Jacobs. It covers the journey from idea to published book in detail, and provides advice for each step of the writing process.',

  // Bookstep: Formatting
  'bookstep-Formatting': 'Formatting',
  'bookstep-Formatting-intro-p1': 'The text is obviously the most important part of your manuscript, but without good formatting, chances are that nobody’s ever going to want to read it, regardless of its quality. A well-formatted manuscript makes for effortless reading, allowing the reader to lose themselves in your story without getting distracted or confused. You can format your manuscript yourself of course, but you can also have a professional do it for you. If you’re leaning towards the latter, you might want to check out our formatting service. Want to do it yourself? Check our guidelines to ensure you get the best possible result.',
  'bookstep-Formatting-helpwithformatting-title': 'Formatting support',
  'bookstep-Formatting-guide-title': 'Formatting guide',
  'bookstep-Formatting-guide-print-template-title': 'Template',
  'bookstep-Formatting-guide-print-template-p1': 'If you’re planning on formatting your manuscript yourself, we recommend working in a document that’s been set up correctly beforehand. This will save you time and effort down the line. If you’re using Word, we suggest using our templates; they’re set up to match the specs of your selected book type. They also account for title and colophon pages, page numbers, and margins. If you’ve already finished your manuscript, you can simply copy the text over to the template - just don’t copy everything at once! Doing so will overwrite the template’s settings, and that would of course defeat the purpose of using it in the first place.',
  'bookstep-Formatting-guide-print-template-p2': 'If you’re creating your manuscript using a professional formatting program like InDesign, set up your document according to the specifications of your chosen book format. Make sure to have a margin of 3mm around the outer edges of each page (i.e. excluding the so-called ‘gutter’, meaning the inner margins). You can get the exact specifications for your book by clicking on the ‘Book specifications’ button above.',
  'bookstep-Formatting-guide-print-template-p3': 'Remember: an odd page is always on the right, and an even page is always on the left.',
  'bookstep-Formatting-guide-print-fonts-title': 'Fonts, font sizes, and line spacing',
  'bookstep-Formatting-guide-print-fonts-p1': 'We’re not going to tell you which font to use. It’s a personal choice and varies depending on the book. The first thing you need to decide is whether you want to use a serif or a sans serif typeface. Serifs are the little lines attached to the ends of letters, and are widely considered to help make printed text more legible. Serif fonts are the classic choice for print books and newspapers. Popular serif fonts include Cambria, Garamond, Georgia, and Times New Roman.',
  'bookstep-Formatting-guide-print-fonts-p2': 'Sans serif fonts do not have these lines, resulting in a more modern, minimalist aesthetic. These typefaces are commonly used online, and are widely considered to offer better legibility than serif fonts in this case. Popular sans serif fonts include Arial, Calibri, Helvetica and Verdana.',
  'bookstep-Formatting-guide-print-fonts-p3': 'Additionally, you’ll need to decide on a font size and line spacing. Again, there’s no correct choice here; it all comes down to personal preference. Generally, the most common font sizes are 10, 11, and 12, depending on the font being used. If your book has an older target audience, use a larger font size and more line spacing (between 1.1 and 1.3 is customary when it comes to line spacing). Printing out a few pages of your manuscript is a good way to check whether you’re happy with your font and line spacing.',
  'bookstep-Formatting-guide-print-moreoptions-title': 'Even more formatting options',
  'bookstep-Formatting-guide-print-moreoptions-p1': 'We’re not done yet; there are some more choices you need to make before continuing with your book. Here’s a list of the most important ones:',
  'bookstep-Formatting-guide-print-moreoptions-list1': 'Page layout: The main thing to keep in mind here are the margins. Make sure they’re not too small; around 2 cm with a little extra for the gutter (the inward-facing sides of the pages where the book is bound together). Part of the inner margins of the pages will disappear, as they will be used to bind the book. Our templates are designed to accommodate this, so you won’t need to worry about margins. If you don’t want to use our templates, use the following margins:',
  'bookstep-Formatting-guide-print-moreoptions-list1-topmargin': 'Top margin: {size}',
  'bookstep-Formatting-guide-print-moreoptions-list1-bottommargin': 'Bottom margin: {size}',
  'bookstep-Formatting-guide-print-moreoptions-list1-innermargin': 'Inner margin: {size}',
  'bookstep-Formatting-guide-print-moreoptions-list1-outermargin': 'Outer margin: {size}',
  'bookstep-Formatting-guide-print-moreoptions-list2': 'New chapters: Start each new chapter on a new page. You can give your chapters names, numbers, or both. If you’re using Word, make sure to use the style ‘Header 1’ for your chapter title/number. This way you ensure that the formatting for each chapter is the same.',
  'bookstep-Formatting-guide-print-moreoptions-list3': 'Paragraphs: You can opt to separate paragraphs with blank lines, which results in a pleasant reading experience. You could also choose to indent the first word of each paragraph. Whatever you choose, make sure your paragraphs aren’t too long, as it detracts from the legibility of your text.',
  'bookstep-Formatting-guide-print-moreoptions-list4': 'Images and text: If the contents of your book alternate between text and images, like in a cookbook, then place your images on even pages (left) and your text on odd pages (right).',
  'bookstep-Formatting-guide-print-uploadscript-title': 'Uploading your manuscript',
  'bookstep-Formatting-guide-print-uploadscript-p1': 'You can write your manuscript using any word processing software you like, but we only accept Word files (.doc or .docx) and PDFs when it’s time to upload. So if you’re working in a program other than Word (e.g. Pages) you’ll need to convert your manuscript to a PDF before continuing. ',
  'bookstep-Formatting-guide-print-uploadscript-p2': 'Even if you’ve written your manuscript in Word, we recommend converting it to a PDF before uploading. This minimizes the risk of formatting errors appearing during the conversion process. Make sure that each page of your manuscript is also a separate page in your converted PDF (don’t use any so-called ‘spreads’), otherwise it won’t come out right. Once you’ve uploaded your manuscript, we’ll convert it to a print-ready PDF. Review this file thoroughly; check all the pages to make sure that they’re correctly formatted.',
  'bookstep-Formatting-guide-print-uploadscript-p3': 'Still not happy? No problem. You can upload a new version of your manuscript as many times as you like. Doing so will overwrite any previous versions you’ve uploaded.',
  'bookstep-Formatting-guide-epub-howepubswork-title': 'How does an ePub work?',
  'bookstep-Formatting-guide-epub-howepubswork-p1': 'Before you get started on your eBook, it’s important to know how they actually work, as there are some important differences between eBooks and other digital documents. As such, what you’ve created using your word processing software might not appear as intended when read on an e-reader.',
  'bookstep-Formatting-guide-epub-howepubswork-p2': 'Firstly, eBooks published via our platform will always be in ePub format. This is the standard eBook format globally, meaning it can be read on every e-reader. One of the most important characteristics of ePub files is that they are ‘reflowable’. This means that the text in the file adapts to the size of the screen on which it’s being read. On a smaller screen, sentences will be made shorter, and the book will therefore have more pages than it would have on a bigger screen. This means that the layout of an eBook is dependent on the device being used to read it.',
  'bookstep-Formatting-guide-epub-howepubswork-p3': 'As a result, a text segment may appear on a single page in Word, but be split over several pages when viewed on an e-reader. Try not to worry about  the exact positioning of lines and paragraphs; instead, just make sure that the text has a logical flow to it and that the paragraph spacing is correct. This will produce an optimal result for most readers.',
  'bookstep-Formatting-guide-epub-templatefonts-title': 'Templates and fonts',
  'bookstep-Formatting-guide-epub-templatefonts-p1': 'When formatting your eBook in Microsoft Word, we recommend using one of our Word templates. These templates are set up to ensure a good eBook layout with minimal hassle, meaning less work for you. You can download our templates by clicking on the ‘Word Template’ button above.',
  'bookstep-Formatting-guide-epub-templatefonts-p2': 'Fonts are another thing you shouldn’t think too much about when it comes to eBooks. The vast majority of e-readers allow users to select their own fonts, meaning that whatever font you originally chose to write in will most likely be overridden. Our advice would therefore be to simply select a font that you think looks good and that you enjoy working with. Georgia and Times New Roman are a some classic serif choices, whereas Aria, Helvetica, or Verdana tend to get the job done if you prefer sans serif.',
  'bookstep-Formatting-guide-epub-chapterstoc-title': 'Chapters and the table of contents',
  'bookstep-Formatting-guide-epub-chapterstoc-p1': 'When formatting your eBook in Microsoft Word, it’s important to make correct use of so-called ‘styles’, e.g. ‘Heading 1’, ‘Heading 2’, and so on. These styles are used to structure your eBook and to generate the table of contents during the conversion process. What follows is a short list of essential styles that you need to be familiar with:',
  'bookstep-Formatting-guide-epub-chapterstoc-l1-normal': 'Normal text: Use this for the regular body text in your manuscript.',
  'bookstep-Formatting-guide-epub-chapterstoc-l1-title': 'Title: Use this to write the title of your book on e.g. the title page.',
  'bookstep-Formatting-guide-epub-chapterstoc-l1-heading1': 'Heading 1: Use this heading for chapter titles. In the table of contents, this heading will appear at the top of the hierarchy. We’ve set up our templates so that these headings always appear on a new page.',
  'bookstep-Formatting-guide-epub-chapterstoc-l1-heading2': 'Heading 2: Use this heading for subtitles, for example when you want to divide chapters into sections. In the table of contents, these are directly subordinate to ‘heading 1’.',
  'bookstep-Formatting-guide-epub-chapterstoc-l1-heading3': 'Heading 3: You can use this heading in the somewhat unlikely event that you want to divide your subsections (under ‘heading 2’)  into even smaller sections. You will rarely ever see this in novels, though it is used in academic books.',
  'bookstep-Formatting-guide-epub-chapterstoc-p2': 'If you’re planning on creating your ePub manually, chapters and headings will work a bit differently. It’s therefore important that you do the following:',
  'bookstep-Formatting-guide-epub-chapterstoc-l2-separatefiles': 'Make sure that each chapter of your ePub is in a separate HTML file. This file must then be saved correctly in the OPF file where the order of the HTML files is established.',
  'bookstep-Formatting-guide-epub-chapterstoc-l2-useh1tags': 'Chapters must always begin with an ‘h1’ HTML tag in your HTML file. This h1 tag should contain the chapter title and/or number.',
  'bookstep-Formatting-guide-epub-chapterstoc-l2-useh2h3tags': 'When using subheadings, you can use h2, h3, or h4 (etc.) tags. These work the same way as Heading 1, 2, 3 etc. in Word.',
  'bookstep-Formatting-guide-epub-chapterstoc-l2-generatetoc': 'Make sure to generate a table of contents for your ePub file. Most ePub programs can do this for you automatically using an ‘NCX’ file which will be added to your ePub.',
  'bookstep-Formatting-guide-epub-moreformattips-title': 'Extra formatting tips',
  'bookstep-Formatting-guide-epub-moreformattips-p1': 'In addition to the tips already mentioned, there are a few more things worth keeping in mind when formatting your eBook:',
  'bookstep-Formatting-guide-epub-moreformattips-list1': 'Page numbers: make sure that your Word or ePub file doesn’t contain any page numbers. As mentioned before, the individual settings of each e-reader will make page numbers redundant. Including them will only cause problems.',
  'bookstep-Formatting-guide-epub-moreformattips-list2': 'Numbered lists: if it so happens that you’d like to include a numbered list in your eBook, you should know that while it is possible, there are certain limitations. Make sure that your list isn’t interrupted by a paragraph only to resume later on in the text (e.g. if the items on your list are particularly lengthy). ePubs don’t allow for this, meaning that your lists always have to connect.',
  'bookstep-Formatting-guide-epub-moreformattips-list3': 'Images: you can absolutely add images to your ePub file. Make sure that the images you use are in JPG or PNG format, and that their resolution isn’t too large (we recommend an upper limit of 1000x1000 pixels). ',
  'bookstep-Formatting-guide-epub-moreformattips-list4': 'Cover: When uploading a Word document, make sure NOT to include the cover in the Word document. The cover is created and/or uploaded in the next step of the publishing process (‘Cover’). If you’re creating your own ePub file, you could potentially include your cover in the file; if done correctly in a high enough resolution, our system will export it to the cover step automatically.',
  'bookstep-Formatting-guide-epub-uploadscript-title': 'Uploading your manuscript',
  'bookstep-Formatting-guide-epub-uploadscript-p1': 'You can write your manuscript using any program you want, but we only accept Word documents (doc or docx) or ePubs when it’s time to upload. This means that if you’re working with a word processor other than Microsoft Word, you’ll first need to convert the file to a Word file or an ePub before you can upload it to our platform.',
  'bookstep-Formatting-guide-epub-uploadscript-p2': 'A word of warning: in our experience, converting files created in LibreOffice, Apple Pages or other word processing applications to doc or docx files doesn’t always go smoothly, which may result in error messages when attempting to upload these converted files.',
  'bookstep-Formatting-guide-epub-uploadscript-p3': 'After uploading your file, we’ll convert it to an ePub. You can then check this ePub using an e-reader or an application that reads ePubs. It’s important to review this file thoroughly and make sure it’s as close to perfect as can be, as it’s the version that we will be making available to your readers.',
  'bookstep-Formatting-guide-epub-uploadscript-p4': 'If you’re having trouble formatting your eBook, you can always make use of our eBook formatting service. Please note that we charge for this service, however.',
  'bookstep-Formatting-guide-pdfebook-template-title': 'Template',
  'bookstep-Formatting-guide-pdfebook-template-p1': 'If you’re planning on formatting your manuscript yourself, we strongly recommend that you start working in a properly set up document as early as possible; preferably when you start writing your book. If you’re working in Microsoft Word, make use of our templates. In case you’ve already finished your manuscript, you can copy the contents over to the template, but make sure not to copy and paste the entire manuscript at once! Doing so may alter the formatting, leading to problems when uploading.',
  'bookstep-Formatting-guide-pdfebook-template-p2': 'Should you already be satisfied with the layout of your Word document, you can also simply convert it to PDF and upload that. This method is usually a reliable way to create a PDF eBook.',
  'bookstep-Formatting-guide-pdfebook-template-p3': 'If you’re using a professional layout design software like InDesign, you can simply apply the specifications of your chosen book format to the document.',
  'bookstep-Formatting-guide-pdfebook-fonts-title': 'Fonts, font size, and line spacing',
  'bookstep-Formatting-guide-pdfebook-fonts-p1': 'We’re not going to tell you which font to use, as it’s very subjective and, to a certain extent, dependent on the type of book you’re writing. The first decision that needs to be made is whether to use a serif or sans serif font. Serif fonts have little ‘feet’ at the ends of the strokes. It’s generally accepted that these assist in guiding a reader’s eyes along the page. Serif fonts are, by far, the most used font type in books. Cambria, Garamond, Georgia, and Times New Roman are some of the most popular serif fonts.',
  'bookstep-Formatting-guide-pdfebook-fonts-p2': 'Sans serif fonts are simpler and tend to look more ‘modern’. They’re commonly used online and on book covers.  Arial, Calibri, Helvetica, and Verdana are some of the most popular sans serif fonts.',
  'bookstep-Formatting-guide-pdfebook-fonts-p3': 'In addition, you also need to decide on a font size and line spacing. Again, there isn’t a correct answer here; it’s all subjective and also very reliant on the font you’ve chosen. 10, 11, and 12 are the most commonly used font sizes. As for line spacing, something between 1,1 and 1,3 is conventional.',
  'bookstep-Formatting-guide-pdfebook-fonts-p4': 'If your book has a target audience where the average age is high, it might be worth considering a larger font size and line spacing. It can be helpful to print out a few pages of your book to get an idea of what setup you prefer.',
  'bookstep-Formatting-guide-pdfebook-moreoptions-title': 'Even more formatting options',
  'bookstep-Formatting-guide-pdfebook-moreoptions-p1': 'We’re not done yet folks, there are still some choices to be made. Here are the most important ones:',
  'bookstep-Formatting-guide-pdfebook-moreoptions-list1': 'Margins: Make sure they’re big enough; 2 cm is a good rule of thumb. If you’re not using our templates, then you can recreate a similar layout by setting the margins as follows:',
  'bookstep-Formatting-guide-pdfebook-moreoptions-list1-topmargin': 'Top margin: {size}',
  'bookstep-Formatting-guide-pdfebook-moreoptions-list1-bottommargin': 'Bottom margin: {size}',
  'bookstep-Formatting-guide-pdfebook-moreoptions-list1-leftmargin': 'Left margin: {size}',
  'bookstep-Formatting-guide-pdfebook-moreoptions-list1-rightmargin': 'Right margin: {size}',
  'bookstep-Formatting-guide-pdfebook-moreoptions-list2': 'New chapters: start new chapters on new pages. This leads to a better reading experience. You can either give your chapters a title, a number, or both. Whichever you choose, make sure you apply the style ‘Heading 1’ to your chapter headings in Word to make sure the formatting is consistent.',
  'bookstep-Formatting-guide-pdfebook-moreoptions-list3': 'Paragraphs: You can either separate paragraphs with a blank line, or by indenting the first word of a new paragraph. Just make sure that your paragraphs aren’t too long; that will negatively impact readability.',
  'bookstep-Formatting-guide-pdfebook-uploadscript-title': 'Uploading your manuscript',
  'bookstep-Formatting-guide-pdfebook-uploadscript-p1': 'You are of course free to use whatever word processing software you like when writing your manuscript, but when it comes to uploading a manuscript for a PDF eBook, we only accept PDFs. As such, regardless of how you created your manuscript, you’ll need to convert it to a PDF before you can upload it to our platform. Most programs have a conversion tool built in, either under ‘Save as’ or ‘Export’. Usually, all you need to do is select either of these options and then choose PDF as the file format.',
  'bookstep-Formatting-guide-pdfebook-uploadscript-p2': 'Once you’ve uploaded your manuscript, we’ll convert it to a publishable PDF that’ll appear on your screen. Check this file thoroughly and make sure the layout is perfect on every page. ',
  'bookstep-Formatting-guide-pdfebook-uploadscript-p3': 'Not happy with how it turned out? No problem, you can change and reupload your manuscript as many times as you like in this step. Keep in mind that when uploading a new version of your manuscript, the old version will be overwritten.',
  'bookstep-Formatting-nextstep': 'Next step: upload',
  'bookstep-Formatting-tips-title': 'Formatting tips',
  'bookstep-Formatting-tips-print-newpage-label' : 'New page',
  'bookstep-Formatting-tips-print-newpage': 'When a chapter ends halfway down the page and you want to start the next chapter on a new page, never use the Enter key to get there. If you do, it’ll cause massive white spaces to appear when converting the document to a PDF, displacing the entire text. Therefore, you should always use CTRL + Enter (CMD + Enter on Mac) to create a line break in Word. This will prevent any issues. The same applies when inserting pictures in your manuscript: do not simply press Enter to create space!',
  'bookstep-Formatting-tips-print-colophon-label': 'Colophon page',
  'bookstep-Formatting-tips-print-colophon': 'It’s customary to have your book begin with title pages and a colophon page. Generally speaking, the first pages of a book tend to be the following:',
  'bookstep-Formatting-tips-print-colophon-page1': 'First page: Only the title written in a large font',
  'bookstep-Formatting-tips-print-colophon-page2': 'Second page: Empty',
  'bookstep-Formatting-tips-print-colophon-page3': 'Third page: The title of the book with the name of the author underneath',
  'bookstep-Formatting-tips-print-colophon-page4': 'Fourth page: The colophon page, which should contain the author name, ISBN (if you have one), the name of the cover designer, the copyright, the date of publication, and published via {publicbrand}',
  'bookstep-Formatting-tips-print-colophon-rest': 'The pages listed above are not numbered, meaning that the page numbers of your book will start at 5. Our templates are set up according to this standard.',
  'bookstep-Formatting-tips-print-images-label': 'Images',
  'bookstep-Formatting-tips-print-images': 'You can of course add images to your manuscript if you wish. An image with a resolution of 250 dpi is sufficient to ensure high quality printed images. Simply place images wherever you want them to appear in your manuscript (mind the bleed if you’re placing images near the edges). If you want to make a spread, i.e. an image that covers two pages, you can cut the image in half and place the leftmost section on an even page, and the rightmost half on the following uneven page.',
  'bookstep-Formatting-tips-print-wrongformat-label': 'Wrong format?',
  'bookstep-Formatting-tips-print-wrongformat': 'When uploading a document with a format that doesn’t match your chosen book format, we’ll rescale your document to match your book. This can go smoothly, especially if there are only minor differences, but it can also lead to formatting issues. In short, always check the converted PDF you get from the platform thoroughly! It would be a shame if you order a proof copy only to notice that the formatting is off.',
  'bookstep-Formatting-tips-print-colorpages-label': 'Colour and black & white',
  'bookstep-Formatting-tips-print-colorpages': 'If your book contains both B&W pages and colour pages, it’s important that you follow a certain procedure in order to avoid having your B&W pages printed in colour. Click here for a comprehensive overview of how to add colour pages to your book. {link}',
  'bookstep-Formatting-tips-print-colorpages-link': 'Click here to read all the ins and outs about working with pages in colour.',
  'bookstep-Formatting-tips-print-proofcopy-label': 'Proof copy',
  'bookstep-Formatting-tips-print-proofcopy': 'You’re not quite ready to order a proof copy yet, but it’s worth mentioning already: always buy a proof copy before publishing or placing large print orders. No matter how thoroughly you (or your proofreaders) have checked your manuscript digitally, you’re always going to notice a few errors in the print version. Typos, aesthetic details, and colours will at some point get hard to distinguish on a screen, and the print copy will always look slightly different; you’ll be surprised at what you find. It’s extremely frustrating to pick up on these minor errors after a book has been published or printed. Bulk discounts also apply to proof copies, so you can easily order several and have other people help you proofread your final draft.',
  'bookstep-Formatting-tips-epub-chapterheadings-label': 'Chapter headings',
  'bookstep-Formatting-tips-epub-chapterheadings': 'In order to create a good ebook, it’s important to use the right chapter headings. You can do this in Microsoft Word by using the so-called ‘Styles’, such as ‘Heading 1’. If you don’t do this, your ebook will just be one long text without headings, and no possibility to navigate between chapters.',
  'bookstep-Formatting-tips-epub-colophon-label': 'Colophon page',
  'bookstep-Formatting-tips-epub-colophon': 't’s customary that a book begins with title pages and a colophon page. Generally, the first couple of pages of a book are in the following order:',
  'bookstep-Formatting-tips-epub-colophon-page1': 'First page: book title followed by the author',
  'bookstep-Formatting-tips-epub-colophon-page2': 'Second page: the colophon page, which usually lists the author, the ISBN (if available), the name of the person who designed and/or illustrated the cover, the copyright, the year of publication, and published via {publicbrand}',
  'bookstep-Formatting-tips-epub-colophon-rest': 'If you’re using Microsoft Word, make sure that you insert a page break between these two pages, so that they stay separate.',
  'bookstep-Formatting-tips-epub-pagenumbers-label': 'No page numbers',
  'bookstep-Formatting-tips-epub-pagenumbers': 'Make sure that there are no page numbers, or other header/footer text in your manuscript file. If left in, these will appear randomly within the text of the eBook itself, rather than the intended spot.',
  'bookstep-Formatting-tips-epub-images-label': 'Images',
  'bookstep-Formatting-tips-epub-images': 'You can of course add images to your eBook. If you choose to do so, keep in mind that image is placed ‘in line with text’. You cannot add so-called ‘floating’ images with text on the side; images must always occupy the entire line with the accompanying text above and/or below it.',
  'bookstep-Formatting-tips-pdfebook-newpage-label': 'New page',
  'bookstep-Formatting-tips-pdfebook-newpage': 'When a chapter ends partway down a page and you want to start the next chapter on a new page, never use the Enter key to navigate to this new page. Doing so will result in large empty spaces in the document when converting your file to PDF, effectively shifting the new chapter much further down the page than intended. When using Word, always use CTRL + Enter (or CMD + Enter if you’re using a Mac) to start on a new page. The same applies when you want to add full-page images to your manuscript. Do not use the Enter key to create space for your image.',
  'bookstep-Formatting-tips-pdfebook-colophon-label': 'Colophon page',
  'bookstep-Formatting-tips-pdfebook-colophon': 'It’s customary that a book begins with title pages and a colophon page. Generally, the first couple of pages of a book are in the following order:',
  'bookstep-Formatting-tips-pdfebook-colophon-page1': 'First page: Book title in large letters',
  'bookstep-Formatting-tips-pdfebook-colophon-page2': 'Second page: Empty',
  'bookstep-Formatting-tips-pdfebook-colophon-page3': 'Third page: book title followed by the author',
  'bookstep-Formatting-tips-pdfebook-colophon-page4': 'Fourth page: this is the colophon page, which usually lists the author, the ISBN (if available), the name of the person who designed and/or illustrated the cover, the copyright, the year of publication, and published via {publicbrand}',
  'bookstep-Formatting-tips-pdfebook-colophon-rest': 'These pages aren’t numbered. As such, the page numbers, which are included from the next page onward, start at number 5. Our templates are already set up according to this standard.',
  'bookstep-Formatting-tips-pdfebook-images-label': 'Images',
  'bookstep-Formatting-tips-pdfebook-images': 'You can of course add images to your manuscript. A resolution of 96 dpi is sufficient for eBooks. You’ll need to insert the images into your manuscript yourself; just make sure there’s enough space between the image and the text.',
  'bookstep-Formatting-tips-pdfebook-dimensions-label': 'Dimensions',
  'bookstep-Formatting-tips-pdfebook-dimensions': 'There are no restrictions on the dimensions of your PDF. Nevertheless, it’s a good idea to use a fairly standard size, preferably somewhere between A5 and A4, as an extremely large or small format may have a negative effect on the readability of your eBook.',

  // Account settings
  'AccountSettings-Security-button-2FA-title': 'Use Two-Factor authentication',
  'AccountSettings-Security-button-2FA-subtitle-enabled': 'Enabled',
  'AccountSettings-Security-button-2FA-subtitle-disabled': 'Disabled',
  'AccountSettings-Security-button-2FA-subtitle': '{enableddisabled} • We’ll ask for a code when you login from a new device.',

  // Checkout
  'Checkout-Cart-title-Yourcart': 'Your shopping cart',
  'Checkout-Cart-RemoveFromCart': 'Remove from cart',
  'Checkout-Cart-CartItem-Title-Buscards': 'Business cards',
  'Checkout-Cart-CartItem-Title-Isbn': 'ISBN',
  'Checkout-Cart-CartItem-Originally': 'Originally: <span>{price}</span>',
  'Checkout-Cart-CartItem-Forbook': 'For book: {bookTitle}',
  'Checkout-Cart-CartIsEmpty': 'Your shopping cart is empty.',
  'Checkout-Cart-title-Total': 'Total',
  'Checkout-Cart-total-Subtotal': 'Subtotal <span>{price}</span>',
  'Checkout-Cart-total-Discount': 'Discount <span>{price}</span>',
  'Checkout-Cart-total-Total': 'Total <span>{price}</span>',
  'Checkout-Cart-Button-ProceedCheckout': 'Proceed to checkout',
  'Checkout-Cart-ErrorPopup-title': 'Some products in your shopping cart cannot be ordered',
  'Checkout-Cart-ErrorPopup-p1': 'If you click on the button ‘Proceed to checkout’, we’ll delete any unavailable products from your cart and you’ll be proceed to checkout.',
  'Checkout-Cart-ErrorPopup-unavailableprods': 'Unavailable products:',
  'Checkout-Cart-ErrorPopup-Button-Cancel': 'Cancel',
  'Checkout-Cart-ErrorPopup-Button-ContinueOrder': 'Proceed to checkout',
  'Checkout-Cart-WarningPopup-title': 'You are about to order an ISBN and the associated book simultaneously.',
  'Checkout-Cart-WarningPopup-p1': 'If you order an ISBN and the associated book simultaneously, the ordered book will <b>NOT</b> have the ISBN. We recommend first ordering the ISBN and then ordering the book once the ISBN has been assigned to your book.',
  'Checkout-Cart-WarningPopup-p2': 'If you click the button ‘Order ISBN first’ klikt, we’ll remove the following book(s) from your cart:',
  'Checkout-Cart-WarningPopup-Button-IgnoreContinue': 'Ignore and proceed',
  'Checkout-Cart-WarningPopup-Button-OrderIsbnFirst': 'Order ISBN first',
  'Checkout-Error-CheckoutSpecApiError': 'An unexpected error occurred during checkout. Please contact support and attach a screenshot of this page. Our apologies for the inconvenience.',
  'Checkout-CheckoutDetails-Button-Save': 'Save',
  'Checkout-CheckoutDetails-Button-Next': 'Continue',
  'Checkout-CheckoutDetails-Button-Cancel': 'Cancel',
  'Checkout-CheckoutDetails-Button-NextStep': 'Next step',
  'Checkout-CheckoutDetails-title-Email': 'Email adress',
  'Checkout-CheckoutDetails-title-ShippingAddress': 'Shipping address',
  'Checkout-CheckoutDetails-title-BillingAddress': 'Billing address',
  'Checkout-CheckoutDetails-title-ShippingMethod': 'Shipping method',
  'Checkout-CheckoutDetails-Email-label-Email': 'Email',
  'Checkout-CheckoutDetails-Email-Button-EditEmail': 'Edit email',
  'Checkout-CheckoutDetails-ShippingAddress-label-YourShippingAddress': 'Your shipping address',
  'Checkout-CheckoutDetails-ShippingAddress-Button-EnterDifferentAddress': 'Change shipping address',
  'Checkout-CheckoutDetails-BillingAddress-SameAsShipping-label': 'Identical to shipping address',
  'Checkout-CheckoutDetails-BillingAddress-label-YourBillingAddress': 'Your billing address',
  'Checkout-CheckoutDetails-BillingAddress-Button-EnterDifferentAddress': 'Change billing address',
  'Checkout-CheckoutDetails-BillingAddress-CustomerType-label': 'Customer type',
  'Checkout-CheckoutDetails-BillingAddress-CustomerType-labelDesc': 'Please select what kind of customer you are. Business customers require a valid EU VAT number.',
  'Checkout-CheckoutDetails-BillingAddress-CustomerType-Private': 'Private person',
  'Checkout-CheckoutDetails-BillingAddress-CustomerType-Private-desc': 'Customer without EU VAT number',
  'Checkout-CheckoutDetails-BillingAddress-CustomerType-Company': 'Business',
  'Checkout-CheckoutDetails-BillingAddress-CustomerType-Company-desc': 'Customer with EU VAT nummer',
  'Checkout-CheckoutDetails-ShippingMethod-SelectMethod-label': 'Select your preferred shipping method',
  'Checkout-Address-Phoneline': 'Tel: {phone}',
  'Checkout-Address-Vatline': 'VAT number: {taxno}',
  'Checkout-Address-Input-Fn': 'First name',
  'Checkout-Address-Input-Ln': 'Last name',
  'Checkout-Address-Input-L': 'Country',
  'Checkout-Address-GenericAddress-S': 'Street and building number',
  'Checkout-Address-GenericAddress-P': 'Postal code',
  'Checkout-Address-GenericAddress-Nr': 'Building no.',
  'Checkout-Address-GenericAddress-NrA': 'Building no. + addition',
  'Checkout-Address-GenericAddress-C': 'City',
  'Checkout-Address-GenericAddress-R': 'Region / State',
  'Checkout-Address-Input-T': 'Phone number',
  'Checkout-Address-Input-Shipping-Cp': 'Company name (on address label)',
  'Checkout-Address-Input-Cp': 'Company name',
  'Checkout-Address-Input-Shipping-V': 'Tax number',
  'Checkout-Address-Input-V': 'VAT nummer',
  'Checkout-Address-Button-Save': 'Save',
  'Checkout-AddressStreetWarningNoNumber': 'Warning: your address does not have a building number',
  'Checkout-PostalcodecheckError-ADDRESS_NOT_FOUND': 'We could not find an address for the entered postal code, building number and addition. Please check if you’ve entered everything correctly.',
  'Checkout-PostalcodecheckError-INVALID_API_CALL': 'An error occurred while looking up your address. Please contact support.',
  'Checkout-CheckoutOverview-title-YourDeliveryDetails': 'Your delivery details',
  'Checkout-CheckoutOverview-title-Paymentmethod': 'Payment method',
  'Checkout-CheckoutOverview-YourDeliveryDetails-label-Email': 'Email',
  'Checkout-CheckoutOverview-YourDeliveryDetails-label-YourAddress': 'Your address',
  'Checkout-CheckoutOverview-YourDeliveryDetails-label-ShippingAddress': 'Shipping address',
  'Checkout-CheckoutOverview-YourDeliveryDetails-label-BillingAddress': 'Billing address',
  'Checkout-CheckoutOverview-YourDeliveryDetails-label-ShippingMethod': 'Shipping method',
  'Checkout-OrderSidebar-title-YourOrder': 'Your order',
  'Checkout-OrderSidebar-ProductItem-Quantity': 'Qty: {quantity}',
  'Checkout-OrderSidebar-totals-Shipping': 'Shipping costs: <span>{price}</span>',
  'Checkout-OrderSidebar-totals-Total': 'Total: <span>{price}</span>',
  'Checkout-OrderSidebar-totals-IncludedVAT': 'Including VAT: <span>{price}</span>',
  'CheckoutSuccess-title-Thankyou': 'Thank you for your order!',
  'CheckoutSuccess-YourOrderNoIs': 'Your order number is: <b>{orderId}</b>',
  'CheckoutSuccess-IntroWhatToExpect': 'You have just placed an order with us. Depending on what you have ordered, you can read what to expect below.',
  'CheckoutSuccess-title-PaperbackHardcover': 'Paperback / hardcover',
  'CheckoutSuccess-title-Ebook': 'eBook',
  'CheckoutSuccess-title-Isbn': 'ISBN',
  'CheckoutSuccess-title-Buscards': 'Business cards',
  'CheckoutSuccess-li1-OrderConfEmail': 'You will receive confirmation via e-mail',
  'CheckoutSuccess-li1-OrderNumberIs': 'Your order number is: <b>{orderId}</b>.',
  'CheckoutSuccess-li1-OrderConfSentTo': 'We will send an order confirmation to {email}. Note: the e-mail could end up in your spam box.',
  'CheckoutSuccess-PaperbackHardcover-li2-WeMakePOD': 'We are making your book using Print on Demand',
  'CheckoutSuccess-PaperbackHardcover-li2-WePrintRightAway': 'We are printing your order right away. Keep in mind that this will take a few days.',
  'CheckoutSuccess-PaperbackHardcover-li3-OrderWillShipped': 'Your order will be shipped',
  'CheckoutSuccess-PaperbackHardcover-li3-ShipConfEMail': 'You will receive a shipping confirmation with a tracking link via e-mail.',
  'CheckoutSuccess-PaperbackHardcover-ExpectedShippingDate': 'Expected shipping date',
  'CheckoutSuccess-PaperbackHardcover-ExpectedShippingDate-BetweenDates': 'Between {startdate} and {enddate}',
  'CheckoutSuccess-Ebook-li2-dlLinkEmail': 'Your download link is in the confirmation e-mail',
  'CheckoutSuccess-Ebook-li2-dlLinkEmailOrClickBelow': 'The link to download your e-book is in the confirmation e-mail or you can click the button below.',
  'CheckoutSuccess-Ebook-DownloadYourEbook': 'Download your e-book',
  'CheckoutSuccess-Isbn-li2-IsbnReady': 'Your ISBN will be ready within 1 hour',
  'CheckoutSuccess-Isbn-li2-IsbnReadyInStep': 'Within 1 hour, you’ll be able to find the ISBN in the step ISBN of your book. We automatically add the ISBN to the back of your cover.',
  'CheckoutSuccess-Isbn-ContinueWithBook': 'Continue with your book',
  'CheckoutSuccess-Isbn-ContinueWithBookClickHere': 'Click here to continue working on your book.',
  'CheckoutSuccess-Buscards-li2-WeMakeCards': 'We are making your business cards',
  'CheckoutSuccess-Buscards-li2-WePrintRightAway': 'We are printing your business cards right away. It will take approximately one week to deliver them.',
  'CheckoutSuccess-Buscards-li2-NoShipmentMail': 'Note: you will not receive a separate shipping confirmation for the business cards.',
  'CheckoutFailed-title-PaymentFailed': 'Your payment failed',
  'CheckoutFailed-SomethingWentWrongTryAgain': 'Something went wrong during the payment of your order. Please try again.',
  'CheckoutFailed-button-CheckoutAgain': 'Checkout again',
  'CheckoutError-title-SomethingWrong': 'Something went wrong',
  'CheckoutError-SomethingWentWrongContactUs': 'Something went wrong while finalizing the payment of your order. Please try again or contact support. Please include the following reference when contacting support: {orderId}',
  'CheckoutError-button-TryAgain': 'Try again',
  'CheckoutPending-title-PaymentInProgress': 'Your payment is being verified',
  'CheckoutPending-PaymentProcessingTakesLonger': 'Processing your payment is taking slightly longer than expected. At the moment, we cannot definitively inform you if your payment was successful.',
  'CheckoutPending-CheckAgainInFewHours': 'Please check your email in the next few hours. As soon as we receive confirmation of your payment, we’ll send you an order confirmation email. If you do not receive this confirmation, please check your bank account or credit card to verify if the payment was successful.',
  'CheckoutPending-OrderReference': 'Your order number is: {orderId}',
  'CheckoutCancelled-title-PaymentCancelled': 'You have cancelled the payment',
  'CheckoutCancelled-TryAgainIfYouWant': 'You’ve cancelled your payment. If this wasn’t your intention, please click the button below to checkout again.',
  'CheckoutCancelled-button-CheckoutAgain': 'Checkout again',

  // All ISO-3166-1 alpha 2 countries
  'Country-AD': 'Andorra',
  'Country-AE': 'United Arab Emirates',
  'Country-AF': 'Afghanistan',
  'Country-AG': 'Antigua and Barbuda',
  'Country-AI': 'Anguilla',
  'Country-AL': 'Albania',
  'Country-AM': 'Armenia',
  'Country-AO': 'Angola',
  'Country-AQ': 'Antarctica',
  'Country-AR': 'Argentina',
  'Country-AS': 'American Samoa',
  'Country-AT': 'Austria',
  'Country-AU': 'Australia',
  'Country-AW': 'Aruba',
  'Country-AX': 'Åland Islands',
  'Country-AZ': 'Azerbaijan',
  'Country-BA': 'Bosnia and Herzegovina',
  'Country-BB': 'Barbados',
  'Country-BD': 'Bangladesh',
  'Country-BE': 'Belgium',
  'Country-BF': 'Burkina Faso',
  'Country-BG': 'Bulgaria',
  'Country-BH': 'Bahrain',
  'Country-BI': 'Burundi',
  'Country-BJ': 'Benin',
  'Country-BL': 'Saint Barthélemy',
  'Country-BM': 'Bermuda',
  'Country-BN': 'Brunei',
  'Country-BO': 'Bolivia',
  'Country-BQ': 'Caribbean Netherlands',
  'Country-BR': 'Brazil',
  'Country-BS': 'Bahamas',
  'Country-BT': 'Bhutan',
  'Country-BV': 'Bouvet Island',
  'Country-BW': 'Botswana',
  'Country-BY': 'Belarus',
  'Country-BZ': 'Belize',
  'Country-CA': 'Canada',
  'Country-CC': 'Cocos (Keeling) Islands',
  'Country-CD': 'Congo (Kinshasa)',
  'Country-CF': 'Central African Republic',
  'Country-CG': 'Congo (Brazzaville)',
  'Country-CH': 'Switzerland',
  'Country-CI': 'Côte d’Ivoire',
  'Country-CK': 'Cook Islands',
  'Country-CL': 'Chile',
  'Country-CM': 'Cameroon',
  'Country-CN': 'China',
  'Country-CO': 'Colombia',
  'Country-CR': 'Costa Rica',
  'Country-CU': 'Cuba',
  'Country-CV': 'Cabo Verde',
  'Country-CW': 'Curaçao',
  'Country-CX': 'Christmas Island',
  'Country-CY': 'Cyprus',
  'Country-CZ': 'Czech Republic',
  'Country-DE': 'Germany',
  'Country-DJ': 'Djibouti',
  'Country-DK': 'Denmark',
  'Country-DM': 'Dominica',
  'Country-DO': 'Dominican Republic',
  'Country-DZ': 'Algeria',
  'Country-EC': 'Ecuador',
  'Country-EE': 'Estonia',
  'Country-EG': 'Egypt',
  'Country-EH': 'Western Sahara',
  'Country-ER': 'Eritrea',
  'Country-ES': 'Spain',
  'Country-ET': 'Ethiopia',
  'Country-FI': 'Finland',
  'Country-FJ': 'Fiji',
  'Country-FK': 'Falkland Islands',
  'Country-FM': 'Micronesia',
  'Country-FO': 'Faroe Islands',
  'Country-FR': 'France',
  'Country-GA': 'Gabon',
  'Country-GB': 'United Kingdom',
  'Country-GD': 'Grenada',
  'Country-GE': 'Georgia',
  'Country-GF': 'French Guiana',
  'Country-GG': 'Guernsey',
  'Country-GH': 'Ghana',
  'Country-GI': 'Gibraltar',
  'Country-GL': 'Greenland',
  'Country-GM': 'Gambia',
  'Country-GN': 'Guinea',
  'Country-GP': 'Guadeloupe',
  'Country-GQ': 'Equatorial Guinea',
  'Country-GR': 'Greece',
  'Country-GS': 'South Georgia and the South Sandwich Islands',
  'Country-GT': 'Guatemala',
  'Country-GU': 'Guam',
  'Country-GW': 'Guinea-Bissau',
  'Country-GY': 'Guyana',
  'Country-HK': 'Hong Kong SAR China',
  'Country-HM': 'Heard Island and McDonald Islands',
  'Country-HN': 'Honduras',
  'Country-HR': 'Croatia',
  'Country-HT': 'Haiti',
  'Country-HU': 'Hungary',
  'Country-ID': 'Indonesia',
  'Country-IE': 'Ireland',
  'Country-IL': 'Israel',
  'Country-IM': 'Isle of Man',
  'Country-IN': 'India',
  'Country-IO': 'British Indian Ocean Territory',
  'Country-IQ': 'Iraq',
  'Country-IR': 'Iran',
  'Country-IS': 'Iceland',
  'Country-IT': 'Italy',
  'Country-JE': 'Jersey',
  'Country-JM': 'Jamaica',
  'Country-JO': 'Jordan',
  'Country-JP': 'Japan',
  'Country-KE': 'Kenya',
  'Country-KG': 'Kyrgyzstan',
  'Country-KH': 'Cambodia',
  'Country-KI': 'Kiribati',
  'Country-KM': 'Comoros',
  'Country-KN': 'Saint Kitts and Nevis',
  'Country-KP': 'North Korea',
  'Country-KR': 'South Korea',
  'Country-KW': 'Kuwait',
  'Country-KY': 'Cayman Islands',
  'Country-KZ': 'Kazakhstan',
  'Country-LA': 'Laos',
  'Country-LB': 'Lebanon',
  'Country-LC': 'Saint Lucia',
  'Country-LI': 'Liechtenstein',
  'Country-LK': 'Sri Lanka',
  'Country-LR': 'Liberia',
  'Country-LS': 'Lesotho',
  'Country-LT': 'Lithuania',
  'Country-LU': 'Luxembourg',
  'Country-LV': 'Latvia',
  'Country-LY': 'Libya',
  'Country-MA': 'Morocco',
  'Country-MC': 'Monaco',
  'Country-MD': 'Moldova',
  'Country-ME': 'Montenegro',
  'Country-MF': 'Saint Martin',
  'Country-MG': 'Madagascar',
  'Country-MH': 'Marshall Islands',
  'Country-MK': 'North Macedonia',
  'Country-ML': 'Mali',
  'Country-MM': 'Myanmar (Burma)',
  'Country-MN': 'Mongolia',
  'Country-MO': 'Macao SAR China',
  'Country-MP': 'Northern Mariana Islands',
  'Country-MQ': 'Martinique',
  'Country-MR': 'Mauritania',
  'Country-MS': 'Montserrat',
  'Country-MT': 'Malta',
  'Country-MU': 'Mauritius',
  'Country-MV': 'Maldives',
  'Country-MW': 'Malawi',
  'Country-MX': 'Mexico',
  'Country-MY': 'Malaysia',
  'Country-MZ': 'Mozambique',
  'Country-NA': 'Namibia',
  'Country-NC': 'New Caledonia',
  'Country-NE': 'Niger',
  'Country-NF': 'Norfolk Island',
  'Country-NG': 'Nigeria',
  'Country-NI': 'Nicaragua',
  'Country-NL': 'Netherlands',
  'Country-NO': 'Norway',
  'Country-NP': 'Nepal',
  'Country-NR': 'Nauru',
  'Country-NU': 'Niue',
  'Country-NZ': 'New Zealand',
  'Country-OM': 'Oman',
  'Country-PA': 'Panama',
  'Country-PE': 'Peru',
  'Country-PF': 'French Polynesia',
  'Country-PG': 'Papua New Guinea',
  'Country-PH': 'Philippines',
  'Country-PK': 'Pakistan',
  'Country-PL': 'Poland',
  'Country-PM': 'Saint Pierre and Miquelon',
  'Country-PN': 'Pitcairn Islands',
  'Country-PR': 'Puerto Rico',
  'Country-PS': 'Palestinian Territories',
  'Country-PT': 'Portugal',
  'Country-PW': 'Palau',
  'Country-PY': 'Paraguay',
  'Country-QA': 'Qatar',
  'Country-RE': 'Réunion',
  'Country-RO': 'Romania',
  'Country-RS': 'Serbia',
  'Country-RU': 'Russia',
  'Country-RW': 'Rwanda',
  'Country-SA': 'Saudi Arabia',
  'Country-SB': 'Solomon Islands',
  'Country-SC': 'Seychelles',
  'Country-SD': 'Sudan',
  'Country-SE': 'Sweden',
  'Country-SG': 'Singapore',
  'Country-SH': 'Saint Helena',
  'Country-SI': 'Slovenia',
  'Country-SJ': 'Svalbard and Jan Mayen',
  'Country-SK': 'Slovakia',
  'Country-SL': 'Sierra Leone',
  'Country-SM': 'San Marino',
  'Country-SN': 'Senegal',
  'Country-SO': 'Somalia',
  'Country-SR': 'Suriname',
  'Country-SS': 'South Sudan',
  'Country-ST': 'São Tomé and Príncipe',
  'Country-SV': 'El Salvador',
  'Country-SX': 'Sint Maarten (Dutch part)',
  'Country-SY': 'Syria',
  'Country-SZ': 'Eswatini',
  'Country-TC': 'Turks and Caicos Islands',
  'Country-TD': 'Chad',
  'Country-TF': 'French Southern Territories',
  'Country-TG': 'Togo',
  'Country-TH': 'Thailand',
  'Country-TJ': 'Tajikistan',
  'Country-TK': 'Tokelau',
  'Country-TL': 'Timor-Leste',
  'Country-TM': 'Turkmenistan',
  'Country-TN': 'Tunisia',
  'Country-TO': 'Tonga',
  'Country-TR': 'Turkey',
  'Country-TT': 'Trinidad and Tobago',
  'Country-TV': 'Tuvalu',
  'Country-TW': 'Taiwan',
  'Country-TZ': 'Tanzania',
  'Country-UA': 'Ukraine',
  'Country-UG': 'Uganda',
  'Country-UM': 'U.S. Minor Outlying Islands',
  'Country-US': 'United States',
  'Country-UY': 'Uruguay',
  'Country-UZ': 'Uzbekistan',
  'Country-VA': 'Vatican City',
  'Country-VC': 'Saint Vincent and the Grenadines',
  'Country-VE': 'Venezuela',
  'Country-VG': 'British Virgin Islands',
  'Country-VI': 'U.S. Virgin Islands',
  'Country-VN': 'Vietnam',
  'Country-VU': 'Vanuatu',
  'Country-WF': 'Wallis and Futuna',
  'Country-WS': 'Samoa',
  'Country-XK': 'Kosovo',
  'Country-YE': 'Yemen',
  'Country-YT': 'Mayotte',
  'Country-ZA': 'South Africa',
  'Country-ZM': 'Zambia',
  'Country-ZW': 'Zimbabwe',
};

export default messages;
