import { SVGIcon } from 'mbs-common';
import {
  useAppDispatch,
  useAppSelector,
  useGetLanguage,
  useGetVATRateForUserProfile
} from '../../../hooks';
import {
  CartCurrency,
  PricingObject,
  ProductTypes,
  deleteItem,
  getPricePerUnitForQuantity
} from '../../cartSlice';
import { FormattedMessage } from 'react-intl';
import { VATRateReturnType } from '../../../helper/VATRate';
import { minorUnitsToCurrency } from '../../../helper/localize';

export default function CartItem({
  itemId,
  type,
  sourceCountry,
  image,
  title,
  subtitle,
  specifications,
  quantityInput,
  pricing,
  quantity,
  currency
}: {
  itemId: string;
  type: ProductTypes;
  sourceCountry: string;
  image: JSX.Element;
  title: string;
  subtitle?: string;
  specifications?: JSX.Element;
  quantityInput: JSX.Element;
  pricing: PricingObject[];
  quantity: number;
  currency: CartCurrency;
}) {
  const language = useGetLanguage();
  const dispatch = useAppDispatch();
  const handleClickRemoveItem = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    dispatch(deleteItem(itemId));
  };

  const { data: vatrate, isSuccess: isSuccesVATrate } = useGetVATRateForUserProfile(
    type,
    sourceCountry,
    VATRateReturnType.IncreaseRatio
  );

  const minorpriceForQuantity1 = getPricePerUnitForQuantity(pricing, 1);
  const minorPriceForCurrentQuantity = getPricePerUnitForQuantity(pricing, quantity);

  const totalClassName =
    minorpriceForQuantity1 !== minorPriceForCurrentQuantity
      ? 'cart-block-cartitem-amounts-totals-discounted'
      : 'cart-block-cartitem-amounts-totals-total';

  return (
    <div className="cart-block-cartitem">
      <div className="cart-block-cartitem-image">{image}</div>
      <div className="cart-block-cartitem-prodinfoamounts-container">
        <div className="cart-block-cartitem-prodinfo">
          <div className="cart-block-cartitem-prodinfo-titles">
            <div className="cart-block-cartitem-prodinfo-titles-title">{title}</div>
            {subtitle && (
              <div className="cart-block-cartitem-prodinfo-titles-subtitle">{subtitle}</div>
            )}
          </div>
          <div className="cart-block-cartitem-prodinfo-specs">{specifications}</div>
          <div className="cart-block-cartitem-prodinfo-removefromcart">
            {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
            <a onClick={handleClickRemoveItem}>
              <SVGIcon name="app/Buttons/Small/Trash" height="16" />
              <span className="cart-block-cartitem-prodinfo-removefromcart-text">
                <FormattedMessage
                  id="Checkout-Cart-RemoveFromCart"
                  defaultMessage="Remove from cart"
                />
              </span>
            </a>
          </div>
        </div>
        <div className="cart-block-cartitem-amounts">
          {quantityInput}
          {isSuccesVATrate && (
            <div className="cart-block-cartitem-amounts-totals">
              <div className={totalClassName}>
                {minorUnitsToCurrency({
                  language,
                  amount: minorPriceForCurrentQuantity * quantity * vatrate,
                  currency
                })}
              </div>
              {minorpriceForQuantity1 !== minorPriceForCurrentQuantity && (
                <>
                  <div className="cart-block-cartitem-amounts-totals-original">
                    <FormattedMessage
                      id="Checkout-Cart-CartItem-Originally"
                      defaultMessage="Originally: <span>{price}</span>"
                      values={{
                        span: (chunks: any) => <span>{chunks}</span>,
                        price: minorUnitsToCurrency({
                          language,
                          amount: minorpriceForQuantity1 * quantity,
                          multiplier: vatrate,
                          currency
                        })
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
