import { FormItem } from 'mbs-common';
import { UserAddress } from '../../user';
import { localizeAddress } from '../../helper/localize';
import { FormattedMessage } from 'react-intl';
import { Fragment } from 'react';

export type CurrentAddressBlockProps = {
  currentAddress: UserAddress;
  blockTitle: string | React.ReactNode;
  blockId: string;
} & (
  | {
      showOtherAddressButton?: true;
      handleClickOtherAddress: () => void;
      otherAddressButtonLabel: string | React.ReactNode;
    }
  | {
      showOtherAddressButton?: false;
      handleClickOtherAddress?: () => void;
      otherAddressButtonLabel?: string | React.ReactNode;
    }
);

export default function CurrentAddressBlock({
  currentAddress,
  handleClickOtherAddress,
  blockTitle,
  blockId,
  showOtherAddressButton,
  otherAddressButtonLabel
}: CurrentAddressBlockProps) {
  return (
    <FormItem label={blockTitle}>
      <div>
        {localizeAddress(currentAddress).map((line, index) => (
          <Fragment key={`${blockId}-${index}`}>
            {line.format.includes('l') ? (
              <FormattedMessage id={`Country-${line.content}`} defaultMessage={line.content} />
            ) : line.format.includes('t') ? (
              <FormattedMessage
                id="Checkout-Address-Phoneline"
                defaultMessage="Phone: {phone}"
                values={{ phone: line.content }}
              />
            ) : line.format.includes('v') ? (
              <FormattedMessage
                id="Checkout-Address-Vatline"
                defaultMessage="Tax number: {taxno}"
                values={{ taxno: line.content }}
              />
            ) : (
              line.content
            )}
            <br />
          </Fragment>
        ))}
      </div>
      {showOtherAddressButton && (
        <div>
          <button className="app-button-inlinelink" onClick={handleClickOtherAddress}>
            {otherAddressButtonLabel}
          </button>
        </div>
      )}
    </FormItem>
  );
}
