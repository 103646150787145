const LOAD_I18N_REQUEST = 'mbs-promotion/i18n/LOAD_I18N_REQUEST';
const LOAD_I18N_SUCCESS = 'mbs-promotion/i18n/LOAD_I18N_SUCCESS';
const LOAD_I18N_FAIL = 'mbs-promotion/i18n/LOAD_I18N_FAIL';
const I18N_SET_LANGUAGE = 'mbs-promotion/i18n/SET_LANGUAGE';

const initialState = {
  isLoaded: false,
  language: undefined,
  country: undefined
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case I18N_SET_LANGUAGE:
      return { ...state, language: action.language };
    default:
      return state;
  }
}
