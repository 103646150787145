import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks';
import {
  deleteItems,
  productTypeIsBook,
  productTypeIsBuscards,
  productTypeIsIsbn
} from './cartSlice';
import { Dialog } from 'mbs-common';
import { FormattedMessage, useIntl } from 'react-intl';

export default function ErrorPopUp({
  errors,
  handleClearErrors
}: {
  errors: Record<string, string>;
  handleClearErrors: () => void;
}) {
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const handleClickDeleteContinue = () => {
    const deleteArray = Object.keys(errors);
    dispatch(deleteItems(deleteArray));
    setIsVisible(false);
    if (handleClearErrors) {
      handleClearErrors();
    }
  };

  const items = useAppSelector((state) => state.cart.items);

  const buttons = [
    {
      label: intl.formatMessage({
        id: 'Checkout-Cart-ErrorPopup-Button-Cancel',
        defaultMessage: 'Cancel'
      }),
      class: 'app-button-standard',
      onClick: () => setIsVisible(false)
    },
    {
      label: intl.formatMessage({
        id: 'Checkout-Cart-ErrorPopup-Button-ContinueOrder',
        defaultMessage: 'Continue ordering'
      }),
      class: 'app-button-cta',
      onClick: handleClickDeleteContinue
    }
  ];

  const renderUnavailableProducts = Object.entries<string>(errors).map(
    ([itemId, itemError], index) => {
      if (items) {
        const cartItem = items.find((theItem) => theItem.id === itemId);
        if (cartItem) {
          if (productTypeIsBook(cartItem.type)) {
            return (
              <li key={`errorpopup-key-${index}`}>
                {cartItem.bookSpecs?.title} (
                {intl.formatMessage({
                  id: `binding-${cartItem.bookSpecs?.binding}`,
                  defaultMessage: 'Book'
                })}
                )
              </li>
            );
          } else if (productTypeIsIsbn(cartItem.type)) {
            return (
              <li key={`errorpopup-key-${index}`}>
                {intl.formatMessage({
                  id: 'Checkout-Cart-CartItem-Title-Isbn',
                  defaultMessage: 'ISBN'
                })}{' '}
                ({cartItem.isbnSpecs?.bookTitle})
              </li>
            );
          } else if (productTypeIsBuscards(cartItem.type)) {
            return (
              <li key={`errorpopup-key-${index}`}>
                {intl.formatMessage({
                  id: 'Checkout-Cart-CartItem-Title-Buscards',
                  defaultMessage: 'Business cards'
                })}{' '}
                ({cartItem.buscardsSpecs?.bookTitle})
              </li>
            );
          }
        }
      }
      return <li key={`errorpopup-key-${index}`}>{itemId}</li>;
    }
  );
  return (
    <Dialog
      id="cart-errorpopup"
      isVisible={isVisible}
      onClose={() => setIsVisible(false)}
      title={intl.formatMessage({
        id: 'Checkout-Cart-ErrorPopup-title',
        defaultMessage: 'Some products you are trying to order are no longer available.'
      })}
      buttons={buttons}
    >
      <p>
        <FormattedMessage
          id="Checkout-Cart-ErrorPopup-p1"
          defaultMessage="If you click the ‘continue ordering’ button, we’ll remove the unavailable products from your cart and you can continue to checkout."
        />
      </p>
      <p>
        <FormattedMessage
          id="Checkout-Cart-ErrorPopup-unavailableprods"
          defaultMessage="Unavailable products:"
        />
      </p>
      <ul>{renderUnavailableProducts}</ul>
    </Dialog>
  );
}
