import { Link, useHistory, useParams } from 'react-router-dom';
import useLocalStorageState from 'use-local-storage-state';
import { CheckoutResultRouteParams } from '..';
import { CheckoutState, setFullCheckoutState } from '../../checkoutSlice';
import { SVGGraphic, SectionIntroduction } from 'mbs-common';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch } from '../../../hooks';

export default function CheckoutPending() {
  let { id: orderId } = useParams<CheckoutResultRouteParams>();
  const [localorder, setLocalOrder] = useLocalStorageState<CheckoutState>(`order-${orderId}`);
  const history = useHistory();
  const dispatch = useAppDispatch();

  const handleClick = () => {
    if (localorder) {
      dispatch(setFullCheckoutState(localorder));
      history.push('/checkout');
    } else {
      history.push('/cart');
    }
  };
  return (
    <>
      <SectionIntroduction>
        <h2>
          <FormattedMessage
            id="CheckoutPending-title-PaymentInProgress"
            defaultMessage="Your payment is still being processed"
          />
        </h2>
        <p>
          <FormattedMessage
            id="CheckoutPending-PaymentProcessingTakesLonger"
            defaultMessage="Processing your payment is taking longer than expected. Due to that, we currently cannot guarantee if your payment succeeded."
          />
        </p>
        <p>
          <FormattedMessage
            id="CheckoutPending-CheckAgainInFewHours"
            defaultMessage="Please check your email in the next few hours. Once we receive confirmation of your payment, we will send you an order confirmation mail. If you do not receive it, please check with your bank/credit card if your payment succeeded."
          />
        </p>
        <p>
          <FormattedMessage
            id="CheckoutPending-OrderReference"
            defaultMessage="Your order reference is: {orderId}"
            values={{ orderId }}
          />
        </p>
      </SectionIntroduction>
    </>
  );
}
