import { FormattedMessage } from "react-intl";

export default function ProductItem({
  image,
  title,
  subtitle,
  specifications,
  quantity
}: {
  image: JSX.Element;
  title: string;
  subtitle?: string;
  specifications?: string;
  quantity: number;
}) {
  return (
    <div className="chkt-ordsdbr-prods-proditem">
      <div className="chkt-ordsdbr-prods-proditem-image">{image}</div>
      <div className="chkt-ordsdbr-prods-proditem-prodinfo">
        <div className="chkt-ordsdbr-prods-proditem-titles">
          <div className="chkt-ordsdbr-prods-proditem-titles-title">{title}</div>
          {subtitle && (
            <div className="chkt-ordsdbr-prods-proditem-titles-subtitle">{subtitle}</div>
          )}
        </div>
        {specifications && (
          <div className="chkt-ordsdbr-prods-proditem-specs">{specifications}</div>
        )}
        <div className="chkt-ordsdbr-prods-proditem-qty">
          <FormattedMessage
            id="Checkout-OrderSidebar-ProductItem-Quantity"
            defaultMessage="Amount: {quantity}"
            values={{ quantity }}
          />
        </div>
      </div>
    </div>
  );
}
