const messages = {
  'Promotion': 'Promovering',
  'binding-1': 'Hardcover',
  'binding-3': 'Paperback',
  'binding-6': 'E-bog',
  'binding-7': 'PDF',
  'interiorcolor-bw': 'Sort/Hvid',
  'interiorcolor-fc': 'Farve',
  'interiorcolor-short-bw': 'Sort/hvid',
  'interiorcolor-short-fc': 'Farve',
  'Interior color': 'Colour interior',
  'Papertype-short': 'Papir',
  'Papertype-short-1': 'Creme',
  'Papertype-short-2': 'Creme',
  'Papertype-short-3': 'Hvid',
  'Papertype-short-4': 'Hvid',
  'Papertype-short-5': 'Semi-blank',
  'Papertype-short-6': 'Semi-blank',
  'Papertype-short-7': 'Hvid',
  'Papertype-short-8': 'Blank hvid',
  'Papertype-short-9': 'Tonet',
  'Papertype-short-10': '150 grams',
  'Papertype-short-12': 'Hvid',
  'Amountpages-short': 'Sider',
  'Amountcolorpages-short': 'Farvesider',
  'Help-howdoesitwork-question': 'Sådan fungerer det?',
  'ISBN': 'ISBN',
  'Author website': 'Forfatterside',
  'Author webshop': 'Forfatterside',
  'authorwebsite-notEnabled': 'Ikke aktiveret - {link}',
  'authorwebsite-notEnabled-enablenow': 'Aktivér',
  'Link to book page': 'Link til bogens side',
  'button-downloadcover': 'Hent omslag',
  'button-downloadexcerpt': 'Hent uddrag',
  'button-copybooklink': 'Kopier link',
  'button-copyannouncementtext': 'Kopier meddelelsen',
  'button-openinstagram': 'Åben Instagram',
  'button-addtoshoppingcart': 'Tilføj til kurv',
  'button-orderbooks': 'Bestil din bog',
  'button-registerbook': 'Registrer din bog',
  'Channels-availability': 'Tilgængelighed',
  'Channelname': 'Butik',
  'Price': 'Pris',
  'Your margin': 'Din margen',
  'Bookspec-ISBN-isbn': 'ISBN: {isbn}',
  'Bookspec-LastEdited-date': 'Seneste udgave: {date}',

  'What do you have to do?': 'Hvad skal du gøre?',
  
  // form labels
  'label-announcementtext': 'Meddelelse',
  'label-instagram-addbooklinktobio': 'Tilføj salgslinket til din Instagram bio',
  'label-instagram-downloadcover': 'Download omslaget til dit opslag',
  'label-instagram-openinstagram': 'Åben Instagram',
  'label-instagram-chooseanimage': 'Vælg et billede',
  'label-attachbooklinktomessage': 'Tilføj automatisk bogens link til din besked',

  // zod validation errors
  'ZodValidationError-EmailRequired': 'Email addresse påkrævet',
  'ZodValidationError-EmailNotValidEmail': 'Indtast venligst en korrekt e-mail',
  'ZodValidationError-AddressFnRequired': 'Fornavn er påkrævet',
  'ZodValidationError-AddressFnLongerThan30': 'Fornavn kan ikke være på end 30 tegn',
  'ZodValidationError-AddressLnRequired': 'Efternavn er påkrævet',
  'ZodValidationError-AddressLnLongerThan30': 'Efternavn må ikke være længere end 30 tegn',
  'ZodValidationError-AddressSRequired': 'Gadenavn og nummer er påkrævet',
  'ZodValidationError-AddressSLongerThan50': 'Gadenavn og nummer må ikke være længere end 50 tegn',
  'ZodValidationError-AddressS2LongerThan50': 'Anden adresselinje må ikke være længere end 50 tegn',
  'ZodValidationError-AddressNrMustBeNumber': 'Gadenummer skal være et tal',
  'ZodValidationError-AddressPRequired': 'Postnummer er påkrævet',
  'ZodValidationError-AddressPLongerThan20': 'Postnummeret må ikke være mere end 20 tegn',
  'ZodValidationError-AddressCRequired': 'Bynavn er påkrævet',
  'ZodValidationError-AddressCLongerThan35': 'By må ikke være på mere end 35 tegn',
  'ZodValidationError-AddressRLongerThan35': 'Region må ikke være på mere end 35 tegn',
  'ZodValidationError-AdressLRequired': 'Land er påkrævet',
  'ZodValidationError-AddressCountryNotTwoLetterCode': 'Land skal være en 2 tegns landekode',
  'ZodValidationError-AddressCpLongerThan50': 'Firmanavn må ikke være på mere end 50 tegn',
  'ZodValidationError-AddressTLongerThan20': 'Telefonnummer må ikke være på mere end 20 tegn',
  'ZodValidationError-AddressFullNameLongerThan40': 'Fornavn må ikke være længere end 40 tegn',
  'ZodValidationError-AddressPostalCodeIncorrect': 'Postnummer er ikke genkendt',
  'ZodValidationError-AddressS2SameAsS': 'Anden adresselinje må ikke være samme som første adresselinje',
  'ZodValidationError-AddressVRequiredForCanarias': 'Skattekode er påkrævet for Vanary Islands',

  // guide popup
  'wikiguidepopup-title-selectaguide': 'Vælg en manuel',
  
  // errors
  'error-booknotpublished': 'Du kan ikke starte promoveringen af denne bog, da den ikke er udgivet endnu. Gå til ‘Mine Bøger’ for at udgive bogen.',
  'error-booknotavailable': 'Bogen du prøver at åbne eksisterer ikke. Gå til ‘Mine bøger’ og vælg en bog.',
  'error-booknotauthorized': 'Du har ikke adgang til denne side. Hvis dette er forkert, kan du skrive ind til os på publish@saxo.com ',
  'error-bookunknownerror': 'Der opstod et problem under indlæsningen af siden. Opdater siden for at prøve igen. Hvis problemet fortsætter, kontakt os på publish@saxo.com og oplys bogens nummer: {bookid}',

  // promo popup
  'promoapp-publishedpopup-title': 'Tillykke, du er nu en udgivet forfatter!',
  'promoapp-publishedpopup-paragraph1': 'TILLYKE! Din bog er nu udgivet. Sørg for at fejre dig selv, fortæl det til din famile og dine venner og nyd at bogen nu er ude.',
  'promoapp-publishedpopup-paragraph2': 'Nu hvor bogen er sat til salg er det tid til næste skridt på din rejse som forfatter og udgiver: promovering af bogen. Forfattere kan noget med ord - brug denne evne når du skal lave dit promoveringsmateriale. Er du i tvivl om hvor du skal starte? Vores promoveringstrin kan hjælpe dig til en effektiv måde at komme godt igang.',
  'promoapp-publishedpopup-startbutton': 'Start promovering',

  // promo categories
  'promoapp-havetodo-title': 'Valg du bør tage',
  'promoapp-shoulddo-title': 'Valg vi anbefaler dig at tage',
  'promoapp-coulddo-title': 'Valg der vil give bogen en hjælpende hånd',
  'blocktitle-thingsyouneedtopromote': 'Ting du behøver for at promovere bogen',

  // promo overview
  'promoapp-overview-intro-p1': 'At skive en bog er alene en stor bedrift, men bøger sælger desværre sjældet sig selv. Det er vgtigt at promovere bogen, så boghandlere og læsere ved at den eksisterer og kan købes. For mange forfattere er det en udfordring, for hvor starter man? Det kræver arbejde at få bogen ud, men de kræfter man lægger i vil være godt givet ud og give resultater hurtigere end du tror. For at hjælpe dig godt på vej giver vi dig her nogle tips og en række værktøjer til at gøre promoveringen let og effektiv.',

  // list of all promotool descriptions
  'promotool-authorwebsite': 'Forfatterside',
  'promotool-description-authorwebsite': 'Den bedste måde at sælge bøger på',
  'promotool-whatsapp': 'WhatsApp',
  'promotool-description-whatsapp': 'Sælg bøgerne direkte til dine venner, og få deres hjælp til at sprede ordet',
  'promotool-socialmedia': 'Sociale medier',
  'promotool-description-socialmedia': 'Sæt dine sociele medier op, så du kan sælge bøgerne derigennem og holde dine følgere opdateret på nye udgivelser',
  'promotool-instagram': 'Instagram',
  'promotool-description-instagram': 'Brug visuelle effekter til at promovere din bog',
  'promotool-facebook': 'Facebook',
  'promotool-description-facebook': 'Her kan du interagere med læsere eller sælge dine bøger gennem grupper. Du kan lave opslag der omhandler dit forfatterskab eller din bog og på den måde skabe interesse.',
  'promotool-twitter': 'Twitter',
  'promotool-description-twitter': 'Promover din bog til dit twitter-nætværk',
  'promotool-linkedin': 'LinkedIn',
  'promotool-description-linkedin': 'Dokumenter din rejse fra idé til færdig bog for at inspirere andre i dit professionelle netværk',
  'promotool-libraries': 'Biblioteket',
  'promotool-description-libraries': 'Få din bog ud på bibliotekerne',
  'promotool-nbdbiblion': 'Lektørudtalelse - DBC',
  'promotool-description-nbdbiblion': 'Send bogen ind til DBC - Dansk Bogfortegnelse så bogen kan komme i betragtning til at komme på biblioteket',
  'promotool-nationallibrary': 'Det Kongelige Bibliotek - Pligtlevering',
  'promotool-description-nationallibrary': 'Send din bog ind til Pligtlevering',
  'promotool-launchparty': 'Lav en begivenhed!',
  'promotool-description-launchparty': 'Ingen bogudgivelse uden. Lancering af en bog kan både foregå online og fysisk',
  'promotool-bookstores': 'Boghandler',
  'promotool-description-bookstores': 'Kontakt lokale boghandlere så de kan købe bogen hjem og den kan blive vist frem',
  'promotool-pressrelease': 'Pressemeddelelse',
  'promotool-description-pressrelease': 'Send en pressemeddelslese ud til forskellige medier så både du og bogen kan få omtale',
  'promotool-businesscards': 'Visitkort eller bogmærker',
  'promotool-description-businesscards': 'Lav visitkort eller bogmærker som du altid har på dig og eventuelt kan gives væk sammen med bogen.',

  // launch party
  'launchparty-intro-p1': 'En boglancering kan være afgørende for at bogen får sig en god start. Ikke alene kan det være en god måde at gøre opmærksom på bogen, men det er også en god måde at fejre udgivelsen med venner og familie samt en mulighed for dine læsere at møde dig som forfatter. Du kan lave et stort formelt event eller et mindre uformelt event; hvad end der passer til dig og til din bog.',
  'launchparty-bookdrinks-title': 'Uformel boglancering',
  'launchparty-bookdrinks-intro': 'Hvis du gerne vil holde det stille og roligt kan en uformel boglancering være en god idé. Det behøver ikke at være en stor og dyr fornøjelse at fejre udgivelsen. Inviter venner og familie til en kop kaffe eller en drink, vis dem bogen og fortæl om processen. Læs mere herunder for idéer til en uformel boglancering.',
  'launchparty-bookdrinks-whenandwho-title': 'Hvem skal med?',
  'launchparty-bookdrinks-whenandwho-paragraph1': 'Lav en liste over alle dem du ønsker skal med. Venner, familie, naboer og folk der har været en del af din udgivelsesproces. Find en dato, en lokation og lav en begivenhed. På facebook kan man lave private begivenheder, hvor kun de inviterede kan se tid og sted. Alternativt kan du sende invitationer ud pr. mail. Sørg for at inkludere bogens beskrivelse og forside.',
  'launchparty-bookdrinks-orderbooks-title': 'Bestil bøger',
  'launchparty-bookdrinks-orderbooks-paragraph1': 'Når du har sendt invitationer ud og har en idé om antallet af tilmeldte, kan du overveje at bestille et oplag hjem. Antag at alle gæster har lyst til at købe en bog og bestil hjem efter det. Sørg for at bestil dine bøger hjem i god tid.',
  'launchparty-bookdrinks-agenda-title': 'Program',
  'launchparty-bookdrinks-agenda-paragraph1': 'selvom det er et uformelt event kan det stadig godt betale sig at forberede et program. Sørg for at overvej hvad du gerne vil sige og hvordan. Skal det være en lille tale, skal du læse op af bogen, fortælle om rejsen fra idé til færdig bog eller noget helt andet. Du kan også fortælle om din oplevelse med at udgive selvstændigt. Sørg for at holde det konkret og relevant så dine gæster ikke mister interessen.',
  'launchparty-bookdrinks-foodandhouse-title': 'Forfriskninger',
  'launchparty-bookdrinks-foodandhouse-paragraph1': 'Sørg for at dine gæster får en forfriskning. Det er vigtigt at de hygger sig og har lyst til at blive hængende. Sørg for at dine bøger står opstillet pænt og nydeligt. Har du udgivet andre bøger kan det være en ide at have dem med også, men sørg for at det er din nye bog der får hovedrollen. Overvej om du skal bede en ven eller et familiemedlem om at tage billeder under lanceringen, det kan være en fin og gratis måde at få promoveringsmateriale til dine sociale medier.',
  'launchparty-bookdrinks-enjoy-title': 'Husk at nyd øjeblikket!',
  'launchparty-bookdrinks-enjoy-paragraph1': 'Sidst, men ikke mindst: nyd at du er til din egen boglancering!',
  'launchparty-bookpresentation-title': 'Formel boglancering',
  'launchparty-bookpresentation-intro': 'At holde en formel boglancering kan være en måde at vise at dit ambitionsniveau er højt og seriøst. Det er muligt at holde det i dit private hjem, men du bør overveje om du skal rykke lokation til et større sted. Det kunne være dit lokale bibliotek, en hyggelig café eller måske en boghandel. Til en formel boglancering bør du invitere pressen og eventuelt også bogbloggere eller en anmelder. Sørg for at tænk kreativt når du laver din gæsteliste. Herefter er det vigtigt at du får planlagt begivenheden i god tid og får stablet et program på benene der kan trække folk til.',
  'launchparty-guestlist-title': 'Lav en gæsteliste',
  'launchparty-guestlist-paragraph1': 'Et godt sted at starte kan være at lave en gæsteliste. Oplagte gæster er familie og venner, men også dem der har været med i tilblivelsen af bogen. Kolleger og folk fra dit professionelle nætværk kan også være værd at overveje. Det kan være en god idé at lave gæstelisten som det første, da det kan give dig en idé om hvor mange der potentielt kan dukke op. Dette er relevant hvis du skal ud og lede efter et lokale.',
  'launchparty-findalocation-title': 'Find en lokation og beslut en dato.',
  'launchparty-findalocation-paragraph1': 'Der findes mange gode steder du kan lancere en bog. Cafeér, biblioteker, boghandlere eller andre forretninger vil ofte gerne samarbejde. Kig også på bogens tema og se om der er et oplagt sted der passer til. Udgiver du en strikkebog kan det være at din lokale garn- og stofbutik er oplagt, eller har du udgivet en bog der omhandler dit lokalområde, kan du undersøge om byen har et historiske museeum. Tag kontakt til de steder du ønsker skal danne rammerne for bogens lancering og hør hvad der skal til for et samarbejde.',
  'launchparty-sendinvites-title': 'Send invitationer',
  'launchparty-sendinvites-paragraph1': 'Fysiske invitationer er langt fra normen - og måske lige netop derfor kan dette være en grund til at overveje det. Invitationer kan naturligvis også sendes ud på mail og en facebook-begivenhed er også en god idé under alle omstændigheder. Måske kan du få hjælpe fra stedet du skal holde lanceringen, så de kan dele begivenheden med deres følgere på sociale medier. På den måde kan du være helig at tiltrække folk der ellers ikke ville være kommet. Bed folk om at vende tilbage om de kommer eller ej - så har du en idé om hvor mange gæster der kommer.',
  'launchparty-createagenda-title': 'Program',
  'launchparty-createagenda-paragraph1': 'Et stor lancering med mange gæster kræver et tilrettelagt program. Begynd derfor at tænke over begivendheden i god tid. Et program kan eksempelvis se sådan ud:',
  'launchparty-createagenda-list1': 'Velkomstdrinks. Overvej om der skal sættes lidt musik på til at give en god stemning. Hvis du vil spare udgiften til velkomstdrinks, så sørg for at have en bar eller et bord hvor dine gæster kan købe sig noget at drikke. Hav flyers eller programmer printet og placér dem rundt omkring - evt. på stolene hvor folk skal sidde. ',
  'launchparty-createagenda-list2': 'Når dine gæster er ankommet skal de bydes velkommen. Dette kan gøres af en ansat på stedet, et familiemedlem, en ven eller naturligvis af dig selv.',
  'launchparty-createagenda-list3': 'Sørg for at der er tid til at dine gæster kan stille dig spørgsmål.',
  'launchparty-createagenda-list4': 'Vælg en passende passage fra bogen og læs den højt',
  'launchparty-createagenda-list5': 'Signér de bøger der bliver købt',
  'launchparty-createagenda-list6': 'Snacks og socialisering',
  'launchparty-contactmedia-title': 'Media',
  'launchparty-contactmedia-paragraph1': 'Vi anbefaler at du på forhånd tager kontakt til lokale medier, så de ved at begivenheden finder sted. Spørg dem om de kunne være interesserede i at deltage.',
  'launchparty-refreshments-title': 'Snacks og drinks',
  'launchparty-refreshments-paragraph1': 'Hvis ikke stedet kan hjælpe dig med at sørge for lidt til ganen, må du tænke kreativt og sørge for at der er forberedt lidt på forhånd. Hvis du selv skal stå for det, så hold det simpelt - det skal ikke tage fokus. Sørg for at snak med dem der har stedet hvor du gerne vil afholde det, så de kan være med til at beslutte hvad der er passende at servere.',
  'launchparty-orderbooks-title': 'Bøger',
  'launchparty-orderbooks-paragraph1': 'Sørg for at der er bestilt bøger hjem i god tid, så du kan have dem med til lanceringen. Det er oplagt at gæsterne kan købe bogen og få den signeret. Hvis du holder din lancering hos en boghandler kan du opleve at de gerne vil have en del af salget. Sørg for at denne aftale er på plads på forhånd.',
  'launchparty-photos-title': 'Foto og video',
  'launchparty-photos-paragraph1': 'At lave en boglancering er ikke kun godt til at vække interesse og få bogen solgt, men det er også en god mulighed for at  That’s why it’s important that you arrange for someone to take pictures and/or record the event. This doesn’t have to be a professional photographer; you probably have a friend who’s decent with a camera. You can then select some of the material to post on social media to promote and legitimise your book.',
  'launchparty-followup-title': 'Følg op efter eventet',
  'launchparty-followup-paragraph1': 'Det kan være e god ide at takke folk for at komme. Det kan man gøre ved at poste på de sociale medie. Brug gerne nogle af de billeder der blev taget på dagen og inkluder gerne et salgslink til bogen, så dem der ikke kunne komme kan finde bogen og købe den.',

  // bookstores
  'bookstores-intro-p1': 'De fysiske boghandlere har stadig rigtig meget at skulle have sagt i bogbrancen, også selvom nethandlen kun stødt vokser. De fysiske boghandlere kan have en tendens til ikke at ville tage chancer med nye, selvstændige forfattere, men på trods af det synes vi at det er værd at opsøge de fysiske boghandlere.',
  'bookstores-intro-p2': 'Hvis du får en fysisk boghandler til at tage et parti af dine bøger hjem, skal de naturligvis have en del af salget. Bestiller boghandleren gennem bogportalen, sker dette helt automatisk, og salgene vil blive registreret under din salgshistorik her på din profil, men bestiller de gennem dig kan i forhandle andre og måske bedre aftaler for jer begge. Vi har samlet et par gode råd herunder.',
  'bookstores-checklist-title': 'Gode råd til at få bogen ud i boghandlerne.',
  'bookstores-selectbookstores-title': 'Vælg de rigtige boghandlere',
  'bookstores-selectbookstores-p1': 'Første skridt er at vælge de rigtige boghandlere. Undersøg hvilke boghandlere du har i nærheden og hvilke boghandlere der kan være relevant både for dig som forfatter men også for din bog.',
  'bookstores-contactbookstore-title': 'Kontakt boghandlerne',
  'bookstores-contactbookstore-p1': 'Skriv til dem og hør om du kan lave en officiel aftale. Hvis du dukker op uanmldt er det ikke sikkert at de ansatte har tiden til at snakke med dig og kigge på dine bøger',
  'bookstores-contactbookstore-p2': 'Til mødet er det en god idé at være forberedt.',
  'bookstores-contactbookstore-list1': 'Fortæl dem lidt om dig - både som person og som forfatter. Fortæl hvorfor det er en god idé for boghandleren, at de skal sælge dine bøger.',
  'bookstores-contactbookstore-list2': 'Fortæl dem om bogen: hvad handler den om, hvorfor har du skrevet den og hvem er den skrevet til?',
  'bookstores-contactbookstore-list3': 'Tag en fysisk bog med til de ansatte i boghandleren - måske vil personalet få lyst til at læse den og anbefale den til andre, selvom den ikke kommer til at stå i butikken.',
  'bookstores-agreement-title': 'Hvis boghandleren gerne vil sælge bogen, så hør om i kan lave et forfatterarrangement sammen.',
  'bookstores-agreement-p1': 'Herefter skal du sørge for at alt det praktiske er på plads. Sørg for at forventningsafstemme med boghandleren og sørg for at aftalen findes på skrift så der ikke kan opstå uenigheder',
  'bookstores-agreement-whobuysbooks': 'Hvordan skal det foregå med betaling?',
  'bookstores-agreement-buy-option1': 'Mulighed 1: Du køber bøgerne gennem din Publish profil og sælger dem videre til boghandleren. På den måde undgår i mellemmænd og boghandleren vil måske være mere tilbøjelig til at tage bøgerne ind i butikken, hvis de ved at de kan sende dem tilbage til dig såfremt de ikke sælger. ',
  'bookstores-agreement-buy-option2': 'Mulighed 2: Boghandleren bestiller bøgerne gennem DBK og bogportalen. På denne måde bliver salgene vist under "Salgshistorik" på din profil og du vil lægge ansvaret over på DBK. På denne måde er du også fri for at tage stilling til din fortjeneste da du automatisk modtager de royalties du har fastsat under prissætningen.',
  'bookstores-agreement-howlongavailable': 'Aftal hvor længe boghandleren skal have bogen i butikken. Måske vil boghandleren være mere tilbøjelig til at bestille bogen hjem, hvis i aftaler at de kan aflevere bøgerne tilbage til dig efter en tidsperiode.',
  'bookstores-agreement-positioninstore': 'Placering af bogen i butikken. Forhør dig gerne hos de ansatte om der kan gøres noget for at bogen får en fin og synlig plads. ',
  'bookstores-beactive-title': 'Vær aktiv!',
  'bookstores-beactive-p1': 'Hvis bogen kommer ud og står hos en boghandler, kan det være en god ide at gøre folk opmærksomme på at de kan købe bogen dér.',
  'bookstores-beactive-list1': 'Den bedste måde at gøre dette på, vil være hvis boghandleren vil være med til at lave et forfatterarrangement eller måske endda være med til at holde din lanceringsfest.',
  'bookstores-beactive-list2': 'Sørg igen for at bruge dine sociale medier som promoveringskanal. Tag et billede hvor man kan se at bogen står i boghandleren og læg det op så dine følgere kan se det.',
  'bookstores-beactive-list3': '',

  // press release
  'pressrelease-intro-p1': 'En pressemeddelelse er den officielle og mest gængse måde at annocere en bogs udgivelse på. Alle forlagene gør det når de udgiver en ny bog. Her er det også en god idé at tage fat i mindre aviser og medier. Lokale medier er altid interesserede i hvad der sker af nye og spændende ting i deres lokalområde.',
  'pressrelease-intro-p2': 'Platformen genererer automatisk en pressemeddelselse til dig når din bog er udgivet. Den inkuderer alle de informationer du har indtastet i undgivelsesprocessen. Alt relevant information såsom forsiden, ISBN og bogens beskrivelse er derfor inkluderet.',
  'pressrelease-intro-p3': 'Du kan overveje at sende en bog, eller et uddrag af bogen med pressemeddelelsen. Dette kan du både gøre fysisk med også digitalt.',
  'pressrelease-identifytarget-title' : 'Overvej nøje hvem du sender ud til',
  'pressrelease-identifytarget-p1': 'Vi kan ikke understrege nok, hvor vigtigt det er at undersøge modtageren af din henvendelse. Det giver ikke mening at bruge tid og penge på at sende din lokalhistoriske slægtsroman ind til et ungdomsmagasin eller din selvhjælpsbog til en bogblogger der specialiserer sig i ungdomsromaner',
  'pressrelease-identifytarget-p2': 'Medier i dag er meget mere end tv, radio og aviser. Undersøg nettet for hjemmesider der har samme tematikker som din bog. Undersøg hvilke bogbloggere der læser samme genre - og hvis du her skrevet en bog på et andet sprog end dansk kan du med fordel sende den ud over landets grænser.',
  'pressrelease-collectcontact-title': 'Lav en liste',
  'pressrelease-collectcontact-p1': 'Det lyder måske ikke som den sjoveste opgave, men den kan vise sig at være vældig nyttig. Lav en liste over de medier og personer du sender din pressemeddelelse afsted til. På denne liste kan du indføre deres svar - på den måde ved du om det er folk du kan vende tilbage til eller om det er folk du ikke skal sende materiale til en anden gang. Hvis én siger ja til din første bog, kan det nemlig sagtens være at de også vil sige ja til nummer to.',
  'pressrelease-sendpressrelease-title': 'Send',
  'pressrelease-sendpressrelease-p1': 'Hvis du sender din pressemeddelelse ud via mail skal du skrive en lille hilsen i mailen der gør at modtageren får lyst til at læse dine vedhæftede filer. Hold det kort og undgå at brug de samme vendinger som der bruges i pressemeddelelsen. Mailen skal vække interesse for at læse videre og pressemeddelelsen skal vække interesse for selve bogen. ',
  'pressrelease-sendpressrelease-p2': 'Hvis du sender en pressemeddelelse ind til store medier eller personer, kan det være værd at overveje at sende en kopi af bogen med. Det kan både være en fysisk bog eller en digital udgave af bogen. Du kan også overveje at vedhæfte en læseprøve - det kunne eksempelvis være bogens første kapitel. Sørg for at dine kontaktoplysninger er til stede så du kan kontaktes. Det kunne jo være at folk gerne ville høre mere. ',
  'pressrelease-downloadbutton': 'Download pressemeddelelse',
  'pressrelease-followup-title': 'Følg op',
  'pressrelease-followup-p1': 'Det er ikke en selvfølge at folk responderer på en pressemeddelelse. Du kan derfor godt overveje skrive en opfølgende mail, for at høre om de har set og læst meddelelsen. Sørg dog for at der går et par uger mellem afsendelsen af pressemeddelelsen og den opfølgende mail.',
  'pressrelease-sharelinks-title': 'Share the resulting media links',
  'pressrelease-sharelinks-p1': 'Every time your book is mentioned in the media you need to share this on social media. It’s free promotion for your book, and the more people see it, the higher the chance that someone will buy your book.',

  // NBD Biblion
  'nbdbiblion-copyandpastetext': 'Klik her for at kopiere teksten',

  // Business cards
  'businesscards-intro-p1': 'Promotional materials are a great way to complement your marketing strategy. It can add a tangible, personal touch that a lot of people can appreciate in the digital age. That’s why we provide you with the option to print your own business cards, on which you can place a picture of your book along with info on where to buy it. The business cards are printed in full colour on sturdy 400 gram cardboard. Your order will be delivered to you within two weeks.',
  'businesscards-blocktitle-order': 'Order business cards',
  'businesscards-preview-label-front': 'Forside',
  'businesscards-preview-label-back': 'Bagside',
  'businesscards-numberofcards-label': 'Number of business cards',
  'businesscards-selectnumberofcards-item-label': '{number} cards - {price}',

  // whatsapp
  'whatsapp-intro-p1': 'WhatsApp, or whichever messaging app you’re using, is probably where you’re going to get your first sales. The people you reach via Whatsapp make up your own personal network, and will therefore be the ones most likely to support you as an author. Be sure to involve them in the book creation process before you spring the news of the publication and ask them for the ultimate favour of buying your book though! You could, for example, show them your cover ideas and ask for their opinions, or brainstorm the title with them. You could even send them the blurb ahead of time to get them excited.',
  'whatsapp-intro-p2': 'It might not seem like a big deal if your friends and family buy your book, but they could potentially be your biggest ambassadors. Word of mouth is king, so don’t neglect this promotion method!',
  'whatsapp-promoteviawhatsapp': 'Promote your book via WhatsApp',
  'whatsapp-button-sendviawhatsapp': 'Send via WhatsApp',
  'whatsapp-week1-title': 'Week 1 - Announcement',
  'whatsapp-week1-message': 'Hey everyone, just wanted to let you know that the book I’ve been blabbering about for so long is now finally published! I’m super excited about it and would really appreciate you spreading the word if you know anyone who might be interested in it!\n\nIf you’re interested in it yourself, or if you need a present for a book-lover, you can get your hands on a copy here:\n\n',
  'whatsapp-week2-title': 'Week 2 - (informal) book launch party',
  'whatsapp-week2-message': 'Hey again, and thanks to everyone who has already bought my book, you guys are fantastic!\n\nI just wanted to let you all know that I’m throwing a book launch party! You are of course all invited. There’ll be snacks, drinks, a short reading of an excerpt by yours truly! I’ll even sign your books for you if you like ;).\n\nIt’ll be super fun and I’d be honoured if you came!',
  'whatsapp-week3-title': 'Week 3 - Thanks for coming!',
  'whatsapp-week3-message': 'Hey everyone! Thanks to everyone who came to my book launch party, it was amazing to see you all there! Here are some great pics I took during the event.\n\nI’d also like to thank everyone who bought a copy, it really means a lot to me! If you couldn’t make it and still want to get your hands on a copy of my book, you can do that right here:',
  'whatsapp-week4-title': 'Week 4 - Review request',
  'whatsapp-week4-message': 'Hey!\n\nIf I could ask for a tiny favour, it would be a massive help to me if you could write a short review of the book on Goodreads? It would do wonders for the visibility of the book online and help me reach more people. I’d be more than happy to pay you back with a coffee one of these days!',
  'whatsapp-week4-goodreadswarning': 'Bemærk: Før du sender denne besked, er det vigtigt, at du først sender din bog til Goodreads!',
  'whatsapp-tips-title': 'Tips',
  'whatsapp-tips-groups-label': 'Create dedicated Whatsapp groups',
  'whatsapp-tips-groups': 'Chances are that you have a varied list of contacts on Whatsapp. It can be a good idea to create several groups and send specially crafted messages to each of these groups that match your usual level of familiarity.',
  'whatsapp-tips-readtheroom-label': 'Read the room',
  'whatsapp-tips-readtheroom': 'The people you’re talking to on Whatsapp are likely the people you’re closest to, which means they’re probably going to be your most loyal readers and customers. Consider nurturing that by not sending constant reminders to buy your book, but rather thank the people who do buy it. This is usually a more effective way to motivate anyone lagging behind.',
  'whatsapp-tips-askspread-label': 'Ask people to spread the word',
  'whatsapp-tips-askspread': 'Word of mouth is one of the most powerful ways to raise awareness of your book. It’s also one of the hardest. Therefore, take advantage of your Whatsapp groups and have friends and family spread the word within their own social circles.',
  'whatsapp-tips-participation-label': 'Create a sense of participation',
  'whatsapp-tips-participation': 'People on Whatsapp are likely going to be your closest and most loyal readers and customers. Nurture this by letting them know how much you appreciate them buying your book and spreading the word. Invite them to your book launch party and other book-related events, share pictures of these events in the group. Remind them of how important they are to your success.',

  // social media
  'socialmedia-intro-p1': 'Sociale medier er et af de vigtigste værktøjer til promovering. Det er gratis at bruge og forholdsvist lige til at gå til. Som en forfatter der har udgivet bøger selvstændigt og dermed også selv skal lave markedsføringen, kan vi ikke enbefale brugen af de sociale medier nok. Det er ikke bare en mulighed for at promovere sig selv og sine bøger gratis, det er også en mulighed for at kunne kommunikere og interagere med læserne. Vi har samlet lidt information om de forskellige kanaler, som forhåbbentlig kan hjælpe dig godt i gang med at promovere din bog. ',
  'socialmedia-tips-title': 'Tips',
  'socialmedia-tips-beconsistent-label': 'Vær dig selv og vær konsistent',
  'socialmedia-tips-beconsistent': 'Du har som forfatter din egen unikke stemme. Du har en unik måde hvorpå du deler dine tanker om verden, og denne stemme må gerne skinne igennem på dine sociale medier.',
  'socialmedia-tips-beshareable-label': 'Sørg for at det du ligger op kan deles.',
  'socialmedia-tips-beshareable': 'Jo flere der deler, jo større rækkevide får dine opslag. Derfor er det vigtigt at dine opslag kan deles, såfremt dine følgere skulle få lyst til dét. ',
  'socialmedia-tips-berelatable-label': 'Vær relaterbar',
  'socialmedia-tips-berelatable': 'Det er en selvfølge at det primære du gerne vil dele er om bogen og dit forfatterskab. Derfor kan det godt være en god idé at du af og til ligger noget mere hverdags-nært ud. Giv gerne dine følgere et lille indblik i din hverdag, din skriveprocess, dine opture og nedture. På den måde fpr dine følgere et nært forhold til dig. Det kan også være en god idé at finde andre forfattere som du kan følge og interagere med for på den måde at skabe et fællesskab.',
  'socialmedia-tips-hashtaglover-label': 'Brug hashtags',
  'socialmedia-tips-hashtaglover': 'Hashtags er vigtige. De hjælper til at udbrede dit opslag. Prøv både at bruge genereisk hashtags og hashtags der er knapt så generiske. Gør dig umage med at undersøg hvilke hashtags der findes, som passer til dit forfatterskab og til din bog, for på den måde at nå ud til læserne. Ikke alt som du ligger op, behøver hashtags. Fokuser på det du gerne vil dele ud vidt og bred og knyt hashtags til disse posts. Kig også gerne på de hashtags andre bruger. Hvordan gør de? Måske kan du drage inspiration til hvordan man kan bruge dem og hvilke hashtags man kan benytte.',
  'socialmedia-tips-beflexible-label': 'Tilpas dine opslag',
  'socialmedia-tips-beflexible': 'De sociale medier er forskellige og derfor skal det indhold du gerne vil dele være tilpasset de forskellige kanaler. Instagrams hovedfokus er billeder og visuelt indhold. LinkedIn har fokus på netværk. Facebook er et godt medie til at dele ting. Til sidst er der et medie som Twitter, som ikke er særlig udbredt i Danmark i forhold til eksempelvis Facebook, men det kan alligevel være et nyttigt medie at benytte hvis du skriver bøger indenfor undervisning, samfund, journalistik eller i det hele taget udgiver fagbøger. Twitters fokus er på korte, præcise sætninger. En god tommelfingerregel er at du ikke skal indsætte samme tekst på de forskellige medier. Tilpas tekst og bileldmateriale til det medie du benytter. ',
  'socialmedia-tips-beanalytical-label': 'Vær analytisk',
  'socialmedia-tips-beanalytical': 'Alle platformene kan give dig en idé om hvilket publikum der ser og interagerer med dine opslag. Brug denne viden! Hvis du opretter en virksomhedsprofil vi du få adgang til en lang række data, som kan være nyttig i din markedsføring.',

  // Instagram
  'instagram-intro-p1': 'Instagram er efterhånden ved at være et must for forlag og forfattere. Instagrams bogscene kaldes bookstagram, og er et fællesskab fyldt med bogbloggere og potentielle læsere. Specielt hvis du udgiver bøger på engelsk, er det en oplagt mulighed for at interagere med læsere fra andre lande. At bygge en stærk Instagram profil op kan være hård og tidskrævende arbejde, da det kræver at man tager sig tiden til at lave visuelt og appelerende indhold på jævnlig basis, men det kan være det hele værd.',
  'instagram-intro-p2': 'Der er tre ting du hovedsageligt skal prioritere hvis du vil benytte Instagram. Først er det vigtigt at opbygge en æstetisk og pæn profil, herefter at bruge relevante hashtags (vi har tilføjet nogle til skabelonen, men tilføj og fjerne gerne så de passer til dig og dit opslag) og til sidst at lave stories.',
  'instagram-promoteoninstagram': 'Promover din bog på Instagram',
  'instagram-switchtophone': 'Skift til din telefon',
  'instagram-switchtophone-description': 'Du skal benytte din telefon for at lave instagram-indhold. Du kan scanne QR koden herunder for at komme til mobilsiden. De fleste smartphones vil scanne QR koden automatisk hvis du åbner kameraet og holder det henover koden.',
  'instagram-setupinsta-title': 'Sæt Instragram op',
  'instagram-week1-title': '1. Fortæl at du har udgivet en bog',
  'instagram-week1-message': 'Her er den endelig: den første udgave af min bog, {title} frisk fra tryk. Det er en stor oplevelse endelig at sidde med den i hånden og jeg har glædet mig til at dele dette med jer! Bogen kan købes på saxo.com, se link i bio!\n\n{keywords} #selfpub #bookworms #publish #booklaunch',
  'instagram-week2-title': '2. Fortæl om dine første salg',
  'instagram-week2-chooseanimage-description': 'Når du laver dette post, er det vigtigt at gøre det personligt. Det er det personlige dine følgere gerne vil se og læse. Prøv både at have lidt af dit ansigt og lidt af bogen med eller gerne begge dele. Det kunne eksemplelvis være et billede af dig der holder bogen foran dig, mens du står foran en boghandler eller en reol fyldt med bøger.',
  'instagram-week2-message': 'Jeg har allerede solgt de første fem bøger! Tusind tak til jer der har udvist interesse for den og tak til jer der har købt den. Jeres støtte er helt uundværelig. Skulle du have lyst til at lære mere om hvad det er for en bog jeg har skrevet, eller måske have lyst til at købe den, kan du følge linket i min bio.\n\n#selfpublishing #writerslife',
  'instagram-week3-title': '3. Giveaway',
  'instagram-week3-chooseanimage-description': 'Når du laver dette post, er det vigtigt at visualisere hvad det er du giver væk. Hvis du vil forære to eksemplarer væk kan det være en god idé at vise to bøger på billedet. Sørg for at stil dem pænt op så bøgerne tager sig ud fra deres bedste side. Sørg også for at gøre teksten til din egen, indsæt flere hashtags og emojies.',
  'instagram-week3-message': 'Det er nu et par uger siden at jeg udgav {title}, og jeg er mildest talt ovevældet over den positive respons bogen har modtaget og den enorme større jeg har fået som ny forfatter. Af hjertet tak til jer alle!\nHvis du endnu ikke har får fat i en af bøgerne, har jeg nu en glædelig nyhed. Jeg vil nemlig forære to bøger væk til to af jer! Bøgerne vil være signerede og indholde en personlig hilsen til vinderen. Alt hvad du skal gøre er at like dette post og tagge en ven som du tænker også kunne have interesse i at læse bogen. Vinderne finder jeg på mandag og bliver annonceret i en story!\n\n#giveaway #bookstagram #indieauthor',
  'instagram-week4-title': '4. Efterspørg læsernes anmeldelser og feedback',
  'instagram-week4-chooseanimage-description': 'Når du efterspørger anmeldelser er du afhængig af dine følgere og deres velvilje til at hjælpe dig. Igen er det vigtigt at kommunikere med dem genne noget æstetisk og pænt indhold. Det kan være gennem billeder di poster, men det kan også være igennem en story. Stories kommer ofte bredere ud, og der kan du bruge Instagrams forskellige funktioner til at lave meningsmålinger eller til at besvare spørgsmål. ',
  'instagram-week4-message': 'Hej med jer! I dag vil jeg gerne bede jer om en lille tjeneste!\n\nHvis du har læst min bog, kunne jeg rigtig godt tænke mig at høre hvad du synes om den. Det vil hjælpe mig til at blive bedre som forfatter og det vil hjælpe bogen med at blive set af andre.\n\n#review #selfpub #bookstragram #indieauthor #saxopublish',
  'instagram-tips-title': 'Tips',
  'instagram-tips-visuals-label': 'Det hele handler om det visuelle udtryk',
  'instagram-tips-visuals': 'Som du måske er klar over, så handler Instargram om det visuelle udtryk. Det kan godt være en udfordring for forfattere som hellere vil lege med ord end med billeder. Heldigvis udelukker det ene ikke andet. Billeder som er gode at have med er forsiden af bogen, behind the scenes billeder af skriveprocessen samt små opdateringer fra dit liv som forfatter.',
  'instagram-tips-quotes-label': 'Citater',
  'instagram-tips-quotes': 'Citater kan være en rigtig god idé for at skabe interesse for bogens indhold. Skriv ikke et længere citat end der kan stå på et billede.',
  'instagram-tips-interact-label': 'Interager med dine følgere',
  'instagram-tips-interact': 'Fokus skal ikke altid kun være på at få din bog solgt. Det kan nemligogså være rigtig havnligt at få trofaste læsere og følgere, som kan give dig sparring og feedback og som kommer igen når du vil begynde på næste bogprojekt. Ud over dette, så fungerer instragram således, at jo mere du interagerr med andre, jo mere opmærksomhed vil din profil tiltrække. Del dine læseres anmeldelser på dine stories, kommenter på deres opslag, svar dem tilbage når de skriver til dig eller hvis de kommenterer på dine opslag.',
  'instagram-tips-bookstagram-label': 'Bookstagram',
  'instagram-tips-bookstagram': 'Der findes et stort fællesskab af læsere på Instargam som samles under den paralyterm der hedder “Bookstagram”. Dette online fællesskab er helt unikt. Her deler folk hvad de læser i øjeblikket og hvad de planlægger at læse. Det kan være en fin idé at følge nogle af disse, gerne nogle af dem som anmelder og læser bøger indenfor samme genre som dine bøger. Et mål kunne være at få en af dem til at læse og omtale din bog. Det skader hvert fald ikke at skrive til dem med en personlig hilsen og et tilbud om at fremsende din bog.',
  'instagram-tips-addlinks-label': 'Brug funktionen Link i Bio',
  'instagram-tips-addlinks': 'Det er vigtigt at du bruger funktionen med at sætte et link ind i din biografi, så dine følgere altid kan gå direkte til salgssiden hvor bogen kan købes. Du kan benytte det link vi automatisk genererer til dig eller du kan linke til din egen hjemmeside.',
  'instagram-tips-balance-label': 'Skab en god balnance',
  'instagram-tips-balance': 'Instagram prioriterer engagement højere end deling af indhold. Det er derfor ikke vigtigt at poste nye ting hver dag, men det er en god idé at være aktiv og interagere med dine følgere ofte. At ligge noget op 2-3 dage om ugen er en fornuftig mængde. Instagram stories er en god måde at dele indhold der ikke behøver at være alt for redigeret, og er en god funktion til at dele hverdagsglimt. ',
  'instagram-tips-contests-label': 'Lav konkurrencer og giveaways',
  'instagram-tips-contests': 'Alle elsker gratis ting. At lave en giveaway er den perfekte måde hvorpå du kan fange folks opmærksomhed og skabe flere følgere. ',
  'instagram-tips-scheduleposts-label': 'Skemalæg dine opslag',
  'instagram-tips-scheduleposts': 'Det kan være hård arbejde at være en selvstændig forfatter. Det er både dig der skal skrive bogen og din der skal gøre reklama for bogen. Derfor kan det være en god ide at sætte tid af til at lave noget indhold på forhånd, som du altid kan bruge hvis du mangler inspiration til ting du kan poste. Der findes også værktøjer som kan gøre det nemt for dig, hvor du kan planlægge fremad og hvor du kan time dine posts. Hvis du laver en business account på Facebook, får du adgang til det der hedder Creator Studio, som kan hjælpe dig med at lave og time post både til Facebook og Instagram. Det er et godt sted at starte.',

  // facebook
  'facebook-copyandpastetext': 'Klik her for at kopiere teksten. Indsæt teksten i feltet "status" efter at have klikket på knappen "del". Tilpas gerne teksten så den passer til dig og din bog.',
  'facebook-button-shareonfb': 'Del på Facebook',
  'facebook-intro-p1': 'For at få det meste ud af at bruge Facebook til at markedsføre din bog, skal du sørge for at dele bogen og gøre opmærksom på den så du kan få likes og kommentarer på den. Det kan også være en at du skal undersøge om der findes grupper du kan blive medlem af, hvor du kan dele bogen. Husk ikke at del bare for at del, det bliver dine følgere trætte af. Del med et budskab. Husk derfor altid at lave et lille skriv, så du ikke kun deler linket.',
  'facebook-promoteviafacebook': 'Promovér på Facebook. Nogle eksempler til hvordan du kan promovere dine bøger over tid.',
  'facebook-week1-title': '1. Fortæl at du har udgivet en bog',
  'facebook-week1-message': 'Så kom dagen! Jeg er nu officielt en udgivet forfatter. Det har været en lang process, men at få bogen ind af døren og stå med den i hånden, er det hele værd. Hvis du er interesseret i at høre mere om bogen, kan du læse mere på min side her: . Bogen kan købes hos Saxo.com',
  'facebook-week2-title': '2. Fortæl om dine første salg',
  'facebook-week2-message': 'Det glæder mig at fortælle at jeg nu har solgt de første 5 bøger! Tusind tak til de første der har købt min bog, det betyder alverden for en ny og spirende forfatter som mig. Hvis du også godt kunne tænke dig at vide mere om min bog og måske enddag har lyst til at læse med kan du finde den her:',
  'facebook-week3-title': '3. Giveaway',
  'facebook-week3-message': 'Tusind tak for den støtte i har vist i forbindelse med min bog. Det er slet ikke så dumt at være forfatter!. Jeg havde ikke i min vildseste fantasi turde drømme om at den ville få så fine ord med på vejen. For at fejre det med jer, laver jeg nu en lille giveaway. Like og skriv en kommentar til dette opslag, så er du med i lodtrækningen. Jeg udvælger to vindere af {title}. Konkurrencen løber til næste fredag.',
  'facebook-week4-title': '4. Efterspørg læsernes anmeldelser og feedback',
  'facebook-week4-message': 'Tusind tak til alle der over de seneste uger har købt min bog. Hvis der er nogle af jer der har læst bogen og har feedback til den, eller måske er der en af jer der har skrevet en anmeldelse, så vil jeg meget gerne læse det. Det kan nemlig hjælpe mig til at blive bedre som forfatter. På forhånd tusind tak!',
  'facebook-tips-title': 'Tips',
  'facebook-tips-authorpage-label': 'Forfatterside',
  'facebook-tips-authorpage': 'Overvej om du skal oprette en forfatterside på Facebook. På den måde kan du adskille din personlige profil og din forfatterprofil. ',
  'facebook-tips-engagement-label': 'Engagement',
  'facebook-tips-engagement': 'Facebook’s algoritmer handler om engagement. Der er ingen garanti for at dine opslag kommer langt ud, og derfor er det vigtigt at engagere dine følgere så de liker og kommentrer. Jo flere likes og kommentarer, jo større chance er der for at Facebook vil vise dit opslag til andre. Dette kan skabe en sneboldeffekt, som kan være rigtig gavnlig for dig som forfatter og for din bog. ',
  'facebook-tips-sellyourbook-label': 'Sælg din bog',
  'facebook-tips-sellyourbook': 'Når du poster og laver opslag er det vigtigt at dine følgere altid kan se eler finde et salgslink til bogen så de kan købe den. ',
  'facebook-tips-promoteothers-label': 'Promover andres bøger',
  'facebook-tips-promoteothers': 'Det kan virker ulogisk, men det er en gangke almindelig praksis at nye, selvstændige forfattere promoverer hinandens arbejde.',
  'facebook-tips-sharewriting-label': 'Del noget du har skrevet',
  'facebook-tips-sharewriting': 'For at få dine læsere og følgere til at blive hængende og for at få dem til at interessere sig for din bog kan det være en god idé at dele noget du har skrevet. Del derfor gerne en lille passage fra bogen, en beskrivelse af en person eller noget helt trejde som kan vække interesse for bogen og dit forfatterskab.',
  'facebook-tips-startagroup-label': 'Lav en gruppe',
  'facebook-tips-startagroup': 'Rigtig mange bruger grupperne på Facebook til at være en del af et fællesskab. Hvis du har mod på det kan du derfor oprette din egen gruppe og starte dit eget.',

  // Twitter
  'twitter-intro-p1': 'Twitter is the place for people who can make their words count, so as a writer, you should feel right at home.',
  'twitter-intro-p2': 'To make Twitter work for you, you need to tweet and, perhaps even more importantly, retweet often. Keep an eye on hashtags that are relevant to books, publishing, your particular genre, and even your personal interests. Make sure to contribute with substance to any discourses you engage with. The sales potential of Twitter is far less direct than that of e.g. Instagram, but it can nevertheless be useful in gaining a following and, by extension, ambassadors for your book.',
  'twitter-promoteviatwitter': 'Promote your book on Twitter',
  'twitter-button-shareviatwitter': 'Share on Twitter',
  'twitter-charcount': 'You have used {chars} out 280 characters',
  'twitter-week1-title': 'Week 1 - Announcement',
  'twitter-week1-message': 'My book, {title} is finally out! Head over to my website to order a copy of your own, or read some sample chapters to get a feel for the book first! #indieauthor',
  'twitter-week2-title': 'Week 2 - First sales post',
  'twitter-week2-message': 'It’s happening! First 5 books are sold; thank you so much to everyone who bought a copy! If anyone else is interested in my book, {title} you can get it on my website.',
  'twitter-week3-title': 'Week 3 - Giveaway',
  'twitter-week3-message': 'Attention #bookworms! It’s time for a #giveaway! If you want to win a signed copy of my book, follow me, like and retweet this tweet, and leave a comment explaining why you’d like a copy of the book!',
  'twitter-week4-title': 'Week 4 - Review request',
  'twitter-week4-message': 'Attention all readers! If you’ve read my book, {title} and enjoyed it, please consider going over to Goodreads and leaving a review! Not only would I love to read your opinions, but it does wonders for the book’s visibility! #selfpub',
  'twitter-tips-title': 'Tips',
  'twitter-tips-sharequotes-label': 'Share quotes',
  'twitter-tips-sharequotes': 'As you’re undoubtedly familiar with Twitter, we won’t go too deep into the basics. As it is a platform focused on concise, text-based messages, it’s very well-suited to writers. One obvious way to make use of Twitter is for sharing quotes from your book. Try to show your audience who you are as a writer.',
  'twitter-tips-interaction-label': 'Interaction above all',
  'twitter-tips-interaction': 'Twitter is not only about sharing your own ideas but also participating in ongoing discussions; probably even more so when starting out. Twitter creates the possibility to interact with potential readers, other authors and of course your own fans. The more interaction, the better!',
  'twitter-tips-balance-label': 'Balance',
  'twitter-tips-balance': 'With book sales being your main goal, it’s easy to get lost in the constant marketing of your book. However, it is important to show a personal, and even better, a funny side of yourself. People don’t want to constantly read tweets that boil down to“buy my book”; they also want to hear your stories and get a glimpse of your life!',
  'twitter-tips-pinapost-label': 'Pin a tweet',
  'twitter-tips-pinapost': 'If you’re posting on Twitter regularly and are worried specific information about your book might get lost or overlooked as a result, a good idea would be to pin a tweet! You can pin an engaging tweet that links to buying your book and that everyone can see once they go into your profile. Then you just have to get them there.',
  'twitter-tips-bio-label': 'Bio',
  'twitter-tips-bio': 'A lot of your time on Twitter is solely focused on tweeting, but don’t neglect your bio! It will be visible to everyone, so it’s a good place to squeeze in a few words describing yourself and including the title of your book. Make yourself look interesting!',

  // LinkedIn
  'linkedin-intro-p1': 'LinkedIn has more potential than you’d think for self-published authors. For one thing, it’s practically tailor-made for those who have written non-fiction. Even if you’ve written a fiction book however, you can still find a supportive audience on LinkedIn. It’s a platform well-suited to sharing your writing and publishing journey from what we like to call an “authorpreneurial” perspective. Frame your book as a project and talk about the challenges and rewards involved in working on it. Don’t forget to be human about it though!',
  'linkedin-intro-p2': 'Posting on LinkedIn will require a bit more preparatory work than on other platforms. You’re going to want to avoid being too salesy and instead focus on sharing knowledge and experiences. Writing an article, for example, will require some effort, but it will be much better received on the platform than an unabashedly sales-focused post.',
  'linkedin-promotevialinkedin': 'Promote your book on LinkedIn',
  'linkedin-copyandpastetext': 'Click here to copy the text above. Next, paste this text in the status field after clicking the share button.',
  'linkedin-button-shareonlinkedin': 'Share on LinkedIn',
  'linkedin-week1-title': 'Week 1 - Announcement',
  'linkedin-week1-message': 'I’m immensely proud to announce that my book, {title} is finally published! Becoming an author has always been a dream of mine, and it’s been an incredible experience to actually realize that dream for myself. If you’ve got any feedback on the book or want to discuss the contents in depth, I’d love to connect with you and exchange thoughts! For those who are interested, Here’s a link where you can learn more about it!\n\n#authorpreneur #publishing #books',
  'linkedin-week2-title': 'Week 2 - Book launch',
  'linkedin-week2-message': 'I recently threw my first ever book launch event and it was an absolute blast! Seeing everyone gathered to celebrate the publication of my book with me was both incredibly exciting and extremely humbling. A heartfelt thank you to all who attended, and for those who couldn’t make it, here are some nice photos from the event!\n\n#booklaunch #selfpublished #author',
  'linkedin-week3-title': 'Week 3 - Workshop',
  'linkedin-week3-message': 'My book {title} has been selling really well lately, and I’ve been noticing a keen interest in not just the subject matter, but in the process of writing and self-publishing itself. This gave me the idea to organize a free workshop to discuss writing, self-publishing and authorpreneurship with you all!  I think it’d be extremely rewarding to exchange ideas and share insights with one another. Let me know if you’d be interested!\n\n#workshop #authorpreneur',
  'linkedin-week4-title': 'Week 4 - Giveaway',
  'linkedin-week4-message': 'Publishing my book was one of the most challenging things I’ve ever done, but it really paid off! I had never imagined that the book would ever end up selling this well, and I want to celebrate that by giving away 5 copies! If you want to participate in the giveaway, just react to this post and leave a comment describing why you think the subject of my book is important today! The 5 most inspiring comments will get a copy of {title}!\n\n#authorpreneur #selfpublishing #giveaway',
  'linkedin-tips-title': 'Tips',
  'linkedin-tips-finishprofile-label': 'Finish your profile... but really finish it',
  'linkedin-tips-finishprofile': 'This may seem obvious, but a lot of people postpone completing all steps of their Linkedin profiles indefinitely. Not finishing your profile means there are fewer ways for people to find or identify with you.',
  'linkedin-tips-connect-label': 'Connect',
  'linkedin-tips-connect': 'Linkedin is all about networking; the platform is based on creating connections with people. You need to make sure you add other authors, potential readers and so on. Creating content for a small network will get you nowhere.',
  'linkedin-tips-publisharticles-label': 'Publish articles',
  'linkedin-tips-publisharticles': 'Linkedin offers the possibility of posting articles and it is extremely useful for an aspiring author! You can use this platform to share pieces of your novel, or perhaps write articles in which you share tips and experiences that other aspiring authors might enjoy. People tend to engage and read a lot on Linkedin, and articles are perfect for an author that has something to say! Additionally, articles are a great way to funnel traffic towards your website.',
  'linkedin-tips-jobposition-label': 'Make your book a job position',
  'linkedin-tips-jobposition': 'You can put your book as its own job! The title would refer to your name and the company would be the name of your book. In this way, people can clearly see your book on your Linkedin profile. You can add the dates you’ve worked on your novel along with a nice description. This is also a neat little way of announcing potential sequels.',
  'linkedin-tips-behuman-label': 'Be human',
  'linkedin-tips-behuman': 'Since Linkedin is primarily known for being a business platform, people often think they need to be formal when posting there. However, if you are an author trying to sell your book, you will earn points by developing a personalized profile, and you should focus on that! Don’t try to be something you’re not. People on LinkedIn go crazy for stories of personal growth and overcoming challenges, so use that to your advantage.',

  // Libraries
  'libraries-intro-p1': 'Enhver forfatter bør have deres bog på biblioteket hvis du spørger os. this is know as a legal deposit. Formalities aside however, depositing your book in the library is just a great way to legitimize yourself as an author. It also makes for a neat marketing opportunity, and if you can get enough people to request your book at their local libraries, you might even end up getting some copies sold!',

  // Upload zone
  'uploadzone-default-title': 'Upload',
  'uploadzone-default-title-progress': 'Dit manuskript uploades',
  'uploadzone-filerequirements': 'Filkrav: max. {filesize}, formater: {filetypes}',
  'uploadzone-button-selectfile': 'Vælg fil',
  'uploadzone-ordragfilehere': 'træk og slip din fil her',
  'uploadzone-dropfiletoupload': 'Træk en fil her for at uploade',

  // Account settings
  'AccountSettings-Security-button-2FA-title': 'Totrinsgodkendelse bruge',
  'AccountSettings-Security-button-2FA-subtitle-enabled': 'Aktiveret',
  'AccountSettings-Security-button-2FA-subtitle-disabled': 'Deaktiveret',
  'AccountSettings-Security-button-2FA-subtitle': '{enableddisabled} • Vi beder om en kode, når du logger ind fra en ny enhed.',

  // Checkout
  'Checkout-Cart-title-Yourcart': 'Din indkøbskurv',
  'Checkout-Cart-RemoveFromCart': 'Slet fra indkøbskurven',
  'Checkout-Cart-CartItem-Title-Buscards': 'Visitkort',
  'Checkout-Cart-CartItem-Title-Isbn': 'ISBN',
  'Checkout-Cart-CartItem-Originally': 'Oprindeligt: <span>{price}</span>',
  'Checkout-Cart-CartItem-Forbook': 'Til bog: {bookTitle}',
  'Checkout-Cart-CartIsEmpty': 'Din indkøbskurv er tom.',
  'Checkout-Cart-title-Total': 'Total',
  'Checkout-Cart-total-Subtotal': 'Subtotal <span>{price}</span>',
  'Checkout-Cart-total-Discount': 'Korting <span>{price}</span>',
  'Checkout-Cart-total-Total': 'Total <span>{price}</span>',
  'Checkout-Cart-Button-ProceedCheckout': 'Gå til kassen',
  'Checkout-Cart-ErrorPopup-title': 'Nogle produkter i kurven kan ikke bestilles',
  'Checkout-Cart-ErrorPopup-p1': 'Hvis du trykker på knappen "Gå til kassen", så fjerner vi de produkter fra indkøbskurven der ikke er tilgængelige, og så bliver du sendt videre til bestilling.',
  'Checkout-Cart-ErrorPopup-unavailableprods': 'Ikke tilgængelige produkter:',
  'Checkout-Cart-ErrorPopup-Button-Cancel': 'Annuller',
  'Checkout-Cart-ErrorPopup-Button-ContinueOrder': 'Gå til kassen',
  'Checkout-Cart-WarningPopup-title': 'Du er ved at bestille udgivelselspakke og bogen samtidig.',
  'Checkout-Cart-WarningPopup-p1': 'Hvis du bestiller udgivelselspakken og bogen samtidig, vil den bestilte bog <b>IKKE</b> have et ISBN. Vi vil anbefale at du først bestiller udgivelsespakken, og når bogen har fået ISBN, så bestil bogen.',
  'Checkout-Cart-WarningPopup-p2': 'Hvis du trykker på knappen “Bestil udgivelselspakken først”, så fjerner vi følgende bog/bøger fra din indkøbskurv:',
  'Checkout-Cart-WarningPopup-Button-IgnoreContinue': 'Ignorer og fortsæt',
  'Checkout-Cart-WarningPopup-Button-OrderIsbnFirst': 'Bestil udgivelselspakken først',
  'Checkout-Error-CheckoutSpecApiError': 'En uventet fejl er opstået under bestillingen. Tag venligst fat i kundeservice, og inkludere meget gerne et screenshot af siden her. Vi beklager ulejligheden mange gange.',
  'Checkout-CheckoutDetails-Button-Save': 'Gem',
  'Checkout-CheckoutDetails-Button-Next': 'Fortsæt',
  'Checkout-CheckoutDetails-Button-Cancel': 'Annuller',
  'Checkout-CheckoutDetails-Button-NextStep': 'Næste trin',
  'Checkout-CheckoutDetails-title-Email': 'E-mailadresse',
  'Checkout-CheckoutDetails-title-ShippingAddress': 'Leveringsadresse',
  'Checkout-CheckoutDetails-title-BillingAddress': 'Faktureringsadresse',
  'Checkout-CheckoutDetails-title-ShippingMethod': 'Leveringsmetode',
  'Checkout-CheckoutDetails-Email-label-Email': 'E-mail',
  'Checkout-CheckoutDetails-Email-Button-EditEmail': 'Skift e-mail',
  'Checkout-CheckoutDetails-ShippingAddress-label-YourShippingAddress': 'Din leveringsadresse',
  'Checkout-CheckoutDetails-ShippingAddress-Button-EnterDifferentAddress': 'Skift leveringsadresse',
  'Checkout-CheckoutDetails-BillingAddress-SameAsShipping-label': 'Identisk med leveringsadressen',
  'Checkout-CheckoutDetails-BillingAddress-label-YourBillingAddress': 'Din faktureringsadresse',
  'Checkout-CheckoutDetails-BillingAddress-Button-EnterDifferentAddress': 'Skift faktureringsadresse',
  'Checkout-CheckoutDetails-BillingAddress-CustomerType-label': 'Kundetype',
  'Checkout-CheckoutDetails-BillingAddress-CustomerType-labelDesc': 'Angiv hvilken type kunde du er. Erhverskunder kræver et gyldigt CVR-nummer.',
  'Checkout-CheckoutDetails-BillingAddress-CustomerType-Private': 'Privatperson',
  'Checkout-CheckoutDetails-BillingAddress-CustomerType-Private-desc': 'Kunde uden europæisk CVR-nummer',
  'Checkout-CheckoutDetails-BillingAddress-CustomerType-Company': 'Virksomhed',
  'Checkout-CheckoutDetails-BillingAddress-CustomerType-Company-desc': 'Kunde med europæisk CVR-nummer',
  'Checkout-CheckoutDetails-ShippingMethod-SelectMethod-label': 'Vælg din foretrukne leveringsmetode',
  'Checkout-Address-Phoneline': 'Tel: {phone}',
  'Checkout-Address-Vatline': 'CVR-nummer: {taxno}',
  'Checkout-Address-Input-Fn': 'Fornavn',
  'Checkout-Address-Input-Ln': 'Efternavn',
  'Checkout-Address-Input-L': 'Land',
  'Checkout-Address-GenericAddress-S': 'Gadenavn og nummer',
  'Checkout-Address-GenericAddress-P': 'Postnummer',
  'Checkout-Address-GenericAddress-Nr': 'Gadenummer',
  'Checkout-Address-GenericAddress-NrA': 'Gadenummer og etage',
  'Checkout-Address-GenericAddress-C': 'By',
  'Checkout-Address-GenericAddress-R': 'Region',
  'Checkout-Address-Input-T': 'Telefon',
  'Checkout-Address-Input-Shipping-Cp': 'Firma (på adresselabel)',
  'Checkout-Address-Input-Cp': 'Firma',
  'Checkout-Address-Input-Shipping-V': 'Skatte-nummer',
  'Checkout-Address-Input-V': 'CVR-nummer',
  'Checkout-Address-Button-Save': 'Opslaan',
  'Checkout-AddressStreetWarningNoNumber': 'Bemærk: Din adresse indeholder ikke et gadenummer',
  'Checkout-PostalcodecheckError-ADDRESS_NOT_FOUND': 'Vi kan ikke finde en adresse i det postnummer, nummer og etage. Kontroller meget gerne at alt er tastet korrekt ind.',
  'Checkout-PostalcodecheckError-INVALID_API_CALL': 'Der skete en fejl, da vi ledte efter adressen. Kontakt venligst kundeservice',
  'Checkout-CheckoutOverview-title-YourDeliveryDetails': 'Jouw bestelgegevens',
  'Checkout-CheckoutOverview-title-Paymentmethod': 'Betaalmethode',
  'Checkout-CheckoutOverview-YourDeliveryDetails-label-Email': 'E-mail',
  'Checkout-CheckoutOverview-YourDeliveryDetails-label-YourAddress': 'Jouw adres',
  'Checkout-CheckoutOverview-YourDeliveryDetails-label-ShippingAddress': 'Bezorgadres',
  'Checkout-CheckoutOverview-YourDeliveryDetails-label-BillingAddress': 'Factuuradres',
  'Checkout-CheckoutOverview-YourDeliveryDetails-label-ShippingMethod': 'Verzendmethode',
  'Checkout-OrderSidebar-title-YourOrder': 'Din bestilling',
  'Checkout-OrderSidebar-ProductItem-Quantity': 'Antal: {quantity}',
  'Checkout-OrderSidebar-totals-Shipping': 'Levering: <span>{price}</span>',
  'Checkout-OrderSidebar-totals-Total': 'Total: <span>{price}</span>',
  'Checkout-OrderSidebar-totals-IncludedVAT': 'Inklusive moms: <span>{price}</span>',
  'CheckoutSuccess-title-Thankyou': 'Vi takker for dit køb!',
  'CheckoutSuccess-YourOrderNoIs': 'Ordrenummer: <b>{orderId}</b>',
  'CheckoutSuccess-IntroWhatToExpect': 'Vi har modtaget din bestilling. Du kan læse mere om det produkt du har bestilt herunder.',
  'CheckoutSuccess-title-PaperbackHardcover': 'Paperback / hardback',
  'CheckoutSuccess-title-Ebook': 'E-bog',
  'CheckoutSuccess-title-Isbn': 'ISBN',
  'CheckoutSuccess-title-Buscards': 'Visitkort',
  'CheckoutSuccess-li1-OrderConfEmail': 'Du modtager en bekræftelse på din mail',
  'CheckoutSuccess-li1-OrderNumberIs': 'Je bestelnummer is: <b>{orderId}</b>.',
  'CheckoutSuccess-li1-OrderConfSentTo': 'Vi sender en ordrebekræftelse til {email}. Vær opmærksom på at denne mail kan havne i dit spam-filter.',
  'CheckoutSuccess-PaperbackHardcover-li2-WeMakePOD': 'Din bog laves som Print on Demand',
  'CheckoutSuccess-PaperbackHardcover-li2-WePrintRightAway': 'Vi går straks i gang med at printe dine bøger. Vær opmærksom på at produktionen kan tage flere dage.',
  'CheckoutSuccess-PaperbackHardcover-li3-OrderWillShipped': 'Din bestilling bliver afsendt',
  'CheckoutSuccess-PaperbackHardcover-li3-ShipConfEMail': 'Du modtager en mail med et track & trace link når ordren afsendes.',
  'CheckoutSuccess-PaperbackHardcover-ExpectedShippingDate': 'Forventet dato for afsendelse',
  'CheckoutSuccess-PaperbackHardcover-ExpectedShippingDate-BetweenDates': 'Mellem {startdate} og {enddate}',
  'CheckoutSuccess-Ebook-li2-dlLinkEmail': 'Dit downloadlink finder du i bekræftelsesmailen',
  'CheckoutSuccess-Ebook-li2-dlLinkEmailOrClickBelow': 'Linket til download af din e-bog finder du i mailen. Du kan også benytte knappen herunder.',
  'CheckoutSuccess-Ebook-DownloadYourEbook': 'Download din e-bog',
  'CheckoutSuccess-Isbn-li2-IsbnReady': 'Dit ISBN er klar til brug',
  'CheckoutSuccess-Isbn-li2-IsbnReadyInStep': 'Du kan finde dit ISBN på trinnet ISBN. Udgiver du din bog som Print on Demand tilføjer vi automatisk dit ISBN til bogens bagside.',
  'CheckoutSuccess-Isbn-ContinueWithBook': 'Fortsæt med din bog',
  'CheckoutSuccess-Isbn-ContinueWithBookClickHere': 'Klik her for at fortsætte med udgivelsen af din bog',
  'CheckoutSuccess-Buscards-li2-WeMakeCards': 'Vi laver dit visitkort',
  'CheckoutSuccess-Buscards-li2-WePrintRightAway': 'Vi trykker dine visitkort. Der går ca. en uge før de leveres.',
  'CheckoutSuccess-Buscards-li2-NoShipmentMail': 'Du skal være opmærksom på, at du ikke modtager en separat bekræftelsesmail på dine visitkort.',
  'CheckoutFailed-title-PaymentFailed': 'Betalingen fejlede',
  'CheckoutFailed-SomethingWentWrongTryAgain': 'Der gik noget galt under betalingen af din ordre. Prøv venligst igen.',
  'CheckoutFailed-button-CheckoutAgain': 'Prøv venligst igen',
  'CheckoutError-title-SomethingWrong': 'Noget gik galt',
  'CheckoutError-SomethingWentWrongContactUs': 'Noget gik galt i forbindelse med betalingen. Prøv venligst igen, eller kontakt kundeservice og inkluder følgende ingo{orderId}',
  'CheckoutError-button-TryAgain': 'Prøv igen',
  'CheckoutPending-title-PaymentInProgress': 'Betalingen godkendes',
  'CheckoutPending-PaymentProcessingTakesLonger': 'Godkendelsen tager lidt længere tid end ventet. Vi kan ikke lige nu sige om betalingen er gået igennem.',
  'CheckoutPending-CheckAgainInFewHours': 'Hold venligst øje med din e-mail i de næste par timer. Så snart betalingen er gået igennem, sender vi en bekræftelse til din mail. Hvis ikke du modtager denne besked, så tjek venligst hos din bank om betalingen er gået igennem',
  'CheckoutPending-OrderReference': 'Dit ordrenummer er: {orderId}',
  'CheckoutCancelled-title-PaymentCancelled': 'Du har annulleret betalingen',
  'CheckoutCancelled-TryAgainIfYouWant': 'Du har annulleret betalingen. Hvis dette var en fej, så tryk på knappen herunder, og prøv igen.',
  'CheckoutCancelled-button-CheckoutAgain': 'Prøv igen.',

  // All ISO-3166-1 alpha 2 countries
  'Country-AD': 'Andorra',
  'Country-AE': 'De Forenede Arabiske Emirater',
  'Country-AF': 'Afghanistan',
  'Country-AG': 'Antigua og Barbuda',
  'Country-AI': 'Anguilla',
  'Country-AL': 'Albanien',
  'Country-AM': 'Armenien',
  'Country-AO': 'Angola',
  'Country-AQ': 'Antarktis',
  'Country-AR': 'Argentina',
  'Country-AS': 'Amerikansk Samoa',
  'Country-AT': 'Østrig',
  'Country-AU': 'Australien',
  'Country-AW': 'Aruba',
  'Country-AX': 'Åland',
  'Country-AZ': 'Aserbajdsjan',
  'Country-BA': 'Bosnien-Hercegovina',
  'Country-BB': 'Barbados',
  'Country-BD': 'Bangladesh',
  'Country-BE': 'Belgien',
  'Country-BF': 'Burkina Faso',
  'Country-BG': 'Bulgarien',
  'Country-BH': 'Bahrain',
  'Country-BI': 'Burundi',
  'Country-BJ': 'Benin',
  'Country-BL': 'Saint Barthélemy',
  'Country-BM': 'Bermuda',
  'Country-BN': 'Brunei Darussalam',
  'Country-BO': 'Bolivia',
  'Country-BQ': 'Caribisk Nederland',
  'Country-BR': 'Brasilien',
  'Country-BS': 'Bahamas',
  'Country-BT': 'Bhutan',
  'Country-BV': 'Bouvetøen',
  'Country-BW': 'Botswana',
  'Country-BY': 'Hviderusland',
  'Country-BZ': 'Belize',
  'Country-CA': 'Canada',
  'Country-CC': 'Cocosøerne',
  'Country-CD': 'Congo-Kinshasa',
  'Country-CF': 'Centralafrikanske Republik',
  'Country-CG': 'Congo-Brazzaville',
  'Country-CH': 'Schweiz',
  'Country-CI': 'Elfenbenskysten',
  'Country-CK': 'Cookøerne',
  'Country-CL': 'Chile',
  'Country-CM': 'Cameroon',
  'Country-CN': 'Kina',
  'Country-CO': 'Colombia',
  'Country-CR': 'Costa Rica',
  'Country-CU': 'Cuba',
  'Country-CV': 'Kap Verde',
  'Country-CW': 'Curaçao',
  'Country-CX': 'Juleøen',
  'Country-CY': 'Cypern',
  'Country-CZ': 'Tjekkiet',
  'Country-DE': 'Tyskland',
  'Country-DJ': 'Djibouti',
  'Country-DK': 'Danmark',
  'Country-DM': 'Dominica',
  'Country-DO': 'Dominikanske Republik',
  'Country-DZ': 'Algeriet',
  'Country-EC': 'Ecuador',
  'Country-EE': 'Estland',
  'Country-EG': 'Egypten',
  'Country-EH': 'Vestsahara',
  'Country-ER': 'Eritrea',
  'Country-ES': 'Spanien',
  'Country-ET': 'Etiopien',
  'Country-FI': 'Finland',
  'Country-FJ': 'Fiji',
  'Country-FK': 'Falklandsøerne',
  'Country-FM': 'Mikronesien',
  'Country-FO': 'Færøerne',
  'Country-FR': 'Frankrig',
  'Country-GA': 'Gabon',
  'Country-GB': 'Storbritannien',
  'Country-GD': 'Grenada',
  'Country-GE': 'Georgien',
  'Country-GF': 'Fransk Guyana',
  'Country-GG': 'Guernsey',
  'Country-GH': 'Ghana',
  'Country-GI': 'Gibraltar',
  'Country-GL': 'Grønland',
  'Country-GM': 'Gambia',
  'Country-GN': 'Guinea',
  'Country-GP': 'Guadeloupe',
  'Country-GQ': 'Ækvatorialguinea',
  'Country-GR': 'Grækenland',
  'Country-GS': 'Sydgeorgien og Sydsandwichøerne',
  'Country-GT': 'Guatemala',
  'Country-GU': 'Guam',
  'Country-GW': 'Guinea-Bissau',
  'Country-GY': 'Guyana',
  'Country-HK': 'Hongkong',
  'Country-HM': 'Heard- og McDonald-øerne',
  'Country-HN': 'Honduras',
  'Country-HR': 'Kroatien',
  'Country-HT': 'Haiti',
  'Country-HU': 'Ungarn',
  'Country-ID': 'Indonesien',
  'Country-IE': 'Irland',
  'Country-IL': 'Israel',
  'Country-IM': 'Isle of Man',
  'Country-IN': 'Indien',
  'Country-IO': 'Det Britiske Territorium i Det Indiske Ocean',
  'Country-IQ': 'Irak',
  'Country-IR': 'Iran',
  'Country-IS': 'Island',
  'Country-IT': 'Italien',
  'Country-JE': 'Jersey',
  'Country-JM': 'Jamaica',
  'Country-JO': 'Jordan',
  'Country-JP': 'Japan',
  'Country-KE': 'Kenya',
  'Country-KG': 'Kirgisistan',
  'Country-KH': 'Cambodja',
  'Country-KI': 'Kiribati',
  'Country-KM': 'Comorerne',
  'Country-KN': 'Saint Kitts og Nevis',
  'Country-KP': 'Nordkorea',
  'Country-KR': 'Sydkorea',
  'Country-KW': 'Kuwait',
  'Country-KY': 'Caymanøerne',
  'Country-KZ': 'Kasakhstan',
  'Country-LA': 'Laos',
  'Country-LB': 'Libanon',
  'Country-LC': 'Saint Lucia',
  'Country-LI': 'Liechtenstein',
  'Country-LK': 'Sri Lanka',
  'Country-LR': 'Liberia',
  'Country-LS': 'Lesotho',
  'Country-LT': 'Litauen',
  'Country-LU': 'Luxembourg',
  'Country-LV': 'Letland',
  'Country-LY': 'Libyen',
  'Country-MA': 'Marokko',
  'Country-MC': 'Monaco',
  'Country-MD': 'Moldova',
  'Country-ME': 'Montenegro',
  'Country-MF': 'Saint Martin',
  'Country-MG': 'Madagaskar',
  'Country-MH': 'Marshalløerne',
  'Country-MK': 'Nordmakedonien',
  'Country-ML': 'Mali',
  'Country-MM': 'Myanmar (Burma)',
  'Country-MN': 'Mongoliet',
  'Country-MO': 'Macao',
  'Country-MP': 'Nordmarianerne',
  'Country-MQ': 'Martinique',
  'Country-MR': 'Mauretanien',
  'Country-MS': 'Montserrat',
  'Country-MT': 'Malta',
  'Country-MU': 'Mauritius',
  'Country-MV': 'Maldiverne',
  'Country-MW': 'Malawi',
  'Country-MX': 'Mexico',
  'Country-MY': 'Malaysia',
  'Country-MZ': 'Mozambique',
  'Country-NA': 'Namibia',
  'Country-NC': 'Ny Caledonien',
  'Country-NE': 'Niger',
  'Country-NF': 'Norfolk Island',
  'Country-NG': 'Nigeria',
  'Country-NI': 'Nicaragua',
  'Country-NL': 'Nederlandene',
  'Country-NO': 'Norge',
  'Country-NP': 'Nepal',
  'Country-NR': 'Nauru',
  'Country-NU': 'Niue',
  'Country-NZ': 'New Zealand',
  'Country-OM': 'Oman',
  'Country-PA': 'Panama',
  'Country-PE': 'Peru',
  'Country-PF': 'Fransk Polynesien',
  'Country-PG': 'Papua Ny Guinea',
  'Country-PH': 'Filippinerne',
  'Country-PK': 'Pakistan',
  'Country-PL': 'Polen',
  'Country-PM': 'Saint Pierre og Miquelon',
  'Country-PN': 'Pitcairn',
  'Country-PR': 'Puerto Rico',
  'Country-PS': 'Palæstina',
  'Country-PT': 'Portugal',
  'Country-PW': 'Palau',
  'Country-PY': 'Paraguay',
  'Country-QA': 'Qatar',
  'Country-RE': 'Réunion',
  'Country-RO': 'Rumænien',
  'Country-RS': 'Serbien',
  'Country-RU': 'Rusland',
  'Country-RW': 'Rwanda',
  'Country-SA': 'Saudi-Arabien',
  'Country-SB': 'Salomonøerne',
  'Country-SC': 'Seychellerne',
  'Country-SD': 'Sudan',
  'Country-SE': 'Sverige',
  'Country-SG': 'Singapore',
  'Country-SH': 'Saint Helena',
  'Country-SI': 'Slovenien',
  'Country-SJ': 'Svalbard og Jan Mayen',
  'Country-SK': 'Slovakiet',
  'Country-SL': 'Sierra Leone',
  'Country-SM': 'San Marino',
  'Country-SN': 'Senegal',
  'Country-SO': 'Somalia',
  'Country-SR': 'Surinam',
  'Country-SS': 'Sydsudan',
  'Country-ST': 'São Tomé og Príncipe',
  'Country-SV': 'El Salvador',
  'Country-SX': 'Sint Maarten (Hollandsk del)',
  'Country-SY': 'Syrien',
  'Country-SZ': 'Eswatini',
  'Country-TC': 'Turks- og Caicosøerne',
  'Country-TD': 'Tchad',
  'Country-TF': 'Franske Besiddelser i Det Sydlige Indiske Ocean',
  'Country-TG': 'Togo',
  'Country-TH': 'Thailand',
  'Country-TJ': 'Tadsjikistan',
  'Country-TK': 'Tokelau',
  'Country-TL': 'Østtimor',
  'Country-TM': 'Turkmenistan',
  'Country-TN': 'Tunesien',
  'Country-TO': 'Tonga',
  'Country-TR': 'Tyrkiet',
  'Country-TT': 'Trinidad og Tobago',
  'Country-TV': 'Tuvalu',
  'Country-TW': 'Taiwan',
  'Country-TZ': 'Tanzania',
  'Country-UA': 'Ukraine',
  'Country-UG': 'Uganda',
  'Country-UM': 'USA’s ydre øer',
  'Country-US': 'USA',
  'Country-UY': 'Uruguay',
  'Country-UZ': 'Usbekistan',
  'Country-VA': 'Vatikanstaten',
  'Country-VC': 'Saint Vincent og Grenadinerne',
  'Country-VE': 'Venezuela',
  'Country-VG': 'De Britiske Jomfruøer',
  'Country-VI': 'De Amerikanske Jomfruøer',
  'Country-VN': 'Vietnam',
  'Country-VU': 'Vanuatu',
  'Country-WF': 'Wallis og Futuna',
  'Country-WS': 'Samoa',
  'Country-XK': 'Kosovo',
  'Country-YE': 'Yemen',
  'Country-YT': 'Mayotte',
  'Country-ZA': 'Sydafrika',
  'Country-ZM': 'Zambia',
  'Country-ZW': 'Zimbabwe',
};

export default messages;
