import { Block, BlockContent } from 'mbs-common';
import {
  CartItemBuscards,
  CartItemEbook,
  CartItemIsbn,
  CartItemPrintBook,
  CartStateSucceeded,
  productTypeIsBuscards,
  productTypeIsEBook,
  productTypeIsIsbn,
  productTypeIsPrintBook
} from '../cartSlice';
import { FormattedMessage, useIntl } from 'react-intl';
import PrintBookItem from './PrintBookItem';
import EbookItem from './EbookItem';
import IsbnItem from './IsbnItem';
import BuscardsItem from './BuscardsItem';

export default function YourCartBlock({ cart }: { cart: CartStateSucceeded }) {
  const intl = useIntl();

  return (
    <Block
      blockTitle={intl.formatMessage({
        id: 'Checkout-Cart-title-Yourcart',
        defaultMessage: 'Your cart'
      })}
    >
      {cart.items.length > 0 ? (
        cart.items.map((item, index) => {
          if (productTypeIsPrintBook(item.type)) {
            return (
              <PrintBookItem
                cartItem={item as CartItemPrintBook}
                key={`cartItem-${index}`}
                currency={cart.config.currency}
                sourceCountry={cart.config.sourceCountry}
              />
            );
          } else if (productTypeIsEBook(item.type)) {
            return (
              <EbookItem
                cartItem={item as CartItemEbook}
                key={`cartItem-${index}`}
                currency={cart.config.currency}
                sourceCountry={cart.config.sourceCountry}
              />
            );
          } else if (productTypeIsIsbn(item.type)) {
            return (
              <IsbnItem
                cartItem={item as CartItemIsbn}
                key={`cartItem-${index}`}
                currency={cart.config.currency}
                sourceCountry={cart.config.sourceCountry}
              />
            );
          } else if (productTypeIsBuscards(item.type)) {
            return (
              <BuscardsItem
                cartItem={item as CartItemBuscards}
                key={`cartItem-${index}`}
                currency={cart.config.currency}
                sourceCountry={cart.config.sourceCountry}
              />
            );
          } else {
            return (
              <div className="cart-block-cartitem" key={`cartItem-${item.id}-${index}`}>
                {item.quantity}x {item.id} ({item.type}){' '}
              </div>
            );
          }
        })
      ) : (
        <BlockContent>
          <FormattedMessage
            id="Checkout-Cart-CartIsEmpty"
            defaultMessage="Your shopping cart is empty."
          />
        </BlockContent>
      )}
    </Block>
  );
}
