import { EGridColType, FormItem, GridCol, MBSInput } from 'mbs-common';
import { UserAddress } from '../../user';
import { UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { getPostalCodeInputType } from '../../helper/addressSupport';
import { useState } from 'react';

type GenericAddressProps = {
  selectedCountry: string;
  methods: UseFormReturn<UserAddress, any>;
};

export default function GenericAddress({ selectedCountry, methods }: GenericAddressProps) {
  const intl = useIntl();
  const {
    register,
    formState: { errors },
    getValues
  } = methods;
  const [showStreetNumberWarning, setShowStreetNumberWarning] = useState(false);

  const handleStreetCheckForNumber = () => {
    const streetValue = getValues('s');
    const street2Value = getValues('s2');
    if ((streetValue && streetValue.length > 0) || (street2Value && street2Value.length > 0)) {
      if (!streetValue.match(/[0-9]+/g) && !street2Value?.match(/[0-9]+/g)) {
        setShowStreetNumberWarning(true);
      } else {
        setShowStreetNumberWarning(false);
      }
    } else {
      setShowStreetNumberWarning(false);
    }
  };

  return (
    <>
      <GridCol type={[EGridColType.Default12]}>
        <FormItem
          label={intl.formatMessage({
            id: 'Checkout-Address-GenericAddress-S',
            defaultMessage: 'Street and building number'
          })}
        >
          <MBSInput
            {...register('s')}
            error={errors.s?.message && intl.formatMessage({ id: errors.s?.message })}
            warning={
              showStreetNumberWarning
                ? intl.formatMessage({ id: 'Checkout-AddressStreetWarningNoNumber' })
                : undefined
            }
            onBlur={handleStreetCheckForNumber}
          />
          <MBSInput
            {...register('s2')}
            error={errors.s2?.message && intl.formatMessage({ id: errors.s2?.message })}
            onBlur={handleStreetCheckForNumber}
          />
        </FormItem>
      </GridCol>
      <GridCol type={[EGridColType.Default6]}>
        <FormItem
          label={intl.formatMessage({
            id: 'Checkout-Address-GenericAddress-P',
            defaultMessage: 'Postal code'
          })}
        >
          <MBSInput
            type={getPostalCodeInputType(selectedCountry)}
            maxLength={15}
            {...register('p')}
            error={errors.p?.message && intl.formatMessage({ id: errors.p?.message })}
          />
        </FormItem>
      </GridCol>
      <GridCol type={[EGridColType.Default6]}>
        <FormItem
          label={intl.formatMessage({
            id: 'Checkout-Address-GenericAddress-C',
            defaultMessage: 'City'
          })}
        >
          <MBSInput
            maxLength={30}
            {...register('c')}
            error={errors.c?.message && intl.formatMessage({ id: errors.c?.message })}
          />
        </FormItem>
      </GridCol>
      <GridCol type={[EGridColType.Default6, EGridColType.DefaultRowEnd]}>
        <FormItem
          type="split2"
          label={intl.formatMessage({
            id: 'Checkout-Address-GenericAddress-R',
            defaultMessage: 'Region/State'
          })}
        >
          <MBSInput
            {...register('r')}
            error={errors.r?.message && intl.formatMessage({ id: errors.r?.message })}
          />
        </FormItem>
      </GridCol>
    </>
  );
}
