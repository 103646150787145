import React from 'react';

const styles = ".st0{fill:#FFFFFF;}";

const mySvg = ({ width = "20px"}) => (
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 20 18" xmlSpace="preserve" width={width}>
<style type="text/css">
	{styles}
</style>
<g>
	<polygon className="st0" points="0,18 20,9 0,0 1.1,7.9 16.6,9 1.1,10.1 	"/>
</g>
</svg>
);

export default mySvg;