import { combineReducers } from 'redux';

// import books from './books';
// import authors from './authors';
// import promoTools from './PromoTools';
import i18n from './i18n';
// import bookpromo from './bookpromo';
// import events from './events';
import notifications from './notifications';
import user from './user';
import cart from './Cart/cartSlice';
import checkout from './Checkout/checkoutSlice';
import { apiSlice } from './api/apiSlice';

export default combineReducers({
  // books,
  // authors,
  // promoTools,
  // bookpromo,
  i18n,
  // events,
  notifications,
  user,
  cart,
  checkout,
  [apiSlice.reducerPath]: apiSlice.reducer
});
