import { useHistory, useParams } from 'react-router-dom';
import useLocalStorageState from 'use-local-storage-state';
import { CheckoutResultRouteParams } from '..';
import { CheckoutState, setFullCheckoutState } from '../../checkoutSlice';
import { SVGGraphic, SectionIntroduction } from 'mbs-common';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch } from '../../../hooks';

export default function CheckoutFailed() {
  let { id: orderId } = useParams<CheckoutResultRouteParams>();
  const [localorder, setLocalOrder] = useLocalStorageState<CheckoutState>(`order-${orderId}`);
  const history = useHistory();
  const dispatch = useAppDispatch();

  const handleClick = () => {
    if (localorder) {
      dispatch(setFullCheckoutState(localorder));
      history.push('/checkout');
    } else {
      history.push('/cart');
    }
  };
  return (
    <>
      <SectionIntroduction>
        <SVGGraphic name="Bookbuddy/Warningsign" className="introsvg" />
        <h2>
          <FormattedMessage
            id="CheckoutFailed-title-PaymentFailed"
            defaultMessage="Something went wrong during payment"
          />
        </h2>
        <p>
          <FormattedMessage
            id="CheckoutFailed-SomethingWentWrongTryAgain"
            defaultMessage="Something went wrong with the payment of your order. Please try ordering again."
          />
        </p>
        <p>
          <button onClick={handleClick} className="app-button-cta">
            <FormattedMessage
              id="CheckoutFailed-button-CheckoutAgain"
              defaultMessage="Checkout again"
            />
          </button>
        </p>
      </SectionIntroduction>
    </>
  );
}
