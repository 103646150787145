import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { apiSlice } from '../api/apiSlice';
import { CustomerType, UserAddress } from '../user';
import { ProductTypes } from '../Cart/cartSlice';
import sortByKey from '../helper/sortByKey';

type CheckoutItemPricing = Array<{ quantity: number; price: number }>;

interface CheckoutItemBase {
  id: string;
  type: ProductTypes;
  bookId?: number | string;
  serviceId?: number | string;
  quantity: number;
}

interface CheckoutItem extends CheckoutItemBase {
  sku: string;
  weight: number;
  pricing: CheckoutItemPricing;
  currency: string;
  productionCountry: string;
  channel: string;
  unknownCustomsFees?: boolean;
}

interface CheckoutItemError extends CheckoutItemBase {
  error: string;
}

export type ShippingMethod = {
  currency: string;
  id: string;
  name: string;
  value: number;
};

export type CheckoutSpecification = {
  isLoading: boolean;
  items: CheckoutItem[];
  errorItems: CheckoutItemError[];
  shippingMethods: ShippingMethod[];
  defaultShippingMethod: string;
  currency: string;
  productionCountry: string;
  signature: string;
  paymentMethods?: any[];
};

export type CheckoutVatSpecification = {
  items: Record<string, { priceExcl: number; vatRate: number }>;
  shipping: Record<string, { priceExcl: number; vatRate: number }>;
};

export type CheckoutTotalAmounts = {
  productTotalExclVat: number;
  productVat: number;
  shippingTotalExclVat: number;
  shippingVat: number;
  totalInclVat: number;
};

type CheckoutBaseState = {
  cartId?: string | number;
  email?: string;
  shippingAddress?: UserAddress;
  specification?: CheckoutSpecification;
  selectedShippingMethod?: string;
  vatSpecification?: CheckoutVatSpecification;
  totalAmounts?: CheckoutTotalAmounts;
  paymentSession?: PaymentSession;
};

type CheckoutStateCustomerPrivate = {
  customerType: CustomerType.PRIVATE;
};

type CheckoutStateCustomerCompanyBookstore = {
  customerType: CustomerType.COMPANY | CustomerType.BOOKSTORE;
};

type BillingAddressSameAsShipping = {
  billingAddressSameAsShipping: true;
};

type BillingAddressNotSameAsShipping = {
  billingAddressSameAsShipping: false;
  billingAddress: UserAddress;
};

export type CheckoutState = CheckoutBaseState &
  (BillingAddressSameAsShipping | BillingAddressNotSameAsShipping) &
  (CheckoutStateCustomerPrivate | CheckoutStateCustomerCompanyBookstore);

export type CheckoutPartialState = Partial<CheckoutBaseState> &
  (BillingAddressSameAsShipping | BillingAddressNotSameAsShipping) &
  (CheckoutStateCustomerPrivate | CheckoutStateCustomerCompanyBookstore);

export type AdyenCheckoutData = {
  environment: string;
  clientKey: string;
  orderId: string;
  session: { id: string; sessionData: string };
}

export type PaymentSession = {
  psp: 'adyen';
  paymentData: AdyenCheckoutData;
};

export type DeliverytimesData = {
  minDays: number;
  maxDays: number;
  error?: string;
};

export type EbookDownloadlinksData = Array<{
  url: string;
  label: string;
}>;

export const initialState: CheckoutState = {
  customerType: CustomerType.PRIVATE,
  billingAddressSameAsShipping: true
};

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState: initialState as CheckoutState,
  reducers: {
    setInitial: (state, action: PayloadAction<CheckoutPartialState | null>) => {
      const newState = { ...initialState };
      if (action.payload !== null) {
        Object.assign(newState, action.payload);
      }
      return newState;
    },
    setShippingMethod: (state, action: PayloadAction<string>) => {
      state.selectedShippingMethod = action.payload;
    },
    setCartId: (state, action: PayloadAction<string | number>) => {
      state.cartId = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setShippingAddress: (state, action: PayloadAction<UserAddress>) => {
      if (state.shippingAddress && state.billingAddressSameAsShipping) {
        const oldShippingAddress = state.shippingAddress;
        Object.assign(state, {
          billingAddressSameAsShipping: false,
          billingAddress: oldShippingAddress,
          shippingAddress: action.payload
        });
      } else {
        state.shippingAddress = action.payload;
      }
    },
    setBillingAddress: (
      state,
      action: PayloadAction<{ billingAddress: UserAddress; customerType: CustomerType }>
    ) => {
      Object.assign(state, {
        billingAddressSameAsShipping: false,
        billingAddress: action.payload.billingAddress,
        customerType: action.payload.customerType
      });
    },
    setBillingAddressToSameAsShipping: (state, action: PayloadAction<boolean>) => {
      // if there was a different shipping address and the customer forces it to the same as the shipping address,
      // we are no longer sure of the customerType, so we have to reset it to private.
      if (!state.billingAddressSameAsShipping && state.billingAddress) {
        Object.assign(state, {
          billingAddress: undefined,
          billingAddressSameAsShipping: true,
          customerType: CustomerType.PRIVATE
        });
      }
    },
    setTotalAmounts: (state, action: PayloadAction<CheckoutTotalAmounts>) => {
      state.totalAmounts = action.payload;
    },
    setVATSpecification: (state, action: PayloadAction<CheckoutVatSpecification>) => {
      state.vatSpecification = action.payload;
    },
    setPaymentSession: (state, action: PayloadAction<PaymentSession>) => {
      state.paymentSession = action.payload;
    },
    setFullCheckoutState: (state, action: PayloadAction<CheckoutState>) => {
      state = action.payload;
    }
  },
  extraReducers(builder) {
    builder.addMatcher(
      isAnyOf(apiSlice.endpoints.requestCheckoutSpecification.matchFulfilled),
      (state, action) => {
        state.specification = action.payload;

        // if no option is set, set it to the default, so an option is preselected
        if (!state.selectedShippingMethod) {
          state.selectedShippingMethod = action.payload.defaultShippingMethod;
        } else {
          // check if current selected option still exists
          // if it doesn't exist, reset the selected option to the default.
          if (
            !action.payload.shippingMethods.find(
              (value) => value.id === state.selectedShippingMethod
            )
          ) {
            state.selectedShippingMethod = action.payload.defaultShippingMethod;
          }
        }
      }
    );
  }
});

export const getPriceForCheckoutItem = (
  pricingArray: CheckoutItemPricing,
  quantity: number
): number => {
  const sortedPricingArray: CheckoutItemPricing = sortByKey([...pricingArray], 'quantity', 'desc');

  const priceItem = sortedPricingArray.find((el, index) => {
    return el.quantity <= quantity;
  })!;

  return priceItem.price;
};

export const {
  setInitial,
  setShippingMethod,
  setCartId,
  setEmail,
  setShippingAddress,
  setBillingAddress,
  setBillingAddressToSameAsShipping,
  setTotalAmounts,
  setVATSpecification,
  setPaymentSession,
  setFullCheckoutState
} = checkoutSlice.actions;

export default checkoutSlice.reducer;
