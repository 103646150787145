import { NotificationBar } from './NotificationBar';

export { NotificationBar };

const ADD_NOTIFICATION = 'mbs-promotion/notifications/ADD_NOTIFICATION';
const MARK_NOTIFICATION_SEEN = 'mbs-promotion/notifications/MARK_NOTIFICATION_SEEN';

const initialState = [];

// type options: default (or empty), success, error, notice
export function createNotification(message = '', type = 'default', visibletime = 5000) {
  const uid = Math.floor(Math.random() * Date.now());
  return function (dispatch, getState) {
    dispatch({
      type: ADD_NOTIFICATION,
      payload: { id: uid, type, message, visibletime, seen: false }
    });
    //return;
  };
}

export function markNotificationAsSeen(id) {
  return function (dispatch, getState) {
    dispatch({ type: MARK_NOTIFICATION_SEEN, id });
    //return;
  };
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return [...state, action.payload];
    case MARK_NOTIFICATION_SEEN:
      const id = action.id;
      const nextState = state.map((item) => {
        if(item.id !== id) {
          return item;
        }

        return { ...item, seen: true };
      });
      return nextState;
    default:
      return state;
  }
}
