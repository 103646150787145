import {
  Block,
  BlockContent,
  EGridColType,
  EGridTypes,
  FormItem,
  GridCol,
  GridContainer
} from 'mbs-common';
import { useIntl } from 'react-intl';
import { useAppSelector, useGetLanguage } from '../../hooks';
// import { useKeycloak } from '@react-keycloak/web';
import CurrentAddressBlock from '../AddressInput/CurrentAddressBlock';
import { minorUnitsToCurrency } from '../../helper/localize';

export default function YourDeliveryDetails() {
  const intl = useIntl();
  // const { keycloak } = useKeycloak();
  const checkoutState = useAppSelector((state) => state.checkout);
  const language = useGetLanguage();
  const {
    email,
    shippingAddress,
    billingAddressSameAsShipping,
    selectedShippingMethod,
    specification,
    vatSpecification
  } = checkoutState;
  const billingAddress = billingAddressSameAsShipping
    ? shippingAddress
    : checkoutState.billingAddress;

  const shippingMethod = specification?.shippingMethods.find(
    (item) => item.id === selectedShippingMethod
  );

  return (
    <Block
      blockTitle={intl.formatMessage({
        id: 'Checkout-CheckoutOverview-title-YourDeliveryDetails',
        defaultMessage: 'Your delivery details'
      })}
    >
      <BlockContent>
        <GridContainer type={EGridTypes.Insidegrid}>
          {
            /* {!keycloak.authenticated  && */
            !window.__ISAUTHENTICATED__ && (
              <GridCol type={[EGridColType.Default12]}>
                <FormItem
                  label={intl.formatMessage({
                    id: 'Checkout-CheckoutOverview-YourDeliveryDetails-label-Email',
                    defaultMessage: 'E-mail'
                  })}
                >
                  {email}
                </FormItem>
              </GridCol>
            )
          }
          {shippingAddress && billingAddressSameAsShipping ? (
            <GridCol type={[EGridColType.Default12]}>
              <CurrentAddressBlock
                blockTitle={intl.formatMessage({
                  id: 'Checkout-CheckoutOverview-YourDeliveryDetails-label-YourAddress',
                  defaultMessage: 'Your address'
                })}
                blockId="checkoutoverview-youraddress"
                currentAddress={shippingAddress}
              />
            </GridCol>
          ) : shippingAddress && billingAddress ? (
            <>
              <GridCol type={[EGridColType.Default12, EGridColType.Md6]}>
                <CurrentAddressBlock
                  blockTitle={intl.formatMessage({
                    id: 'Checkout-CheckoutOverview-YourDeliveryDetails-label-ShippingAddress',
                    defaultMessage: 'Shipping address'
                  })}
                  blockId="checkoutoverview-shippingaddress"
                  currentAddress={shippingAddress}
                />
              </GridCol>
              <GridCol type={[EGridColType.Default12, EGridColType.Md6]}>
                <CurrentAddressBlock
                  blockTitle={intl.formatMessage({
                    id: 'Checkout-CheckoutOverview-YourDeliveryDetails-label-BillingAddress',
                    defaultMessage: 'Billing address'
                  })}
                  blockId="checkoutoverview-billingaddress"
                  currentAddress={billingAddress}
                />
              </GridCol>
            </>
          ) : (
            <></>
          )}
          {shippingMethod && vatSpecification ? (
            <GridCol type={[EGridColType.Default12]}>
              <FormItem
                label={intl.formatMessage({
                  id: 'Checkout-CheckoutOverview-YourDeliveryDetails-label-ShippingMethod',
                  defaultMessage: 'Shipping method'
                })}
              >
                {`${shippingMethod.name} (${minorUnitsToCurrency({
                  language,
                  amount: shippingMethod.value,
                  currency: shippingMethod.currency,
                  multiplier: 1 + vatSpecification.shipping[shippingMethod.id].vatRate
                })})`}
              </FormItem>
            </GridCol>
          ) : (
            <></>
          )}
        </GridContainer>
      </BlockContent>
    </Block>
  );
}
