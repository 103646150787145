import { Block, BlockContent, Popup, PopupBoard, PopupContent } from 'mbs-common';
import { UserAddress } from '../../../user';
import CurrentAddressBlock from '../../AddressInput/CurrentAddressBlock';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import ShippingAddressInput from './ShippingAddressInput';

export default function ShippingAddress({ currentAddress }: { currentAddress?: UserAddress }) {
  const intl = useIntl();
  const [showAddressInputPopup, setShowAddressInputPopup] = useState<boolean>(false);

  const handleShowAddressInputPopup = () => {
    setShowAddressInputPopup(true);
  };

  const handleHideAddressInputPopup = () => {
    setShowAddressInputPopup(false);
  };

  return (
    <Block
      blockTitle={intl.formatMessage({
        id: 'Checkout-CheckoutDetails-title-ShippingAddress',
        defaultMessage: 'Shipping address'
      })}
    >
      {currentAddress ? (
        <>
          <BlockContent>
            <CurrentAddressBlock
              blockId="shippingAddress"
              currentAddress={currentAddress}
              handleClickOtherAddress={handleShowAddressInputPopup}
              showOtherAddressButton={true}
              blockTitle={intl.formatMessage({
                id: 'Checkout-CheckoutDetails-ShippingAddress-label-YourShippingAddress',
                defaultMessage: 'Your shipping address'
              })}
              otherAddressButtonLabel={intl.formatMessage({
                id: 'Checkout-CheckoutDetails-ShippingAddress-Button-EnterDifferentAddress',
                defaultMessage: 'Enter other shipping address'
              })}
            />
          </BlockContent>
          <Popup
            isVisible={showAddressInputPopup}
            id="shippingAddressPopup"
            handleClose={handleHideAddressInputPopup}
            activeBoard="shippingAddressPopup-board1"
          >
            <PopupBoard
              id="shippingAddressPopup-board1"
              headerTitle={intl.formatMessage({
                id: 'Checkout-CheckoutDetails-title-ShippingAddress',
                defaultMessage: 'Shipping address'
              })}
              headerType="fixed"
              showClose={true}
            >
              <PopupContent>
                <ShippingAddressInput onComplete={handleHideAddressInputPopup} />
              </PopupContent>
            </PopupBoard>
          </Popup>
        </>
      ) : (
        <BlockContent>
          <ShippingAddressInput onComplete={() => setShowAddressInputPopup(false)} />
        </BlockContent>
      )}
    </Block>
  );
}
