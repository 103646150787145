import React, {useState, useEffect} from 'react';
import { Switch, Route } from 'react-router-dom';
//import './App.css';
import HeaderBar from './HeaderBar';
import FooterComponent from './FooterComponent';
import PoweredbyComponent from './FooterComponent/PoweredbyComponent';
import HomeComponent from './HomeComponent';
import AllbooksComponent from './AllbooksComponent';
import BookdetailsComponent from './BookdetailsComponent';
import NotFoundComponent from './NotFoundComponent';
import Cart from './Cart';
import CheckoutApp from './CheckoutApp';
import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import messagesNL from './i18n/lang/nl';
import messagesEN from './i18n/lang/en';
import messagesDA from './i18n/lang/da';
import messagesDE from './i18n/lang/de';
import messagesPT from './i18n/lang/pt';
import messagesES from './i18n/lang/es';
import messagesFR from './i18n/lang/fr';
import { useGetCartQuery } from './hooks';

function App(props) {
	const [showBackButton, setShowBackbutton] = useState(false);
	const [hasError, setErrors] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [pagePath, setPagePath] = useState(false);
	const { language } = useSelector((state) => state.i18n);
	const { isLoading: isCartLoading, isSuccess, isError, data } = useGetCartQuery();

	let apiKey = '';
  if (!hasError) {
    if (document.getElementById('root').dataset.apikey || apiKey) {
      apiKey = document.getElementById('root').dataset.apikey;
    } else {
      setErrors(true);
    }
  }

  function getMessagesForLang(lang) {
    switch (lang) {
      case 'nl':
        return messagesNL;
      case 'en':
        return messagesEN;
      case 'da':
        return messagesDA;
      case 'de':
        return messagesDE;
      case 'es':
        return messagesES;
      case 'fr':
        return messagesFR;
      case 'pt':
        return messagesPT;
      default:
        return messagesEN;
    }
  }

	const [authorData, setAuthorData] = useState({/*
		name: 'Hans Anders',
		photoUrl: 'http://www.site1.nl/site/index.php?r=rsrc/sc/anon/0',
		topBook: {
			id: 74612,
			title: 'Rotterdam in the house',
			subtitle: 'Alles over de house-scene',
			author: 'Hans Anders',
			price: '€20,00',
			binding: 3,
			bindingText: 'Paperback',
			isbn: '9789402134407',
			noPages: 220,
			publishDate: 'November 2016',
			url: 'https://www.bravenewbooks.nl/books/74611',
			imageUrl: 'https://www.bravenewbooks.nl/site/index.php?r=bookWidgets/showCoverImage&id=74611',
			relatedBooks: [],
			keywords: '',
			sizeText: '170 x 240mm',
			colorText: 'Zwart-wit',
			languageText: 'Nederlands',
			genreText: 'Kunst & Cultuur > Muziek',
			descriptionShort: 'Rotterdam in the House is het boekwerk, waarin de Rotterdamse housepioniers zelf vertellen over die unieke tijd begin jaren ‘90, waarin Rotterdam de house wereldwijd op de kaart zette.',
			description: 'Rotterdam in the House is een absolute must have voor iedere houseliefhebber. Top-dj’s en producers als Paul Elstak, Michel de Hey, Ferry Corsten, Jeroen Verheij (Secret Cinema), party-organisator Ted Langenbach en vele anderen komen uitgebreid aan het woord. Verder is er ruim aandacht voor clubs als Parkzicht, Nighttown en Carrera. Ook legendarische platenzaken als Mid-Town, Basic Beat en Hotsound komen aan bod in dit unieke boek over de Rotterdamse housepioniers.'
		},
		books: [{
			id: 122171,
			title: 'Rotterdam in the house #2',
			subtitle: '',
			author: 'Ronald Tukker',
			price: '€46,22',
			binding: 3,
			bindingText: 'Hardcover',
			isbn: '9789402163131',
			noPages: 181,
			publishDate: 'Augustus 2017',
			url: 'https://www.bravenewbooks.nl/books/122171',
			imageUrl: 'https://s.s-bol.com/imgbase0/imagebase3/large/FC/1/8/7/6/9200000078266781.jpg',
			relatedBooks: [],
			keywords: '',
			sizeText: '170 x 240mm',
			colorText: 'Kleur',
			languageText: 'Nederlands',
			genreText: 'Kunst & Cultuur > Muziek',
			descriptionShort: 'Rotterdam in the House #2 is het boekwerk, waarin toonaangevende Rotterdamse dj’s en producers vertellen over hun passie, hun muziek en hun producties. Hardcore, techno, EDM en deephouse komen volop aan bod in dit unieke boek.',
			description: 'Rotterdam in the House #2 is een absolute must have voor iedere houseliefhebber. Oliver Heldens, de huidige nummer 8 dj van de wereld, komt uitgebreid aan het woord. De hardcore komt volop aan bod met Human Resource, Sperminator, Dyewitness en 3 Steps Ahead. Verder is er ruim aandacht voor techno met Joris Voorn, Bas Mooy, Mike Drama, Strobe, Taras van de Voorde en Stranger. Ook de house is goed vertegenwoordigd met Benny Royal, Jill Kleinjan, Maarten Vorwerk (Jeckyll & Hyde), Steven Pieters en Diephuis. Allemaal komen zij aan bod in dit unieke boek over de Rotterdamse housescene, voorzien van veel nieuw fotomateriaal en unieke oude foto’s uit de archieven van de artiesten zelf.'
			},
			{
			id: 108010,
			title: 'Rotterdamse Sporthelden',
			subtitle: '',
			author: 'Ronald Tukker',
			price: '€22,57',
			binding: 3,
			bindingText: 'Paperback',
			isbn: '9789402155976',
			noPages: 181,
			publishDate: 'Augustus 2017',
			url: 'https://www.bravenewbooks.nl/books/108010',
			imageUrl: 'https://www.bravenewbooks.nl/site/index.php?r=bookWidgets/showCoverImage&id=108010',
			relatedBooks: [],
			keywords: '',
			sizeText: '170 x 240mm',
			colorText: 'Zwart-wit',
			languageText: 'Nederlands',
			genreText: 'Sport & hobby > Sportverhalen',
			descriptionShort: 'Rotterdamse Sporthelden is het boek over de topsporters van de regio Rotterdam. Auteur Ronald Tukker portretteerde 22 topsporters in woord en beeld.',
			description: 'Op de Olympische Spelen in Rio schitterden vele Rotterdamse topsporters. Judoka Anicka van Emden pakte een bronzen medaille. Bokser Nouchka Fontijn kwam thuis met een zilveren plak. Turners Bart Deurloo en Eythora Thorsdottir presteerden historisch goed. De Rotterdamse topsportwereld is echter breder dan alleen de toppers, die volop in the picture stonden tijdens de Spelen. Dit boek is een bloemlezing en een dwarsdoorsnede van de Rotterdamse topsportwereld. Een must have voor iedere sportliefhebber!\nDe sporters, die in woord en beeld aan bod komen in dit boek: Anicka van Emden (judo), Bart Deurloo (turnen), Britt Eerland (tafeltennis), Chantal Blaak (wielrennen), Eythora Thorsdottir (turnen), Francisco Elson (basketbal), Ingmar Vos (atletiek), Jeroen Hertzberger (hockey), Joost Luiten (golf), Madelon Baans (zwemmen), Mady Tims (korfbal), Marhinde Verkerk (judo), Marieke Keijser (roeien), Mark Koevermans (tennis), Nelli Cooman (atletiek), Nouchka Fontijn (boksen), Peter Houtman (voetbal), Pirmin Blaak (hockey), Raemon Sluiter (tennis), Robert Doornbos (autosport), Robin van Galen (waterpolo) & Thomas Verhaar (voetbal).'
		},
		{
			id: 74612,
			title: 'Rotterdam in the house',
			subtitle: 'Alles over de house-scene',
			author: 'Hans Anders',
			price: '€20,00',
			binding: 3,
			bindingText: 'Paperback',
			isbn: '9789402134407',
			noPages: 220,
			publishDate: 'November 2016',
			url: 'https://www.bravenewbooks.nl/books/74611',
			imageUrl: 'https://www.bravenewbooks.nl/site/index.php?r=bookWidgets/showCoverImage&id=74611',
			relatedBooks: [],
			keywords: '',
			sizeText: '170 x 240mm',
			colorText: 'Zwart-wit',
			languageText: 'Nederlands',
			genreText: 'Kunst & Cultuur > Muziek',
			descriptionShort: 'Rotterdam in the House is het boekwerk, waarin de Rotterdamse housepioniers zelf vertellen over die unieke tijd begin jaren ‘90, waarin Rotterdam de house wereldwijd op de kaart zette.',
			description: 'Rotterdam in the House is een absolute must have voor iedere houseliefhebber. Top-dj’s en producers als Paul Elstak, Michel de Hey, Ferry Corsten, Jeroen Verheij (Secret Cinema), party-organisator Ted Langenbach en vele anderen komen uitgebreid aan het woord. Verder is er ruim aandacht voor clubs als Parkzicht, Nighttown en Carrera. Ook legendarische platenzaken als Mid-Town, Basic Beat en Hotsound komen aan bod in dit unieke boek over de Rotterdamse housepioniers.'
		},
		{
			id: 74319,
			title: 'Rotterdam in the house',
			subtitle: 'Alles over de house-scene',
			author: 'Hans Anders',
			price: '€9,99',
			binding: 6,
			bindingText: 'E-book',
			isbn: '9789402134230',
			noPages: 0,
			publishDate: 'November 2016',
			url: 'https://www.bravenewbooks.nl/books/74319',
			imageUrl: 'https://www.bravenewbooks.nl/site/index.php?r=bookWidgets/showCoverImage&id=74319',
			relatedBooks: [],
			keywords: '',
			sizeText: '170 x 240mm',
			colorText: 'Zwart-wit',
			languageText: 'Nederlands',
			genreText: 'Kunst & Cultuur > Muziek',
			descriptionShort: 'Rotterdam in the House is het boekwerk, waarin de Rotterdamse housepioniers zelf vertellen over die unieke tijd begin jaren ‘90, waarin Rotterdam de house wereldwijd op de kaart zette.',
			description: 'Rotterdam in the House is een absolute must have voor iedere houseliefhebber. Top-dj’s en producers als Paul Elstak, Michel de Hey, Ferry Corsten, Jeroen Verheij (Secret Cinema), party-organisator Ted Langenbach en vele anderen komen uitgebreid aan het woord. Verder is er ruim aandacht voor clubs als Parkzicht, Nighttown en Carrera. Ook legendarische platenzaken als Mid-Town, Basic Beat en Hotsound komen aan bod in dit unieke boek over de Rotterdamse housepioniers.'
		},
		{
			id: 117098,
			title: 'Dordrecht in detail',
			subtitle: '',
			author: 'Hans Anders',
			price: '€25,66',
			binding: 3,
			bindingText: 'Paperback',
			isbn: '9789402160772',
			noPages: 98,
			publishDate: 'November 2019',
			url: 'https://www.bravenewbooks.nl/books/117098',
			imageUrl: 'https://www.bravenewbooks.nl/site/index.php?r=bookWidgets/showCoverImage&id=117098',
			relatedBooks: [],
			keywords: '',
			sizeText: '193 x 260mm',
			colorText: 'Kleur',
			languageText: 'Nederlands',
			genreText: 'Kunst & Cultuur > Fotografie',
			descriptionShort: 'Dordrecht in Detail is een uniek fotoboek, dat is opgedragen aan alle Dordtenaren. Het is een bijzondere stad om in te wonen, geniet van de vele herkenbare stadsgezichten en ontdek details die je misschien nog nooit eerder hebt gezien.',
			description: 'Een stad is tijdloos. Mensen komen en gaan, geven er kleur en sfeer aan en zijn prachtig in al hun diversiteit. Generaties inwoners markeren er hun eigen plekje, slopen en bouwen. Ze geven beweging aan het straatbeeld. In de hoop dat wat ze maken zo lang mogelijk blijft bestaan. Dit boek is opgedragen aan alle Dordtenaren. Het is een bijzondere stad om in te wonen, geniet van de herkenbare stadsgezichten en ontdek details die je misschien nog nooit eerder hebt gezien. Ga op zoektocht in je eigen stad en wie weet heeft zij een plek gefotografeerd waar ook jij een speciale herinnering aan hebt.\n\n‘Nicolette laat je Dordrecht zien, zoals je het nog niet eerder zag. Ze zoomt in op vele details.’ – Ronald Tukker, auteur van Rotterdamse Sporthelden en Dordrecht in the House.\n\nOver de fotografe:\nNicolette Vermeulen heeft zich verschillende stijlen van fotografie eigen gemaakt. Zij wisselt liever af dan zich tot één stijl te beperken. Kenmerkend aan haar foto’s zijn de compositie, met aandacht voor symmetrie en diagonale lijnen en het lichte, heldere kleurgebruik. Lange tijd woonde en werkte zij in Dordrecht. In 2015 heeft ze de stad verlaten en dat inspireerde haar tot dit boek.'
		}],
		socialmedia: {
			facebook:'https://www.facebook.com/mbruijne',
			linkedin: '',
		},
		email: 'pietjepuk@hotmail.com',
		translations: {
			isbnlabel: 'ISBN:',
			nopageslabel: 'pagina’s',
			titleAboutme: 'Over Ronald Tukker',
			titleMybooks: 'Mijn boeken',
			buybuttonFull: 'Koop nu',
			buybuttonEbook: 'e-book',
			titleStayintouch: 'Blijf op de hoogte',
			titleSendmemessage: 'Stuur me een bericht',
			titleGetsocial: 'Volg me op social media',
			titleAllbooks: 'Alle boeken',
			textSendmemessage: 'Ik ben benieuwd wat je van mijn boeken vindt! Als je me een bericht wilt sturen, gebruik dan de knop hieronder of kopieer mijn e-mailadres.',
			textGetsocial: 'Vind je het leuk om continu op de hoogte blijven van mijn werk? Volg me dan op de onderstaande social media!',
			buttonSendemail: 'Email sturen',
			textPoweredby: 'Powered by Mybestseller.com',
			linkAllbooks: 'Alle boeken',
			placeholderSearch: 'Zoeken...',
			blocktitleDescription: 'Samenvatting',
			blocktitleSpecifications: 'Specificaties',
			textNoBooks: 'Er zijn helaas geen boeken die getoond kunnen worden.',
		},
		biography: 'Ronald Tukker (1974) schreef in 2015 het boek Rotterdam in the House, over de housepioniers uit Rotterdam en directe omgeving. Het boek werd een doorslaand succes. De roep om een tweede deel was groot. Na een uitstapje naar de sport, met zijn boek Rotterdamse Sporthelden in 2016, is er nu Rotterdam in the House #2.'
	*/});
	const [dataLoaded, setDataLoaded] = useState(false);
	const apiUrl = window.location.origin+'/site/index.php?r=userwebsite/ajaxGetAuthorData';

	async function fetchData() {
		setIsLoading(true);
		await fetch(apiUrl,{
			method: 'POST',
			mode: 'cors',
			cache: 'no-cache',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json'
			},
			redirect: 'follow',
			body: JSON.stringify({sessionkey: apiKey})
		})
		.then(response => {
			if(response.ok) {
				response.json().then(res => {
						setAuthorData(res);
						setDataLoaded(true);
						setErrors(false);
						setIsLoading(false);
					}
				);
				
			} else {
				setErrors('Response not OK');
			}
			
		})
		.catch(error => {
			setErrors(error);
		});
	}

	useEffect(() => {
		if(!dataLoaded) {
			fetchData();
			/*setDataLoaded(true);
			setErrors(false);
			setIsLoading(false);*/
		}
	},[apiKey]);
	
	if(isLoading) {
		return (
			<div className="App cw-container">
				<div className="app-icon-loading-40px-container">
					<img src="/site/images/app/icons/app-icon-loading-40px.svg" alt="Loading" className="app-icon-loading-40px" />	
				</div>
			</div>
		);
	} else if(hasError) {
		return (
			<div className="App cw-container">
				There was an error loading the website. Please try again. If the error persists, contact support.
			</div>
		);
	} else if(!isLoading && dataLoaded && isSuccess) {
		return (
			<div className="App cw-container">
				<IntlProvider locale={language} defaultLocale="en" messages={getMessagesForLang(language)}>
					<HeaderBar showBackButton={showBackButton} authorName={authorData.name} authorPhoto={authorData.photoUrl} pagePath={pagePath} />
					<Switch>
						<Route exact path='/' render={(props) => <HomeComponent {...props} setShowBackbutton={setShowBackbutton} authorData={authorData} setPagePath={setPagePath} />}></Route>
						<Route exact path='/allbooks' render={(props) => <AllbooksComponent {...props} setShowBackbutton={setShowBackbutton} authorData={authorData} setPagePath={setPagePath} />}></Route>
						<Route exact path='/bookdetails/:id' render={(props) => <BookdetailsComponent {...props} setShowBackbutton={setShowBackbutton} authorData={authorData} setPagePath={setPagePath} />}></Route>
						<Route path="/cart" children={<Cart setShowBackbutton={setShowBackbutton} setPagePath={setPagePath} />} />
						<Route path="/checkout" children={<CheckoutApp setShowBackbutton={setShowBackbutton} setPagePath={setPagePath} />} />
						<Route render={(props) => <NotFoundComponent {...props} setShowBackbutton={setShowBackbutton} />} />
					</Switch>
					
					<FooterComponent email={authorData.email} socialmedia={authorData.socialmedia} translations={authorData.translations}/>
					<PoweredbyComponent text={authorData.translations.textPoweredby} />
				</IntlProvider>
			</div>
		);
	} else {
		return (
			<div className="App cw-container">
				<div className="app-icon-loading-40px-container">
					<img src="/site/images/app/icons/app-icon-loading-40px.svg" alt="Loading" className="app-icon-loading-40px" />	
				</div>
			</div>
		);
	}
	
}

export default App;
