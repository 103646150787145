import { ResultCode } from '@adyen/adyen-web/dist/types/components/types';

// see adyen for an accurate list: https://docs.adyen.com/online-payments/payment-result-codes
type AdyenResultCode = ResultCode | 'Refused' | 'Received';

export function getRedirectUrl(resultCode: AdyenResultCode, orderId: string) {
  switch (resultCode) {
    case 'Authorised':
      return `/checkout/success/orderId/${orderId}`;
    case 'Refused':
      return `/checkout/failed/orderId/${orderId}`;
    case 'Pending':
    case 'Received':
      return `/checkout/pending/orderId/${orderId}`;
    case 'Cancelled':
      return `/checkout/cancelled/orderId/${orderId}`;
    default:
      return `/checkout/error/orderId/${orderId}`;
  }
}
