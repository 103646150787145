import { Redirect } from 'react-router-dom';
import { useAppSelector } from '../../hooks';
import YourDeliveryDetails from './YourDeliveryDetails';
import { usePreparePaymentSessionMutation } from '../../api/apiSlice';
import { Block, Loading } from 'mbs-common';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import AdyenHandler from './AdyenHandler';

export default function CheckoutOverview() {
  const checkout = useAppSelector((state) => state.checkout);
  const [trigger, { data, isSuccess, isError }] = usePreparePaymentSessionMutation();
  const intl = useIntl();

  useEffect(() => {
    if (
      checkout.specification &&
      checkout.shippingAddress &&
      checkout.selectedShippingMethod &&
      checkout.totalAmounts &&
      checkout.vatSpecification
    ) {
      trigger(checkout);
    }
  }, [checkout, trigger]);

  // make sure the page is only called with a proper checkout state. If not, redirect back to the details page.
  if (
    !checkout.specification ||
    !checkout.shippingAddress ||
    !checkout.selectedShippingMethod ||
    !checkout.totalAmounts ||
    !checkout.vatSpecification
  ) {
    return <Redirect to="/checkout" />;
  }

  return (
    <>
      <YourDeliveryDetails />
      {isSuccess && data ? (
        <Block
          blockTitle={intl.formatMessage({
            id: 'Checkout-CheckoutOverview-title-Paymentmethod',
            defaultMessage: 'Payment method'
          })}
          style={{ overflow: 'initial' }}
        >
          <AdyenHandler checkoutData={checkout} paymentData={data.paymentData} />
        </Block>
      ) : isError ? (
        <>Error</>
      ) : (
        <Loading container={true} size="40px" />
      )}
    </>
  );
}
