import React from 'react';
import CartIcon from './icons/CartIcon';
import { useAppDispatch } from './hooks';
import { CartItem, PricingObject, ProductTypes, addItem, generateCartItemId, productTypeIsPrintBook } from './Cart/cartSlice';
import { useHistory } from 'react-router-dom';

export type BuybuttonProps = {
	label: string;
	bookData: Record<string, string>;
	highlight?: boolean;
	buttonType?: string;
	url?: string;
}

function Buybutton(props: BuybuttonProps) {
	const dispatch = useAppDispatch();
	const history = useHistory();

	let buttonClass = 'app-button-cta';
	if(props.highlight)
		buttonClass = 'cw-button-highlight'
	
	if(props.buttonType === "short")
		buttonClass = buttonClass+" app-button-icononly";

	function handleClick() {
		if(props.bookData.productType)
		{
			const cartItem: CartItem = {
				type: props.bookData.productType as ProductTypes,
				id: generateCartItemId({ type: props.bookData.productType as ProductTypes, bookId: props.bookData.id}),
				bookId: props.bookData.id,
				quantity: 1,
				pricing: props.bookData.pricing as unknown as PricingObject[],
				bookSpecs: {
					title: props.bookData.title,
					binding: props.bookData.binding,
					lastEdited: props.bookData.lastEdited,
					suggestedSku: props.bookData.suggestedSku,
					imageUrl: props.bookData.imageUrl,
					...(productTypeIsPrintBook(props.bookData.productType as ProductTypes) ? { size: props.bookData.size as any, color: props.bookData.color } : undefined) 
				}
			};
			
			dispatch(addItem(cartItem));
			history.push('/cart');
		}
		else if(props.url)
		{
			window.location.href = props.url;
		}
	}
	
	const cartIcon = <CartIcon />;
	if(props.buttonType === "full") {
		return (<button className={buttonClass} onClick={handleClick} title={props.label}>{cartIcon} {props.label}</button>);
	}
	else if(props.buttonType === "short") {
		return(<button className={buttonClass} onClick={handleClick} title={props.label}>{cartIcon}</button>);
	}
	else if(props.buttonType === "ebook") {
		return(<button className={buttonClass} onClick={handleClick} title={props.label}>{props.label}</button>);
	}
	else if(props.buttonType === "pdf") {
		return(<button className={buttonClass} onClick={handleClick} title={props.label}>{cartIcon} {props.label}</button>);
	}

}

export default Buybutton;