import React from 'react'

function ContentContainer(props)
{
	let sectionClass = "cw-content-container";
	if(props.sectionType)
	{
		sectionClass = sectionClass+"-"+props.sectionType;
	}

	return(
		<section className={sectionClass}>
			<div className="cw-content">
				{props.children}
			</div>
		</section>
	);
}

export default ContentContainer;