import { useIntl } from 'react-intl';
import ProductItem from '.';
import { CartItemBuscardsSpecs, CartItemIsbnSpecs } from '../../../../Cart/cartSlice';
import { SVGIcon } from 'mbs-common';

export default function BuscardsItem({
  buscardsSpecs,
  quantity,
  id
}: {
  buscardsSpecs: CartItemBuscardsSpecs;
  quantity: number;
  id: string;
}) {
  const intl = useIntl();

  const title = intl.formatMessage({
    id: 'Checkout-Cart-CartItem-Title-Buscards',
    defaultMessage: 'Business cards'
  });
  const subtitle = intl.formatMessage(
    {
      id: 'Checkout-Cart-CartItem-Forbook',
      defaultMessage: 'For book: {bookTitle}'
    },
    { bookTitle: buscardsSpecs.bookTitle }
  );

  const theImage = <SVGIcon name="app/ProductImages/Buscards" width="80" />;

  return <ProductItem title={title} subtitle={subtitle} image={theImage} quantity={quantity} />;
}
