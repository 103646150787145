import { Block, BlockContent, Loading } from 'mbs-common';
import { useIntl } from 'react-intl';
import ShippingMethodInput from './ShippingMethodInput';
import { useAppSelector } from '../../../hooks';

export default function ShippingMethod() {
  const intl = useIntl();
  const { specification, selectedShippingMethod } = useAppSelector((state) => state.checkout);

  return (
    <Block
      blockTitle={intl.formatMessage({
        id: 'Checkout-CheckoutDetails-title-ShippingMethod',
        defaultMessage: 'Shipping method'
      })}
    >
      <BlockContent>
        {specification && selectedShippingMethod ? (
          <ShippingMethodInput
            selectedShippingMethod={selectedShippingMethod}
            shippingOptions={specification.shippingMethods}
          />
        ) : (
          <Loading container={true} size="40px" />
        )}
      </BlockContent>
    </Block>
  );
}
