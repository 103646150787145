import React from 'react';
import AuthorPhoto from './AuthorPhoto';
import Backbutton from './Backbutton';
import { useHistory } from "react-router-dom";
import CartButton from './CartButton';

function HeaderBar(props) {

	let headerBarClass = (props.showBackButton ? "cw-headerbar-container cw-headerbar-backvisible" : "cw-headerbar-container");
	const history = useHistory();

	function handleHomeClick() {
		history.push("/");
	}
	return(
		<header className={headerBarClass}>
			{(props.showBackButton && <Backbutton pagePath={props.pagePath} />)}
			{(props.authorPhoto && <AuthorPhoto url={props.authorPhoto} alt={props.authorName} onClick={handleHomeClick} />)}
			<h1 onClick={handleHomeClick}>{props.authorName}</h1>
			<CartButton />
		</header>
	);
}

export default HeaderBar;