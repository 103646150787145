import { SVGIcon } from 'mbs-common';
import { CartCurrency, CartItemEbook } from '../cartSlice';
import CartItem from './CartItem';
import { FormattedMessage } from 'react-intl';
import { useAppSelector, useGetLanguage } from '../../hooks';

export default function EbookItem({
  cartItem,
  currency,
  sourceCountry
}: {
  cartItem: CartItemEbook;
  currency: CartCurrency;
  sourceCountry: string;
}) {
  const language = useGetLanguage();
  const dateFormatter = new Intl.DateTimeFormat(language, { dateStyle: 'short' });
  const formattedLastedited = dateFormatter.format(new Date(cartItem.bookSpecs.lastEdited));

  const image = <img src={cartItem.bookSpecs.imageUrl} alt={cartItem.bookSpecs.title} />;
  //const quantityInput = <>{cartItem.quantity}</>;

  const quantityInput = (
    <div className="cart-block-cartitems-amounts-fixedamount">{cartItem.quantity}</div>
  );

  const specsBlock = (
    <>
      <div className="cart-block-cartitem-prodinfo-specs-block">
        <div className="cart-block-cartitem-prodinfo-specs-block-icon">
          <SVGIcon name={`app/Book/Small/Binding/${cartItem.bookSpecs.binding}`} />
        </div>
        <div>
          <div className="cart-block-cartitem-prodinfo-specs-block-boldtext">
            <FormattedMessage id={`binding-${cartItem.bookSpecs.binding}`} />
          </div>
          <div className="cart-block-cartitem-prodinfo-specs-block-darktext">{` `}</div>
        </div>
      </div>
      <div className="cart-block-cartitem-prodinfo-specs-block">
        <div>
          {cartItem.bookSpecs.isbn && (
            <div className="cart-block-cartitem-prodinfo-specs-block-lighttext">
              <FormattedMessage
                id="Bookspec-ISBN-isbn"
                defaultMessage="ISBN: {isbn}"
                values={{ isbn: cartItem.bookSpecs.isbn }}
              />
            </div>
          )}
          <div className="cart-block-cartitem-prodinfo-specs-block-lighttext">
            <FormattedMessage
              id="Bookspec-LastEdited-date"
              defaultMessage="Last edited: {date}"
              values={{ date: formattedLastedited }}
            />
          </div>
        </div>
      </div>
    </>
  );

  return (
    <CartItem
      itemId={cartItem.id}
      type={cartItem.type}
      sourceCountry={sourceCountry}
      image={image}
      title={cartItem.bookSpecs.title}
      subtitle={cartItem.bookSpecs.subtitle}
      specifications={specsBlock}
      quantityInput={quantityInput}
      currency={currency}
      pricing={cartItem.pricing}
      quantity={cartItem.quantity}
    ></CartItem>
  );
}
