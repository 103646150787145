import { CardSelector, CardSelectorItem, EGridColType, FormItem, GridCol, MBSInput } from 'mbs-common';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomerType, UserAddress, userAddressSchema } from '../../../../user';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import PrivateClient from './PrivateClient';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { setBillingAddress } from '../../../checkoutSlice';

export default function BillingAddressInput({
  customerType,
  onComplete
}: {
  customerType: CustomerType;
  onComplete?: () => void;
}) {
  const [customerTypeField, setCustomerTypeField] = useState(customerType);
  const intl = useIntl();
  const reduxI18n = useAppSelector((state) => state.i18n);
  const dispatch = useAppDispatch();

  const methods = useForm<UserAddress>({
    resolver: zodResolver(userAddressSchema),
    shouldFocusError: true,
    mode: 'onBlur'
    // reValidateMode: 'onSubmit'
  });

  const {
    register,
    getValues,
    resetField,
    handleSubmit,
    formState: { errors },
    watch,
    setValue
  } = methods;

  useEffect(() => {
    if (reduxI18n.country) {
      const country = reduxI18n.country as string;
      setValue('l', country);
    }
  }, [reduxI18n.country, setValue]);

  const handleChangeCustomerTypeField = (value: CustomerType) => {
    setCustomerTypeField(value);
    if (reduxI18n.country) {
      setValue('l', reduxI18n.country as string);
    }
  };

  const onSubmit: SubmitHandler<UserAddress> = (data, event) => {
    event?.preventDefault();

    data.n = `${data.fn} ${data.ln}`;

    const { nra, ...billingAddress } = data;
    dispatch(setBillingAddress({ billingAddress, customerType: customerTypeField }));
    // call oncomplete callback
    if (onComplete) {
      onComplete();
    }
  };

  return (
    <form className="grid2 formgrid" onSubmit={handleSubmit(onSubmit)}>
      {/* <GridCol type={[EGridColType.Default12]}>
        <FormItem
          label={intl.formatMessage({
            id: 'Checkout-CheckoutDetails-BillingAddress-CustomerType-label',
            defaultMessage: 'Customer type'
          })}
          labelDescription={intl.formatMessage({
            id: 'Checkout-CheckoutDetails-BillingAddress-CustomerType-labelDesc',
            defaultMessage:
              'Select which type of customer you are. For businesses, we require a valid EU VAT number.'
          })}
        >
          <CardSelector
            value={customerTypeField}
            handleChange={handleChangeCustomerTypeField}
            id="customertype"
          >
            <CardSelectorItem
              imageSrc="/site/images/app/icons/app-icon-customertype-private.svg"
              label={intl.formatMessage({
                id: 'Checkout-CheckoutDetails-BillingAddress-CustomerType-Private',
                defaultMessage: 'Private person'
              })}
              description={intl.formatMessage({
                id: 'Checkout-CheckoutDetails-BillingAddress-CustomerType-Private-desc',
                defaultMessage: 'Customer without EU VAT number'
              })}
              value="PRIVATE"
            />
            <CardSelectorItem
              imageSrc="/site/images/app/icons/app-icon-customertype-business.svg"
              label={intl.formatMessage({
                id: 'Checkout-CheckoutDetails-BillingAddress-CustomerType-Company',
                defaultMessage: 'Company'
              })}
              description={intl.formatMessage({
                id: 'Checkout-CheckoutDetails-BillingAddress-CustomerType-Company-desc',
                defaultMessage: 'Customer with EU VAT number'
              })}
              value="COMPANY"
            />
          </CardSelector>
        </FormItem>
      </GridCol> */}
      <GridCol type={[EGridColType.Default6]}>
        <FormItem
          label={intl.formatMessage({
            id: 'Checkout-Address-Input-Fn',
            defaultMessage: 'First name'
          })}
        >
          <MBSInput
            {...methods.register('fn')}
            error={errors.fn?.message && intl.formatMessage({ id: errors.fn?.message })}
          />
        </FormItem>
      </GridCol>
      <GridCol type={[EGridColType.Default6]}>
        <FormItem
          label={intl.formatMessage({
            id: 'Checkout-Address-Input-Ln',
            defaultMessage: 'Last name'
          })}
        >
          <MBSInput
            {...methods.register('ln')}
            error={errors.ln?.message && intl.formatMessage({ id: errors.ln?.message })}
          />
        </FormItem>
      </GridCol>
      {customerTypeField === CustomerType.PRIVATE && <PrivateClient methods={methods} />}
      {(customerTypeField === CustomerType.COMPANY ||
        customerTypeField === CustomerType.BOOKSTORE) && <PrivateClient methods={methods} />}
      <GridCol type={[EGridColType.Default12]}>
        <button type="submit" className="app-button-cta">
          <FormattedMessage id={'Checkout-Address-Button-Save'} defaultMessage="Save" />
        </button>
      </GridCol>
    </form>
  );
}
