import React from 'react';

function AuthorPhoto(props) {
	const theStyle = {
		backgroundImage: 'url('+props.url+')'
	}
	//const theStyle = "background-image: url("+props.url+")";
	return(
		<div className="cw-headerbar-authorphoto" style={theStyle} onClick={props.onClick}>
		</div>
	);
}

export default AuthorPhoto;