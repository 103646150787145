import { SVGIcon, NumberSpinner } from 'mbs-common';
import {
  CartCurrency,
  CartItemPrintBook,
  changeQuantity,
  decreaseQuantity,
  increaseQuantity
} from '../cartSlice';
import CartItem from './CartItem';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useGetLanguage } from '../../hooks';

export default function PrintBookItem({
  cartItem,
  currency,
  sourceCountry
}: {
  cartItem: CartItemPrintBook;
  currency: CartCurrency;
  sourceCountry: string;
}) {
  const dispatch = useAppDispatch();
  const language = useGetLanguage();
  const dateFormatter = new Intl.DateTimeFormat(language, { dateStyle: 'short' });
  const formattedLastedited = dateFormatter.format(new Date(cartItem.bookSpecs.lastEdited));

  const image = <img src={cartItem.bookSpecs.imageUrl} alt={cartItem.bookSpecs.title} />;
  //const quantityInput = <>{cartItem.quantity}</>;

  const handleDecrease = () => {
    dispatch(decreaseQuantity(cartItem.id));
  };
  const handleIncrease = () => {
    dispatch(increaseQuantity(cartItem.id));
  };

  const handleChangeQuantity = (value: number) => {
    dispatch(changeQuantity({ id: cartItem.id, quantity: value }));
  };

  const quantityInput = (
    <NumberSpinner
      onDecrease={handleDecrease}
      onIncrease={handleIncrease}
      externalValue={cartItem.quantity}
      onValueChange={handleChangeQuantity}
      minValue={1}
    />
  );

  const specsBlock = (
    <>
      <div className="cart-block-cartitem-prodinfo-specs-block">
        <div className="cart-block-cartitem-prodinfo-specs-block-icon">
          <SVGIcon
            name={cartItem.bookSpecs.color === 'fc' ? 'app/Book/ColorFC' : 'app/Book/ColorBW'}
          />
        </div>
        <div className="cart-block-cartitem-prodinfo-specs-block-icon">
          <SVGIcon name={`app/Book/Small/Binding/${cartItem.bookSpecs.binding}`} />
        </div>
        <div>
          <div className="cart-block-cartitem-prodinfo-specs-block-boldtext">
            <FormattedMessage id={`binding-${cartItem.bookSpecs.binding}`} />
          </div>
          <div className="cart-block-cartitem-prodinfo-specs-block-darktext">
            {cartItem.bookSpecs.size.shortLocalized}
          </div>
        </div>
      </div>
      <div className="cart-block-cartitem-prodinfo-specs-block">
        <div>
          {cartItem.bookSpecs.isbn && (
            <div className="cart-block-cartitem-prodinfo-specs-block-lighttext">
              <FormattedMessage
                id="Bookspec-ISBN-isbn"
                defaultMessage="ISBN: {isbn}"
                values={{ isbn: cartItem.bookSpecs.isbn }}
              />
            </div>
          )}
          <div className="cart-block-cartitem-prodinfo-specs-block-lighttext">
            <FormattedMessage
              id="Bookspec-LastEdited-date"
              defaultMessage="Last edited: {date}"
              values={{ date: formattedLastedited }}
            />
          </div>
        </div>
      </div>
    </>
  );

  return (
    <CartItem
      itemId={cartItem.id}
      type={cartItem.type}
      sourceCountry={sourceCountry}
      image={image}
      title={cartItem.bookSpecs.title}
      subtitle={cartItem.bookSpecs.subtitle}
      specifications={specsBlock}
      quantityInput={quantityInput}
      currency={currency}
      pricing={cartItem.pricing}
      quantity={cartItem.quantity}
    ></CartItem>
  );
}
