import React from 'react';
import { Link } from 'react-router-dom';
import BookItem from '../BookItem';
import ArrowIcon from '../icons/ArrowIcon';

function Latestbooks(props) {
	const books = props.booksData.slice(0,props.amount).map((item) =>
		<li key={item.id}><BookItem book={item} translations={props.translations} /></li>
	);
	
	return(
		<div>
			<h2>{props.translations.titleMybooks}</h2>
			<div className="cw-latestbooks-container">
				<ul className="cw-latestbooks">
					{books}
				</ul>
			</div>
			{props.booksData.length > 4 &&
				<div className="cw-home-allbookslink">
				<Link to="/allbooks">{props.translations.linkAllbooks}<ArrowIcon name="right-dark"/></Link>
			</div>}
		</div>
	);
}
export default Latestbooks;