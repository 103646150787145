const messages = {
  'Promotion': 'Promoción',
  'binding-1': 'Tapa dura',
  'binding-3': 'Tapa blanda',
  'binding-6': 'eBook',
  'binding-7': 'PDF',
  'interiorcolor-bw': 'Blanco/negro',
  'interiorcolor-fc': 'Color',
  'interiorcolor-short-bw': 'B/N',
  'interiorcolor-short-fc': 'Color',
  'Interior color': 'Color de interior',
  'Papertype-short': 'Papel',
  'Papertype-short-1': 'Crema',
  'Papertype-short-2': 'Crema',
  'Papertype-short-3': 'Blanco',
  'Papertype-short-4': 'Blanco',
  'Papertype-short-5': 'Semi brilliante',
  'Papertype-short-6': 'Semi brilliante',
  'Papertype-short-7': 'Blanco',
  'Papertype-short-8': 'Blanco brilliante',
  'Papertype-short-9': 'Tinted',
  'Papertype-short-10': '150 grams',
  'Papertype-short-12': 'Blanco',
  'Amountpages-short': 'Pàginas',
  'Amountcolorpages-short': 'Pàginas en color',
  'Help-howdoesitwork-question': '¿Como funciona?',
  'ISBN': 'ISBN',
  'Author website': 'Web del autor',
  'Author webshop': 'Web del autor',
  'authorwebsite-notEnabled': 'No activado - {link}',
  'authorwebsite-notEnabled-enablenow': 'Activar ahora',
  'Link to book page': 'Enlace al libro',
  'button-downloadcover': 'Descargar portada',
  'button-downloadexcerpt': 'Descargar muestra',
  'button-copybooklink': 'Copiar enlace del libro',
  'button-copyannouncementtext': 'Copiar anuncio',
  'button-openinstagram': 'Abrir Instagram',
  'button-addtoshoppingcart': 'Añadir al carrito de compra',
  'button-orderbooks': 'Pedir libros',
  'button-registerbook': 'Registrar libro',
  'Channels-availability': 'Disponibilidad',
  'Channelname': 'Tienda',
  'Price': 'Precio',
  'Your margin': 'Tu ganancia',
  'Bookspec-ISBN-isbn': 'ISBN: {isbn}',
  'Bookspec-LastEdited-date': 'Última versión: {date}',

  'What do you have to do?': '¿Qué tienes que hacer?',
  
  // form labels
  'label-announcementtext': 'Anuncio',
  'label-instagram-addbooklinktobio': 'Añade el enlace a tu libro en tu biografía de Instagram',
  'label-instagram-downloadcover': 'Descarga tu imagen de portada para tu post',
  'label-instagram-openinstagram': 'Abrir Instagram',
  'label-instagram-chooseanimage': 'Elegir imagen',
  'label-attachbooklinktomessage': 'Añade automáticamente el enlace a tu libro al mensaje',

  // zod validation errors
  'ZodValidationError-EmailRequired': 'Necesitas una dirección de correo electrónico',
  'ZodValidationError-EmailNotValidEmail': 'Por favor, introduce una dirección de correo electrónico válida',
  'ZodValidationError-AddressFnRequired': 'Debes indicar un nombre',
  'ZodValidationError-AddressFnLongerThan30': 'Tu nombre no puede tener más de 30 caracteres',
  'ZodValidationError-AddressLnRequired': 'Debes indicar un apellido',
  'ZodValidationError-AddressLnLongerThan30': 'Tu apellido no puede tener más de 30 caracteres',
  'ZodValidationError-AddressSRequired': 'Debes indicar una dirección postal y número de puerta',
  'ZodValidationError-AddressSLongerThan50': 'Tu dirección postal no puede tener más de 50 caracteres',
  'ZodValidationError-AddressS2LongerThan50': 'La segunda línea no puede tener más de 50 caracteres',
  'ZodValidationError-AddressNrMustBeNumber': 'El número de portal debe ser un número',
  'ZodValidationError-AddressPRequired': 'Debes indicar tu código postal',
  'ZodValidationError-AddressPLongerThan20': 'El código postal no puede tener más de 20 caracteres',
  'ZodValidationError-AddressCRequired': 'Indica tu ciudad',
  'ZodValidationError-AddressCLongerThan35': 'El nombre de tu ciudad no puede tener más de 35 caracteres',
  'ZodValidationError-AddressRLongerThan35': 'El nombre de tu región no puede tener más de 35 caracteres',
  'ZodValidationError-AdressLRequired': 'debes introducir el país',
  'ZodValidationError-AddressCountryNotTwoLetterCode': 'El país debe estar en código ISO de 2 letras',
  'ZodValidationError-AddressCpLongerThan35': 'El nombre de la empresa no puede contener más de 35 caracteres',
  'ZodValidationError-AddressTLongerThan20': 'El número de teléfono no puede tener más de 20 caracteres',
  'ZodValidationError-AddressFullNameLongerThan40': 'Tu nombre completo no puede tener más de 40 caracteres',
  'ZodValidationError-AddressPostalCodeIncorrect': 'Código postal incorrecto',
  'ZodValidationError-AddressS2SameAsS': 'La segunda línea de la dirección no puede ser igual',
  'ZodValidationError-AddressVRequiredForCanarias': 'Introduce un número de identificación para envíos a las Islas Canarias',

  // guide popup
  'wikiguidepopup-title-selectaguide': 'Elige una opción',

  // errors
  'error-booknotpublished': 'No puedes promocionar este libro porque no está publicado. Ve a "Mis libros" para editar este libro.',
  'error-booknotavailable': 'El libro que intentas abrir no existe. Ve a "Mis libros" y selecciona un libro.',
  'error-booknotauthorized': 'No estás autorizado a acceder a esta página. Si este mensaje es incorrecto, ponte en contacto con el servicio de atención al cliente.',
  'error-bookunknownerror': 'Se ha producido un error inesperado al cargar esta página. Por favor, actualiza la página para volver a intentarlo. Si el problema persiste, ponte en contacto con el servicio de atención al cliente. No olvides indicar el identificador del libro en tu mensaje: {bookid}.',

  // promo popup
  'promoapp-publishedpopup-title': 'Felicidades, ¡ya eres un autor publicado!',
  'promoapp-publishedpopup-paragraph1': '¡Lo has conseguido! Tu libro ya está publicado, así que tómate un momento o diez para celebrarlo. Díselo a tus amigos, date un capricho y disfruta de tu logro durante un rato.',
  'promoapp-publishedpopup-paragraph2': 'Ahora que tu libro está a la venta, es el momento de dar el siguiente paso en tu viaje como autor: promocionar tu libro. Naturalmente, estamos aquí para ayudarte en este aspecto. Nuestros pasos de promoción te guiarán a través de algunas de las formas más efectivas de dar a conocer tu libro.',
  'promoapp-publishedpopup-startbutton': 'Empieza a promocionar tu libro',

  // promo categories
  'promoapp-havetodo-title': 'Acciones que deberías realizar',
  'promoapp-shoulddo-title': 'Acciones que te recomendamos realizar',
  'promoapp-coulddo-title': 'Acciones que te darán un plus',
  'blocktitle-thingsyouneedtopromote': 'Herramientas que necesitarás para promocionar tu libro',

  // promo overview
  'promoapp-overview-intro-p1': 'Escribir un libro es un gran logro en sí mismo, pero lo más probable es que también quieras dar a conocerlo y venderlo. Para conseguirlo, tendrás que promocionarlo. Para muchos autores aquí es donde comienza el verdadero reto. Requiere bastante trabajo y dedicación, pero empezarás a ver los resultados más rápido de lo que crees. Para ayudarte a empezar hemos recopilado una selección de consejos, guías y herramientas para que la promoción de tu libro sea más fácil y eficaz.',

  // list of all promotool descriptions
  'promotool-authorwebsite': 'Web del autor',
  'promotool-description-authorwebsite': 'El mejor lugar para vender tus libros.',
  'promotool-whatsapp': 'WhatsApp',
  'promotool-description-whatsapp': 'Vende tu libro directamente a tus amigos y haz que corran la voz',
  'promotool-socialmedia': 'Redes Sociales',
  'promotool-description-socialmedia': 'Prepara tus perfiles en redes sociales para impulsar las ventas y ganar lectores',
  'promotool-instagram': 'Instagram',
  'promotool-description-instagram': 'Promociona tu libro con imágenes impactantes.',
  'promotool-facebook': 'Facebook',
  'promotool-description-facebook': 'Conecta con los lectores e impulsa tus ventas publicando contenido interesante',
  'promotool-twitter': 'Twitter',
  'promotool-description-twitter': 'Utiliza tu habilidad con las palabras para promocionar tu libro',
  'promotool-linkedin': 'LinkedIn',
  'promotool-description-linkedin': 'Documenta tu viaje como autor emprendedor para inspirar a tu red profesional',
  'promotool-libraries': 'Bibliotecas',
  'promotool-description-libraries': 'Llega a nuevos lectores enviando libro tu a bibliotecas locales o relevantes',
  'promotool-nbdbiblion': 'NBD Biblion',
  'promotool-description-nbdbiblion': 'Get NBD to review your book and get it in the libraries',
  'promotool-nationallibrary': 'National Library',
  'promotool-description-nationallibrary': 'Immortalize your work by depositing a copy of your book',
  'promotool-launchparty': 'Presentación del libro',
  'promotool-description-launchparty': 'Ninguna publicación está completa sin una fiesta de presentación del libro',
  'promotool-bookstores': 'Librerías',
  'promotool-description-bookstores': 'Consigue que tu libro esté disponible en las librerías y tiendas locales',
  'promotool-pressrelease': 'Nota de prensa',
  'promotool-description-pressrelease': 'Lograr apariciones en la prensa hará maravillas tanto para tus ventas',
  'promotool-businesscards': 'Tarjetas de visita',
  'promotool-description-businesscards': 'Impresiona con tus propias tarjetas de visita personalizadas',

  // launch party
  'launchparty-intro-p1': 'La presentación de un libro es un paso fundamental en el proceso de promoción del mismo. No solo es una forma estupenda de llamar la atención sobre el hecho de que tu libro ha sido publicado, sino que también es una forma muy agradable de celebrar la ocasión y tu gran logro con amigos y familiares. El acto de presentación de tu libro puede ser tan grande o tan pequeño como quieras; lo que esté en línea con los objetivos que te hayas fijado. A continuación hemos elaborado una guía tanto para una presentación pequeña e informal como para una presentación más grande y formal.',
  'launchparty-bookdrinks-title': 'Presentación informal del libro',
  'launchparty-bookdrinks-intro': 'Si quieres mantener la sencillez y empezar con algo pequeño, puedes hacer de la presentación de tu libro un evento más íntimo. No tiene por qué ser algo magnificente en un recinto alquilado con oradores invitados y prensa local. En lugar de eso, puedes invitar a amigos, familiares y conocidos para que se unan a ti y celebren el hito que has alcanzado. Piensa en ello como una fiesta para ti y para tu libro; es una forma fácil y relativamente barata de llamar la atención sobre el hecho de ser un autor publicado y de vender tus primeros libros. Aquí tienes algunos consejos si estás pensando en organizar una fiesta de presentación de tu libro:',
  'launchparty-bookdrinks-whenandwho-title': 'Decide a quién invitar y cuándo',
  'launchparty-bookdrinks-whenandwho-paragraph1': 'Haz una lista de todas las personas que quieras invitar. Tus amigos, tu familia, tus vecinos y las personas que han contribuido a tu libro de alguna manera son siempre buenas opciones. Fija una fecha, crea una especie de chat de grupo y envía las invitaciones. Asegúrate de que tus mensajes sean alegres y acogedores, y no olvides incluir una foto de tu libro.',
  'launchparty-bookdrinks-orderbooks-title': 'Pide los libros',
  'launchparty-bookdrinks-orderbooks-paragraph1': 'Cuando hayas enviado las invitaciones en el chat, probablemente tendrás una idea bastante aproximada de cuántas personas vendrán a tu presentación. Da por hecho que todos los invitados querrán comprar uno o dos ejemplares y encarga suficientes libros con antelación, así no tendrás que preocuparte de que lleguen a tiempo para la presentación de tu libro.',
  'launchparty-bookdrinks-agenda-title': 'Programa',
  'launchparty-bookdrinks-agenda-paragraph1': 'Aunque la idea es que se trate de un acto informal y espontáneo, nunca está de más preparar un poco el acto para que sea una experiencia memorable y para que tus invitados estén encantados de comprar tu libro. Pregúntate qué quieres decir y si quieres proponer un brindis. ¿Quieres dar las gracias a alguien en especial? ¿Quieres entregar el primer ejemplar de tu libro a alguien como regalo? ¿Quizás quieras contar a todo el mundo tu experiencia autopublicando un libro? Haz una breve lista de los puntos que te gustaría tratar en el acto.',
  'launchparty-bookdrinks-foodandhouse-title': 'Refrescos y picoteo',
  'launchparty-bookdrinks-foodandhouse-paragraph1': 'Asegúrate de ofrecer un buen número de aperitivos y bebidas para tus invitados; es importante que la gente se divierta y se sienta cómoda. Recuerda incluir bebidas sin alcohol y tener en cuenta las opciones preferidas de tus invitados. Además, asegúrate de que tu casa esté impecable y coloca los libros en un lugar destacado; deben estar siempre visibles. También es una buena idea que alguien haga fotos, ya que serán muy apreciadas por los invitados y constituirán un magnífico material promocional para tu libro después del evento.',
  'launchparty-bookdrinks-enjoy-title': '¡Disfruta!',
  'launchparty-bookdrinks-enjoy-paragraph1': 'Por último, pero no por ello menos importante, asegúrate de disfrutar de la fiesta de presentación de tu libro. La gente está aquí para celebrar un gran logro contigo, así que disfruta un poco de la atención. Tendrás que decidir si quieres regalar tu libro a cada uno de los invitados o pedirles que compren ellos mismos sus ejemplares. Aunque esta última opción puede sonar un poco extraña, es una petición absolutamente normal, y tus invitados la entenderán. Los asistentes son personas cercanas a ti y querrán apoyarte, al fin y al cabo.',
  'launchparty-bookpresentation-title': 'Presentación formal del libro',
  'launchparty-bookpresentation-intro': 'La presentación formal de un libro es simplemente una forma más ambiciosa de organizar una fiesta de presentación. Aunque puedes organizarla en casa, quizá quieras considerar la posibilidad de alquilar un local; algo como una cafetería o una librería es siempre una buena opción, o también puedes consultar a la biblioteca de tu municipio o barrio. Además, querrás invitar a la prensa local y quizás incluso a personas influyentes en redes sociales que sean de tu zona o estén interesadas en el tema de tu libro. Esto eleva un poco el nivel de exigencia, por lo que conviene planificar con antelación y contar con un programa sólido. Estas son algunas de las cosas que debes tener en cuenta al planificar el evento de presentación de tu libro:',
  'launchparty-guestlist-title': 'Decide a quién invitas',
  'launchparty-guestlist-paragraph1': 'Un buen punto de partida es redactar una lista de invitados. Las opciones obvias son los amigos, la familia, cualquier persona que haya contribuido a tu libro de alguna manera, y quizás colegas u otras personas de tu red profesional. Por lo general, puedes dar por sentado que la gente estará encantada de celebrar este hito contigo, así que no seas tímido a la hora de enviar invitaciones. La razón por la que es una buena idea empezar con una lista de invitados es que facilita la elección de un lugar adecuado para celebrar el evento.',
  'launchparty-findalocation-title': 'Encuentra un lugar y una fecha',
  'launchparty-findalocation-paragraph1': 'Hay un montón de lugares adecuados para un acto de presentación de un libro. Puedes optar por celebrarlo en la comodidad de tu casa, en tu cafetería o bar favoritos o en una librería o biblioteca local. Esta última opción es nuestra favorita, ya que creemos que estar rodeado de libros mejora el ambiente de la presentación de un libro. Intenta conseguir un lugar barato (o gratuito) y haz que el propietario participe en la planificación para que sepa lo que le espera.',
  'launchparty-sendinvites-title': 'Envía las invitaciones',
  'launchparty-sendinvites-paragraph1': 'Aunque una invitación en papel ya no es la norma, este ya es motivo suficiente para diferenciarte y probarlo. Una invitación en papel puede dar cierta legitimidad al evento y te permite hacer cosas divertidas como hacer invitaciones con la portada de tu libro. Por supuesto, también puedes optar por enviar las invitaciones por correo electrónico o por whatsapp. La ventaja de esto es que es gratis y te ahorra tiempo. No olvides pedir confirmación de asistencia; necesitarás saber cuántas personas vendrán.',
  'launchparty-createagenda-title': 'Programa',
  'launchparty-createagenda-paragraph1': 'Debes planificar con antelación los principales actos de la presentación del libro. Somos partidarios de hacer que el evento de presentación de tu libro sea tuyo, pero hay ciertos elementos básicos que te recomendamos que incluyas:',
  'launchparty-createagenda-list1': 'Comenzar con una bebida de bienvenida y dejar que los invitados se mezclen hasta que todos hayan llegado. Un poco de música también sería un buen complemento.',
  'launchparty-createagenda-list2': 'Una vez que hayan llegado todos los invitados, hacer que un amigo u otra persona reconocible dé la bienvenida a todos.',
  'launchparty-createagenda-list3': 'Una sesión de preguntas y respuestas en la que tus invitados podrán preguntarte cosas como por qué decidiste escribir un libro, de qué trata, de dónde sacaste la inspiración y si tienes alguna anécdota divertida sobre el proceso.',
  'launchparty-createagenda-list4': 'Leer un pasaje de tu libro en voz alta a tus invitados',
  'launchparty-createagenda-list5': 'Firmar los libros que la gente compra',
  'launchparty-createagenda-list6': 'Picoteo y socialización',
  'launchparty-contactmedia-title': 'Invita a los medios',
  'launchparty-contactmedia-paragraph1': 'Te recomendamos encarecidamente que intentes ponerte en contacto con los medios de comunicación de tu pueblo o ciudad, con medios temáticos relacionados con tu libro o incluso con personas influyentes en las redes sociales de tu zona. Pregúntales si están interesados en cubrir la presentación de tu libro e invítales al evento. Te sorprenderá la frecuencia con la que esto funciona, sobre todo si la presentación incluye picoteo, así que no te cortes en intentarlo.',
  'launchparty-refreshments-title': 'Organiza los aperitivos y bebidas',
  'launchparty-refreshments-paragraph1': 'Nuestro consejo es que sea sencillo. Elige lo que más te guste y mantén una selección limitada. Asegúrate de obtener el visto bueno del propietario del local de antemano. Si vas a organizar la fiesta en un lugar que normalmente no sirve comida ni bebida, es posible que tengan normas al respecto, y si la organizas en un lugar que sí lo hace, querrán servir su propia comida y bebida.',
  'launchparty-orderbooks-title': 'Libros',
  'launchparty-orderbooks-paragraph1': 'Asegúrate de tener suficientes ejemplares de tu libro a mano. Así, tus invitados podrán comprar un ejemplar durante el acto y tú podrás firmárselo allí mismo para que la experiencia sea más personal. Ten en cuenta que si organizas la presentación de tu libro en una librería, el propietario querrá una parte de las ventas del libro. Asegúrate de negociar los detalles de antemano y de pedir tus libros con suficiente antelación.',
  'launchparty-photos-title': 'Foto y vídeo',
  'launchparty-photos-paragraph1': 'El acto de presentación de un libro no solo es una buena forma de darlo a conocer y generar algunas ventas, sino que también puede crear material que podrás utilizar en tus acciones de marketing en el futuro. Por eso es importante que alguien haga fotos o grabe el acto. No hace falta que sea un fotógrafo profesional; probablemente tengas un amigo que se defienda con la cámara. Luego puedes seleccionar parte del material para publicarlo en las redes sociales y promocionar y legitimar tu libro.',
  'launchparty-followup-title': 'Seguimiento',
  'launchparty-followup-paragraph1': 'Asegúrate de agradecer a tus invitados su asistencia, como muy tarde un par de días después del evento. Hazles saber que aprecias su apoyo y comparte las fotos y vídeos del evento. También puedes compartirlos con las personas que no hayan asistido, e incluso incluir un enlace para que puedan comprar tu libro. Si has conseguido que algún medio de comunicación local o persona influyente asista o cubra el evento, asegúrate de difundirlo también e interactuar con ellos en las redes sociales después.',

  // bookstores
  'bookstores-intro-p1': 'Las librerías tradicionales siguen siendo un potente canal de venta de libros, a pesar del creciente dominio de la compra online. Sin embargo, al no estar en su mejor momento desde hace tiempo, las librerías tienden a ser cautelosas a la hora de comprar libros de autores desconocidos, ya que suelen considerarlo un riesgo innecesario. No obstante, te recomendamos que te pongas en contacto con las librerías de tu localidad para saber si están dispuestas a vender tu libro. El hecho de que tu libro esté disponible en una tienda física supone un gran impulso de legitimidad y una gran oportunidad de marketing.',
  'bookstores-intro-p2': 'Si consigues que una librería tenga tu libro, querrá una parte de los beneficios si se vende. Por lo general, este porcentaje será de entre el 20 y el 40 por ciento. Además, lo más probable es que solo acepten vender tu libro sin riesgo, lo que significa que se reservarán el derecho a devolverte los ejemplares no vendidos. Hemos creado una lista paso a paso que te dará una visión general de las cosas que debes tener en cuenta al acercarte y negociar con las librerías.',
  'bookstores-checklist-title': 'Lista para las librerías',
  'bookstores-selectbookstores-title': 'Escoge la librería correcta',
  'bookstores-selectbookstores-p1': 'El primer paso es determinar a qué librerías quieres dirigirte. Te recomendamos que investigues qué hay cerca de ti, hagas una lista y te acerques a ellas de una en una.',
  'bookstores-contactbookstore-title': 'Contacta con las librerías',
  'bookstores-contactbookstore-p1': 'Una vez que haya determinado a qué librerías le gustaría vender tu libro, es el momento de ponerse en contacto con ellas y concertar una reunión para presentarte oficialmente.',
  'bookstores-contactbookstore-p2': 'Estos son nuestros consejos para tu primera reunión:',
  'bookstores-contactbookstore-list1': 'Cuéntales un poco sobre ti',
  'bookstores-contactbookstore-list2': 'Háblales de tu libro: de qué trata, por qué lo has escrito y cuál es tu público objetivo. Sobre todo cuéntales por qué puede ser de interés para la gente de tu localidad.',
  'bookstores-contactbookstore-list3': 'Lleva un ejemplar de tu libro y déjalo con ellos. El propietario examinar el libro antes de decidirse a venderlo o no',
  'bookstores-agreement-title': 'Establecer algunos acuerdos con la librería',
  'bookstores-agreement-p1': 'Si la librería está dispuesta a vender tu libro, deberás llegar a un acuerdo con respecto a varias cosas:',
  'bookstores-agreement-whobuysbooks': '¿Quién paga por los libros?',
  'bookstores-agreement-buy-option1': 'Opción 1: Tu pagas los libros y la librería los vende en tu nombre. Esta es la opción más atractiva para la librería, ya que no corre ningún riesgo financiero. Puedes comprar el libro utilizando la función integrada en la plataforma Mibestseller y acordar un margen de beneficio con la librería. No te olvides de acordar cuándo vas a cobrar y cómo te van a informar de las ventas.',
  'bookstores-agreement-buy-option2': 'Opción 2: La librería te compra los libros. Esta es la solución más sencilla; ambos podéis cobrar automáticamente vuestros respectivos márgenes de beneficio. Si optas por esta opción, asegúrate de que has establecido el alcance en "Nacional" en el paso de fijación de precios de la plataforma.',
  'bookstores-agreement-howlongavailable': '¿Cuánto tiempo estará el libro a la venta en la librería?',
  'bookstores-agreement-positioninstore': '¿Dónde se colocará/exhibirá el libro?',
  'bookstores-beactive-title': 'Sé activo',
  'bookstores-beactive-p1': 'Una vez que tu libro esté disponible en la tienda, es imprescindible que informes a la gente sobre él. Hemos preparado algunas algunas formas de hacerlo para que te inspires:',
  'bookstores-beactive-list1': 'Una forma festiva de hacerlo sería organizar la fiesta de presentación del libro en la propia librería. Consulta nuestra guía sobre presentaciones de libros para obtener más información.',
  'bookstores-beactive-list2': 'Anuncia el hecho de que tu libro está disponible en esta tienda en particular en las redes sociales, en tu sitio web o en línea por un',
  'bookstores-beactive-list3': 'Haz apariciones en la librería y anúncialo a tus lectores para generar atención y estimular las ventas',

  // press release
  'pressrelease-intro-p1': 'Una nota de prensa es una declaración oficial que haces en calidad de autor a los medios de comunicación. En el mundo editorial, las notas de prensa se utilizan sobre todo para anunciar la publicación de un libro. Como autor autopublicado, es probable que no tenga los contactos que tienen las grandes editoriales. Pero no dejes que esto te detenga. Puedes dirigirte a los medios de comunicación locales; los periódicos y los sitios de noticias locales están siempre buscando contenido, y te sorprenderá el interés que pueden tener en un autor local con un proyecto interesante.',
  'pressrelease-intro-p2': 'Nuestra plataforma genera una nota de prensa automática para ti, basada en la información que rellenas durante los distintos pasos de la publicación. Se incluye información relevante como la portada, la sinopsis, el ISBN, el precio y el enlace a la tienda.',
  'pressrelease-intro-p3': 'Si quieres maximizar las posibilidades de obtener una reseña, envía un ejemplar de tu libro junto con la nota de prensa.',
  'pressrelease-identifytarget-title' : 'Piensa en tu público objetivo',
  'pressrelease-identifytarget-p1': 'Es importante que tengas en cuenta a tu público objetivo a la hora de seleccionar los destinatarios de tu nota de prensa. No tendría sentido enviar un comunicado de prensa a un medio de comunicación con un público objetivo completamente diferente al tuyo.',
  'pressrelease-identifytarget-p2': 'Aunque hasta ahora hemos hablado de medios de comunicación locales, no tienes porqué limitarte innecesariamente. También puedes considerar la posibilidad de buscar páginas web que cubran los mismos temas que tú en tu libro. Cuanto más específico, mejor.',
  'pressrelease-collectcontact-title': 'Busca los detalles de contacto',
  'pressrelease-collectcontact-p1': 'Ha llegado la hora de la parte más aburrida del proceso: hacer una lista estructurada de todas las personas con las que tienes que ponerte en contacto. Una buena manera de hacerlo es crear una hoja de excel con los nombres y direcciones de los puntos de venta prometedores, junto con el nombre, el correo electrónico y el número de teléfono de un contacto en dicho punto de venta. Siempre que sea posible, intenta obtener los datos de contacto de personas concretas, en lugar de correos electrónicos genéricos y números de teléfono de empresas. Dependiendo del tamaño de tu lista, también puedes añadir columnas que te permitan hacer un seguimiento de las personas con las que has contactado y su respuesta.',
  'pressrelease-sendpressrelease-title': 'Envía la nota de prensa ',
  'pressrelease-sendpressrelease-p1': 'Cuando envíes la nota de prensa por correo electrónico, asegúrate de que el texto sea breve, persuasivo y directo. No reutilices el texto del comunicado de prensa, pero intenta que sea lo suficientemente interesante como para que tu contacto se sienta tentado a saber más. El objetivo principal es que el contacto abra la nota de prensa. Asegúrate de enviar la nota en formato PDF. También puedes adjuntar un extracto de tu libro en formato PDF.',
  'pressrelease-sendpressrelease-p2': 'En el caso de los medios de comunicación de alta prioridad, aquellos que por tema o audiencia son más interesantes para ti, puedes considerar la posibilidad de enviar un ejemplar impreso de tu libro. Es una inversión relativamente pequeña que puede marcar una gran diferencia. Por último, asegúrate de indicar en tu correo electrónico que estás disponible para entrevistas y tus datos de contacto.',
  'pressrelease-downloadbutton': 'Descargar nota de prensa',
  'pressrelease-followup-title': 'Haz un seguimiento',
  'pressrelease-followup-p1': 'No esperes que la gente publique tu nota de prensa o escriba algo sobre tu libro automáticamente. Haz siempre un seguimiento para comprobar si han hecho o piensan hacer algo con tu nota de prensa. Considera la posibilidad de volver a enviar el comunicado de prensa al cabo de unas semanas si no obtienes respuesta. Ten en cuenta que los medios de comunicación reciben cientos de correos al día, por lo que una llamada para confirmar la recepción del correo y presentarte nunca está de más.',
  'pressrelease-sharelinks-title': 'Comparte los enlaces de tus apariciones',
  'pressrelease-sharelinks-p1': 'Cada vez que se mencione tu libro en los medios de comunicación, debes compartirlo en las redes sociales. Es una promoción gratuita para tu libro, y cuanta más gente lo vea, más posibilidades habrá de que alguien compre tu libro.',

  // NBD Biblion
  'nbdbiblion-copyandpastetext': 'Haz clic aquí para copiar el texto anterior',

  // Business cards
  'businesscards-intro-p1': 'El material promocional es una forma estupenda de complementar tu estrategia de marketing. Puedes añadir un toque personal y tangible que mucha gente aprecia en la era digital. Por eso te ofrecemos la opción de imprimir tus propias tarjetas de visita, en las que puedes colocar una imagen de tu libro junto con información sobre dónde comprarlo. Las tarjetas de visita se imprimen a todo color en cartón resistente de 400 gramos. Tu pedido te será entregado en un plazo de dos semanas.',
  'businesscards-blocktitle-order': 'Pedir tarjetas de visita',
  'businesscards-preview-label-front': 'Frente',
  'businesscards-preview-label-back': 'Posterior',
  'businesscards-numberofcards-label': 'Cantidad de tarjetas de visita',
  'businesscards-selectnumberofcards-item-label': '{number} tarjetas - {price}',

  // whatsapp
  'whatsapp-intro-p1': 'En Whatsapp, o en cualquier otra aplicación de mensajería que utilices, es donde probablemente vas a conseguir tus primeras ventas. Las personas a las que llegas a través de Whatsapp conforman tu propia red personal y, por tanto, serán las que probablemente más te apoyen como autor. Eso sí, asegúrate de hacerles partícipes del proceso de creación del libro antes de dar la noticia de la publicación y pedirles el favor definitivo de que compren tu libro. Por ejemplo, puedes mostrarles tus ideas de portada y pedirles su opinión, o hacer una encuesta de ideas sobre el título con ellos. Incluso puedes enviarles la sinopsis con antelación para que se entusiasmen.',
  'whatsapp-intro-p2': 'Puede que no parezca gran cosa que tus amigos y familiares compren tu libro, pero podrían ser tus mayores embajadores. El boca a boca es el rey, así que no descuides este método de promoción.',
  'whatsapp-promoteviawhatsapp': 'Promociona tu libro en WhatsApp',
  'whatsapp-button-sendviawhatsapp': 'Enviar via WhatsApp',
  'whatsapp-week1-title': 'Semana 1 - Anuncio ',
  'whatsapp-week1-message': '¡Hola a todos! Solo quería haceros saber que el libro del que he estado parloteando durante tanto tiempo ha sido finalmente publicado. Estoy muy entusiasmada/o con él y me gustaría que lo difundierais si conocéis a alguien que pueda estar interesado en él.\n\nSi estás interesado en él, o si necesitas un regalo para un amante de los libros, puedes hacerte con un ejemplar aquí:\n\n',
  'whatsapp-week2-title': 'Semana 2 - Fiesta de lanzamiento',
  'whatsapp-week2-message': 'Hola de nuevo, y gracias a todos los que ya habéis comprado mi libro, ¡sois fantásticos!\n\nSolo quería informaros de que voy a organizar una fiesta de presentación del libro. Por supuesto, estáis todos invitados. Habrá aperitivos, bebidas y una breve lectura de un fragmento del libro por parte de un servidor. Incluso os firmaré los libros si queréis ;)\n\nSerá muy divertido y será un honor que vengáis.',
  'whatsapp-week3-title': 'Semana 3 - ¡Gracias por venir!',
  'whatsapp-week3-message': '¡Hola a todos! Gracias a todos los que vinisteis a la fiesta de presentación de mi libro, ¡fue increíble verlos a todos allí! Aquí hay algunas fotos que hicimos durante el evento.\n\nTambién me gustaría agradeceros a todos los que comprasteis un ejemplar, ¡de verdad que significa mucho para mí! Si hay alguien que no pudo asistir y todavía quiere hacerse con un ejemplar de mi libro, puede hacerlo aquí:',
  'whatsapp-week4-title': 'Semana 4 - Solicitud de revisión',
  'whatsapp-week4-message': '¡Hola!\n\nSi os puedo pedir un pequeño favor, me sería de gran ayuda si pudierais escribir una breve reseña del libro en Goodreads. Haría maravillas por la visibilidad del libro en Internet y me ayudaría a llegar a más gente. Estaría más que feliz de pagártelo con un café un día de estos!',
  'whatsapp-week4-goodreadswarning': 'Atención: antes de enviar este mensaje asegúrate de que ya has añadido tu libro a Goodreads.',
  'whatsapp-tips-title': 'Consejos',
  'whatsapp-tips-groups-label': 'Crea grupos de Whatsapp específicos',
  'whatsapp-tips-groups': 'Es probable que tengas contactos de todo tipo en tu Whatsapp: compañeros de trabajo, familia, amigos u otros autores. Como es normal que no tengas la misma confianza y cercanía con todos ellos, puede ser una buena idea crear varios grupos distintos en vez de un solo grupo, y enviarles mensajes especialmente creados teniendo en cuenta tu relación con ellos.',
  'whatsapp-tips-readtheroom-label': 'Evita ser pesado',
  'whatsapp-tips-readtheroom': 'Las personas con las que hablas por Whatsapp son probablemente las más cercanas a ti, lo que significa que probablemente serán tus lectores y clientes más fieles. Considera la posibilidad de mantener esta relación agradeciéndoles sus compras en vez  de enviándoles recordatorios constantes para que compren tu libro. Esta suele ser una manera más eficaz de motivar a los más rezagados.',
  'whatsapp-tips-askspread-label': 'Pide a la gente que corra la voz',
  'whatsapp-tips-askspread': 'El boca a boca es una de las formas más efectivas de dar a conocer su libro. También es una de las más difíciles. Por eso, aprovecha tus grupos de Whatsapp y haz que tus amigos y familiares corran la voz dentro de sus propios círculos sociales.',
  'whatsapp-tips-participation-label': 'Crear un sentimiento de participación',
  'whatsapp-tips-participation': 'Es probable que los usuarios de Whatsapp sean tus lectores y clientes más cercanos y fieles. Foméntalo haciéndoles saber lo mucho que aprecias que compren tu libro y que te ayuden a correr la voz. Invítales a la fiesta de presentación de tu libro y a otros eventos relacionados con él, y comparte fotos de estos eventos en el grupo. Recuérdales lo importantes que son para ti y el éxito de tu libro.',

  // social media
  'socialmedia-intro-p1': 'Estamos seguros de que ya lo sabes, pero las redes sociales son una de las herramientas de promoción más importantes en este momento. Como autor autopublicado, poder comunicarte directamente con los lectores interesados en el nicho concreto sobre el que escribes tiene un valor incalculable. A continuación hemos recopilado algunos consejos y herramientas para distintas redes sociales, y otros más.',
  'socialmedia-tips-title': 'Consejos',
  'socialmedia-tips-beconsistent-label': 'Sé consistente',
  'socialmedia-tips-beconsistent': 'Como escritor, tienes una voz única, una forma particular de compartir tus pensamientos, y esto también  debería trasladarse a tu comunicación en redes sociales. Es importante que compartas tus pensamientos de forma activa y elocuente, y que proyectes tu voz de forma consistente de manera que tu audiencia la reconozca.',
  'socialmedia-tips-beshareable-label': 'Sé compartible',
  'socialmedia-tips-beshareable': 'Aunque debes desarrollar hábitos de publicación en las redes sociales y hacer tus propias cosas, también es importante crear contenido que pueda ser compartido fácilmente por tus seguidores. Cuanto más se compartan tus publicaciones, más atención recibirá tu cuenta.',
  'socialmedia-tips-berelatable-label': 'Sé cercano',
  'socialmedia-tips-berelatable': 'Sabemos que has venido a hablar de tu libro. Sin embargo, es una buena idea ofrecer a la gente algo más de variedad, como por ejemplo momentos de tu vida personal para que la gente te conozca un poco más. Esto te convertirá en algo más que un vendedor de libros. También deberás interactuar con el contenido de otras personas; dar "me gusta", comentar y compartir.',
  'socialmedia-tips-hashtaglover-label': 'Sé un amante de los hashtags',
  'socialmedia-tips-hashtaglover': 'Los hashtags siguen siendo extremadamente importantes. No utilices hashtags genéricos; esfuérzate por encontrar y usar aquellos que te ayuden a conectar con las personas adecuadas, y viceversa. Investiga un poco sobre los principales hashtags en, por ejemplo, la comunidad de escritores que están preparando su libro. Por supuesto, no debes ponerle hashtags a todo. Céntrate en el contenido que realmente quieres difundir. No todas las publicaciones necesitan un hashtag.',
  'socialmedia-tips-beflexible-label': 'Sé flexible',
  'socialmedia-tips-beflexible': 'Cada red social es diferente. En Instagram todo gira en torno a las imágenes. LinkedIn se centra en la creación de redes. En Facebook se trata de compartir. Twitter es todo palabras. Es importante que tu contenido no sea un copia-pega; tienes que adaptarte al estilo y las normas de cada plataforma. Tu voz debe seguir siendo la misma, pero la forma de proyectarla debe ser flexible.',
  'socialmedia-tips-beanalytical-label': 'Sé analítico',
  'socialmedia-tips-beanalytical': 'Todas las plataformas de redes sociales ofrecen análisis, ¡aprovéchalos! Debes prestar atención al tipo de público al que llegas, a las mejores horas para publicar, y a las tendencias del momento. Tus plataformas de redes sociales te proporcionarán esa información en cuanto cambies tu perfil a una cuenta de empresa.',

  // Instagram
  'instagram-intro-p1': 'Instagram es prácticamente una necesidad para cualquier autor hoy en día. La escena del libro en Instagram, bookstagram, es una comunidad enorme y llena de lectores potenciales. Construir una fuerte presencia en Instagram puede ser bastante difícil, ya que requiere que produzcas contenido visual de alta calidad de forma regular.',
  'instagram-intro-p2': 'Hay tres cosas principales que debes priorizar cuando estés en Instagram: construir un feed estéticamente agradable, usar hashtags relevantes y variados (hemos añadido algunos a las plantillas, pero siéntete libre de cambiarlos o añadir más) y publicar historias.',
  'instagram-promoteoninstagram': 'Promociona tu libro en Instagram',
  'instagram-switchtophone': 'Cambia a tu teléfono',
  'instagram-switchtophone-description': 'Tendrás que usar tu teléfono para publicar contenido en Instagram. Puedes escanear el código QR de abajo para ir a la versión móvil de esta página. La mayoría de los teléfonos inteligentes escanean los códigos QR automáticamente si abres la aplicación de la cámara y apuntas hacia el código. Si no puedes escanear el código QR, también puedes iniciar sesión en la plataforma desde tu teléfono.',
  'instagram-setupinsta-title': 'Configurar instagram',
  'instagram-week1-title': 'Semana 1 - Anuncio',
  'instagram-week1-message': 'Aquí está por fin: ¡el primer ejemplar impreso de mi nuevo libro, {title}! - ¡Es un momento tan grande para mí y no podría estar más feliz de compartirlo con vosotros! Si quieres apoyarme, el libro está disponible ahora por {price}, ¡enlace en la biografía!\n\n{keywords} #autopublicación #autorpublicado #lanzamientodellibro',
  'instagram-week2-title': 'Semana 2 – Primer post de venta',
  'instagram-week2-chooseanimage-description': 'Cuando crees el visual para tu primer post de ventas, es importante que sea personal; eso es lo que tus seguidores de Instagram quieren ver. Intenta incluir tu cara, tu libro y, preferiblemente, ambos. Por ejemplo, puedes publicar una foto tuya delante de una librería, sosteniendo un ejemplar de tu libro.',
  'instagram-week2-message': '¡Estoy muy emocionado por haber vendido mis primeros 5 libros! Muchas gracias a todos los que comprasteis un ejemplar; ¡no sabéis cuánto aprecio vuestro apoyo! Espero que me perdonéis por la descarada autopromoción que voy a hacer, pero si leer un libro que he escrito con mis propias manos os parece una idea interesante, pensad en ir a mi tienda web y echarle un vistazo. Podréis leer la reseña, admirar la portada e incluso conseguir vuestro propio ejemplar. El enlace está en la biografía.\n\n#autopublicación #vidadeescritor',
  'instagram-week3-title': 'Semana 3 - Sorteo',
  'instagram-week3-chooseanimage-description': 'Al crear el visual para tu sorteo, tiene sentido mostrar qué es exactamente lo que estás regalando. En nuestro ejemplo de abajo, estamos regalando 5 copias firmadas, y en ese caso, podrías tomar una foto de 5 copias de tu libro, con una de ellas abierta para mostrar tu firma. Asegúrate de que la iluminación es la adecuada para que los libros tengan el mejor aspecto posible.',
  'instagram-week3-message': 'Al crear el visual para tu sorteo, tiene sentido mostrar qué es exactamente lo que estás regalando. En nuestro ejemplo de abajo, estamos regalando 5 copias firmadas, y en ese caso, podrías tomar una foto de 5 copias de tu libro, con una de ellas abierta para mostrar tu firma. Asegúrate de que la iluminación es la adecuada para que los libros tengan el mejor aspecto posible.\n\nSi aún no has conseguido hacerte con el libro, lo que viene a continuación puede interesarte: ¡voy a regalar 5 ejemplares a mis seguidores! Todos los libros estarán firmados, así que, si cualquier día me hago muy famoso, tendrás algo único en tu estantería ;)\n\nTodo lo que tienes que hacer es darle un "me gusta" a este post y etiquetar a un amigo que creas que también puede estar interesado en el libro. ¡Seleccionaré 5 comentarios al azar y anunciaré los ganadores en mis stories la próxima semana!\n\n#sorteo #bookstagram #autopublicación',
  'instagram-week4-title': 'Semana 4 – Solicitud de reseña',
  'instagram-week4-chooseanimage-description': 'Cuando pides una reseña dependes de la buena voluntad de tus seguidores, así que es importante conectar con ellos a través de un buen visual. Una foto sincera de ti mismo sosteniendo tu libro hacia la cámara es una forma sencilla de hacerlo. Si quieres ponerte creativo, puedes poner unos cuantos libros en una cesta y colocar un trozo de cartón con las palabras "Revísame" delante. También puedes optar por hacer una historia para solicitar reseñas en lugar de un simple post; esta opción probablemente llegará a más gente.',
  'instagram-week4-message': '¡Hola a todo el mundo! Estoy aquí para pedir un favor!\n\nSi has leído mi libro, por favor, escribe una breve reseña sobre él en Goodreads. Sería increíblemente útil para aumentar su visibilidad en línea y llegar a más gente. Incluso si no tienes tiempo para escribir una reseña detallada, una simple valoración sería genial. Te lo agradecería mucho. Además, muchas gracias a todos los que han comprado mi libro, ¡no sabéis lo que significa para mí!\n\n#reseña #autopublicación #bookstragram #autorindie',
  'instagram-tips-title': 'Consejos',
  'instagram-tips-visuals-label': 'Una imagen vale más…',
  'instagram-tips-visuals': 'Como probablemente sepas, en Instagram todo gira en torno a lo visual. Esto puede parecer un reto para ti como autor que intenta promocionar su libro, pero no tiene por qué ser una desventaja. Algunos de los pilares de tu contenido en Instagram deberían ser la portada de tu libro, fotos entre bastidores de tu proceso de escritura y algunas fotos de tu día a día como autor.',
  'instagram-tips-quotes-label': 'Citas',
  'instagram-tips-quotes': 'Para estimular la curiosidad de tu audiencia, una táctica que suele tener éxito es compartir citas de tu libro. Aunque Instagram es muy visual, editar fotos con una frase es siempre una buena manera de compartir tu obra.',
  'instagram-tips-interact-label': 'Interactúa',
  'instagram-tips-interact': 'Tu atención no debe centrarse sólo en la promoción, sino también en interactuar con tu audiencia. Cuanta más interacción, más atención recibirá tu cuenta. Es esencial compartir las publicaciones de tus lectores en tus historias que mencionen o muestren tu libro, o comentar sus publicaciones. Mantén a la comunidad comprometida',
  'instagram-tips-bookstagram-label': 'Bookstagram',
  'instagram-tips-bookstagram': 'Hay una enorme comunidad de lectores en Instagram que se agrupan bajo el término “Bookstagram”. Esta comunidad online comparte fotografías de los libros que están leyendo y de los que tienen previsto leer, y son extremadamente influyentes. Tú, como autor, deberías acercarte y seguir a estos bookstagramers, con el objetivo final de que compartan tu libro en su perfil. Para empezar, deberías apuntar a cuentas con 1.000-20.000 seguidores, porque su compromiso suele ser alto y es más fácil para ti iniciar una conversación. Recuerda que debes crear mensajes personalizados.',
  'instagram-tips-addlinks-label': 'Añade enlaces',
  'instagram-tips-addlinks': 'Es importante añadir un enlace en tu biografía que lleve a una página web donde tu libro esté a la venta. Puedes añadir el enlace a nuestra tienda, o a tu página web de autor, y la gente en tu Instagram podrá comprar tu libro con un par de clics.',
  'instagram-tips-balance-label': 'Crea un equilibrio',
  'instagram-tips-balance': 'Ten en cuenta que Instagram da prioridad a la participación sobre el intercambio de contenidos. Así que no es necesario publicar todos los días, sin embargo es bueno apuntar hacia la publicación de 2 a 3 días a la semana. Además, usar Instagram Stories para compartir fotos menos editadas, con más contenido del día a día puede hacer que el engagement crezca y llamar la atención de Instagram.',
  'instagram-tips-contests-label': 'Organiza concursos/sorteos',
  'instagram-tips-contests': 'No hay nada que le guste más a la gente que recibir cosas gratis. Crear un sorteo es la forma perfecta de atraer la atención de la gente hacia ti y hacer crecer tu audiencia. Puedes ofrecer un ejemplar firmado de tu libro y pedir a la gente que te siga y comente para participar en el sorteo.',
  'instagram-tips-scheduleposts-label': 'Programa tus publicaciones',
  'instagram-tips-scheduleposts': 'Ser un autor es un trabajo duro, y a menudo te encontrarás presionado por el tiempo cuando necesites crear y subir nuevos contenidos. Una buena estrategia es utilizar varias herramientas online para programar tus publicaciones. De este modo, puedes crear varias publicaciones a la vez y programarlas para las próximas semanas. Si creas una cuenta de empresa en Facebook, tendrás acceso a Creator Studio, que te permite crear y programar publicaciones tanto en Facebook como en Instagram. Este es un buen punto de partida.',

  // facebook
  'facebook-copyandpastetext': 'Haz clic aquí para copiar el texto anterior. A continuación, pega este texto en el campo de estado después de hacer clic en el botón de compartir.',
  'facebook-button-shareonfb': 'Compartir en Facebook',
  'facebook-intro-p1': 'La clave para sacar el máximo partido a tus esfuerzos de marketing en Facebook es maximizar la interacción con tus seguidores (esto es, el engagement). Para ello, intenta conseguir el mayor número posible de "me gusta" y comentarios, y encuentra grupos relevantes a los que unirte y en los que ser activo compartiendo tu contenido.',
  'facebook-promoteviafacebook': 'Promociona tu libro en Facebook',
  'facebook-week1-title': 'Semana 1 – Anuncio',
  'facebook-week1-message': '¡Hoy es el día! Ya soy oficialmente un autor publicado, ha sido un largo camino y mucho trabajo, pero tener mi libro en mis manos realmente hace que todo valga la pena. En caso de que no hayas seguido mi progreso y estés interesado en saber de qué trata este libro, aquí está la reseña:\n\n{shortdesc}\n\nSi te parece interesante, puedes dirigirte a mi sitio web.',
  'facebook-week2-title': 'Semana 2 – Primer post de venta',
  'facebook-week2-message': '¡Estoy muy emocionado por haber vendido mis primeros 5 libros! Muchas gracias a todos los que comprasteis un ejemplar, significa mucho para mí. Espero que me perdonéis por la descarada autopromoción que voy a hacer, pero si pensáis que leer un libro que he escrito yo mismo con mis propias manos puede ser interesante, pensad en dirigiros a mi sitio web y echadle un vistazo.',
  'facebook-week3-title': 'Semana 3 – Sorteo',
  'facebook-week3-message': 'Realizar mi sueño de ser autor ha sido maravilloso hasta ahora; todo el mundo me ha apoyado en este viaje, y realmente no podría estar más contento. Nunca imaginé que mi libro tendría tan buena acogida, y para celebrarlo, ¡estoy organizando un sorteo! Así que, si aún no te has hecho con un ejemplar, dale a “me gusta” y comparte este post, y deja un comentario describiendo el argumento de tu libro favorito con el menor número de palabras posible. Elegiré los cinco mejores comentarios y les enviaré un ejemplar firmado de {title}.',
  'facebook-week4-title': 'Semana 4 – Solicitud de revisión',
  'facebook-week4-message': 'Muchas gracias a todos los que habéis comprado mi libro; ¡sois fantásticos! Sin embargo, vuelvo a pediros otro pequeño favor: ¡sería una gran ayuda para mí si fuerais a Goodreads y dejárais una reseña de mi libro! Incluso una simple valoración sería increíble, y si no estáis o no queréis estar en Goodreads, una reseña en cualquier plataforma o en cualquier tienda también sería muy apreciada. Muchas gracias de antemano.',
  'facebook-tips-title': 'Consejos',
  'facebook-tips-authorpage-label': 'Página web de autor',
  'facebook-tips-authorpage': 'En Facebook es importante crear páginas para desarrollar contenidos específicos. Al crear una página de autor, tu público puede conocerte mejor y obtener la información más reciente sobre tu libro.',
  'facebook-tips-engagement-label': 'Interactúa',
  'facebook-tips-engagement': 'El algoritmo de Facebook se basa en el engagement, el grado de participación que obtienes en las publicaciones. No hay garantía de que tus seguidores y amigos vean tus publicaciones, así que la mejor manera de maximizar el número de impresiones que recibe tu contenido es fomentando la participación. Cuantos más "me gusta" y comentarios tengas, mayor será la probabilidad de que Facebook muestre tus publicaciones a los demás. Si lo haces correctamente, conseguirás un efecto de bola de nieve, en el que los primeros me gusta y comentarios estimularán una mayor participación, haciendo que tus publicaciones sean más visibles, lo que invita a una participación aún mayor.',
  'facebook-tips-sellyourbook-label': 'Vende tu libro',
  'facebook-tips-sellyourbook': 'Es importante incluir en tus publicaciones los enlaces directos a los lugares donde se vende tu libro, como el link a nuestra tienda o a tu sitio web, por ejemplo. Es importante facilitar a tu público el acceso tanto a tu libro como a la información sobre el y sobre ti.',
  'facebook-tips-promoteothers-label': 'Promociona otros libros',
  'facebook-tips-promoteothers': 'Este consejo puede parecer contraproducente, pero en realidad es una estrategia muy utilizada. Cuanto más interactúes con otros autores, más te ayudarán a su vez. Siempre es bueno compartir reseñas y promocionar algunos libros para formar parte de una comunidad activa.',
  'facebook-tips-sharewriting-label': 'Comparte citas',
  'facebook-tips-sharewriting': 'Para que tu público te conozca como autor, es importante que compartas algunos de tus textos, así que: ¡crea posts que incluyan algunas citas o pasajes de tu libro! Las citas son importantes para motivar el interés de la gente en tu historia y en comprar tu libro.',
  'facebook-tips-startagroup-label': 'Empieza un grupo o participa en uno',
  'facebook-tips-startagroup': 'Los grupos son un aspecto central de Facebook. Mucha gente disfruta formando parte de comunidades más pequeñas en el gran mundo de Facebook. Como autor, puedes encontrar grupos relevantes dentro de tu género, que son lugares excelentes para compartir más sobre ti y tu libro. También puedes crear un grupo desde cero para crear poco a poco tu propia comunidad en Facebook.',

  // Twitter
  'twitter-intro-p1': 'Twitter está hecho para la gente que sabe hacer valer sus palabras, así que, como escritor, deberías sentirte como en casa.',
  'twitter-intro-p2': 'Para que Twitter te funcione, tienes que tuitear y, lo que es más importante, retuitear a menudo. Mantente atento a los hashtags que son relevantes para los libros, las publicaciones, tu género literario particular e incluso tus intereses personales. Asegúrate de contribuir con sustancia a cualquier discurso en el que participes. El potencial de ventas de Twitter es mucho menos directo que el de, por ejemplo, Instagram, pero puede ser útil para ganar seguidores y, por extensión, embajadores de tu libro.',
  'twitter-promoteviatwitter': 'Promociona tu libro en Twitter',
  'twitter-button-shareviatwitter': 'Compartir en Twitter',
  'twitter-charcount': 'Has utilizado {chars} de 280 caracteres',
  'twitter-week1-title': 'Semana 1 - Anuncio',
  'twitter-week1-message': '¡Por fin ha salido mi libro! Dirígete a mi página web para pedir un ejemplar, o lee algunos capítulos de muestra para hacerte una idea del libro. #autorindie',
  'twitter-week2-title': 'Semana 2 - Primer post de venta',
  'twitter-week2-message': '¡Ya está en marcha! Los primeros 5 libros ya se han vendido; ¡muchas gracias a todos los que habéis comprado un ejemplar! Si alguien más está interesado en mi libro, {title} podéis conseguirlo en mi página web',
  'twitter-week3-title': 'Semana 3 - Sorteo',
  'twitter-week3-message': '¡Atención amantes de los libros, es hora de un #sorteo! Si queréis ganar un ejemplar firmado de mi libro solo tenéis que seguirme, darle a me gusta, retuitear este tuit y dejar un comentario explicando por qué os gustaría un ejemplar del libro.',
  'twitter-week4-title': 'Semana 4 - Solicitud de reseña',
  'twitter-week4-message': 'Atención a todos los lectores: Si has leído mi libro, {title} y lo has disfrutado, por favor, considera ir a Goodreads y dejar una reseña. No solo me encantaría leer vuestras opiniones, sino que también ayudaría mucho a mi libro.',
  'twitter-tips-title': 'Consejos',
  'twitter-tips-sharequotes-label': 'Comparte citas',
  'twitter-tips-sharequotes': 'Como sin duda estás familiarizado con Twitter, no vamos a profundizar demasiado en los aspectos básicos. Al ser una plataforma centrada en mensajes concisos y basados en texto, es muy adecuada para los escritores. Una forma obvia de utilizar Twitter es para compartir citas de tu libro. Intenta mostrar a tu audiencia quién eres como escritor.',
  'twitter-tips-interaction-label': 'Interacción por encima de todo',
  'twitter-tips-interaction': 'En Twitter no solo se trata de compartir tus ideas, sino también de participar en los debates que se dan en la plataforma; aún más cuando acabas de empezar. Twitter ofrece la posibilidad de interactuar con lectores potenciales, otros autores y, por supuesto, con tus propios seguidores. Cuanta más interacción, mejor.',
  'twitter-tips-balance-label': 'Encuentra el equilibrio',
  'twitter-tips-balance': 'Como tu objetivo principal es vender tu libro, es fácil muy fácil que solo te centres en esto y olvides todo lo demás.  Sin embargo, es importante mostrar un lado personal, y mejor aún, divertido, de ti mismo. La gente no quiere leer constantemente tuits que se reduzcan a "compra mi libro"; también quieren escuchar tus historias y echar un vistazo a tu vida.',
  'twitter-tips-pinapost-label': 'Fija un post',
  'twitter-tips-pinapost': 'Si publicas regularmente en Twitter y te preocupa que la información específica sobre tu libro se pierda en tu perfil o se pase por alto, una buena idea sería anclar una publicación. Puedes fijar una publicación atractiva que contenga el enlace de venta de tu libro y que todos puedan ver una vez que entren en tu perfil. Luego sólo tienes que dirigirles hasta allí.',
  'twitter-tips-bio-label': 'Bio',
  'twitter-tips-bio': 'Gran parte de tu tiempo en Twitter se centra exclusivamente en tuitear, pero no descuides tu biografía. Será visible para todo el mundo, así que es un buen lugar para incluir unas palabras que te describan e incluyan el título de tu libro, tu enlace de venta o tu web de autor. Hazte el interesante y no desaproveches una oportunidad para colocar un buen link.',

  // LinkedIn
  'linkedin-intro-p1': 'LinkedIn tiene más potencial de lo que te imaginas para los autores autopublicados. Por un lado, está prácticamente hecho a medida para quienes han escrito obras de no ficción. Sin embargo, incluso si has escrito un libro de ficción, puedes encontrar un público que te apoye en LinkedIn. Es una plataforma muy adecuada para compartir tu experiencia sobre el proceso de escribir y publicar desde lo que nos gusta llamar una perspectiva de "autor emprendedor". Piensa en tu libro como un proyecto y habla de los retos y las recompensas que supone trabajar en él. Pero no te olvides de ser humano.',
  'linkedin-intro-p2': 'Publicar en LinkedIn requiere un poco más de preparación que en otras plataformas. Deberás evitar ser demasiado intenso y centrarte en compartir conocimientos y experiencias. Escribir un artículo, por ejemplo, requerirá algo de esfuerzo, pero será mucho mejor recibido en la plataforma que un post descaradamente centrado en las ventas.',
  'linkedin-promotevialinkedin': 'Promociona tu libro en LinkedIn',
  'linkedin-copyandpastetext': 'Haz clic aquí para copiar el texto anterior. A continuación, pega este texto en el campo de estado después de hacer clic en el botón de compartir.',
  'linkedin-button-shareonlinkedin': 'Compartir en LinkedIn',
  'linkedin-week1-title': 'Semana 1 - Anuncio',
  'linkedin-week1-message': 'Estoy inmensamente orgulloso de anunciar que mi libro, {title}, ha sido finalmente publicado. Convertirme en autor siempre ha sido mi sueño, y ha sido una experiencia increíble hacerlo realidad por mí mismo. Si tenéis algún comentario sobre el libro o queréis discutir el contenido en profundidad, ¡me encantaría conectar con vosotros e intercambiar opiniones! Para aquellos que estén interesados, ¡aquí os dejo un link donde podréis descubrir más!\n\n#autorautopublicado #publicación #libros',
  'linkedin-week2-title': 'Semana 2 - Lanzamiento',
  'linkedin-week2-message': 'Hace poco organicé el primer evento de presentación de mi libro y fue una auténtica gozada. Ver a todo el mundo reunido para celebrar la publicación de mi libro conmigo fue increíblemente emocionante y extremadamente humilde. Un sincero agradecimiento a todos los que asistieron, y para los que no pudieron hacerlo, ¡aquí hay algunas bonitas fotos del evento!\n\n#presentacióndelibro #autor #lanzamiento',
  'linkedin-week3-title': 'Semana 3 - Taller',
  'linkedin-week3-message': 'Mi libro {title} se está vendiendo muy bien últimamente y he notado un gran interés no solo en el tema, sino en el proceso de escritura y autopublicación en sí. Esto me dio la idea de organizar un taller gratuito para hablar de la escritura, la autopublicación y el espíritu empresarial de los autores con todos vosotros.  Creo que sería una gran idea intercambiar y compartir puntos de vista con los demás. ¡Decidme si estáis interesados!\n\n#taller #autoremprendedor #autorautopublicado',
  'linkedin-week4-title': 'Semana 4 - Sorteo',
  'linkedin-week4-message': 'Publicar mi libro fue una de las cosas más difíciles que he hecho, ¡pero realmente valió la pena! Nunca imaginé que el libro se vendería tan bien, ¡y quiero celebrarlo regalando 5 ejemplares! Si quieres participar en el sorteo, solo tienes que reaccionar a este post y dejar un comentario describiendo por qué crees que *palabra clave* es importante hoy en día. ¡Los 5 comentarios más inspiradores se llevarán un ejemplar de {title}!\n\n#autorautopublicado #autopublicación #sorteo',
  'linkedin-tips-title': 'Consejos',
  'linkedin-tips-finishprofile-label': 'Termina tu perfil... ¡pero termínalo de verdad!',
  'linkedin-tips-finishprofile': 'Esto puede parecer obvio, pero mucha gente pospone indefinidamente la finalización de todos los pasos para completar su perfil de Linkedin. No terminar tu perfil significa que hay menos formas de que la gente te pueda encontrar o se identifique contigo.',
  'linkedin-tips-connect-label': 'Conéctate',
  'linkedin-tips-connect': 'Linkedin tiene que ver con la creación de redes; la plataforma se basa en la creación de conexiones con la gente. Tienes que asegurarte de añadir a otros autores, lectores potenciales, etc. Crear contenido para una red pequeña no te llevará a ninguna parte.',
  'linkedin-tips-publisharticles-label': 'Publica artículos',
  'linkedin-tips-publisharticles': 'Linkedin ofrece la posibilidad de publicar artículos y es extremadamente útil para un aspirante a autor. Puedes utilizar esta plataforma para compartir fragmentos de tu novela, o tal vez escribir artículos en los que compartas consejos y experiencias que puedan gustar a otros aspirantes a autores. La gente tiende a participar y leer mucho en Linkedin, y los artículos son perfectos para un autor que tiene algo que decir. Además, los artículos son una gran manera de canalizar el tráfico hacia tu sitio web y, por tanto, hacia tu libro.',
  'linkedin-tips-jobposition-label': 'Haz que tu libro sea un puesto de trabajo',
  'linkedin-tips-jobposition': '¡Puedes poner tu libro como tu propio puesto de trabajo! El título haría referencia a tu nombre y la empresa sería el nombre de tu libro. De esta manera, la gente puede ver claramente tu libro en tu perfil de Linkedin. Puedes añadir las fechas en las que has trabajado en tu novela junto con una bonita descripción. Esta es también una buena forma de anunciar posibles secuelas.',
  'linkedin-tips-behuman-label': 'Sé humano',
  'linkedin-tips-behuman': 'Dado que Linkedin es conocido principalmente por ser una plataforma de negocios, la gente suele pensar que tiene que ser formal cuando publica allí. Sin embargo, si eres un autor que intenta vender su libro, ganarás puntos si desarrollas un perfil personalizado, ¡y deberías centrarte en eso! No intentes ser algo que no eres. La gente en LinkedIn se vuelve loca por las historias de crecimiento personal y de superación de retos, así que utiliza eso en tu favor.',

  // Libraries
  'libraries-intro-p1': 'Todo escritor puede tener su libro en la biblioteca. Depositar tu libro en tu biblioteca local o municipal es una forma estupenda de legitimarte como autor y establecer relaciones con este punto de encuentro para todos los amantes de los libros. También es una buena oportunidad de marketing y de vender tu libro directamente a las bibliotecas. Además, si consigues que un número suficiente de personas pidan tu libro, es posible que más personas se interesen por él y acabes vendiendo algunos ejemplares que no esperabas.',

  // Upload zone
  'uploadzone-default-title': 'Subir',
  'uploadzone-default-title-progress': 'Subiendo tu manuscrito',
  'uploadzone-filerequirements': 'Requisitos para los archivos: max. {filesize}, formats: {filetypes}',
  'uploadzone-button-selectfile': 'Seleccionar archivo',
  'uploadzone-ordragfilehere': 'o arrástralo y suéltalo aquí',
  'uploadzone-dropfiletoupload': 'Suelta aqui tu archivo para subirlo',

  // Book problem translations
  'bookproblem-link-moreinfo': 'Más información',
  'bookproblem-stepScriptSizeModifiedUploadAgain': 'Has cambiado el formato de tu libro. Por favor, vuelve a subir el archivo de tu manuscrito.',
  'bookproblem-stepScriptColorModifiedUploadAgain': 'Has cambiado los ajustes de color de tu manuscrito. Por favor, vuelve a subir el archivo de tu manuscrito.',
  'bookproblem-stepScriptBindingModifiedUploadAgain': 'Has cambiado el tipo de libro. Por favor, vuelve a subir el archivo de tu manuscrito.',
  'bookproblem-stepScriptReaddirectionModifiedUploadAgain': 'Has cambiado el idioma de tu libro a un idioma con una sentido de escritura diferente. Por favor, vuelve a subir tu manuscrito para que el sentido de la escritura se corresponda con la lengua elegida.',
  'bookproblem-stepScriptManuscriptConversionFailed': 'No hemos podido convertir tu archivo. Probablemente se deba a que el archivo está dañado. Intenta reparar y volver a cargar tu archivo.',
  'bookproblem-stepScriptManuscriptColorpagesNotCalculated': 'No hemos podido calcular correctamente el número de páginas en color de tu libro. Ponte en contacto con nosotros para que te ayudemos a resolver este problema, o sube una nueva versión de tu archivo de manuscrito.',
  'bookproblem-stepScriptManuscriptConversionTechnicalError': 'Se ha producido un error técnico al convertir tu manuscrito. Código de error: {lasterror}',
  'bookproblem-stepScriptFontsNotEmbedded': 'Hay fuentes en este documento que no están incrustadas correctamente. Consulta nuestra guía sobre la creación de un PDF para obtener más información.',
  'bookproblem-stepScriptEbookConversionHasErrors': 'Hemos detectado varios errores al convertir tu eBook. Tienes que solucionar estos problemas antes de poder publicar tu libro.',
  'bookproblem-printerConstraintNumberpagesLessThanMinimum': 'Tu libro no tiene suficientes páginas. Por favor, asegúrate de que tu libro tiene al menos {min} páginas (el número de páginas actual es {pagecount}).',
  'bookproblem-printerConstraintNumberpagesMoreThanMaximum': 'Tu libro tiene demasiadas páginas. Por favor, asegúrate de que tu libro contiene como máximo {max} páginas (el recuento de páginas actual es {pagecount}).',
  'bookproblem-printerConstraintNumberpagesMoreThanCoverwidth': 'Tu libro contiene demasiadas páginas para el formato y el tipo de papel que has elegido. Asegúrate de que tu libro contiene como máximo {max} páginas, o elige otro tipo de papel y/o un formato más reducido.',

  // Bookstep: manuscript
  'bookstep-Manuscript': 'Manuscrito',
  'bookstep-Manuscript-intro-p1-1': 'Si quieres que tu libro sea un éxito, es esencial que el manuscrito esté bien escrito y tenga un formato profesional. Por eso es importante que te tomes tu tiempo en este paso. Cuesta menos hacerlo bien a la primera que tener que volver a hacerlo después. Estaremos a tu lado para ofrecerte apoyo durante el proceso de creación. No dudes en escribirnos si te gustaría solicitar presupuesto para que un profesional eche un vistazo a tu trabajo. Si tu manuscrito ya está terminado, solo tienes que subirlo como documento de Word o PDF. Después de subirlo, lo convertiremos en un PDF listo para imprimir. Asegúrate de revisar bien este archivo y pide siempre una copia de prueba antes de publicarlo. Hay pocas que den más rabia que encontrarse erratas y otros errores en tu libro una vez publicado.',
  'bookstep-Manuscript-intro-p1-3': 'Si quieres que tu libro sea un éxito, es esencial que el manuscrito esté bien escrito y tenga un formato profesional. Por eso es importante que te tomes tu tiempo en este paso. Cuesta menos hacerlo bien a la primera que tener que volver a hacerlo después. Estaremos a tu lado para ofrecerte apoyo durante el proceso de creación. No dudes en escribirnos si te gustaría solicitar presupuesto para que un profesional eche un vistazo a tu trabajo. Si tu manuscrito ya está terminado, solo tienes que subirlo como documento de Word o PDF. Después de subirlo, lo convertiremos en un PDF listo para imprimir. Asegúrate de revisar bien este archivo y pide siempre una copia de prueba antes de publicarlo. Hay pocas que den más rabia que encontrarse erratas y otros errores en tu libro una vez publicado.',
  'bookstep-Manuscript-intro-p1-6': 'Si quieres que tu libro sea un éxito, es esencial que el manuscrito esté bien escrito y tenga un formato profesional. Por eso es importante que te tomes tu tiempo en este paso. Es mucho menos molesto hacerlo bien a la primera que tener que volver a hacerlo después. Estaremos a tu lado para ofrecerte apoyo durante el proceso de creación. Puedes subir un documento de Word o un archivo ePub para crear un eBook o libro electrónico. Nuestra plataforma procesará el archivo subido (y lo convertirá en un ePub si es necesario), y creará una versión lista para ser publicada. Revisa bien este archivo listo para publicar antes de continuar. Si piensas subir un documento de Word, es importante que el archivo que subas tenga un formato pensado para ser transformado ePub, de lo contrario el libro electrónico resultante podría no ser legible. Si quieres subir un ePub, te recomendamos que utilices Calibre para crearlo.',
  'bookstep-Manuscript-intro-p1-7': 'Si quieres que tu libro sea un éxito, es esencial que el manuscrito esté bien escrito y tenga un formato profesional. Por eso es importante que te tomes tu tiempo en este paso. Es mucho menos molesto hacerlo bien a la primera que tener que volver a hacerlo después. Estaremos a tu lado para ofrecerte apoyo durante el proceso de creación. Puedes subir tu manuscrito terminado directamente a nuestra plataforma en formato PDF. Una vez que hayas subido el archivo de tu manuscrito, te mostraremos una vista previa del archivo convertido. Revisa bien este archivo de vista previa. Hay pocas cosas tan molestas como encontrar erratas y otros errores en tu libro publicado.',
  'bookstep-Manuscript-button-nextstep': 'Siguiente paso',
  'bookstep-Manuscript-resources-toolstoimprovescript': 'Ayuda para tu manuscrito',
  'bookstep-Manuscript-resources-wordtemplate': 'Plantillas y ajustes de Word',
  'bookstep-Manuscript-resources-wordtemplate-description': 'Utiliza estos ajustes y plantillas para dar formato a tu libro en Word',
  'bookstep-Manuscript-resources-bookspecs': 'Especificaciones para profesionales',
  'bookstep-Manuscript-resources-bookspecs-description': 'Especificaciones para InDesign, QuarkXpress, etc.',
  'bookstep-Manuscript-resources-bookformatting': 'Dale formato a tu libro',
  'bookstep-Manuscript-resources-bookformatting-description': 'Una guía para ayudarte a maquetar tu libro',
  'bookstep-Manuscript-resources-services-needhelp': '¿Podemos ayudarte?',
  'bookstep-Manuscript-resources-services-needhelp-description': 'Información sobre servicios profesionales',
  'bookstep-Manuscript-UploadBlock-title-0': 'Mi manuscrito',
  'bookstep-Manuscript-UploadBlock-title-1': 'Estamos conviertiendo tu manuscrito',
  'bookstep-Manuscript-UploadBlock-title-2': 'Revisa tu manuscrito',
  'bookstep-Manuscript-uploadblock-stillwriting-title': 'Todavía estoy escribiendo',
  'bookstep-Manuscript-uploadblock-stillwriting-description': '¿Todavía estás trabajando en tu manuscrito? Si necesitas un poco de orientación o inspiración, puedes consultar algunos de nuestros consejos o echar un vistazo a los servicios de publicación que ofrecemos.',
  'bookstep-Manuscript-uploadblock-stillwriting-button': 'Consejos',
  'bookstep-Manuscript-uploadblock-stillformatting-title': 'Formato y maquetación',
  'bookstep-Manuscript-uploadblock-stillformatting-description': 'Si aún tienes que formatear tu manuscrito puedes utilizar nuestras plantillas gratuitas o consultar nuestros servicios de maquetación para asegurarte de que tu manuscrito tiene la mejor presentación posible.',
  'bookstep-Manuscript-uploadblock-stillformatting-button': 'Consejos',
  'bookstep-Manuscript-uploadblock-conversioninprogress-title': 'Espera un momento por favor, estamos verificando y conviertiendo tu manuscrito',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-1': 'Revisa el archivo de vista previa',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-1-desc': 'Hemos convertido tu manuscrito en un PDF listo para imprimir. Tu libro se imprimirá tal y como se muestra en este documento, así que revísalo bien. Puedes subir una nueva versión tantas veces como quieras.',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-1-previewbutton': 'Descargar vista previa',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-3': 'Revisa el archivo de vista previa',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-3-desc': 'Hemos convertido tu manuscrito en un PDF listo para imprimir. Tu libro se imprimirá tal y como se muestra en este documento, así que revísalo bien. Puedes subir una nueva versión tantas veces como quieras.',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-3-previewbutton': 'Descargar vista previa',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-6': 'Revisa tu eBook',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-6-desc': 'Hemos convertido tu archivo en un libro electrónico. Esta es la versión final de tu manuscrito, aquella que los lectores recibirán cuando compren tu libro, así que asegúrate de revisarla bien. Puedes subir una nueva versión de tu manuscrito tantas veces como quieras.',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-6-previewbutton': 'Descargar eBook',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-7': 'Revisa tu PDF eBook',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-7-desc': 'Hemos convertido tu manuscrito en un PDF. Esta es la versión final de tu manuscrito, aquella que los lectores recibirán cuando compren tu libro, así que asegúrese de revisarla bien. Puedes subir una nueva versión tantas veces como quieras.',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-7-previewbutton': 'Descargar PDF eBook',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-uploadnewbutton': 'Subir nuevo archivo',
  'bookstep-Manuscript-tips-title': 'Consejos para un buen manuscrito',
  'bookstep-Manuscript-tips-print-sizeinterior-label': 'Formato interior / especificaciones',
  'bookstep-Manuscript-tips-print-sizeinterior-word': 'Si utilizas Word, te recomendamos que descargues una de nuestras plantillas de manuscritos haciendo clic en el botón de arriba. Esta plantilla se ha creado especialmente para el tipo de libro que has seleccionado, lo que significa que no tendrás que preocuparte por el formato y que sólo tendrás que rellenar el texto. Esta es la forma más fácil de asegurarte de que el diseño de tu libro coincide con el de tu manuscrito.',
  'bookstep-Manuscript-tips-print-sizeinterior-indesign': 'Si utilizas InDesign u otro programa de maquetación profesional, haz clic en el botón “Especificaciones del libro”. Allí encontrarás toda la información que necesitas para dar el formato perfecto al manuscrito.',
  'bookstep-Manuscript-tips-print-images-label': 'Cómo añadir imágenes a tu manuscrito ',
  'bookstep-Manuscript-tips-print-images': 'Por supuesto, puedes añadir imágenes a tu manuscrito si lo deseas. Una imagen con una resolución de 250 ppp es suficiente para garantizar una alta calidad en las imágenes impresas. Simplemente coloca las imágenes donde quieras que aparezcan en tu manuscrito (ten en cuenta el área de sangrado si colocas las imágenes cerca de los bordes). Si quieres hacer un pliego, es decir, una imagen que cubra dos páginas, puede cortar la imagen por la mitad y colocar la parte más a la izquierda en una página par, y la mitad más a la derecha en la siguiente página impar.',
  'bookstep-Manuscript-tips-print-colophon-label': 'Páginas introductorias',
  'bookstep-Manuscript-tips-print-colophon-p1': 'Es habitual que tu libro comience con una portadilla y página legal. En general, el orden es el siguiente:',
  'bookstep-Manuscript-tips-print-colophon-firstpage': 'Primera página: muestra el título del libro',
  'bookstep-Manuscript-tips-print-colophon-secondpage': 'Segunda página: vacío',
  'bookstep-Manuscript-tips-print-colophon-thirdpage': 'Tercera página: de nuevo el título del libro acompañado por el nombre del autor',
  'bookstep-Manuscript-tips-print-colophon-fourthpage': 'Cuarta página: conocida como la página legal, esta página debe incluir el nombre del autor, el ISBN (si lo tiene), el nombre del diseñador de la cubierta, la declaración de derechos de autor, el año de publicación y, si lo deseas, publicado vía {publicbrand}',
  'bookstep-Manuscript-tips-print-colophon-p2': 'Estas páginas se conocen colectivamente como la "portada" de tu libro. Las páginas mencionadas no están numeradas. Esto significa que la numeración de las páginas comenzará en la página 5. Nuestras plantillas de manuscritos ya están configuradas de acuerdo con esta norma.',
  'bookstep-Manuscript-tips-print-formattinginterior-label': 'Formatear tu manuscrito',
  'bookstep-Manuscript-tips-print-formattinginterior': 'Sin un manuscrito bien maquetado, tu libro pierde valor. Para ayudarte a evitar que pierdas lectores, hemos escrito una guía completa sobre cómo formatear tu manuscrito correctamente.',
  'bookstep-Manuscript-tips-print-formattinginterior-link': 'Cómo formatear tu manuscrito',
  'bookstep-Manuscript-tips-print-minimumpagecount-label': 'Número de páginas mínimo',
  'bookstep-Manuscript-tips-print-minimumpagecount': 'Para que podamos imprimir libros de calidad, es necesario que contengan un determinado número de páginas. El número de páginas requerido varía en función del tipo de libro que se imprima; concretamente, el tipo de cubierta, el tipo de papel y las dimensiones del libro influyen en el número mínimo de páginas. Por ejemplo, un libro de bolsillo tiene un requisito de páginas menor que uno de tapa dura. Este requisito variará en función del tipo de papel que se utilice, ya que todos tienen un gramaje diferente. En el primer paso del proceso de creación del libro, en el que eliges las especificaciones de tu libro, te mostraremos el número mínimo de páginas que necesita el libro que hayas seleccionado.',
  'bookstep-Manuscript-tips-print-pageorientation-label': 'Orientación de la página',
  'bookstep-Manuscript-tips-print-pageorientation': 'Aunque esto pueda parecer una obviedad, no está de más mencionarlo: la primera página de tu manuscrito, la impar, siempre estará a la derecha. Aunque el interior de la cubierta parece una página en blanco, no cuenta como tal. Las páginas de la izquierda son siempre pares. Por lo tanto, cuando hay dos páginas adyacentes, siempre habrá una página par a la izquierda y una página impar a la derecha con un número de página superior. Esto es útil para tenerlo en cuenta si estás haciendo un libro de fotos y quieres una extensión. Ten en cuenta que nosotros no añadimos los números de página por ti - es algo de lo que debes hacer antes de subir tu manuscrito. Sin embargo, nuestras plantillas para manuscritos sí incluyen números de página, así que si la numeración de páginas te da problemas, intenta descargar una de ellas.',
  'bookstep-Manuscript-tips-epub-epubexplain-label': 'Sobre los ePubs',
  'bookstep-Manuscript-tips-epub-epubexplain': 'Un eBook (llamado ePub) puede funcionar de una forma distinta a lo que imaginas. Por ejemplo, un ePub no tiene números de página fijos. En cambio, es "refluible", lo que significa que el texto se adapta al tamaño de la pantalla en la que se lee. En una pantalla más pequeña, las frases se acortan y el número total de páginas aumenta. Lo contrario ocurrirá en un dispositivo con una pantalla más grande. En resumen, el diseño de un libro electrónico depende del dispositivo que utilice el lector.',
  'bookstep-Manuscript-tips-epub-usingword-label': 'Si utilizas Word',
  'bookstep-Manuscript-tips-epub-usingword': 'Puedes crear ePubs con Word, pero hay algunas reglas que debes seguir para asegurarte de que tu archivo se leerá correctamente una vez transformado. En primer lugar, cuando quieras añadir un salto de página, no te limites a pulsar la tecla Intro. Si lo haces, aparecerán como líneas en blanco en tu ePub, lo que significa que los lectores verán enormes huecos en el texto. Te recomendamos que leas nuestra guía sobre cómo crear un ePub con Word antes de empezar. Puedes leerla haciendo clic en “Dale formato a tu libro” en la parte superior de esta página.',
  'bookstep-Manuscript-tips-epub-images-label': 'Añadir imágenes ',
  'bookstep-Manuscript-tips-epub-images': '¿Quieres añadir imágenes a tu eBook? No hay problema. Solo tienes que tener en cuenta que si estás creando tu ePub en Word, debes asegurarte de que tus imágenes estén ‘en línea con el texto’ y no con el ‘ajuste de texto’. Puedes comprobar y cambiar esto utilizando la opción “Posición” en la pestaña de formato de imagen en Word.',
  'bookstep-Manuscript-tips-epub-cover-label': 'Incluir la portada en el ePub o subirla por separado',
  'bookstep-Manuscript-tips-epub-cover': 'Al subir un archivo ePub, tienes la opción de incluir una portada, aunque no es obligatorio. Si tu portada cumple con nuestras especificaciones, la incorporaremos automáticamente y la mostraremos en el paso de diseño de la cubierta. Si subes tu manuscrito como archivo de Word, esto no es posible, y tendrás que subir o crear tu portada en el paso de “diseño de cubierta”.',
  'bookstep-Manuscript-tips-epub-calibre-label': 'Calibre: crea un ePub perfecto',
  'bookstep-Manuscript-tips-epub-calibre': 'Si quieres hacer tu propio ePub desde cero, te recomendamos usar Calibre. Es un programa fácil de usar que te permite crear un archivo ePub. Incluso puedes importar documentos de Word y utilizarlos como base para tu ePub. Consulta nuestras guías sobre cómo crear un ePub con Calibre para obtener más información.',
  'bookstep-Manuscript-tips-epub-checkepub-label': '¡Revisa tu ePub!',
  'bookstep-Manuscript-tips-epub-checkepub': 'El archivo ePub que creamos después de subir tu manuscrito es la versión final, es decir, lo que tus lectores recibirán al comprar tu libro electrónico. Por eso es crucial que compruebes este archivo a fondo. Para ello, utiliza un software ePub fiable, como Calibre, Adobe Digital Editions, Aldiko (para Android) o Apple Books (para iOS). También puedes utilizar un lector electrónico (por ejemplo, un Kindle) para ello.',

  'bookstep-Manuscript-popup-bookspecs-bookspecs': 'Especificaciones del libro',
  'bookstep-Manuscript-popup-bookspecs-selectboard-word': 'Microsoft Word',
  'bookstep-Manuscript-popup-bookspecs-selectboard-word-description': 'Nuestros consejos para hacer un buen libro con Word',
  'bookstep-Manuscript-popup-bookspecs-selectboard-prof': 'Programa de maquetación profesional',
  'bookstep-Manuscript-popup-bookspecs-selectboard-prof-description': 'Por ejemplo: inDesign o QuarkXPress',
  'bookstep-Manuscript-popup-bookspecs-manuscriptspecs': 'Especificaciones de manuscrito',
  'bookstep-Manuscript-popup-bookspecs-pagesize': 'Tamaño de página (excl. sangrado): {sizes}',
  'bookstep-Manuscript-popup-bookspecs-pagesize-inclbleed': 'Tamaño de página incl. sangrado: {sizes}',
  'bookstep-Manuscript-popup-bookspecs-pagesize-nospecificreq': 'ningún requisito específico',
  'bookstep-Manuscript-popup-bookspecs-bleed': 'Sangrado: {bleeds}',
  'bookstep-Manuscript-popup-bookspecs-bleed-nobleedreq': 'no se necesita sangrado',
  'bookstep-Manuscript-popup-bookspecs-bleed-top': 'Superior',
  'bookstep-Manuscript-popup-bookspecs-bleed-bottom': 'Inferior',
  'bookstep-Manuscript-popup-bookspecs-bleed-outside': 'Exterior',
  'bookstep-Manuscript-popup-bookspecs-bleed-inside': 'Interior',
  'bookstep-Manuscript-popup-bookspecs-nopagenumheadfoot': 'No añadas números de páginas, encabezados o pies de página',
  'bookstep-Manuscript-popup-bookspecs-imagesinline': 'Asegúrate de mantener las imágenes ‘alineadas con el texto’. No utilizar imágenes flotantes',
  'bookstep-Manuscript-popup-bookspecs-uploadspecs': 'Especificaciones de subida',
  'bookstep-Manuscript-popup-bookspecs-fileformats': 'Formato del fichero: {formats}',
  'bookstep-Manuscript-popup-bookspecs-noprintmarks': 'Sin marcas de impresion, recorte o sangrado',
  'bookstep-Manuscript-popup-bookspecs-nospreads': 'No uses márgenes',
  'bookstep-Manuscript-popup-bookspecs-imagesdpi': 'Imágenes: min. {dpinum} dpi',
  'bookstep-Manuscript-popup-bookspecs-word-wordtemplate': 'Plantilla para Word',
  'bookstep-Manuscript-popup-bookspecs-word-wordtemplate-p1': 'Cuando formatees su manuscrito en Microsoft Word, te recomendamos encarecidamente que utilices nuestras plantillas. Estas plantillas están hechas a la medida de tu libro y ya tienen los ajustes de formato adecuados. Descarga la plantilla haciendo clic en el botón de abajo.',
  'bookstep-Manuscript-popup-bookspecs-word-downloadtemplate': 'Descargar plantilla',
  'bookstep-Manuscript-popup-bookspecs-word-therightsizemargins': 'Márgenes y tamaño de la página',
  'bookstep-Manuscript-popup-bookspecs-word-therightsizemargins-p1': 'Si utilizas nuestras plantillas, no tendrás que preocuparte por los márgenes o el sangrado. Los ajustes correctos ya se han aplicado al documento.',
  'bookstep-Manuscript-popup-bookspecs-word-therightsizemargins-p2': 'Si no utilizas nuestras plantillas, tendrás que asegurarte de que el tamaño de la página de tu documento manuscrito coincide con las dimensiones del formato de libro que has elegido. Si no es así, escalaremos automáticamente el tamaño de la página de tu archivo subido para que coincida con estas dimensiones. Esto puede provocar importantes cambios en el diseño de tu libro. Además, tendrás que tener en cuenta el sangrado y añadirlo a tus márgenes.',
  'bookstep-Manuscript-popup-bookspecs-word-exampleimage-representsbleed': 'representa el área de sangrado',
  'bookstep-Manuscript-popup-bookspecs-word-whatisbleed': '¿Qué es la sangre?',
  'bookstep-Manuscript-popup-bookspecs-word-whatisbleed-p1': 'Si quieres que tu imagen o fondo ocupen la totalidad de la página, esta debe ser más grande que la página en sí. Así te aseguras que al cortar la página no aparecerán espacios en blanco no deseados en los bordes de las páginas. El margen extra de imagen o fondo que excede la página es lo que llamamos “sangrado” o “sangre”. Si no tienes colores o imágenes que lleguen al borde de la página, ignorar el sangrado no dará lugar a ningún espacio en blanco involuntario, pero puede dar lugar a ligeras diferencias en el formato de tu libro, ya que es posible que tengamos que escalar automáticamente tu documento para añadir este sangrado de todos modos. Podrás ver los cambios en la vista previa de impresión una vez que hayas subido el archivo de tu manuscrito.',
  'bookstep-Manuscript-popup-bookspecs-exampleimage-leftpage-odd': 'Página izquierda (impar)',
  'bookstep-Manuscript-popup-bookspecs-exampleimage-leftpage-even': 'Página izquierda (par)',
  'bookstep-Manuscript-popup-bookspecs-exampleimage-rightpage-odd': 'Página derecha (impar)',
  'bookstep-Manuscript-popup-bookspecs-exampleimage-rightpage-even': 'Página derecha (par)',
  'bookstep-Manuscript-popup-services-needhelp': '¿Podemos ayudarte?',
  'bookstep-Manuscript-popup-services-doyouneedsupport': '¿Podemos ayudarte?',
  'bookstep-Manuscript-popup-services-weofferpaidservices': 'Si necesitas ayuda podemos ponerte en contacto con prestadores de servicios profesionales de pago. Ellos podrán ayudarte con el proceso de escritura y la revisión de tu manuscrito, como la corrección y edición. Pero también con la maquetación de tu manuscrito o la creación de un ePub profesional.',
  'bookstep-Manuscript-popup-services-areyouinterested': '¿Necesitas más información? Haz clic en el botón siguiente y nos pondremos en contacto contigo en un plazo de 2 días laborables.',
  'bookstep-Manuscript-popup-services-contactme': 'Me gustaría saber más',
  'bookstep-Manuscript-popup-services-requestsent': 'Hemos enviado tu solicitud',
  'bookstep-Manuscript-popup-services-wehavesentrequest': 'Hemos enviado tu solicitud',
  'bookstep-Manuscript-popup-services-wewillcontactyousoon': 'Hemos enviado tu solicitud para recibir más información sobre los servicios profesionales. Nos pondremos en contacto contigo en un plazo de 2 días laborables para hablar de ello.',

  'bookstep-Manuscript-guidepopup-wordtips': 'Consejos para Microsoft Word',
  'bookstep-Manuscript-guidepopup-wordtips-description': 'Nuestros consejos para hacer un buen libro con Word',
  'bookstep-Manuscript-guidepopup-pdffontsincl': 'Cómo hacer un PDF ',
  'bookstep-Manuscript-guidepopup-pdffontsincl-description': 'Una breve explicación de cómo crear un buen PDF',
  'bookstep-Manuscript-guidepopup-createepub': 'Cómo hacer un ePub ',
  'bookstep-Manuscript-guidepopup-createepub-description': 'Una breve explicación de cómo hacer un buen ePub',
  'bookstep-Manuscript-guidepopup-colorpages': 'Problemas con páginas a color',
  'bookstep-Manuscript-guidepopup-colorpages-description': 'Nuestros consejos para seleccionar bien las páginas en color',

  // Bookstep: Writing
  'bookstep-Writing': 'Escribir',
  'bookstep-Writing-intro-p1': 'Escribir un libro es una de las cosas más divertidas y gratificantes que se pueden intentar, pero también puede resultar bastante difícil a veces. Aunque no podemos escribir tu libro por ti, podemos apoyarte durante el proceso. Tanto si escribes ficción como si no, es imprescindible que estructures tu proceso de escritura. La mayoría de los escritores que no lo hacen acaban comenzando con mucha fuerza, pero se atascan más adelante. Así que hazte un favor: haz un plan y cúmplelo. ¿No estás seguro de cómo hacerlo? Consulta nuestra guía.',
  'bookstep-Writing-helpwithwriting-title': 'Ayuda para escribir',
  'bookstep-Writing-createwritingplan-title': 'Guía para empezar a escribir',
  'bookstep-Writing-writeblurb-title': 'Escribe la sinopsis primero',
  'bookstep-Writing-writeblurb-p1': 'Te recomendamos que empieces escribiendo la sinopsis. De este modo, te verás obligado a tener en cuenta la visión general de tu obra. Sin duda, escribirás más de una versión de tu sinopsis. Compártelas con otras personas y pide su opinión.',
  'bookstep-Writing-writeblurb-p2': 'Aunque una sinopsis no es muy larga, el proceso de destilar la esencia de tu libro en unas pocas frases es un ejercicio inestimable. Te ayudará a crear un plan de escritura sólido y servirá de base para el propio proceso de escritura. Además, la sinopsis puede crear o evitar una venta, ya que es uno de los principales factores que los lectores tienen en cuenta a la hora de decidir si van a dar una oportunidad a tu libro o no.',
  'bookstep-Writing-writeblurb-howtowriteblurb': '¿Cómo escribir una buena sinopsis?',
  'bookstep-Writing-writeblurb-howtowriteblurb-p1': 'Como en muchas otras cosas de la vida, una buena forma de aprender a escribir una buena sinopsis es estudiar las que ya existen. Esto te dará una idea más clara de cuáles son las mejores prácticas para escribir el resumen de tu libro dentro del género que hayas elegido. Sin embargo, sea cual sea el tipo de libro que escribas, hay algunos criterios generales que debe cumplir toda sinopsis:',
  'bookstep-Writing-writeblurb-howtowriteblurb-list1': 'Una primera frase cautivadora: Hay que atrapar al lector desde el principio. Esto puede lograrse mostrando una cita especialmente prometedora, realizando una pregunta convincente, planteando un hecho inesperado u ofreciendo una solución a un problema.',
  'bookstep-Writing-writeblurb-howtowriteblurb-list2': 'Un resumen: Trata de ofrecer al lector un esbozo de la historia sin desvelar ningún punto importante de la trama.',
  'bookstep-Writing-writeblurb-howtowriteblurb-list3': 'Una promesa: El lector quiere saber qué puede esperar de tu libro. Si estás escribiendo ficción, crea suspense o añade algo de dramatismo. Si escribes no ficción, ofrece soluciones a problemas o conocimientos sobre un tema.',
  'bookstep-Writing-writeblurb-howtowriteblurb-list4': 'Sobre el autor: Especialmente relevante para los libros de no ficción. Asegúrate de incluir información sobre ti que sea relevante para el libro; tus experiencias, otros libros que hayas escrito, etc.',
  'bookstep-Writing-writeblurb-howtowriteblurb-list5': 'Una foto: Esto es opcional, pero muchos de los que deciden incluir una se equivocan. Asegúrate de utilizar una foto con una resolución lo suficientemente alta (300 ppp), de lo contrario te arriesgarás a que tu libro tenga un aspecto poco profesional.',
  'bookstep-Writing-createplan-title': 'Cómo elaborar un plan de escritura',
  'bookstep-Writing-createplan-p1': 'La escritura, como todo proceso creativo, no siempre puede forzarse. No obstante, te aconsejamos encarecidamente que crees un plan de escritura. Divide este plan en dos partes:',
  'bookstep-Writing-createplan-list1': 'Estructura: ¿Cuándo vas a escribir? ¿Cuánto tiempo va a durar cada sesión de escritura? Estas decisiones dependen de ti. Algunas personas se levantan temprano para escribir, mientras que otras son animales nocturnos que solo pueden escribir una vez que han completado otras tareas. Crea un esquema para tu libro, divídelo en capítulos y haz un plan para cada capítulo. No sientas que tienes que empezar desde el principio, sino que puedes empezar en cualquier punto de la historia, siempre que te permita escribir.',
  'bookstep-Writing-createplan-list2': 'Historia: Primero, defina las líneas generales de su historia: ¿qué personajes vas a introducir y cómo se van a desarrollarse? ¿Cómo vas a llegar a los puntos principales de la trama? La fórmula más popular para un argumento es probablemente "el viaje del héroe". Esto es cierto incluso para los libros de no ficción. A la gente le gusta seguir a un protagonista mientras supera obstáculos y crece como persona.',
  'bookstep-Writing-writefeedbackrewrite-title': 'Escribe, escribe, revisa, reescribe',
  'bookstep-Writing-writefeedbackrewrite-p1': 'Si has conseguido crear un esquema de tu trama, es hora de rellenar los espacios en blanco. Aquí es donde de verdad empiezas a escribir tu libro. No te desanimes, no tienes que ir capítulo a capítulo. Puedes empezar por la mitad, o por el final, o por donde te sientas más inspirado para escribir. Este método suele ser mucho más productivo que obligarse a escribir todo en orden.',
  'bookstep-Writing-writefeedbackrewrite-p2': 'En cuanto hayas terminado unos cuantos capítulos, pide a tus amigos o familiares que lean tu obra y te den su opinión. Asegúrate de elegir correctores que no rehúyan la crítica constructiva; los halagos no te servirán de nada. Hay algunas cosas que debes tener en cuenta con respecto a tu escritura:',
  'bookstep-Writing-writefeedbackrewrite-list1': '¿Vas a escribir en primera o en tercera persona?',
  'bookstep-Writing-writefeedbackrewrite-list2': '¿Presente o pasado?',
  'bookstep-Writing-writefeedbackrewrite-list3': 'La coherencia es la clave',
  'bookstep-Writing-writefeedbackrewrite-list4': 'Muestra, no cuentes',
  'bookstep-Writing-writefeedbackrewrite-list5': 'Construye la tensión y la atmósfera para atraer a tus lectores',
  'bookstep-Writing-feedbackediting-title': 'Opiniones y edición',
  'bookstep-Writing-feedbackediting-p1': 'Escribir es una forma de arte, y los artistas tienden a sentirse inseguros sobre su propio trabajo. Pero no dejes que las dudas te desanimen, son parte del proceso. Aprovecha ese sentimiento para pedir a tus correctores que te hagan una crítica constructiva; tu historia mejorará gracias a ello.',
  'bookstep-Writing-feedbackediting-p2': 'En cuanto el manuscrito esté terminado, pide a todas las personas posibles que lo corrijan, quizás a cambio de un café. Ten en cuenta que la mayoría de la gente sigue prefiriendo leer en papel, por lo que puede ser una buena idea imprimir algunas copias de tu manuscrito para aquellos correctores que lo hagan. La mayoría de la gente se siente halagada cuando se le pide que corrija, y estará encantada de ayudar.',
  'bookstep-Writing-feedbackediting-p3': 'Es importante asegurarse de que el manuscrito no tiene errores ortográficos ni gramaticales. Nada debilita más la impresión que el lector tiene de tu texto que una falta de ortografía. Dependiendo de tus ambiciones y medios, puedes considerar un servicio de corrección profesional. Un experto leerá todo tu manuscrito o parte de él y te dará su opinión sobre el estilo, la coherencia, la trama, el desarrollo de los personajes, etc.',
  'bookstep-Writing-nextstep': 'Siguiente paso: formato',
  'bookstep-Writing-uploadnow': 'Subir',
  'bookstep-Writing-tips-title': 'Consejos de escritura',
  'bookstep-Writing-tips-titlesubtitle-label': 'Título y subtítulo',
  'bookstep-Writing-tips-titlesubtitle': '¿Ya tienes un título y un subtítulo en mente para tu libro? En ese caso puedes saltarte esta parte, pero en nuestra experiencia la mayoría de la gente suele tener problemas para decidirse por un título. Te recomendamos que escribas todas tus ideas de títulos en una sesión de “brainstorm” o lluvia de ideas. Es probable que, al tener todas las opciones a la vista, una de ellas destaque rápidamente entre las demás. Te recomendamos que muestres a tus amigos o a tus seguidores en redes tus tres candidatos favoritos a título y les preguntes qué opinan. Una encuesta simple, por ejemplo. En última instancia, la gente tendrá en cuenta tres cosas principales a la hora de determinar si quieren comprar tu libro: la portada (incluido el título), el texto de contraportada y el avance, así que vale la pena conocer la opinión de tus seguidores y conocidos sobre estos aspectos de tu libro.',
  'bookstep-Writing-tips-blurb-label': 'Texto de Contraportada',
  'bookstep-Writing-tips-blurb': 'Si consigues atraer al lector con tu portada, lo más probable es que automáticamente después eche un vistazo a la contracubierta. Este es un momento clave en el proceso de toma de decisiones de tus clientes, y decidirá si la venta es un éxito o no. Tanto si tu libro es de ficción como de no ficción, hay algunas cosas que sin duda interesarán a tus posibles lectores: ¿es el texto atractivo? ¿El libro tratará de resolver algún problema o plantea una pregunta a la que te gustaría tener respuesta? El texto de contracubierta tiene que dejar al lector con ganas de más. Si consigues interesar a tu lector potencial hay muchas posibilidades de que compre tu libro. Así pues, pide a otras personas que corrijan tu texto de contracubierta y pídeles su opinión: ¿comprarían tu libro basándose en lo que leen en la contraportada?',
  'bookstep-Writing-tips-howdoyoufeelabouit-label': 'Pide opiniones',
  'bookstep-Writing-tips-howdoyoufeelabouit': 'Intenta que alguien revise tu trabajo desde el principio. No es necesario que el manuscrito esté terminado para recibir comentarios y opiniones útiles. No importa que lean solo una página o varios capítulos, pero es muy importante que te den su opinión sincera. Por lo general, la gente disfrutará ayudándote y leyendo tu trabajo. También puedes publicar un capítulo en una plataforma de redes sociales de tu elección y pedir opiniones de esa manera. Este último método te permite matar dos pájaros de un tiro: recibes comentarios y promocionas tu libro simultáneamente. ¿Qué te parece?',
  'bookstep-Writing-tips-marketing-label': 'Marketing',
  'bookstep-Writing-tips-marketing': '‘¿Marketing? ¡Pero si todavía no he terminado mi libro!’ Exactamente. Este es el momento de empezar a pensar en cómo vas a promocionar tu libro. En esta fase inicial, puedes empezar con poco, simplemente despertando cierto interés. Comparte tu experiencia como escritor en las redes sociales, habla de cómo te va, de las dificultades a las que te enfrentas, comenta el progreso de tu historia. Comparte tu portada en las redes sociales cuando esté lista. Publica extractos de tus capítulos terminados, diálogos, descripciones de personajes... Es probable que recibas muchos ánimos. Estas son solo algunas ideas que puedes incluir en tu plan de marketing. No lo dejes de lado: es la forma de crear una red de seguidores y embajadores que tendrá un valor incalculable a la hora de dar a conocer tu libro una vez publicado. ',
  'bookstep-Writing-tips-support-label': 'Soporte',
  'bookstep-Writing-tips-support': '¿Estás atascado y necesitas ayuda, o simplemente quieres una opinión profesional sobre tu manuscrito? Podemos ayudarte y ponerte en contacto con profesionales de la edición, corrección, maquetación, ilustración etc. para que te ayuden. Si te tomas realmente en serio tu libro, asegúrate de involucrar a tu red de contactos y/o contratar a uno de nuestros prestadores de servicio para que te ayuden a hacer el mejor libro posible.',
  'bookstep-Writing-tips-dontgiveup-label': '¡No te rindas!',
  'bookstep-Writing-tips-dontgiveup': 'Prácticamente todos los escritores se encuentran con un obstáculo de vez en cuando: desilusión, bloqueo del escritor, miedo, lo que sea. No te rindas. Si es necesario, tómate un descanso y vete a hacer otra cosa durante un rato. Da un paseo, habla con la gente, distráete de otra manera. Tu motivación volverá pronto. Si buscas consejos más concretos durante el proceso de escritura, tenemos múltiples artículos en nuestro blog sobre cómo superar el bloqueo del escritor, crear un hábito de escritura o cómo escribir más.',

  // Bookstep: Formatting
  'bookstep-Formatting': 'Formato',
  'bookstep-Formatting-intro-p1': 'El texto es, obviamente, la parte más importante de tu manuscrito, pero sin un buen formato, lo más probable es que nadie quiera leerlo, independientemente de su calidad. Un manuscrito bien formateado, bien maquetado, facilita la lectura y permite al lector sumergirse en tu historia sin distraerse ni confundirse. Puedes formatear tu manuscrito tú mismo, por supuesto, pero también puedes encargar a un profesional que lo haga por ti. Si te inclinas por esto último, puedes consultar nuestro servicio de maquetación. ¿Quieres hacerlo tú mismo? Consulta nuestra guía y requisitos para asegurarte de obtener el mejor resultado posible.',
  'bookstep-Formatting-helpwithformatting-title': 'Ayuda con el formato',
  'bookstep-Formatting-guide-title': 'Instrucciones de formato',
  'bookstep-Formatting-guide-print-template-title': 'Plantillas',
  'bookstep-Formatting-guide-print-template-p1': 'Si piensas darle formato o maquetar tu manuscrito tú mismo, te recomendamos que trabajes en un documento que haya sido configurado correctamente de antemano. Esto te ahorrará mucho tiempo y esfuerzo en un futuro. Si utilizas Word, te sugerimos que utilices nuestras plantillas, que están configuradas para ajustarse a las especificaciones del tipo de libro seleccionado. También tienen en cuenta las páginas de título y legal, la configuración de los números de página y los márgenes. Si ya has terminado tu manuscrito, puedes copiar el texto en la plantilla, ¡pero no lo copies todo a la vez! Si lo haces, sobrescribirás la configuración de la plantilla, lo que anularía el propósito de utilizarla en primer lugar.',
  'bookstep-Formatting-guide-print-template-p2': 'Si está creando tu manuscrito con un programa de maquetación profesional como InDesign, configura tu documento de acuerdo con las especificaciones del formato de libro elegido. Asegúrate de tener un margen de 3 mm alrededor de los bordes exteriores de cada página (es decir, excluyendo los márgenes del lomo o márgenes interiores). Puedes obtener las especificaciones exactas de tu libro haciendo clic en el botón "Especificaciones del libro" de arriba.',
  'bookstep-Formatting-guide-print-template-p3': 'Recuerda: las páginas impares siempre estarán a la derecha, y las páginas pares siempre estarán a la izquierda.',
  'bookstep-Formatting-guide-print-fonts-title': 'Fuentes, tamaño de de fuentes e interlineado',
  'bookstep-Formatting-guide-print-fonts-p1': 'No vamos a decirte qué tipo de letra debes utilizar. Esa es una elección personal y varía según el libro. Lo primero que debes decidir es si quieres utilizar un tipo de letra con serifas o sin ellas. Las serifas son las pequeñas líneas, ‘florituras’, que van en los extremos de las letras, y se considera que ayudan a que el texto impreso sea más legible. Las fuentes con serifa son la opción clásica para los libros y periódicos impresos. Las fuentes con serifa más populares son Cambria, Garamond, Georgia y Times New Roman.',
  'bookstep-Formatting-guide-print-fonts-p2': 'Las fuentes sin serifa no tienen estas líneas, lo que da lugar a una estética más moderna y minimalista. Estos tipos de letra se utilizan habitualmente en Internet, y se considera que ofrecen una mejor legibilidad que las fuentes con serifas en este caso. Los tipos de letra sans serif más populares son Arial, Calibri, Helvetica y Verdana.',
  'bookstep-Formatting-guide-print-fonts-p3': 'Además, tendrás que decidir el tamaño de la fuente y el interlineado. De nuevo, no hay una elección correcta; todo se reduce a tus preferencias personales y el tipo de libro. Por lo general, los tamaños de letra más comunes son el 10, el 11 y el 12, dependiendo la tipografía que utilices. Si tu libro va dirigido a un público de mayor edad, utiliza un tamaño de letra mayor y, consecuentemente, un mayor interlineado (lo habitual es que el interlineado esté entre 1,1 y 1,3). Imprimir algunas páginas del manuscrito es una buena manera de comprobar si estás satisfecho con el tipo de letra y el interlineado.',
  'bookstep-Formatting-guide-print-moreoptions-title': 'Más opciones de formato',
  'bookstep-Formatting-guide-print-moreoptions-p1': 'Todavía no hemos terminado; hay algunas opciones más que debes tomar en cuenta antes de continuar con tu libro. Aquí tienes una lista de las más importantes:',
  'bookstep-Formatting-guide-print-moreoptions-list1': 'Diseño de página: Lo más importante que hay que tener en cuenta son los márgenes. Asegúrate de que no sean demasiado pequeños; alrededor de 2 cm y un poco más para los márgenes interiores del lomo (el lado derecho en las páginas pares y el izquierdo en las impares, donde se encuaderna el libro). Parte de los márgenes interiores de las páginas desaparecerán, ya que es en parte de los márgenes interiores donde se coloca el pegamento para encuadernar el libro. Nuestras plantillas ya tienen este factor en cuenta, por lo que no tendrás que preocuparte por configurar los márgenes. Si no quieres utilizar nuestras plantillas, utiliza los siguientes márgenes:',
  'bookstep-Formatting-guide-print-moreoptions-list1-topmargin': 'Margen superior: {size}',
  'bookstep-Formatting-guide-print-moreoptions-list1-bottommargin': 'Margen inferior: {size}',
  'bookstep-Formatting-guide-print-moreoptions-list1-innermargin': 'Margen interior: {size}',
  'bookstep-Formatting-guide-print-moreoptions-list1-outermargin': 'Margen exterior: {size}',
  'bookstep-Formatting-guide-print-moreoptions-list2': 'Nuevos capítulos: Comienza cada nuevo capítulo en una nueva página. Utiliza la opción de “Salto de página” para empezar en una página nueva. Asegúrate de que la página es impar. Puedes dar a tus capítulos nombres, números o ambos. Si utilizas Word, asegúrate de utilizar el estilo "Título 1" para el título/número del capítulo. De esta manera te aseguras de que el formato de cada capítulo sea el mismo.',
  'bookstep-Formatting-guide-print-moreoptions-list3': 'Párrafos: Puede optar por separar los párrafos con un espacio en blanco de una línea, lo que resulta en una experiencia de lectura agradable. También puedes optar por sangrar la primera palabra de cada párrafo. Elijas lo que elijas, asegúrate de que los párrafos no sean demasiado largos, ya que restan legibilidad al texto.',
  'bookstep-Formatting-guide-print-moreoptions-list4': 'Imágenes y texto: Si el contenido de tu libro alterna entre texto e imágenes, como en un libro de cocina, coloca las imágenes en las páginas pares (izquierda) y el texto en las impares (derecha).',
  'bookstep-Formatting-guide-print-uploadscript-title': 'Cómo subir tu manuscrito',
  'bookstep-Formatting-guide-print-uploadscript-p1': 'Puedes escribir tu manuscrito con cualquier programa de procesamiento de textos que desees, pero solo aceptamos archivos de Word (.doc o .docx) y PDF a la hora de subirlos. Por lo tanto, si trabajas con un programa distinto de Word (por ejemplo, Pages), tendrás que convertir tu manuscrito a PDF antes de continuar. Incluso si has escrito tu manuscrito en Word, te recomendamos que lo conviertas a PDF antes de subirlo. Esto minimiza el riesgo de que aparezcan errores de formato durante el proceso de conversión. Asegúrate de que cada página de tu manuscrito sea también una página separada en tu PDF convertido, de lo contrario no saldrá bien.',
  'bookstep-Formatting-guide-print-uploadscript-p2': 'Una vez que hayas subido tu manuscrito a la plataforma, lo convertiremos en un PDF listo para imprimir. Por favor, ¡revisa este archivo a conciencia! Comprueba todas las páginas para asegurarte de que están correctas.',
  'bookstep-Formatting-guide-print-uploadscript-p3': '¿Todavía no estás contento? No hay problema. Puedes subir una nueva versión de tu manuscrito tantas veces como quieras. Al hacerlo, se sobrescribirán las versiones anteriores que hayas subido.',
  'bookstep-Formatting-guide-epub-howepubswork-title': '¿Cómo funciona un ePub?',
  'bookstep-Formatting-guide-epub-howepubswork-p1': 'Antes de empezar con tu eBook es importante saber cómo funcionan realmente estos archivos, ya que hay algunas diferencias importantes entre los libros electrónicos y otros documentos digitales. Es por eso que es posible que lo que hayas creado con tu procesador de textos podría no aparecer como se pretende en un lector electrónico.',
  'bookstep-Formatting-guide-epub-howepubswork-p2': 'En primer lugar, los libros electrónicos publicados a través de nuestra plataforma estarán siempre en formato ePub. Este es el formato de libro electrónico estándar en todo el mundo, lo que significa que puede leerse en todos los lectores electrónicos. Una de las características más importantes de los archivos ePub es que son “refluibles”. Esto significa que el texto del archivo se adapta al tamaño de la pantalla en la que se lee. En una pantalla más pequeña, las frases serán más cortas y el libro tendrá más páginas que en una pantalla más grande. Esto significa que el diseño de un libro electrónico depende del dispositivo que se utilice para leerlo.',
  'bookstep-Formatting-guide-epub-howepubswork-p3': 'Por ello, un segmento de texto puede aparecer en una sola página en Word, pero dividirse en varias páginas cuando se ve en un lector electrónico. No te preocupes por la posición exacta de las líneas y los párrafos; en su lugar, asegúrate de que el texto tiene un orden lógico y que el espaciado entre párrafos es correcto. De este modo, el resultado será óptimo para la mayoría de los lectores.',
  'bookstep-Formatting-guide-epub-templatefonts-title': 'Plantillas y fuentes',
  'bookstep-Formatting-guide-epub-templatefonts-p1': 'Cuando des formato a tu eBook en Microsoft Word, te recomendamos que utilices una de nuestras plantillas de Word. Estas plantillas están configuradas para garantizar el buen diseño de tu libro electrónico con la mínima molestia, lo que significa menos trabajo para ti. Puedes descargar nuestras plantillas haciendo clic en el botón “Plantilla de Word” de arriba.',
  'bookstep-Formatting-guide-epub-templatefonts-p2': 'Los tipos de letra son otra cosa en la que no deberías pensar demasiado cuando se trata de libros electrónicos. La gran mayoría de los lectores electrónicos permiten a los usuarios seleccionar sus propias fuentes, lo que significa que la fuente que elegiste originalmente para escribir será probablemente anulada. Por lo tanto, nuestro consejo es que elijas un tipo de letra que te parezca bien y con el que te guste trabajar. Georgia y Times New Roman son algunas de las opciones clásicas con serifa, mientras que Arial, Helvetica o Verdana suelen cumplir su función si prefieres las sin serifa.',
  'bookstep-Formatting-guide-epub-chapterstoc-title': 'Chapters and the table of contents',
  'bookstep-Formatting-guide-epub-chapterstoc-p1': 'Al formatear tu libro electrónico en Microsoft Word, lo más importante es utilizar correctamente los llamados "estilos", por ejemplo, "Título 1", "Título 2", etc. Estos estilos se utilizan para organizar tu eBook y para generar la tabla de contenidos durante el proceso de conversión. A continuación te ofrecemos una breve lista de estilos esenciales con los que debe estar familiarizado:',
  'bookstep-Formatting-guide-epub-chapterstoc-l1-normal': 'Texto normal: Utilízalo para el cuerpo de texto normal de tu manuscrito.',
  'bookstep-Formatting-guide-epub-chapterstoc-l1-title': 'Título: Utilízalo para escribir el título de tu libro en, por ejemplo, la portada.',
  'bookstep-Formatting-guide-epub-chapterstoc-l1-heading1': 'Título 1: Utiliza este encabezamiento para los títulos de los capítulos. En el índice de contenidos, este encabezamiento aparecerá en la parte superior de la jerarquía. Hemos configurado nuestras plantillas para que estos títulos aparezcan siempre en una página nueva.',
  'bookstep-Formatting-guide-epub-chapterstoc-l1-heading2': 'Título 2: Utiliza este estilo para los subtítulos, por ejemplo, cuando quieras dividir los capítulos en secciones. En el índice, están directamente subordinados al “título 1”.',
  'bookstep-Formatting-guide-epub-chapterstoc-l1-heading3': 'Título 3: Puedes utilizar este encabezamiento en el caso poco probable de que quieras dividir tus subsecciones (debajo de " título 2 ") en secciones aún más pequeñas. Este tipo de título rara vez se ve en las novelas, aunque sí se utiliza en los libros académicos.',
  'bookstep-Formatting-guide-epub-chapterstoc-p2': 'Si piensas crear tu ePub manualmente, los capítulos y los títulos funcionarán de forma un poco diferente. Por lo tanto, es importante que hagas lo siguiente:',
  'bookstep-Formatting-guide-epub-chapterstoc-l2-separatefiles': 'Asegúrate de que cada capítulo de tu ePub está en un archivo HTML separado. Este archivo debe guardarse correctamente en el archivo OPF, donde se establece el orden de los archivos HTML.',
  'bookstep-Formatting-guide-epub-chapterstoc-l2-useh1tags': 'Los capítulos deben comenzar siempre con una etiqueta HTML “h1” en tu archivo HTML. Esta etiqueta h1, es decir, este texto en formato h1 debe contener el título y/o el número del capítulo.',
  'bookstep-Formatting-guide-epub-chapterstoc-l2-useh2h3tags': 'Si se utilizan subtítulos, se pueden utilizar las etiquetas h2, h3 o h4 (etc.). Funcionan de la misma manera que los títulos 1, 2, 3, etc. de Word. ',
  'bookstep-Formatting-guide-epub-chapterstoc-l2-generatetoc': 'Asegúrate de generar una tabla de contenidos para tu archivo ePub. La mayoría de los programas de ePub pueden hacerlo automáticamente utilizando un archivo “NCX” que se añadirá a tu ePub.',
  'bookstep-Formatting-guide-epub-moreformattips-title': 'Más consejos',
  'bookstep-Formatting-guide-epub-moreformattips-p1': 'Además de los consejos ya mencionados, hay algunas cosas más que vale la pena tener en cuenta a la hora de formatear eBook:',
  'bookstep-Formatting-guide-epub-moreformattips-list1': 'Números de página: asegúrate de que tu archivo Word o ePub no contenga ningún número de página. Como ya hemos dicho, la configuración individual de cada lector electrónico hará que los números de página sean innecesarios. Incluirlos sólo causará problemas.',
  'bookstep-Formatting-guide-epub-moreformattips-list2': 'Listas numeradas: si quieres incluir una lista numerada en tu eBook, debes saber que, aunque es posible, existen ciertas limitaciones. Asegúrate de que tu lista no se interrumpe con un párrafo para reanudarse más adelante en el texto (por ejemplo, si los elementos de tu lista son especialmente largos). Los ePubs no permiten esto, lo que significa que tus listas siempre tienen que ser continuas.',
  'bookstep-Formatting-guide-epub-moreformattips-list3': 'Imágenes: puedes añadir imágenes a tu archivo ePub. Asegúrate de que las imágenes que utilices estén en formato JPG o PNG y que su resolución no sea demasiado grande (recomendamos un límite máximo de 1000x1000 píxeles).',
  'bookstep-Formatting-guide-epub-moreformattips-list4': 'Portada: Cuando subas un documento de Word, asegúrate de NO incluir la portada en el documento de Word. La portada se crea y/o sube en el siguiente paso del proceso de publicación ("Diseño de Cubierta"). Si estás creando tu propio archivo ePub, sí que puedes incluir tu portada en el archivo; siempre y cuando lo hagas correctamente y en una resolución lo suficientemente alta, nuestro sistema la exportará al paso de portada automáticamente.',
  'bookstep-Formatting-guide-epub-uploadscript-title': 'Subir tu manuscrito',
  'bookstep-Formatting-guide-epub-uploadscript-p1': 'Puedes escribir tu manuscrito con el programa que quieras, pero solo a la horade subirlo solo aceptamos archivos de Word (doc o docx) o ePubs. Esto significa que si trabajas con un procesador de textos que no sea Microsoft Word, primero tendrás que convertir el archivo a un archivo de Word o a un archivo ePub antes de poder subirlo a nuestra plataforma.',
  'bookstep-Formatting-guide-epub-uploadscript-p2': 'Una advertencia: según nuestra experiencia, la conversión de archivos creados en LibreOffice, Apple Pages u otras aplicaciones de procesamiento de textos a archivos doc o docx no siempre funciona bien, lo que puede dar lugar a mensajes de error al intentar subir estos archivos convertidos.',
  'bookstep-Formatting-guide-epub-uploadscript-p3': 'Después de subir tu archivo, lo convertiremos en un archivo ePub. A continuación, podrás revisar este ePub con un lector electrónico o una aplicación que lea ePubs. Es importante que revises bien este archivo y te asegures de que está lo más cerca posible de la perfección, ya que es la versión que pondremos a disposición de tus lectores.',
  'bookstep-Formatting-guide-epub-uploadscript-p4': 'Si tienes problemas para maquetar tu libro electrónico, siempre puedes recurrir a nuestro servicio de creación de eBooks. Sin embargo, ten en cuenta que cobramos por este servicio.',
  'bookstep-Formatting-guide-pdfebook-template-title': 'Plantilla',
  'bookstep-Formatting-guide-pdfebook-template-p1': 'Si tienes pensado formatear tu manuscrito tú mismo, te recomendamos encarecidamente que empieces a trabajar en un documento correctamente configurado lo antes posible; preferiblemente cuando empieces a escribir tu libro. Si trabajas en Microsoft Word, utiliza nuestras plantillas. Si ya has terminado tu manuscrito, puedes copiar el contenido en la plantilla, pero asegúrate de no copiar y pegar todo el manuscrito de golpe. Si lo haces, puede alterar el formato y causar problemas a la hora de subirlo.',
  'bookstep-Formatting-guide-pdfebook-template-p2': 'Si ya estás satisfecho con el diseño de tu documento de Word, también puedes convertirlo en PDF y subirlo directamente. Este método suele ser una forma fiable de crear un libro electrónico en PDF.',
  'bookstep-Formatting-guide-pdfebook-template-p3': 'Si utilizas un software de diseño profesional como InDesign, puedes simplemente aplicar al documento las especificaciones del formato de libro que hayas elegido.',
  'bookstep-Formatting-guide-pdfebook-fonts-title': 'Fuentes, tamaño de de fuentes e interlineado',
  'bookstep-Formatting-guide-pdfebook-fonts-p1': 'No vamos a decirte qué tipo de fuente utilizar, ya que es algo muy subjetivo y, hasta cierto punto, depende del tipo de libro que estés escribiendo. La primera decisión que hay que tomar es la de utilizar un tipo de letra con serifa o sin serifa. Las fuentes con serifa tienen pequeños "pies" en los extremos de los trazos. En general, se acepta que estas ayudan a guiar los ojos del lector a lo largo de la página. Las fuentes con serifas son, con diferencia, las más utilizadas en los libros. Cambria, Garamond, Georgia y Times New Roman son algunas de las fuentes con gracias más populares.',
  'bookstep-Formatting-guide-pdfebook-fonts-p2': 'Las fuentes sin gracias son más sencillas y suelen tener un aspecto más "moderno". Se suelen utilizar en Internet y en las portadas de los libros.  Arial, Calibri, Helvetica y Verdana son algunas de las fuentes sin serifa más populares.',
  'bookstep-Formatting-guide-pdfebook-fonts-p3': 'Además, hay que decidir el tamaño de la fuente y el interlineado. Una vez más, no hay una respuesta correcta; todo es subjetivo y también depende mucho del tipo de letra que hayas elegido. Los tamaños de letra más utilizados son 10, 11 y 12. En cuanto al interlineado, lo más habitual es entre 1,1 y 1,3.',
  'bookstep-Formatting-guide-pdfebook-fonts-p4': 'Si tu libro se dirige a un público en el que la edad media es elevada, puede valer la pena considerar un tamaño de letra y un interlineado mayores. Puede ser útil imprimir algunas páginas de tu libro para hacerte una idea de la configuración que prefieres.',
  'bookstep-Formatting-guide-pdfebook-moreoptions-title': 'Más opciones de formato',
  'bookstep-Formatting-guide-pdfebook-moreoptions-p1': '¡Esto no es todo, amigos!, todavía tienes que tomar algunas decisiones. Aquí están las más importantes:',
  'bookstep-Formatting-guide-pdfebook-moreoptions-list1': 'Márgenes: Asegúrate de que son lo suficientemente anchos; 2 cm es una buena regla general. Si no utilizas nuestras plantillas, puedes recrear un diseño similar ajustando los márgenes de la siguiente manera:',
  'bookstep-Formatting-guide-pdfebook-moreoptions-list1-topmargin': 'Margen superior: {size}',
  'bookstep-Formatting-guide-pdfebook-moreoptions-list1-bottommargin': 'Margen inferior: {size}',
  'bookstep-Formatting-guide-pdfebook-moreoptions-list1-leftmargin': 'Margen links: {size}',
  'bookstep-Formatting-guide-pdfebook-moreoptions-list1-rightmargin': 'Margen izquierdo: {size}',
  'bookstep-Formatting-guide-pdfebook-moreoptions-list2': 'Nuevos capítulos: Empieza los capítulos en una página nueva. Esto ofrece una mejor experiencia de lectura. Puede dar a tus capítulos un título, un número o ambos. Sea cual sea tu elección, asegúrate de aplicar el estilo “Título 1” a los títulos de los capítulos en Word para que el formato sea coherente.',
  'bookstep-Formatting-guide-pdfebook-moreoptions-list3': 'Párrafos: Puedes separar los párrafos con un espacio de una línea en blanco o sangrar la primera línea de un nuevo párrafo. Asegúrate de que los párrafos no sean demasiado largos, ya que eso afectaría negativamente a la legibilidad.',
  'bookstep-Formatting-guide-pdfebook-uploadscript-title': 'Sube tu manuscrito',
  'bookstep-Formatting-guide-pdfebook-uploadscript-p1': 'Por supuesto, eres libre de utilizar el software de procesamiento de textos que quieras para escribir tu manuscrito, pero cuando se trata de subir un manuscrito para un libro electrónico en PDF, solo aceptamos PDFs. Por lo tanto, independientemente de cómo hayas creado tu manuscrito, tendrás que convertirlo a PDF antes de poder subirlo a nuestra plataforma. La mayoría de los programas tienen una herramienta de conversión incorporada, ya sea en “Guardar como” o “Exportar”. Por lo general, todo lo que tienes que hacer es seleccionar cualquiera de estas opciones y luego elegir PDF como formato de archivo.',
  'bookstep-Formatting-guide-pdfebook-uploadscript-p2': 'Una vez que hayas subido tu manuscrito, lo convertiremos en un PDF publicable que aparecerá en tu pantalla. Revisa bien este archivo y asegúrate de que el diseño es perfecto en todas las páginas.',
  'bookstep-Formatting-guide-pdfebook-uploadscript-p3': '¿No estás contento con el resultado? No hay problema, puedes modificarlo y volver a subir tu manuscrito tantas veces como quieras en este paso. Ten en cuenta que cuando subas una nueva versión de tu manuscrito, la versión anterior se sobrescribirá y no podrás recuperarla.',
  'bookstep-Formatting-nextstep': 'Siguiente paso: subir',
  'bookstep-Formatting-tips-title': 'Consejos de formato',
  'bookstep-Formatting-tips-print-newpage-label' : 'Nueva página',
  'bookstep-Formatting-tips-print-newpage': 'Cuando un capítulo termina en medio de la página y quieres empezar el siguiente capítulo en una nueva página, nunca uses la tecla Enter para llegar hasta la nueva página. Si lo haces, aparecerán enormes espacios en blanco al convertir el documento a PDF, desplazando todo el texto. Por lo tanto, siempre debes usar CTRL + Enter (CMD + Enter en Mac) para crear un salto de línea en Word. Esto evitará cualquier problema. Lo mismo se aplica al insertar imágenes en tu manuscrito: ¡Por favor, no pulses simplemente Intro para dejar un espacio!',
  'bookstep-Formatting-tips-print-colophon-label': 'Páginas introductorias',
  'bookstep-Formatting-tips-print-colophon': 'Es habitual que tu libro comience con una portadilla y página legal. En general, el orden es el siguiente:',
  'bookstep-Formatting-tips-print-colophon-page1': 'Primera página: muestra el título del libro',
  'bookstep-Formatting-tips-print-colophon-page2': 'Segunda página: vacío',
  'bookstep-Formatting-tips-print-colophon-page3': 'Tercera página: de nuevo el título del libro acompañado por el nombre del autor',
  'bookstep-Formatting-tips-print-colophon-page4': 'Cuarta página: conocida como la página legal, esta página debe incluir el nombre del autor, el ISBN (si lo tiene), el nombre del diseñador de la cubierta, la declaración de derechos de autor, el año de publicación y, si lo deseas, publicado vía {publicbrand}',
  'bookstep-Formatting-tips-print-colophon-rest': 'Estas páginas se conocen colectivamente como la "portada" de tu libro. Las páginas mencionadas no están numeradas. Esto significa que la numeración de las páginas comenzará en la página 5. Nuestras plantillas de manuscritos ya están configuradas de acuerdo con esta norma.',
  'bookstep-Formatting-tips-print-images-label': 'Imágenes',
  'bookstep-Formatting-tips-print-images': 'Por supuesto, puedes añadir imágenes a tu manuscrito si lo deseas. Una imagen con una resolución de 250 ppp es suficiente para garantizar la alta calidad de las mismas Simplemente coloca las imágenes donde quieras que aparezcan en tu manuscrito (ten en cuenta el sangrado si colocas las imágenes cerca de los bordes). Si quieres hacer un pliego, es decir, una imagen que cubra dos páginas, puedes cortar la imagen por la mitad y colocar la sección más a la izquierda en una página par, y la mitad más a la derecha en la siguiente página impar.',
  'bookstep-Formatting-tips-print-wrongformat-label': '¿Formato diferente?',
  'bookstep-Formatting-tips-print-wrongformat': 'Si subes un documento con unas medidas que no coinciden con el del libro que has elegido, escalaremos el documento automáticamente para que coincida con el libro que has seleccionado. Esto puede salir bien, especialmente si solo hay pequeñas diferencias, pero también puede no salir tan bien y resultar en un documento deformado. En resumen, ¡comprueba siempre a fondo el PDF de vista previa que recibes de la plataforma! Sería una pena que pidieras una copia de prueba y te dieras cuenta de que el formato no es el adecuado.',
  'bookstep-Formatting-tips-print-colorpages-label': 'Color y Blanco&Negro',
  'bookstep-Formatting-tips-print-colorpages': 'Si tu libro contiene tanto páginas en blanco y negro como en color, es importante que sigas un determinado procedimiento para evitar que las páginas en blanco y negro se cuenten como páginas en color y se impriman con negro enriquecido. Haz clic aquí para ver un resumen completo de cómo añadir páginas en color a tu libro. {link}',
  'bookstep-Formatting-tips-print-colorpages-link': 'Haz clic aquí para ver un resumen completo sobre cómo trabajar con páginas a color',
  'bookstep-Formatting-tips-print-proofcopy-label': 'Copia de prueba',
  'bookstep-Formatting-tips-print-proofcopy': 'Todavía no puedes comprar una prueba (antes tienes que crear tu cubierta), pero no está de más mencionarlo ya: compra siempre una copia de tu libro antes de publicarlo o hacer grandes pedidos de impresión. Por mucho que hayas revisado tu manuscrito digitalmente, siempre vas a notar algunos errores en la versión impresa. Los errores tipográficos, los detalles estéticos y los colores en algún momento serán difíciles de distinguir en una pantalla, y la copia impresa siempre tendrá un aspecto ligeramente diferente; te sorprenderás de todos los detalles que encontrarás. Es muy frustrante detectar estos pequeños errores después de que el libro se haya publicado o de haber hecho un gran pedido. Los descuentos por volumen también se aplican al primer pedido, por lo que puedes pedir fácilmente varias copias y hacer que otras personas te ayuden a corregir el borrador final.',
  'bookstep-Formatting-tips-epub-chapterheadings-label': 'Títulos de capítulos',
  'bookstep-Formatting-tips-epub-chapterheadings': 'Para crear un buen eBook, es importante utilizar los títulos adecuados para los capítulos. Puedes hacerlo en Microsoft Word utilizando los llamados “Estilos”, como el “Título 1”. Si no lo haces, tu libro electrónico será un texto largo sin títulos y sin posibilidad de navegar entre capítulos.',
  'bookstep-Formatting-tips-epub-colophon-label': 'Páginas introductorias',
  'bookstep-Formatting-tips-epub-colophon': 'Es habitual que un libro comience con una portadilla y la página legal, si la tiene. Por lo general, las primeras páginas de un libro siguen este orden:',
  'bookstep-Formatting-tips-epub-colophon-page1': 'Primera página: título del libro seguido del autor',
  'bookstep-Formatting-tips-epub-colophon-page2': 'Segunda página: conocida como la página legal, esta página debe incluir el nombre del autor, el ISBN (si lo tiene), el nombre del diseñador de la cubierta, la declaración de derechos de autor, el año de publicación y, si lo deseas, publicado vía {publicbrand}',
  'bookstep-Formatting-tips-epub-colophon-rest': 'Si utilizas Microsoft Word, asegúrate de insertar un salto de página entre estas dos páginas, para que permanezcan separadas.',
  'bookstep-Formatting-tips-epub-pagenumbers-label': 'Elimina los números de página',
  'bookstep-Formatting-tips-epub-pagenumbers': 'Asegúrate de que no hay números de página ni ningún otro texto o elemento en la cabecera o el pie de página de tu archivo de manuscrito. Si es así y no se eliminan, aparecerán aleatoriamente dentro del texto del propio libro electrónico, en lugar de en el lugar previsto.',
  'bookstep-Formatting-tips-epub-images-label': 'Imágenes',
  'bookstep-Formatting-tips-epub-images': 'Por supuesto, puedes añadir imágenes a tu eBook. Si decides hacerlo, ten en cuenta que la imagen se coloca “en línea con el texto”. No puedes añadir las llamadas imágenes “flotantes” con texto al lado; las imágenes deben ocupar siempre toda la línea con el texto que las acompaña por encima y/o por debajo.',
  'bookstep-Formatting-tips-pdfebook-newpage-label': 'Página nueva',
  'bookstep-Formatting-tips-pdfebook-newpage': 'Cuando un capítulo termina en medio de la página y quieres empezar el siguiente capítulo en una nueva página, nunca uses la tecla Enter para llegar hasta la nueva página. Si lo haces, aparecerán enormes espacios en blanco al convertir el documento a PDF, desplazando todo el texto. Por lo tanto, siempre debes usar CTRL + Enter (CMD + Enter en Mac) para crear un salto de línea en Word. Esto evitará cualquier problema. Lo mismo se aplica al insertar imágenes en tu manuscrito: ¡Por favor, no pulses simplemente Intro para dejar un espacio!',
  'bookstep-Formatting-tips-pdfebook-colophon-label': 'Páginas introductorias',
  'bookstep-Formatting-tips-pdfebook-colophon': 'Es habitual que tu libro comience con una portadilla y página legal. En general, el orden es el siguiente:',
  'bookstep-Formatting-tips-pdfebook-colophon-page1': 'Primera página: muestra el título del libro',
  'bookstep-Formatting-tips-pdfebook-colophon-page2': 'Segunda página: vacío',
  'bookstep-Formatting-tips-pdfebook-colophon-page3': 'Tercera página: de nuevo el título del libro acompañado por el nombre del autor',
  'bookstep-Formatting-tips-pdfebook-colophon-page4': 'Cuarta página: conocida como la página legal, esta página debe incluir el nombre del autor, el ISBN (si lo tiene), el nombre del diseñador de la cubierta, la declaración de derechos de autor, el año de publicación y, si lo deseas, publicado vía {publicbrand}',
  'bookstep-Formatting-tips-pdfebook-colophon-rest': 'Estas páginas se conocen colectivamente como la "portada" de tu libro. Las páginas mencionadas no están numeradas. Esto significa que la numeración de las páginas comenzará en la página 5. Nuestras plantillas de manuscritos ya están configuradas de acuerdo con esta norma.',
  'bookstep-Formatting-tips-pdfebook-images-label': 'Imágenes',
  'bookstep-Formatting-tips-pdfebook-images': 'Por supuesto, puedes añadir imágenes a tu manuscrito. Una resolución de 96 ppp es suficiente para los libros electrónicos. Deberás insertar las imágenes en tu manuscrito tú mismo; solo asegúrate de que haya suficiente espacio entre la imagen y el texto.',
  'bookstep-Formatting-tips-pdfebook-dimensions-label': 'Dimensiones',
  'bookstep-Formatting-tips-pdfebook-dimensions': 'No hay restricciones en cuanto a las dimensiones de tu PDF. Sin embargo, es conveniente utilizar un tamaño bastante estándar, preferiblemente entre A5 y A4, ya que un formato extremadamente grande o pequeño puede tener un efecto negativo en la legibilidad de tu libro electrónico.',

  // Account settings
  'AccountSettings-Security-button-2FA-title': 'Utilizar autenticación en dos pasos',
  'AccountSettings-Security-button-2FA-subtitle-enabled': 'Activado',
  'AccountSettings-Security-button-2FA-subtitle-disabled': 'Desactivado',
  'AccountSettings-Security-button-2FA-subtitle': '{enableddisabled} • Le pedimos un código cuando se conecta desde un nuevo dispositivo.',

  // Checkout
  'Checkout-Cart-title-Yourcart': 'Tu cesta',
  'Checkout-Cart-RemoveFromCart': 'Eliminar de la cesta',
  'Checkout-Cart-CartItem-Title-Buscards': 'Tarjetas de visita',
  'Checkout-Cart-CartItem-Title-Isbn': 'ISBN',
  'Checkout-Cart-CartItem-Originally': 'Original: <span>{price}</span>',
  'Checkout-Cart-CartItem-Forbook': 'Por libro: {bookTitle}',
  'Checkout-Cart-CartIsEmpty': 'Tu cesta está vacía.',
  'Checkout-Cart-title-Total': 'Total',
  'Checkout-Cart-total-Subtotal': 'Subtotal <span>{price}</span>',
  'Checkout-Cart-total-Discount': 'Descuento <span>{price}</span>',
  'Checkout-Cart-total-Total': 'Total <span>{price}</span>',
  'Checkout-Cart-Button-ProceedCheckout': 'Tramitar pedido',
  'Checkout-Cart-ErrorPopup-title': 'Algunos de los productos de tu cesta no están disponibles en este momento',
  'Checkout-Cart-ErrorPopup-p1': 'Si clicas en ‘Tramitar pedido’, eliminaremos los productos no disponibles de tu cesta y continuarás con el proceso de compra',
  'Checkout-Cart-ErrorPopup-unavailableprods': 'Productos no disponibles:',
  'Checkout-Cart-ErrorPopup-Button-Cancel': 'Cancelar',
  'Checkout-Cart-ErrorPopup-Button-ContinueOrder': 'Tramitar pedido',
  'Checkout-Cart-WarningPopup-title': 'Estás a punto de comprar un ISBN y el libro al que está asociado al mismo tiempo.',
  'Checkout-Cart-WarningPopup-p1': 'Si compras un ISBN y el libro asociado en el mismo pedido, el libro <b>no</b> incluirá el ISBN. Recomendamos comprar primero el ISBN y, una vez este haya sido asignado a tu título, comprar el libro.',
  'Checkout-Cart-WarningPopup-p2': 'Si clicas en el botón ‘Comprar ISBN primero’, retiraremos los siguientes libros de tu cesta:',
  'Checkout-Cart-WarningPopup-Button-IgnoreContinue': 'Ignorar y continuar',
  'Checkout-Cart-WarningPopup-Button-OrderIsbnFirst': 'Comprar ISBN primero',
  'Checkout-Error-CheckoutSpecApiError': 'Ha ocurrido un error inesperado durante el proceso de compra. Por favor, ponte en contacto con el servicio de atención al cliente y adjunta una captura de pantalla de esta página. Lamentamos las molestias.',
  'Checkout-CheckoutDetails-Button-Save': 'Guardar',
  'Checkout-CheckoutDetails-Button-Next': 'Continuar',
  'Checkout-CheckoutDetails-Button-Cancel': 'Cancelar',
  'Checkout-CheckoutDetails-Button-NextStep': 'Siguiente paso',
  'Checkout-CheckoutDetails-title-Email': 'Correo electrónico',
  'Checkout-CheckoutDetails-title-ShippingAddress': 'Dirección de envío',
  'Checkout-CheckoutDetails-title-BillingAddress': 'Detalles de facturación',
  'Checkout-CheckoutDetails-title-ShippingMethod': 'Método de envío',
  'Checkout-CheckoutDetails-Email-label-Email': 'Correo electrónico',
  'Checkout-CheckoutDetails-Email-Button-EditEmail': 'Cambiar',
  'Checkout-CheckoutDetails-ShippingAddress-label-YourShippingAddress': 'Tu dirección de envío',
  'Checkout-CheckoutDetails-ShippingAddress-Button-EnterDifferentAddress': 'Cambiar dirección de envío',
  'Checkout-CheckoutDetails-BillingAddress-SameAsShipping-label': 'Idéntica a la dirección de envío',
  'Checkout-CheckoutDetails-BillingAddress-label-YourBillingAddress': 'STu dirección de facturación',
  'Checkout-CheckoutDetails-BillingAddress-Button-EnterDifferentAddress': 'Cambiar dirección de facturación',
  'Checkout-CheckoutDetails-BillingAddress-CustomerType-label': 'Tipo de cliente',
  'Checkout-CheckoutDetails-BillingAddress-CustomerType-labelDesc': 'Especifica qué tipo de cliente eres. Los clientes de empresas necesitan un número de identificación fiscal europeo.',
  'Checkout-CheckoutDetails-BillingAddress-CustomerType-Private': 'Particular',
  'Checkout-CheckoutDetails-BillingAddress-CustomerType-Private-desc': 'Cliente sin número de identificación fiscal europeo',
  'Checkout-CheckoutDetails-BillingAddress-CustomerType-Company': 'Empresa',
  'Checkout-CheckoutDetails-BillingAddress-CustomerType-Company-desc': 'Cliente con número de identificación fiscal europeo',
  'Checkout-CheckoutDetails-ShippingMethod-SelectMethod-label': 'Elija tu método de envío preferido',
  'Checkout-Address-Phoneline': 'Tel: {phone}',
  'Checkout-Address-Vatline': 'Número de IVA: {taxno}',
  'Checkout-Address-Input-Fn': 'Nombre',
  'Checkout-Address-Input-Ln': 'Apellido',
  'Checkout-Address-Input-L': 'País',
  'Checkout-Address-GenericAddress-S': 'Calle',
  'Checkout-Address-GenericAddress-P': 'Código postal',
  'Checkout-Address-GenericAddress-Nr': 'Número de portal y piso',
  'Checkout-Address-GenericAddress-NrA': 'Número e información adicional',
  'Checkout-Address-GenericAddress-C': 'Ciudad',
  'Checkout-Address-GenericAddress-R': 'Región',
  'Checkout-Address-Input-T': 'Teléfono',
  'Checkout-Address-Input-Shipping-Cp': 'Empresa (en la etiqueta de dirección)',
  'Checkout-Address-Input-Cp': 'Empresa',
  'Checkout-Address-Input-Shipping-V': 'Número de identificación fiscal',
  'Checkout-Address-Input-V': 'Número de IVA',
  'Checkout-Address-Button-Save': 'Guardar',
  'Checkout-AddressStreetWarningNoNumber': 'Atención: tu dirección no incluye el número de tu portal',
  'Checkout-PostalcodecheckError-ADDRESS_NOT_FOUND': 'No hemos podido encontrar una dirección que coincida con el código postal y la dirección que has introducido. Por favor, comprueba si has introducido la información correctamente.',
  'Checkout-PostalcodecheckError-INVALID_API_CALL': 'Un error ha ocurrido mientras comprobamos tu dirección. Por favor, ponte en contacto con atención al cliente.',
  'Checkout-CheckoutOverview-title-YourDeliveryDetails': 'Datos de tu pedido',
  'Checkout-CheckoutOverview-title-Paymentmethod': 'Método de pago',
  'Checkout-CheckoutOverview-YourDeliveryDetails-label-Email': 'Correo electrónico',
  'Checkout-CheckoutOverview-YourDeliveryDetails-label-YourAddress': 'Tu dirección',
  'Checkout-CheckoutOverview-YourDeliveryDetails-label-ShippingAddress': 'Dirección de envío',
  'Checkout-CheckoutOverview-YourDeliveryDetails-label-BillingAddress': 'Dirección de facturación',
  'Checkout-CheckoutOverview-YourDeliveryDetails-label-ShippingMethod': 'Método de envío',
  'Checkout-OrderSidebar-title-YourOrder': 'Tu pedido',
  'Checkout-OrderSidebar-ProductItem-Quantity': 'Cant.: {quantity}',
  'Checkout-OrderSidebar-totals-Shipping': 'Envío: <span>{price}</span>',
  'Checkout-OrderSidebar-totals-Total': 'Total: <span>{price}</span>',
  'Checkout-OrderSidebar-totals-IncludedVAT': 'IVA incluido: <span>{price}</span>',
  'CheckoutSuccess-title-Thankyou': '¡Gracias por tu compra!',
  'CheckoutSuccess-YourOrderNoIs': 'Tu número de pedido es: <b>{orderId}</b>',
  'CheckoutSuccess-IntroWhatToExpect': '¡Tu pedido ha sido realizado! Nos ponemos a ello de inmediato. A continuación puedes leer cuáles serán los siguientes pasos dependiendo del tipo de pedido que hayas hecho.',
  'CheckoutSuccess-title-PaperbackHardcover': 'Tapa blanda / Tapa dura',
  'CheckoutSuccess-title-Ebook': 'eBook',
  'CheckoutSuccess-title-Isbn': 'ISBN',
  'CheckoutSuccess-title-Buscards': 'Tarjetas de visita',
  'CheckoutSuccess-li1-OrderConfEmail': 'Recibirás un correo de confirmación',
  'CheckoutSuccess-li1-OrderNumberIs': 'Tu número de pedido es: <b>{orderId}</b>.',
  'CheckoutSuccess-li1-OrderConfSentTo': 'Enviaremos la confirmación de tu pedido a {email}. Comprueba que el mensaje no acabe en la bandeja de correo no deseado.',
  'CheckoutSuccess-PaperbackHardcover-li2-WeMakePOD': 'Imprimimos tu libro utilizando el sistema de Impresión Bajo Demanda',
  'CheckoutSuccess-PaperbackHardcover-li2-WePrintRightAway': 'Tu libro entrará en el proceso de impresión inmediatamente, pero ten en cuenta que la impresión tarda unos días.',
  'CheckoutSuccess-PaperbackHardcover-li3-OrderWillShipped': 'Tu pedido será enviado',
  'CheckoutSuccess-PaperbackHardcover-li3-ShipConfEMail': 'Recibirás una confirmación de envío por correo electrónico.',
  'CheckoutSuccess-PaperbackHardcover-ExpectedShippingDate': 'Fecha de envío prevista',
  'CheckoutSuccess-PaperbackHardcover-ExpectedShippingDate-BetweenDates': 'Entre {startdate} y {enddate}',
  'CheckoutSuccess-Ebook-li2-dlLinkEmail': 'Encontrarás el enlace de descarga en el correo electrónico de confirmación',
  'CheckoutSuccess-Ebook-li2-dlLinkEmailOrClickBelow': 'El enlace para descargar tu libro e-book está en el correo electrónico de confirmación que has recibido al realizar tu pedido. También puedes hacer clic en el botón de abajo para descargar tu libro electrónico.',
  'CheckoutSuccess-Ebook-DownloadYourEbook': 'Descarga tu e-book',
  'CheckoutSuccess-Isbn-li2-IsbnReady': 'Tu ISBN está listo en una hora',
  'CheckoutSuccess-Isbn-li2-IsbnReadyInStep': 'Puedes encontrar el ISBN en el paso ‘ISBN’ de tu libro. Añadimos automáticamente el ISBN y el código de barras en la contraportada de tu libro.',
  'CheckoutSuccess-Isbn-ContinueWithBook': 'Continúa con tu libro',
  'CheckoutSuccess-Isbn-ContinueWithBookClickHere': 'Haz click aquí para seguir trabajando en tu libro.',
  'CheckoutSuccess-Buscards-li2-WeMakeCards': 'Estamos produciendo tus tarjetas de visita',
  'CheckoutSuccess-Buscards-li2-WePrintRightAway': 'Estamos imprimiendo tus tarjetas de visita en este momento. Tardaremos aproximadamente una semana en entregarlas.',
  'CheckoutSuccess-Buscards-li2-NoShipmentMail': 'Atención: No recibirás una confirmación de envío por separado para las tarjetas de visita.',
  'CheckoutFailed-title-PaymentFailed': 'No hemos podido procesar tu pago',
  'CheckoutFailed-SomethingWentWrongTryAgain': 'Algo ha salido mal en el proceso de pago. Por favor, vuelve a intentarlo.',
  'CheckoutFailed-button-CheckoutAgain': 'Volver al proceso de compra',
  'CheckoutError-title-SomethingWrong': 'Algo ha salido mal',
  'CheckoutError-SomethingWentWrongContactUs': 'Algo ha salido mal al realizar el pago de tu pedido. Inténtalo de nuevo o ponte en contacto con el servicio de atención al cliente. Incluye la siguiente referencia cuando te pongas en contacto con el servicio de soporte: {orderId}',
  'CheckoutError-button-TryAgain': 'Vuelve a intentarlo',
  'CheckoutPending-title-PaymentInProgress': 'Tu pago está siendo procesado',
  'CheckoutPending-PaymentProcessingTakesLonger': 'El procesamiento de tu pago está tardando un poco más de lo esperado. Por el momento, no podemos informarte definitivamente de si tu pago se ha realizado correctamente.',
  'CheckoutPending-CheckAgainInFewHours': 'Por favor, comprueba tu correo electrónico en las próximas horas. En cuanto recibamos la confirmación de tu pago, te enviaremos un correo electrónico con la confirmación del pedido. Si no recibes esta confirmación, comprueba tu cuenta bancaria o tarjeta de crédito para verificar si el pago se ha realizado correctamente.',
  'CheckoutPending-OrderReference': 'Tu número de pedido es: {orderId}',
  'CheckoutCancelled-title-PaymentCancelled': 'Has cancelado el pago',
  'CheckoutCancelled-TryAgainIfYouWant': ' Has cancelado el pago. Si esta no era tu intención, haz clic en el botón de abajo para volver a realizar el ',
  'CheckoutCancelled-button-CheckoutAgain': 'Volver al proceso de compra',

  // All ISO-3166-1 alpha 2 countries
  'Country-AD': 'Andorra',
  'Country-AE': 'Emiratos Árabes Unidos',
  'Country-AF': 'Afganistán',
  'Country-AG': 'Antigua y Barbuda',
  'Country-AI': 'Anguila',
  'Country-AL': 'Albania',
  'Country-AM': 'Armenia',
  'Country-AO': 'Angola',
  'Country-AQ': 'Antártida',
  'Country-AR': 'Argentina',
  'Country-AS': 'Samoa Americana',
  'Country-AT': 'Austria',
  'Country-AU': 'Australia',
  'Country-AW': 'Aruba',
  'Country-AX': 'Åland',
  'Country-AZ': 'Azerbaiyán',
  'Country-BA': 'Bosnia y Herzegovina',
  'Country-BB': 'Barbados',
  'Country-BD': 'Bangladés',
  'Country-BE': 'Bélgica',
  'Country-BF': 'Burkina Faso',
  'Country-BG': 'Bulgaria',
  'Country-BH': 'Baréin',
  'Country-BI': 'Burundi',
  'Country-BJ': 'Benín',
  'Country-BL': 'San Bartolomé',
  'Country-BM': 'Bermudas',
  'Country-BN': 'Brunéi',
  'Country-BO': 'Bolivia',
  'Country-BQ': 'Caribe neerlandés',
  'Country-BR': 'Brasil',
  'Country-BS': 'Bahamas',
  'Country-BT': 'Bután',
  'Country-BV': 'Isla Bouvet',
  'Country-BW': 'Botsuana',
  'Country-BY': 'Bielorrusia',
  'Country-BZ': 'Belice',
  'Country-CA': 'Canadá',
  'Country-CC': 'Islas Cocos',
  'Country-CD': 'República Democrática del Congo',
  'Country-CF': 'República Centroafricana',
  'Country-CG': 'Congo',
  'Country-CH': 'Suiza',
  'Country-CI': 'Costa de Marfil',
  'Country-CK': 'Islas Cook',
  'Country-CL': 'Chile',
  'Country-CM': 'Camerún',
  'Country-CN': 'China',
  'Country-CO': 'Colombia',
  'Country-CR': 'Costa Rica',
  'Country-CU': 'Cuba',
  'Country-CV': 'Cabo Verde',
  'Country-CW': 'Curazao',
  'Country-CX': 'Isla de Navidad',
  'Country-CY': 'Chipre',
  'Country-CZ': 'Chequia',
  'Country-DE': 'Alemania',
  'Country-DJ': 'Yibuti',
  'Country-DK': 'Dinamarca',
  'Country-DM': 'Dominica',
  'Country-DO': 'República Dominicana',
  'Country-DZ': 'Argelia',
  'Country-EC': 'Ecuador',
  'Country-EE': 'Estonia',
  'Country-EG': 'Egipto',
  'Country-EH': 'Sahara Occidental',
  'Country-ER': 'Eritrea',
  'Country-ES': 'España',
  'Country-ET': 'Etiopía',
  'Country-FI': 'Finlandia',
  'Country-FJ': 'Fiyi',
  'Country-FK': 'Islas Malvinas',
  'Country-FM': 'Micronesia',
  'Country-FO': 'Islas Feroe',
  'Country-FR': 'Francia',
  'Country-GA': 'Gabón',
  'Country-GB': 'Reino Unido',
  'Country-GD': 'Granada',
  'Country-GE': 'Georgia',
  'Country-GF': 'Guayana Francesa',
  'Country-GG': 'Guernsey',
  'Country-GH': 'Ghana',
  'Country-GI': 'Gibraltar',
  'Country-GL': 'Groenlandia',
  'Country-GM': 'Gambia',
  'Country-GN': 'Guinea',
  'Country-GP': 'Guadalupe',
  'Country-GQ': 'Guinea Ecuatorial',
  'Country-GR': 'Grecia',
  'Country-GS': 'Islas Georgias del Sur y Sandwich del Sur',
  'Country-GT': 'Guatemala',
  'Country-GU': 'Guam',
  'Country-GW': 'Guinea-Bisáu',
  'Country-GY': 'Guyana',
  'Country-HK': 'Hong Kong',
  'Country-HM': 'Islas Heard y McDonald',
  'Country-HN': 'Honduras',
  'Country-HR': 'Croacia',
  'Country-HT': 'Haití',
  'Country-HU': 'Hungría',
  'Country-ID': 'Indonesia',
  'Country-IE': 'Irlanda',
  'Country-IL': 'Israel',
  'Country-IM': 'Isla de Man',
  'Country-IN': 'India',
  'Country-IO': 'Territorio Británico del Océano Índico',
  'Country-IQ': 'Irak',
  'Country-IR': 'Irán',
  'Country-IS': 'Islandia',
  'Country-IT': 'Italia',
  'Country-JE': 'Jersey',
  'Country-JM': 'Jamaica',
  'Country-JO': 'Jordania',
  'Country-JP': 'Japón',
  'Country-KE': 'Kenia',
  'Country-KG': 'Kirguistán',
  'Country-KH': 'Camboya',
  'Country-KI': 'Kiribati',
  'Country-KM': 'Comoras',
  'Country-KN': 'San Cristóbal y Nieves',
  'Country-KP': 'Corea del Norte',
  'Country-KR': 'Corea del Sur',
  'Country-KW': 'Kuwait',
  'Country-KY': 'Islas Caimán',
  'Country-KZ': 'Kazajistán',
  'Country-LA': 'Laos',
  'Country-LB': 'Líbano',
  'Country-LC': 'Santa Lucía',
  'Country-LI': 'Liechtenstein',
  'Country-LK': 'Sri Lanka',
  'Country-LR': 'Liberia',
  'Country-LS': 'Lesoto',
  'Country-LT': 'Lituania',
  'Country-LU': 'Luxemburgo',
  'Country-LV': 'Letonia',
  'Country-LY': 'Libia',
  'Country-MA': 'Marruecos',
  'Country-MC': 'Mónaco',
  'Country-MD': 'Moldavia',
  'Country-ME': 'Montenegro',
  'Country-MF': 'San Martín',
  'Country-MG': 'Madagascar',
  'Country-MH': 'Islas Marshall',
  'Country-MK': 'Macedonia del Norte',
  'Country-ML': 'Mali',
  'Country-MM': 'Myanmar',
  'Country-MN': 'Mongolia',
  'Country-MO': 'Macao',
  'Country-MP': 'Islas Marianas del Norte',
  'Country-MQ': 'Martinica',
  'Country-MR': 'Mauritania',
  'Country-MS': 'Montserrat',
  'Country-MT': 'Malta',
  'Country-MU': 'Mauricio',
  'Country-MV': 'Maldivas',
  'Country-MW': 'Malaui',
  'Country-MX': 'México',
  'Country-MY': 'Malasia',
  'Country-MZ': 'Mozambique',
  'Country-NA': 'Namibia',
  'Country-NC': 'Nueva Caledonia',
  'Country-NE': 'Níger',
  'Country-NF': 'Isla Norfolk',
  'Country-NG': 'Nigeria',
  'Country-NI': 'Nicaragua',
  'Country-NL': 'Países Bajos',
  'Country-NO': 'Noruega',
  'Country-NP': 'Nepal',
  'Country-NR': 'Nauru',
  'Country-NU': 'Niue',
  'Country-NZ': 'Nueva Zelanda',
  'Country-OM': 'Omán',
  'Country-PA': 'Panamá',
  'Country-PE': 'Perú',
  'Country-PF': 'Polinesia Francesa',
  'Country-PG': 'Papúa Nueva Guinea',
  'Country-PH': 'Filipinas',
  'Country-PK': 'Pakistán',
  'Country-PL': 'Polonia',
  'Country-PM': 'San Pedro y Miquelón',
  'Country-PN': 'Islas Pitcairn',
  'Country-PR': 'Puerto Rico',
  'Country-PS': 'Territorios Palestinos',
  'Country-PT': 'Portugal',
  'Country-PW': 'Palaos',
  'Country-PY': 'Paraguay',
  'Country-QA': 'Catar',
  'Country-RE': 'Reunión',
  'Country-RO': 'Rumanía',
  'Country-RS': 'Serbia',
  'Country-RU': 'Rusia',
  'Country-RW': 'Ruanda',
  'Country-SA': 'Arabia Saudita',
  'Country-SB': 'Islas Salomón',
  'Country-SC': 'Seychelles',
  'Country-SD': 'Sudán',
  'Country-SE': 'Suecia',
  'Country-SG': 'Singapur',
  'Country-SH': 'Santa Elena, Ascensión y Tristán de Acuña',
  'Country-SI': 'Eslovenia',
  'Country-SJ': 'Svalbard y Jan Mayen',
  'Country-SK': 'Eslovaquia',
  'Country-SL': 'Sierra Leona',
  'Country-SM': 'San Marino',
  'Country-SN': 'Senegal',
  'Country-SO': 'Somalia',
  'Country-SR': 'Surinam',
  'Country-SS': 'Sudán del Sur',
  'Country-ST': 'Santo Tomé y Príncipe',
  'Country-SV': 'El Salvador',
  'Country-SX': 'Sint Maarten',
  'Country-SY': 'Siria',
  'Country-SZ': 'Suazilandia',
  'Country-TC': 'Islas Turcas y Caicos',
  'Country-TD': 'Chad',
  'Country-TF': 'Territorios Australes Franceses',
  'Country-TG': 'Togo',
  'Country-TH': 'Tailandia',
  'Country-TJ': 'Tayikistán',
  'Country-TK': 'Tokelau',
  'Country-TL': 'Timor Oriental',
  'Country-TM': 'Turkmenistán',
  'Country-TN': 'Túnez',
  'Country-TO': 'Tonga',
  'Country-TR': 'Turquía',
  'Country-TT': 'Trinidad y Tobago',
  'Country-TV': 'Tuvalu',
  'Country-TW': 'Taiwán',
  'Country-TZ': 'Tanzania',
  'Country-UA': 'Ucrania',
  'Country-UG': 'Uganda',
  'Country-UM': 'Islas menores alejadas de los Estados Unidos',
  'Country-US': 'Estados Unidos',
  'Country-UY': 'Uruguay',
  'Country-UZ': 'Uzbekistán',
  'Country-VA': 'Ciudad del Vaticano',
  'Country-VC': 'San Vicente y las Granadinas',
  'Country-VE': 'Venezuela',
  'Country-VG': 'Islas Vírgenes Británicas',
  'Country-VI': 'Islas Vírgenes de los Estados Unidos',
  'Country-VN': 'Vietnam',
  'Country-VU': 'Vanuatu',
  'Country-WF': 'Wallis y Futuna',
  'Country-WS': 'Samoa',
  'Country-XK': 'Kosovo',
  'Country-YE': 'Yemen',
  'Country-YT': 'Mayotte',
  'Country-ZA': 'Sudáfrica',
  'Country-ZM': 'Zambia',
  'Country-ZW': 'Zimbabue'
};

export default messages;
