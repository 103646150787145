import { Block, EGridColType, EGridTypes, GridCol, Loading } from 'mbs-common';
//import { useGetCartQuery } from '../api/apiSlice';
import YourCartBlock from './YourCartBlock';
import { useGetCartQuery } from '../hooks';
import TotalsBlock from './TotalsBlock';
import { GridContainer } from 'mbs-common';
import {
  CartItem,
  CartServerState,
  changeQuantity,
  decreaseQuantity,
  deleteItem,
  increaseQuantity,
  productTypeIsIsbn,
  productTypeIsPrintBook
} from './cartSlice';
import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { useVerifyCartBeforeCheckoutMutation } from '../api/apiSlice';
import ErrorPopUp from './ErrorPopup';
import WarningPopup from './WarningPopup';
import { startAppListening } from '../middleware/listenerMiddleware';
import { isAnyOf } from '@reduxjs/toolkit';
import { useHistory } from 'react-router-dom';

enum VerifyingStatus {
  Done = 'DONE',
  Pending = 'PENDING',
  Idle = 'IDLE'
}

type VerificationStatusDone = {
  isVerifying: VerifyingStatus.Done;
  errors?: Record<string, string>;
  isbnAndBookSimultaneously?: CartItem[];
  modalOpen?: boolean;
};

export default function Cart({ setShowBackbutton, setPagePath }: {setShowBackbutton: React.Dispatch<React.SetStateAction<boolean>>, setPagePath: React.Dispatch<React.SetStateAction<string>>}) {
  const { isLoading, isSuccess, isError, data } = useGetCartQuery();
  const [verificationStatus, setVerificationStatus] = useState<
    { isVerifying: VerifyingStatus.Pending | VerifyingStatus.Idle } | VerificationStatusDone
  >({ isVerifying: VerifyingStatus.Idle });
  const [trigger] = useVerifyCartBeforeCheckoutMutation();
  const history = useHistory();

  const verifyCart = async () => {
    if (isSuccess) {
      const cartServerState: CartServerState = {
        config: data.config,
        items: data.items
      };
      const result = await trigger(cartServerState).unwrap();

      // check if user is trying to order a book and an isbn for this book simultaneously
      const isbnItems = data.items.filter((item) => productTypeIsIsbn(item.type));
      const isbnAndBookSimultaneously: CartItem[] = [];
      if (isbnItems.length > 0) {
        // check for book and isbn simultaneously
        isbnItems.forEach((item) => {
          const bookFound = data.items.find(
            (bookItem) => bookItem.bookId === item.bookId && productTypeIsPrintBook(bookItem.type)
          );
          if (bookFound) {
            isbnAndBookSimultaneously.push(bookFound);
          }
        });
      }

      const returnObject: VerificationStatusDone = { isVerifying: VerifyingStatus.Done };
      if (result.status !== 'OK') {
        returnObject.errors = result.errors;
      }
      if (isbnAndBookSimultaneously.length > 0) {
        returnObject.isbnAndBookSimultaneously = isbnAndBookSimultaneously;
        if (returnObject.errors === undefined) {
          returnObject.modalOpen = true;
        }
      }
      // if there are errors, update verificationstatus in state. If not, redirect to checkout
      if (
        returnObject.errors !== undefined ||
        returnObject.isbnAndBookSimultaneously !== undefined
      ) {
        // add redux listener to reset verification status after cart changes not done via the popup
        startAppListening({
          matcher: isAnyOf(deleteItem, changeQuantity, increaseQuantity, decreaseQuantity),
          effect: async (action, listenerApi) => {
            setVerificationStatus({ isVerifying: VerifyingStatus.Idle });
            listenerApi.unsubscribe();
          }
        });
      }
      setVerificationStatus(returnObject);
    }
  };

  useEffect(() => {
    if(typeof(setShowBackbutton) == 'function' && typeof(setPagePath) == 'function')
    {
      setShowBackbutton(true);
      setPagePath('/cart')
    }
  }, []);

  useEffect(() => {
    if (
      verificationStatus.isVerifying === VerifyingStatus.Done &&
      verificationStatus.errors === undefined &&
      verificationStatus.isbnAndBookSimultaneously === undefined
    ) {
      history.push('/checkout');
    }
  }, [verificationStatus, history]);

  const handleOrderSubmit = async () => {
    // this function should only do something if the cart is actually loaded
    if (isSuccess) {
      setVerificationStatus({ isVerifying: VerifyingStatus.Pending });
      verifyCart();
    }
  };

  return (
    <section className="app-context-container">
      {isLoading ? (
        <Block>
          <Loading container={true} size="40px" />
        </Block>
      ) : isSuccess ? (
        <>
          {verificationStatus.isVerifying === VerifyingStatus.Pending ? (
            <div>Verifying...</div>
          ) : verificationStatus.isVerifying === VerifyingStatus.Done &&
            verificationStatus.errors ? (
            <ErrorPopUp
              errors={verificationStatus.errors}
              handleClearErrors={() =>
                setVerificationStatus((prevState) => ({
                  ...prevState,
                  errors: undefined
                }))
              }
            />
          ) : (
            verificationStatus.isVerifying === VerifyingStatus.Done &&
            verificationStatus.isbnAndBookSimultaneously && (
              <WarningPopup
                warningBooks={verificationStatus.isbnAndBookSimultaneously}
                clearWarnings={() =>
                  setVerificationStatus((prevState) => ({
                    ...prevState,
                    isbnAndBookSimultaneously: undefined
                  }))
                }
              />
            )
          )}
          <GridContainer type={EGridTypes.Center}>
            <GridCol
              type={[
                EGridColType.Default12,
                EGridColType.Md8,
                EGridColType.Xl9,
                EGridColType.Xxl12
              ]}
            >
              <YourCartBlock cart={data} />
            </GridCol>
            {data.items.length > 0 && (
              <GridCol
                type={[
                  EGridColType.Default12,
                  EGridColType.Md4,
                  EGridColType.Xl3,
                  EGridColType.Xxl4
                ]}
              >
                <TotalsBlock cart={data} onOrderSubmit={handleOrderSubmit} />
              </GridCol>
            )}
          </GridContainer>
        </>
      ) : isError ? (
        <>Error during loading</>
      ) : (
        ''
      )}
    </section>
  );
}
