import { SVGIcon, MBSSelect } from 'mbs-common';
import { CartCurrency, CartItemBuscards, changeQuantity } from '../cartSlice';
import CartItem from './CartItem';
import { useIntl } from 'react-intl';
import { useAppDispatch } from '../../hooks';

type OptionType = {
  value: number;
  label: string;
};

export default function BuscardsItem({
  cartItem,
  currency,
  sourceCountry
}: {
  cartItem: CartItemBuscards;
  currency: CartCurrency;
  sourceCountry: string;
}) {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const image = <SVGIcon name="app/ProductImages/Buscards" width="80" />;
  const title = intl.formatMessage({
    id: 'Checkout-Cart-CartItem-Title-Buscards',
    defaultMessage: 'Business cards'
  });
  const subtitle = intl.formatMessage(
    {
      id: 'Checkout-Cart-CartItem-Forbook',
      defaultMessage: 'For book: {bookTitle}'
    },
    { bookTitle: cartItem.buscardsSpecs.bookTitle }
  );

  const handleChangeQuantity = (selectedOption: OptionType | null) => {
    if (selectedOption) {
      dispatch(changeQuantity({ id: cartItem.id, quantity: selectedOption.value }));
    }
  };

  const quantityOptions: OptionType[] = [
    {
      value: 50,
      label: '50'
    },
    {
      value: 100,
      label: '100'
    },
    {
      value: 200,
      label: '200'
    },
    {
      value: 500,
      label: '500'
    }
  ];

  const quantityInput = (
    <MBSSelect
      options={quantityOptions}
      isSearchable={false}
      menuPortalTarget={document.body}
      value={quantityOptions.find((option) => option.value === cartItem.quantity)}
      onChange={handleChangeQuantity}
    />
  );

  const specsBlock = (
    <>
      <div className="cart-block-cartitem-prodinfo-specs-block"></div>
      <div className="cart-block-cartitem-prodinfo-specs-block"></div>
    </>
  );

  return (
    <CartItem
      itemId={cartItem.id}
      type={cartItem.type}
      sourceCountry={sourceCountry}
      image={image}
      title={title}
      subtitle={subtitle}
      specifications={specsBlock}
      quantityInput={quantityInput}
      currency={currency}
      pricing={cartItem.pricing}
      quantity={cartItem.quantity}
    ></CartItem>
  );
}
