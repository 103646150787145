import React from 'react';
import Buybutton from '../../Buybutton';

function TopbookContent(props) {
	let isbnText = '';
	if (props.bookData.isbn && props.bookData.isbn.length > 0) {
		isbnText = props.translations.isbnlabel + ' ' + props.bookData.isbn;
	}

	const nopagesText = (
		props.bookData.noPages > 0
		? props.bookData.noPages + ' ' + props.translations.nopageslabel
		: '');

	return(
		<div className="cw-topbook-content">
			<h2>{props.bookData.title}</h2>
			{props.bookData.subtitle && <h3>{props.bookData.subtitle}</h3>}
			<p className="cw-topbook-description">{props.bookData.descriptionShort}</p>
			<div className="cw-topbook-pricebinding">
				<span className="cw-topbook-pricebinding-price">{props.bookData.price}</span>
				<span className="cw-topbook-pricebinding-binding">{props.bookData.bindingText}</span>
			</div>
			<div className="cw-topbook-buybutton"><Buybutton url={props.bookData.url} bookData={props.bookData} buttonType="full" label={props.translations.buybuttonFull} highlight={true}/></div>
			<div className="cw-topbook-isbnpages">
				{isbnText}
				{props.bookData.noPages > 0 && 
					<br />
				}
				{nopagesText}
			</div>
		</div>
	)
}
export default TopbookContent;