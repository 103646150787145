import { useEffect, useState } from 'react';
import { ShippingMethod, setShippingMethod } from '../../../checkoutSlice';
import { useAppDispatch, useAppSelector, useGetLanguage } from '../../../../hooks';
import {
  CardSelector,
  CardSelectorItem,
  EGridColType,
  FormItem,
  GridCol,
  Loading
} from 'mbs-common';
import { useIntl } from 'react-intl';
import { minorUnitsToCurrency } from '../../../../helper/localize';

type ShippingMethodInputProps = {
  selectedShippingMethod: string;
  shippingOptions: ShippingMethod[];
};
export default function ShippingMethodInput({
  selectedShippingMethod,
  shippingOptions
}: ShippingMethodInputProps) {
  const vatSpecification = useAppSelector((state) => state.checkout.vatSpecification);
  const [currentShippingMethod, setCurrentShippingMethod] = useState(selectedShippingMethod);
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const language = useGetLanguage();

  useEffect(() => {
    setCurrentShippingMethod(selectedShippingMethod);
  }, [selectedShippingMethod]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(setShippingMethod(currentShippingMethod));
  };

  const handleChangeShippingMethod = (value: string) => {
    dispatch(setShippingMethod(value));
    setCurrentShippingMethod(value);
  };

  return (
    <>
      {vatSpecification ? (
        <form onSubmit={handleSubmit} className="grid2 formgrid">
          <GridCol type={[EGridColType.Default12]}>
            <FormItem
              label={intl.formatMessage({
                id: 'Checkout-CheckoutDetails-ShippingMethod-SelectMethod-label',
                defaultMessage: 'Select your preferred shipping method'
              })}
            >
              <CardSelector
                id="shippingmethod-cardselect"
                value={currentShippingMethod}
                handleChange={handleChangeShippingMethod}
              >
                {shippingOptions.map((item, index) => (
                  <CardSelectorItem
                    key={`shippingmethod-cardselectitem-${index}`}
                    value={item.id}
                    label={item.name}
                    description={minorUnitsToCurrency({
                      language,
                      amount: item.value,
                      currency: item.currency,
                      multiplier: 1 + vatSpecification.shipping[item.id].vatRate
                    })}
                  />
                ))}
              </CardSelector>
            </FormItem>
          </GridCol>
        </form>
      ) : (
        <Loading container={true} size="40px" />
      )}
    </>
  );
}
