// import { useKeycloak } from '@react-keycloak/web';
import { useAppSelector } from '../../hooks';
import Email from './Email';
import ShippingAddress from './ShippingAddress';
import BillingAddress from './BillingAddress';
import ShippingMethod from './ShippingMethod';
import { Link, useRouteMatch } from 'react-router-dom';
import { Block, Loading } from 'mbs-common';
import { FormattedMessage } from 'react-intl';
import { useRequestCheckoutSpecificationMutation } from '../../api/apiSlice';

enum CheckoutStages {
  Email = 1,
  ShippingAddress = 2,
  BillingAddress = 3,
  ShippingMethod = 4,
  StepsDone = 5
}

export default function CheckoutDetails() {
  const checkoutState = useAppSelector((state) => state.checkout);
  // const { keycloak } = useKeycloak();
  const [
    triggerCheckoutSpec,
    { isSuccess: checkoutSpecIsSuccess, isLoading: checkoutSpecIsLoading, isError: checkoutSpecIsError }
  ] = useRequestCheckoutSpecificationMutation({ fixedCacheKey: 'RequestCheckoutSpec' });

  const currentStage =
    checkoutState.specification &&
    checkoutState.selectedShippingMethod &&
    (checkoutState.billingAddressSameAsShipping ||
      (!checkoutState.billingAddressSameAsShipping && checkoutState.billingAddress)) &&
    checkoutState.shippingAddress &&
    checkoutState.email
      ? CheckoutStages.StepsDone
      : checkoutState.specification &&
        (checkoutState.billingAddressSameAsShipping ||
          (!checkoutState.billingAddressSameAsShipping && checkoutState.billingAddress)) &&
        checkoutState.shippingAddress &&
        checkoutState.email
      ? CheckoutStages.ShippingMethod
      : checkoutState.shippingAddress && checkoutState.email
      ? CheckoutStages.BillingAddress
      : checkoutState.email
      ? CheckoutStages.ShippingAddress
      : CheckoutStages.Email;
  return (
    <div>
      {/* Details component:
      <pre>{JSON.stringify(checkoutState, null, 2)}</pre>
      currentStage: {currentStage} */}
      {currentStage >= CheckoutStages.Email &&
        // !keycloak.authenticated &&
        !window.__ISAUTHENTICATED__ && <Email currentEmail={checkoutState.email} />}
      {currentStage >= CheckoutStages.ShippingAddress && (
        <ShippingAddress currentAddress={checkoutState.shippingAddress} />
      )}
      {currentStage >= CheckoutStages.BillingAddress && (
        <BillingAddress billingAddressSameAsShipping={checkoutState.billingAddressSameAsShipping} />
      )}
      {!checkoutSpecIsLoading && checkoutSpecIsSuccess ? (
        <>
          {currentStage >= CheckoutStages.ShippingMethod && <ShippingMethod />}
          {currentStage >= CheckoutStages.StepsDone && (
            <Block>
              <Link to={`/checkout/overview`} className="app-button-cta">
                <FormattedMessage
                  id="Checkout-CheckoutDetails-Button-NextStep"
                  defaultMessage="Next step"
                />
              </Link>
            </Block>
          )}
        </>
      ) : !checkoutSpecIsLoading && checkoutSpecIsError ? (
        <Block>
          <div className="flash-error">
            <FormattedMessage
              id="Checkout-Error-CheckoutSpecApiError"
              defaultMessage="There was an unexpected error during the checkout process. Please contact support and include a screenshot of this page."
            />
          </div>
        </Block>
      ) : checkoutSpecIsLoading ? (
        <Loading container={true} size="40px" />
      ) : (
        <></>
      )}
    </div>
  );
}
