import { SVGIcon } from 'mbs-common';
import { CartCurrency, CartItemIsbn } from '../cartSlice';
import CartItem from './CartItem';
import { useIntl } from 'react-intl';

export default function IsbnItem({
  cartItem,
  currency,
  sourceCountry
}: {
  cartItem: CartItemIsbn;
  currency: CartCurrency;
  sourceCountry: string;
}) {
  const intl = useIntl();
  const image = <SVGIcon name="app/ProductImages/Isbn" width="80" />;
  const title = intl.formatMessage({
    id: 'Checkout-Cart-CartItem-Title-Isbn',
    defaultMessage: 'ISBN'
  });
  const subtitle = intl.formatMessage(
    {
      id: 'Checkout-Cart-CartItem-Forbook',
      defaultMessage: 'For book: {bookTitle}'
    },
    { bookTitle: cartItem.isbnSpecs.bookTitle }
  );
  //const quantityInput = <>{cartItem.quantity}</>;

  const quantityInput = (
    <div className="cart-block-cartitems-amounts-fixedamount">{cartItem.quantity}</div>
  );

  const specsBlock = (
    <>
      <div className="cart-block-cartitem-prodinfo-specs-block"></div>
      <div className="cart-block-cartitem-prodinfo-specs-block"></div>
    </>
  );

  return (
    <CartItem
      itemId={cartItem.id}
      type={cartItem.type}
      sourceCountry={sourceCountry}
      image={image}
      title={title}
      subtitle={subtitle}
      specifications={specsBlock}
      quantityInput={quantityInput}
      currency={currency}
      pricing={cartItem.pricing}
      quantity={cartItem.quantity}
    ></CartItem>
  );
}
