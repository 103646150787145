import React from 'react';
import Buybutton from '../Buybutton';

export default function BookBuyBlock(props) {
	let isbnText = '';
	if (props.book.isbn && props.book.isbn.length > 0) {
		isbnText = props.translations.isbnlabel + ' ' + props.book.isbn;
	}

	const nopagesText = (
		props.book.noPages > 0
		? props.book.noPages + ' ' + props.translations.nopageslabel
		: '');

	return(
		<div className="cw-bookdetails-buyblock">
			<h2>{props.book.title}</h2>
			{props.book.subtitle && <h3>{props.book.subtitle}</h3>}
			<p className="cw-bookdetails-buyblock-description">{props.book.descriptionShort}</p>
			<div className="cw-bookdetails-pricebinding">
				<span className="cw-bookdetails-pricebinding-price">{props.book.price}</span>
				<span className="cw-bookdetails-pricebinding-binding">{props.book.bindingText}</span>
			</div>
			<div className="cw-bookdetails-buybutton"><Buybutton url={props.book.url} bookData={props.book} buttonType="full" label={props.translations.buybuttonFull} /></div>
			<div className="cw-bookdetails-isbnpages">
			{isbnText}
				{props.book.noPages > 0 && 
					<br />
				}
				{nopagesText}
			</div>
		</div>
	);
}