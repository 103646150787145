import { AdyenCheckoutData, CheckoutState } from '../checkoutSlice';
import useLocalStorageState from 'use-local-storage-state';
import AdyenCheckout from '@adyen/adyen-web';
import { CoreOptions } from '@adyen/adyen-web/dist/types/core/types';
import '@adyen/adyen-web/dist/adyen.css';
import { useEffect, useMemo, useRef } from 'react';
import { getRedirectUrl } from '../CheckoutResult/getRedirectUrl';
import { useHistory } from 'react-router-dom';

export default function AdyenHandler({
  checkoutData,
  paymentData
}: {
  checkoutData: CheckoutState;
  paymentData: AdyenCheckoutData;
}) {
  const paymentContainer = useRef(null);
  const [localorder, setLocalOrder] = useLocalStorageState(`order-${paymentData.orderId}`);
  const [localorderPaymentSession, setLocalorderPaymentSession] = useLocalStorageState(
    `order-${paymentData.orderId}-paymentSession`
  );
  const history = useHistory();

  const configuration = useMemo<CoreOptions>(() => {
    return {
      environment: paymentData.environment,
      session: paymentData.session,
      clientKey: paymentData.clientKey,
      analytics: {
        enabled: false
      },
      beforeSubmit: async (data, components, actions) => {
        setLocalOrder(checkoutData);
        setLocalorderPaymentSession(paymentData);
        actions.resolve(data);
      },
      onPaymentCompleted: (response, component) => {
        const resultCode = response.resultCode;
        const redirecturl = getRedirectUrl(resultCode, paymentData.orderId);
        history.replace(redirecturl);
      },
      onError: (error, component) => {
        console.error(error.name, error.message, error.stack, component);
      },
      paymentMethodsConfiguration: {
        card: {
          hasHolderName: true,
          holderNameRequired: true
          // billingAddressRequired: true
        }
      }
    };
  }, [paymentData]);
  // const configuration: CoreOptions = {
  //   environment: paymentData.environment,
  //   session: paymentData.session,
  //   clientKey: paymentData.clientKey,
  //   analytics: {
  //     enabled: false
  //   },
  //   beforeSubmit: async (data, components, actions) => {
  //     console.log('onsubmit called');
  //     setLocalOrder(checkoutData);
  //     console.log(localorder);
  //     actions.resolve(data);
  //   },
  //   onPaymentCompleted: (response, component) => {
  //     console.log('onPaymentCompleted', response, component);
  //   },
  //   onError: (error, component) => {
  //     console.error(error.name, error.message, error.stack, component);
  //   },
  //   paymentMethodsConfiguration: {
  //     card: {
  //       hasHolderName: true,
  //       holderNameRequired: true
  //       // billingAddressRequired: true
  //     }
  //   }
  // };

  useEffect(() => {
    let ignore = false;
    const createCheckout = async () => {
      const checkout = await AdyenCheckout(configuration);
      //if(isMounted.current === true) return;
      if (ignore === true) return;

      if (paymentContainer.current) {
        checkout.create('dropin').mount(paymentContainer.current);
      }
    };

    createCheckout();

    return () => {
      ignore = true;
    };
  }, [configuration]);

  return (
    <div className="payment-container">
      <div ref={paymentContainer} className="payment"></div>
    </div>
  );
}
