import React, { useState, useEffect } from 'react';
import ContentContainer from '../ContentContainer';
import Searchbox from './Searchbox';
import BookItem from '../BookItem';

function AllbooksComponent(props) {
	useEffect(() => {
		props.setShowBackbutton(true);
		props.setPagePath('/allbooks');
	});
	const [searchFilter, setSearchFilter ] = useState("");
	const [filteredBooks, setFilteredBooks] = useState(false);
	const allbooks = (props.authorData.books ? props.authorData.books : false);

	function handleSearch(e) {
		const searchValue = e.target.value;	
		const searchValueLower = searchValue.toLowerCase();
		setSearchFilter(searchValue);
		if(allbooks) {
			if(searchValue) {
				setFilteredBooks(allbooks.filter(obj => (obj.title.toLowerCase().includes(searchValueLower) ||
				obj.subtitle.toLowerCase().includes(searchValueLower) ||
				obj.author.toLowerCase().includes(searchValueLower) ||
				obj.bindingText.toLowerCase().includes(searchValueLower) ||
				obj.keywords.toLowerCase().includes(searchValueLower) || 
				obj.isbn.includes(searchValueLower)
			)));
			} else {
				setFilteredBooks(false);
			}
		} else {
			setFilteredBooks(false);
		}
	}

	let renderBooks = undefined;
	if(filteredBooks) {
		renderBooks = filteredBooks.map((item) =>
			<li key={item.id}><BookItem book={item} translations={props.authorData.translations} /></li>
		);
	} else {
		if(allbooks) {
			renderBooks = allbooks.map((item) =>
				<li key={item.id}><BookItem book={item} translations={props.authorData.translations} /></li>
			);
		}
	}

	return(
		<main className="cw-main-container">
			<ContentContainer>
				<h2>{props.authorData.translations.titleAllbooks}</h2>
				{allbooks && <Searchbox onChange={handleSearch} translations={props.authorData.translations}/>}
				{renderBooks ? (
					<div className="cw-allbooks-container">
						<ul className="cw-allbooks">
							{renderBooks}
						</ul>
					</div>) :
					<div className="cw-allbooks-container">
						{props.authorData.translations.textNoBooks}
					</div>
				}
			</ContentContainer>
		</main>
	);
}

export default AllbooksComponent;