import React from 'react';

function BookImage(props) {

	return(
		<div className="cw-bookdetails-image-container">
			<img src={props.imageUrl} alt={props.bookTitle}/>
		</div>
	)
}

export default BookImage;