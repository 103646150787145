export type CountryOptionType = {
  value: string;
  label: string;
};

export const postalCodeValidators: Record<string, RegExp> = {
  AT: /^[0-9]{4}$/,
  BE: /^[1-9]{1}[0-9]{3}$/,
  BG: /^[0-9]{4}$/,
  CA: /^[A-Z][0-9][A-Z] [0-9][A-Z][0-9]$/,
  CH: /^[1-9]{1}[0-9]{3}$/,
  CY: /^[0-9]{4}$/,
  CZ: /^[1-7][0-9]{2} [0-9]{2}|[1-7][0-9]{4}$/,
  DE: /^[0-9]{5}$/,
  DK: /^[0-9]{4}$/,
  EE: /^[0-9]{5}$/,
  ES: /^[0-9]{5}$/,
  FI: /^[0-9]{5}$/,
  FR: /^[0-9]{5}$/,
  GB: /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})$/,
  GR: /^[0-9]{3} [0-9]{2}$/,
  HR: /^[0-9]{5}$/,
  HU: /^[0-9]{4}$/,
  IE: /^[A-Z0-9]{3} [A-Z0-9]{4}$/,
  IT: /^[0-9]{5}$/,
  LT: /^[0-9]{5}$/,
  LU: /^[0-9]{4}$/,
  LV: /^(LV-)?[0-9]{4}$/,
  MC: /^[0-9]{5}$/,
  MT: /^[A-Z]{3} [0-9]{4}$/,
  NL: /^[1-9]{1}[0-9]{3}\s?[a-zA-Z]{2}$/,
  PL: /^[0-9]{2}-[0-9]{3}$/,
  PT: /^[0-9]{4}-[0-9]{3}$/,
  RO: /^[0-9]{6}$/,
  SE: /^[0-9]{3} [0-9]{2}$/,
  SI: /^[0-9]{4}$/,
  SK: /^[0-9]{3} [0-9]{2}$/,
  US: /^[0-9]{5}(-[0-9]{4})?$/
};

export function getPostalCodeValidator(country: string): RegExp | false {
  if (postalCodeValidators[country]) {
    return postalCodeValidators[country];
  }

  return false;
}

export function getPostalCodeInputType(country: string): string {
  let inputType = 'text';
  switch (country) {
    case 'BE':
    case 'BG':
    case 'CH':
    case 'CY':
    case 'DE':
    case 'ES':
    case 'FR':
    case 'HR':
    case 'HU':
    case 'IT':
    case 'LT':
    case 'LU':
    case 'MC':
    case 'RO':
    case 'SI':
      inputType = 'tel';
      break;
  }
  return inputType;
}

export function getCanariasValidator(): RegExp {
  return /^(35+)|(38+)/g;
}

export const IsoCountryCodes = [
  'AD', //Andorra
  'AE', //Verenigde Arabische Emiraten
  'AF', //Afghanistan
  'AG', //Antigua en Barbuda
  'AI', //Anguilla
  'AL', //Albanië
  'AM', //Armenië
  'AO', //Angola
  'AQ', //Antarctica
  'AR', //Argentinië
  'AS', //Amerikaans-Samoa
  'AT', //Oostenrijk
  'AU', //Australië
  'AW', //Aruba
  'AX', //Åland
  'AZ', //Azerbeidzjan
  'BA', //Bosnië en Herzegovina
  'BB', //Barbados
  'BD', //Bangladesh
  'BE', //België
  'BF', //Burkina Faso
  'BG', //Bulgarije
  'BH', //Bahrein
  'BI', //Burundi
  'BJ', //Benin
  'BL', //Saint-Barthélemy
  'BM', //Bermuda
  'BN', //Brunei
  'BO', //Bolivia
  'BQ', //Caribisch Nederland
  'BR', //Brazilië
  'BS', //Bahama’s
  'BT', //Bhutan
  'BV', //Bouvet
  'BW', //Botswana
  'BY', //Belarus
  'BZ', //Belize
  'CA', //Canada
  'CC', //Cocoseilanden
  'CD', //Congo-Kinshasa
  'CF', //Centraal-Afrikaanse Republiek
  'CG', //Congo-Brazzaville
  'CH', //Zwitserland
  'CI', //Ivoorkust
  'CK', //Cookeilanden
  'CL', //Chili
  'CM', //Kameroen
  'CN', //China
  'CO', //Colombia
  'CR', //Costa Rica
  'CU', //Cuba
  'CV', //Kaapverdië
  'CW', //Curaçao
  'CX', //Christmaseiland
  'CY', //Cyprus
  'CZ', //Tsjechië
  'DE', //Duitsland
  'DJ', //Djibouti
  'DK', //Denemarken
  'DM', //Dominica
  'DO', //Dominicaanse Republiek
  'DZ', //Algerije
  'EC', //Ecuador
  'EE', //Estland
  'EG', //Egypte
  'EH', //Westelijke Sahara
  'ER', //Eritrea
  'ES', //Spanje
  'ET', //Ethiopië
  'FI', //Finland
  'FJ', //Fiji
  'FK', //Falklandeilanden
  'FM', //Micronesia
  'FO', //Faeröer
  'FR', //Frankrijk
  'GA', //Gabon
  'GB', //Verenigd Koninkrijk
  'GD', //Grenada
  'GE', //Georgië
  'GF', //Frans-Guyana
  'GG', //Guernsey
  'GH', //Ghana
  'GI', //Gibraltar
  'GL', //Groenland
  'GM', //Gambia
  'GN', //Guinee
  'GP', //Guadeloupe
  'GQ', //Equatoriaal-Guinea
  'GR', //Griekenland
  'GS', //Zuid-Georgia en de Zuidelijke Sandwicheilanden
  'GT', //Guatemala
  'GU', //Guam
  'GW', //Guinee-Bissau
  'GY', //Guyana
  'HK', //Hongkong SAR van China
  'HM', //Heard en McDonaldeilanden
  'HN', //Honduras
  'HR', //Kroatië
  'HT', //Haïti
  'HU', //Hongarije
  'ID', //Indonesië
  'IE', //Ierland
  'IL', //Israël
  'IM', //Isle of Man
  'IN', //India
  'IO', //Brits Indische Oceaanterritorium
  'IQ', //Irak
  'IR', //Iran
  'IS', //IJsland
  'IT', //Italië
  'JE', //Jersey
  'JM', //Jamaica
  'JO', //Jordanië
  'JP', //Japan
  'KE', //Kenia
  'KG', //Kirgizië
  'KH', //Cambodja
  'KI', //Kiribati
  'KM', //Comoren
  'KN', //Saint Kitts en Nevis
  'KP', //Noord-Korea
  'KR', //Zuid-Korea
  'KW', //Koeweit
  'KY', //Kaaimaneilanden
  'KZ', //Kazachstan
  'LA', //Laos
  'LB', //Libanon
  'LC', //Saint Lucia
  'LI', //Liechtenstein
  'LK', //Sri Lanka
  'LR', //Liberia
  'LS', //Lesotho
  'LT', //Litouwen
  'LU', //Luxemburg
  'LV', //Letland
  'LY', //Libië
  'MA', //Marokko
  'MC', //Monaco
  'MD', //Moldavië
  'ME', //Montenegro
  'MF', //Sint-Maarten
  'MG', //Madagaskar
  'MH', //Marshalleilanden
  'MK', //Noord-Macedonië
  'ML', //Mali
  'MM', //Myanmar
  'MN', //Mongolië
  'MO', //Macao SAR van China
  'MP', //Noordelijke Marianen
  'MQ', //Martinique
  'MR', //Mauritanië
  'MS', //Montserrat
  'MT', //Malta
  'MU', //Mauritius
  'MV', //Maldiven
  'MW', //Malawi
  'MX', //Mexico
  'MY', //Maleisië
  'MZ', //Mozambique
  'NA', //Namibië
  'NC', //Nieuw-Caledonië
  'NE', //Niger
  'NF', //Norfolk
  'NG', //Nigeria
  'NI', //Nicaragua
  'NL', //Nederland
  'NO', //Noorwegen
  'NP', //Nepal
  'NR', //Nauru
  'NU', //Niue
  'NZ', //Nieuw-Zeeland
  'OM', //Oman
  'PA', //Panama
  'PE', //Peru
  'PF', //Frans-Polynesië
  'PG', //Papoea-Nieuw-Guinea
  'PH', //Filipijnen
  'PK', //Pakistan
  'PL', //Polen
  'PM', //Saint-Pierre en Miquelon
  'PN', //Pitcairn
  'PR', //Puerto Rico
  'PS', //Palestijnse gebieden
  'PT', //Portugal
  'PW', //Palau
  'PY', //Paraguay
  'QA', //Qatar
  'RE', //Réunion
  'RO', //Roemenië
  'RS', //Servië
  'RU', //Rusland
  'RW', //Rwanda
  'SA', //Saoedi-Arabië
  'SB', //Salomonseilanden
  'SC', //Seychellen
  'SD', //Soedan
  'SE', //Zweden
  'SG', //Singapore
  'SH', //Sint-Helena
  'SI', //Slovenië
  'SJ', //Svalbard en Jan Mayen
  'SK', //Slowakije
  'SL', //Sierra Leone
  'SM', //San Marino
  'SN', //Senegal
  'SO', //Somalië
  'SR', //Suriname
  'SS', //Zuid-Soedan
  'ST', //Sao Tomé en Principe
  'SV', //El Salvador
  'SX', //Sint-Maarten (Nederlands deel)
  'SY', //Syrië
  'SZ', //Swaziland
  'TC', //Turks- en Caicoseilanden
  'TD', //Tsjaad
  'TF', //Franse Gebieden in de zuidelijke Indische Oceaan
  'TG', //Togo
  'TH', //Thailand
  'TJ', //Tadzjikistan
  'TK', //Tokelau
  'TL', //Oost-Timor
  'TM', //Turkmenistan
  'TN', //Tunesië
  'TO', //Tonga
  'TR', //Turkije
  'TT', //Trinidad en Tobago
  'TV', //Tuvalu
  'TW', //Taiwan
  'TZ', //Tanzania
  'UA', //Oekraïne
  'UG', //Oeganda
  'UM', //Kleine afgelegen eilanden van de Verenigde Staten
  'US', //Verenigde Staten
  'UY', //Uruguay
  'UZ', //Oezbekistan
  'VA', //Vaticaanstad
  'VC', //Saint Vincent en de Grenadines
  'VE', //Venezuela
  'VG', //Britse Maagdeneilanden
  'VI', //Amerikaanse Maagdeneilanden
  'VN', //Vietnam
  'VU', //Vanuatu
  'WF', //Wallis en Futuna
  'WS', //Samoa
  'XK', //Kosovo
  'YE', //Jemen
  'YT', //Mayotte
  'ZA', //Zuid-Afrika
  'ZM', //Zambia
  'ZW' //'Zimbabwe'
];

export const IsoEUCountryCodes = [
  'AT', // Austria
  'BE', // Belgium
  'BG', // Bulgaria
  'HR', // Croatia
  'CY', // Cyprus
  'CZ', // Czech Republic
  'DK', // Denmark
  'EE', // Estonia
  'FI', // Finland
  'FR', // France
  'DE', // Germany
  'GR', // Greece
  'HU', // Hungary
  'IE', // Ireland
  'IT', // Italy
  'LV', // Latvia
  'LT', // Lithuania
  'LU', // Luxembourg
  'MT', // Malta
  'NL', // Netherlands
  'PL', // Poland
  'PT', // Portugal
  'RO', // Romania
  'SK', // Slovakia
  'SI', // Slovenia
  'ES', // Spain
  'SE' // Sweden
];
