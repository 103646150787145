import { FormattedMessage } from 'react-intl';
import { useAppSelector, useGetLanguage } from '../../../hooks';
import { minorUnitsToCurrency } from '../../../helper/localize';

export default function TotalsBlock() {
  const { totalAmounts, specification } = useAppSelector((state) => state.checkout);
  const language = useGetLanguage();

  return totalAmounts && specification ? (
    <div className="chkt-ordsdbr-totals">
      <div className="chkt-ordsdbr-totals-shipping">
        <FormattedMessage
          id="Checkout-OrderSidebar-totals-Shipping"
          defaultMessage="Shipping: <span>{price}</span>"
          values={{
            span: (chunks: any) => <span>{chunks}</span>,
            price: minorUnitsToCurrency({
              language,
              amount: totalAmounts.shippingTotalExclVat + totalAmounts.shippingVat,
              currency: specification.currency
            })
          }}
        />
      </div>
      <div className="chkt-ordsdbr-totals-total">
        <FormattedMessage
          id="Checkout-OrderSidebar-totals-Total"
          defaultMessage="Total: <span>{price}</span>"
          values={{
            span: (chunks: any) => <span>{chunks}</span>,
            price: minorUnitsToCurrency({
              language,
              amount: totalAmounts.totalInclVat,
              currency: specification.currency
            })
          }}
        />
      </div>
      <div className="chkt-ordsdbr-totals-vat">
        <FormattedMessage
          id="Checkout-OrderSidebar-totals-IncludedVAT"
          defaultMessage="Included VAT: <span>{price}</span>"
          values={{
            span: (chunks: any) => <span>{chunks}</span>,
            price: minorUnitsToCurrency({
              language,
              amount: totalAmounts.productVat + totalAmounts.shippingVat,
              currency: specification.currency
            })
          }}
        />
      </div>
    </div>
  ) : (
    <></>
  );
}
