import React from 'react';
import ReactDOM from 'react-dom';
//import './cw_base2.css';
import App from './App';
import ScrollToTop from './ScrollToTop';
import * as serviceWorker from './serviceWorker';
import { HashRouter, BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store';

//ReactDOM.render(<HashRouter><ScrollToTop/><App /></HashRouter>, document.getElementById('root'));
// not flawless yet, because if there is no slash in the idParam, it doesnt work.
/* const urlParams = new URLSearchParams(window.location.search);
const idParam = (urlParams.get("id") ?
		(urlParams.get("id").indexOf('/') > 0 ? 
	 		urlParams.get("id").substr(0,urlParams.get("id").indexOf("/")) : 
			urlParams.get("id")
		) :
	urlParams.get("id"));
const baseUrl = "/site/index.php?r=userwebsite/indexnew&id="+idParam;*/
if(document.getElementById('root')?.dataset.baseurl) {

}
const rootElementDataset = document.getElementById('root')?.dataset;
const baseUrl = rootElementDataset ? rootElementDataset.baseurl : '';

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter basename={baseUrl}>
			<ScrollToTop/>
			<App />
		</BrowserRouter>
	</Provider>
	, document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
