import React, { useEffect } from 'react';
import ContentContainer from '../ContentContainer';
import Topbook from './Topbook';
import AboutAuthor from './AboutAuthor';
import Latestbooks from './Latestbooks';

function HomeComponent(props) {
	useEffect(() => {
		props.setShowBackbutton(false);
		props.setPagePath('/');
	});
	

	return(
		<main className="cw-main-container">
			{(props.authorData.topBook &&
				<ContentContainer sectionType="darkgrey">
					<Topbook bookData={props.authorData.topBook} translations={props.authorData.translations} />
				</ContentContainer>
			)}
			
			<ContentContainer sectionType="white">
				<AboutAuthor translations={props.authorData.translations} biography={props.authorData.biography} />
			</ContentContainer>
			{(props.authorData.books && props.authorData.books.length > 1) &&
				<ContentContainer>
					<Latestbooks amount={4} booksData={props.authorData.books} translations={props.authorData.translations} />
				</ContentContainer>
			}
			
		</main>
		
	);
}

export default HomeComponent;