import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from '../store';
import { useEffect, useState } from 'react';
import {
  CartLoading,
  CartStateFailed,
  CartStateIdle,
  CartStateLoading,
  CartStateSucceeded,
  ProductTypes,
  fetchCart
} from '../Cart/cartSlice';
import VATRATE, { VATRateReturnType, VATRatesObject } from '../helper/VATRate';
import { useGetUserProfileQuery, useGetVATRatesQuery } from '../api/apiSlice';

// Replacement hooks for redux useDispatch and useSelector hooks
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useGetLanguage = () => {
  const i18nSettings = useAppSelector((state) => state.i18n);
  const language =
    'language' in i18nSettings && i18nSettings.language
      ? i18nSettings.language
      : navigator.language;

  return language as string;
};

export const useGetCountry = () => {
  const i18nSettings = useAppSelector((state) => state.i18n);
  const country = 'country' in i18nSettings && i18nSettings.country ? i18nSettings.country : 'NL';

  return country as string;
};

export const useGetCartQuery = ():
  | {
      data: CartStateSucceeded;
      isUninitialized: false;
      isLoading: false;
      isError: false;
      isSuccess: true;
    }
  | {
      data: CartStateIdle;
      isUninitialized: true;
      isLoading: true;
      isError: false;
      isSuccess: false;
    }
  | {
      data: CartStateLoading;
      isUninitialized: false;
      isLoading: true;
      isError: false;
      isSuccess: false;
    }
  | {
      data: CartStateFailed;
      isUninitialized: false;
      isLoading: false;
      isError: true;
      isSuccess: false;
    } => {
  const dispatch = useAppDispatch();
  const loadingStatus = useAppSelector((state) => state.cart.loading);

  const cart = useAppSelector((state) => state.cart);

  useEffect(() => {
    if (loadingStatus === CartLoading.Idle) {
      dispatch(fetchCart());
    }
  }, [loadingStatus, dispatch]);

  if (loadingStatus === CartLoading.Success) {
    return {
      data: cart as CartStateSucceeded,
      isUninitialized: false,
      isLoading: false,
      isError: false,
      isSuccess: true
    };
  }

  if (loadingStatus === CartLoading.Error) {
    return {
      data: cart as CartStateFailed,
      isUninitialized: false,
      isLoading: false,
      isError: true,
      isSuccess: false
    };
  }

  if (
    (loadingStatus === CartLoading.Pending || loadingStatus === CartLoading.Idle) &&
    cart.loading === CartLoading.Pending
  ) {
    return {
      data: cart as CartStateLoading,
      isUninitialized: false,
      isLoading: true,
      isError: false,
      isSuccess: false
    };
  }

  return {
    data: cart as CartStateIdle,
    isUninitialized: true,
    isLoading: true,
    isError: false,
    isSuccess: false
  };
};

export const useGetVATRates = (
  originCountry: string,
  shippingCountry?: string,
  billingCountry?: string,
  isEUCompany?: boolean,
  returnType?: VATRateReturnType
): { data: VATRatesObject; isSuccess: true } | { data: undefined; isSuccess: false } => {
  const [currentRatesObject, setCurrentRatesObject] = useState<VATRatesObject | undefined>(
    undefined
  );
  const { data, isSuccess } = useGetVATRatesQuery();

  useEffect(() => {
    if (isSuccess && shippingCountry && billingCountry && isEUCompany !== undefined) {
      const vatrateModel = new VATRATE(
        originCountry,
        shippingCountry,
        billingCountry,
        isEUCompany,
        data
      );

      const vatrateObject = vatrateModel.getRates(returnType);
      if (vatrateObject) {
        setCurrentRatesObject(vatrateObject);
      }
    }
  }, [billingCountry, data, isEUCompany, isSuccess, originCountry, returnType, shippingCountry]);

  if (currentRatesObject) {
    return { data: currentRatesObject, isSuccess: true };
  } else {
    return { data: undefined, isSuccess: false };
  }
};

export const useGetVATRate = (
  productType: ProductTypes,
  originCountry: string,
  shippingCountry?: string,
  billingCountry?: string,
  isEUCompany?: boolean,
  returnType?: VATRateReturnType
): { data: number; isSuccess: true } | { data: undefined; isSuccess: false } => {
  const vatRatesObject = useGetVATRates(
    originCountry,
    shippingCountry,
    billingCountry,
    isEUCompany,
    returnType
  );

  if (vatRatesObject.isSuccess) {
    return { data: vatRatesObject.data[productType], isSuccess: vatRatesObject.isSuccess };
  } else {
    return vatRatesObject;
  }
};
/*
export const useGetVATRate = (
  productType: ProductTypes,
  originCountry: string,
  shippingCountry?: string,
  billingCountry?: string,
  isEUCompany?: boolean,
  returnType?: VATRateReturnType
): { data: number; isSuccess: true } | { data: undefined; isSuccess: false } => {
  const [currentRate, setCurrentRate] = useState<number | undefined>(undefined);
  const { data, isSuccess } = useGetVATRatesQuery();

  useEffect(() => {
    if (isSuccess && shippingCountry && billingCountry && isEUCompany !== undefined) {
      const vatrateModel = new VATRATE(
        originCountry,
        shippingCountry,
        billingCountry,
        isEUCompany,
        data
      );

      const vatRate = vatrateModel.getRate(productType, returnType);
      if (vatRate) {
        setCurrentRate(vatRate);
      }
    }
  }, [
    billingCountry,
    data,
    isEUCompany,
    isSuccess,
    originCountry,
    productType,
    returnType,
    shippingCountry
  ]);

  if (currentRate) {
    return { data: currentRate, isSuccess: true };
  } else {
    return { data: undefined, isSuccess: false };
  }
};
*/

export const useGetVATRateForUserProfile = (
  productType: ProductTypes,
  originCountry: string,
  returnType?: VATRateReturnType
) => {
  const { data: userProfile, isSuccess: isSuccessUserProfile } = useGetUserProfileQuery();

  const i18nCountry = useAppSelector((state) => state.i18n.country);
  const defaultCountry = i18nCountry ? i18nCountry : 'NL';

  const shippingCountry = isSuccessUserProfile
    ? userProfile.shippingAddress
      ? userProfile?.shippingAddress.l
      : defaultCountry
    : undefined;
  const billingCountry = isSuccessUserProfile
    ? userProfile?.billingAddressSameAsShipping
      ? userProfile.shippingAddress
        ? userProfile.shippingAddress.l
        : defaultCountry
      : userProfile?.billingAddress.l
    : undefined;

  const isEUCompany = userProfile?.hasCompany && userProfile.euCompany && true;
  return useGetVATRate(
    productType,
    originCountry,
    shippingCountry,
    billingCountry,
    isEUCompany,
    returnType
  );
};

export const useGetVATRatesForUserProfile = (
  originCountry: string,
  returnType?: VATRateReturnType
) => {
  const { data: userProfile, isSuccess: isSuccessUserProfile, isError: isErrorUserProfile } = useGetUserProfileQuery();

  const i18nCountry = useAppSelector((state) => state.i18n.country);
  const defaultCountry = useGetCountry();


  const shippingCountry = isSuccessUserProfile
    ? userProfile.shippingAddress
      ? userProfile?.shippingAddress.l
      : defaultCountry
    : isErrorUserProfile
    ? defaultCountry
    : undefined;
  const billingCountry = isSuccessUserProfile
    ? userProfile && userProfile?.billingAddressSameAsShipping
      ? userProfile.shippingAddress
        ? userProfile.shippingAddress.l
        : defaultCountry
      : userProfile?.billingAddress.l
    : isErrorUserProfile
    ? defaultCountry
    : undefined;

  const isEUCompany = userProfile?.hasCompany && userProfile.euCompany ? true : false;
  return useGetVATRates(originCountry, shippingCountry, billingCountry, isEUCompany, returnType);
};
