const messages = {
  'Promotion': 'Promotion',
  'binding-1': 'Couv. rigide',
  'binding-3': 'Couv. souple',
  'binding-6': 'eBook',
  'binding-7': 'PDF',
  'interiorcolor-bw': 'Noir & blanc',
  'interiorcolor-fc': 'Couleurs',
  'interiorcolor-short-bw': 'N&B',
  'interiorcolor-short-fc': 'Coul.',
  'Interior color': 'Couleurs de l’intérieur',
  'Papertype-short': 'Papier',
  'Papertype-short-1': 'Ivoiré',
  'Papertype-short-2': 'Ivoiré',
  'Papertype-short-3': 'Blanc',
  'Papertype-short-4': 'Blanc',
  'Papertype-short-5': 'Couché',
  'Papertype-short-6': 'Couché',
  'Papertype-short-7': 'Blanc',
  'Papertype-short-8': 'Blanc glossy',
  'Papertype-short-9': 'Tinted',
  'Papertype-short-10': '150 grams',
  'Papertype-short-12': 'Blanc',
  'Amountpages-short': 'Pages',
  'Amountcolorpages-short': 'Pages en couleurs',
  'Help-howdoesitwork-question': 'Comment ça marche?',
  'ISBN': 'ISBN',
  'Author website': 'Le site d’auteur',
  'Author webshop': 'Le site d’auteur',
  'authorwebsite-notEnabled': 'Pas activé - {link}',
  'authorwebsite-notEnabled-enablenow': 'Activer maintenant',
  'Link to book page': 'Lien vers la page du livre',
  'button-downloadcover': 'Télécharger la couverture',
  'button-downloadexcerpt': 'Télécharger l’extrait',
  'button-copybooklink': 'Copier le lien du livre',
  'button-copyannouncementtext': 'Copier l’annonce',
  'button-openinstagram': 'Ouvrir l’application Instagram',
  'button-addtoshoppingcart': 'Ajouter au panier',
  'button-orderbooks': 'Commander des livres',
  'button-registerbook': 'S’incrire livre',
  'Channels-availability': 'Disponibilité',
  'Channelname': 'Boutique',
  'Price': 'Prix',
  'Your margin': 'Votre profit',
  'Bookspec-ISBN-isbn': 'ISBN : {isbn}',
  'Bookspec-LastEdited-date': 'Dernière version : {date}',

  'What do you have to do?': 'Que faut-il que vous fassiez ?',
  
  // form labels
  'label-announcementtext': 'Annonce',
  'label-instagram-addbooklinktobio': 'Ajouter le lien vers votre livre à votre profil Instagram',
  'label-instagram-downloadcover': 'Télécharger l’image de votre couverture pour votre post',
  'label-instagram-openinstagram': 'Ouvrir Instagram',
  'label-instagram-chooseanimage': 'Choisir une image',
  'label-attachbooklinktomessage': 'Ajouter automatiquement le lien vers votre livre au message',

  // zod validation errors
  'ZodValidationError-EmailRequired': 'L’adresse e-mail est requise',
  'ZodValidationError-EmailNotValidEmail': 'Remplis une adresse mail valide',
  'ZodValidationError-AddressFnRequired': 'Le prénom est requis',
  'ZodValidationError-AddressFnLongerThan30': 'Le prénom ne peut pas dépasser 30 caractères',
  'ZodValidationError-AddressLnRequired': 'Le nom is requis',
  'ZodValidationError-AddressLnLongerThan30': 'Le nom ne peut pas dépasser 30 caractères',
  'ZodValidationError-AddressSRequired': 'La rue et le numéro de maison sont requis',
  'ZodValidationError-AddressSLongerThan50': 'La rue et le numéro de maison ne peuvent pas dépasser 50 caractères',
  'ZodValidationError-AddressS2LongerThan50': 'La deuxième ligne d’adresse ne peut pas dépasser 50 caractères',
  'ZodValidationError-AddressNrMustBeNumber': 'Le numéro de maison doit être un nombre',
  'ZodValidationError-AddressPRequired': 'Le code postal est requis',
  'ZodValidationError-AddressPLongerThan20': 'Le code postal ne peut pas dépasser 20 caractères',
  'ZodValidationError-AddressCRequired': 'La ville est requise',
  'ZodValidationError-AddressCLongerThan35': 'La ville ne peut pas dépasser 35 caractères',
  'ZodValidationError-AddressRLongerThan35': 'La région ne peut pas dépasser 35 caractères',
  'ZodValidationError-AdressLRequired': 'Le pays est requis',
  'ZodValidationError-AddressCountryNotTwoLetterCode': 'Le pays doit être un code ISO (2 lettres)',
  'ZodValidationError-AddressCpLongerThan35': 'Le nom d’entreprise ne peut pas dépasser 35 caractères',
  'ZodValidationError-AddressTLongerThan20': 'Le numéro de téléphone ne peut pas dépasser 20 caractères',
  'ZodValidationError-AddressFullNameLongerThan40': 'Le nom complet ne peut pas dépasser 40 caractères',
  'ZodValidationError-AddressPostalCodeIncorrect': 'Le code postal est incorrect',
  'ZodValidationError-AddressS2SameAsS': 'La deuxième ligne d’adresse est égale à la première ligne',
  'ZodValidationError-AddressVRequiredForCanarias': 'Le numéro fiscal est obligatoire pour les îles Canaries',

  // guide popup
  'wikiguidepopup-title-selectaguide': 'Choissisez un guide',

  // errors
  'error-booknotpublished': 'Vous ne pouvez pas promouvoir ce livre, car il n’est pas publié. Allez dans ‘mes livres’ pour éditer ce livre.',
  'error-booknotavailable': 'Le livre que vous essayez d’ouvrir n’existe pas. Allez dans “mes livres” et sélectionnez un livre.',
  'error-booknotauthorized': 'Vous n’êtes pas autorisé à accéder à cette page. Si ce message est incorrect, veuillez contacter le support.',
  'error-bookunknownerror': 'Un problème inattendu s’est produit lors du chargement de cette page. Veuillez rafraîchir la page pour réessayer. Si le problème persiste, veuillez contacter le service d’assistance et mentionner l’identifiant du livre : {bookid}',

  // promo popup
  'promoapp-publishedpopup-title': 'Félicitations, vous êtes un auteur publié !',
  'promoapp-publishedpopup-paragraph1': 'Vous avez réussi ! Votre livre est maintenant publié, alors prenez quelques instants pour fêter ça ! Parlez-en à vos amis, faites-vous plaisir et savourez votre réussite.',
  'promoapp-publishedpopup-paragraph2': 'Maintenant que votre livre est en vente, il est temps de passer à l’étape suivante de votre parcours d’auteur : la promotion de votre livre. Naturellement, nous sommes là pour vous aider dans cette tâche. Nos étapes de promotion vous guideront à travers quelques-uns des moyens les plus efficaces de faire connaître votre livre.',
  'promoapp-publishedpopup-startbutton': 'Commencer à promouvoir votre livre',

  // promo categories
  'promoapp-havetodo-title': 'Mesures nécessaires qu’il ne faut pas louper',
  'promoapp-shoulddo-title': 'Mesures recommandées qui vous donneront un coup de pouce',
  'promoapp-coulddo-title': 'Mesures utiles qui pourrait vous aider encore un peu plus',
  'blocktitle-thingsyouneedtopromote': 'Choses dont vous aurez besoin pour promouvoir votre livre',

  // promo overview
  'promoapp-overview-intro-p1': 'Écrire un livre est une grande réussite en soi, mais il est fort probable que vous souhaitiez également le vendre. Pour ce faire, vous allez devoir en faire la promotion. Pour de nombreux auteurs, c’est là que commence le véritable défi. Cela demande un peu de travail et de dévouement, mais vous commencerez à voir les résultats plus rapidement que vous ne le pensez. Pour vous aider à démarrer, nous avons compilé une sélection de conseils, de guides et d’outils pour rendre la promotion de votre livre plus facile et plus efficace.',

  // list of all promotool descriptions
  'promotool-authorwebsite': 'Site d’auteur',
  'promotool-description-authorwebsite': 'Le meilleur moyen de vendre vos livres',
  'promotool-whatsapp': 'WhatsApp',
  'promotool-description-whatsapp': 'Le meilleur moyen de vendre vos livres',
  'promotool-socialmedia': 'Réseaux Sociaux',
  'promotool-description-socialmedia': 'Créez des profils sur les réseaux sociaux pour faire connaître votre livre',
  'promotool-instagram': 'Instagram',
  'promotool-description-instagram': 'Faites la promotion de votre livre à l’aide d’images',
  'promotool-facebook': 'Facebook',
  'promotool-description-facebook': 'Entrez en contact avec des lecteurs potentiels et partagez du contenu lié à votre livre',
  'promotool-twitter': 'Twitter',
  'promotool-description-twitter': 'Utilisez votre sens de la tournure pour promouvoir votre livre',
  'promotool-linkedin': 'LinkedIn',
  'promotool-description-linkedin': 'Documentez votre parcours d’auteur-entrepreneur',
  'promotool-libraries': 'Bibliothèques',
  'promotool-description-libraries': 'Touchez de nouveaux lecteurs en faisant paraître votre livre dans les bibliothèques',
  'promotool-nbdbiblion': 'NBD Biblion',
  'promotool-description-nbdbiblion': 'Get NBD to review your book and get it in the libraries',
  'promotool-nationallibrary': 'Bibliothèque Nationale',
  'promotool-description-nationallibrary': 'Effectuez le dépôt légal de votre livre à la Bibliothèque nationale de France',
  'promotool-launchparty': 'Soirée de lancement',
  'promotool-description-launchparty': 'Soirée de lancement',
  'promotool-bookstores': 'Librairies',
  'promotool-description-bookstores': 'Contactez les librairies et essayez de faire en sorte que votre livre soit disponible',
  'promotool-pressrelease': 'Communiqué de presse',
  'promotool-description-pressrelease': 'Faites connaître votre livre à la presse locale',
  'promotool-businesscards': 'Cartes de visite',
  'promotool-description-businesscards': 'C’est peut-être un peu vieux jeu, mais c’est un bon moyen de se légitimer',

  // launch party
  'launchparty-intro-p1': 'Le lancement d’un livre fait partie intégrante du processus de promotion de votre livre. C’est non seulement un excellent moyen d’attirer l’attention sur le fait que votre livre est publié, mais c’est aussi une façon très agréable de marquer l’occasion et de célébrer une grande réussite avec vos amis et votre famille. Votre événement de lancement de livre peut être aussi grand ou petit que vous le souhaitez, en fonction des objectifs que vous vous êtes fixés. Nous avons élaboré un guide pour un petit lancement de livre informel, ainsi que pour un lancement plus important et plus formel que vous pouvez consulter ci-dessous. Vous pouvez alors déterminer quel type correspond le mieux à vos ambitions personnelles.',
  'launchparty-bookdrinks-title': 'Lancement informel',
  'launchparty-bookdrinks-intro': 'Si vous voulez garder les choses simples et commencer petit, vous pouvez faire de votre lancement un événement plus intime. Il n’est pas nécessaire d’organiser un grand événement dans un lieu loué, avec des orateurs invités et la presse locale. Vous pouvez simplement inviter vos amis, votre famille et vos connaissances à se joindre à vous pour célébrer l’étape que vous avez franchie. Pensez-y comme à une fête pour vous et votre livre. C’est un moyen facile et relativement peu coûteux d’attirer l’attention sur le fait que vous êtes un auteur publié et que vous vendez vos premiers livres. Voici quelques conseils si vous envisagez d’organiser une fête de lancement de livre :',
  'launchparty-bookdrinks-whenandwho-title': 'Qui inviter et quand',
  'launchparty-bookdrinks-whenandwho-paragraph1': 'Dressez une liste de toutes les personnes que vous souhaitez inviter. Vos amis, votre famille, vos voisins et les personnes qui ont contribué à votre livre d’une manière ou d’une autre sont toujours de bons choix. Fixez une date, créez un groupe de chat avec tout ce petit monde et envoyez les invitations ! Veillez à ce que vos messages soient optimistes et accueillants, et n’oubliez pas d’inclure une photo de votre livre !',
  'launchparty-bookdrinks-orderbooks-title': 'Commandez des livres',
  'launchparty-bookdrinks-orderbooks-paragraph1': 'Lorsque vous aurez envoyé les invitations sur le chat, vous aurez probablement assez rapidement une bonne idée du nombre de personnes qui viendront. Partez du principe que chaque invité voudra acheter un ou deux exemplaires et commandez suffisamment de livres à l’avance pour ne pas avoir à vous soucier qu’ils arrivent à temps pour le lancement de votre livre.',
  'launchparty-bookdrinks-agenda-title': 'Programme',
  'launchparty-bookdrinks-agenda-paragraph1': 'Bien que l’idée soit de faire de cet événement un moment informel et spontané, il n’est jamais inutile de le préparer un peu afin d’en faire une expérience mémorable et de donner envie à vos invités d’acheter votre livre. Demandez-vous ce que vous voulez dire, et si vous voulez proposer un toast. Voulez-vous offrir le premier exemplaire de votre livre à une personne spéciale en guise de cadeau ? Peut-être souhaitez-vous raconter à tout le monde votre expérience d’auto-édition ? Dressez une courte liste des points que vous souhaitez aborder lors de l’événement.',
  'launchparty-bookdrinks-foodandhouse-title': 'Rafraîchissements',
  'launchparty-bookdrinks-foodandhouse-paragraph1': 'Veillez à proposer de nombreux encas et boissons à vos invités ; il est important qu’ils puissent s’amuser et se sentir à l’aise. N’oubliez pas de prévoir des boissons sans alcool et tenez compte des éventuelles restrictions alimentaires. Veillez également à ce que votre maison soit impeccable et disposez vos livres de manière à ce qu’ils soient toujours visibles. C’est aussi une bonne idée de demander à quelqu’un de prendre des photos, car elles seront très appréciées des invités et constitueront un excellent matériel promotionnel pour votre livre après l’événement.',
  'launchparty-bookdrinks-enjoy-title': 'Profitez-en !',
  'launchparty-bookdrinks-enjoy-paragraph1': 'Dernier point, mais non des moindres, assurez-vous de profiter de la soirée de lancement de votre livre ! Vos invités sont là pour célébrer une grande réussite avec vous, alors profitez un peu de l’attention ! Vous devrez déterminer à l’avance si vous souhaitez offrir votre livre à chacun de vos invités ou leur demander d’acheter eux-mêmes leur exemplaire. Si cette dernière option peut sembler un peu étrange, il s’agit d’une demande tout à fait normale, et vos invités la comprendront. Les personnes présentes sont proches de vous et voudront vous soutenir après tout !',
  'launchparty-bookpresentation-title': 'Lancement formel',
  'launchparty-bookpresentation-intro': 'Le lancement officiel d’un livre est simplement une façon plus ambitieuse d’organiser une fête de lancement. Bien que vous puissiez toujours l’organiser chez vous, vous pouvez aussi envisager de louer un local à la place ; un café ou une librairie est toujours un bon choix. En outre, vous voudrez inviter la presse locale et peut-être même des influenceurs qui sont actifs dans votre région. Les enjeux sont donc un peu plus élevés, et vous devrez planifier à l’avance et mettre en place un programme solide. Voici quelques éléments auxquels vous devez penser lorsque vous planifiez le lancement de votre livre de manière plus formelle :',
  'launchparty-guestlist-title': 'Choisissez qui inviter',
  'launchparty-guestlist-paragraph1': 'Pour bien commencer, nous vous recommandons de dresser une liste d’invités. Les choix évidents sont les amis, la famille, toute personne ayant contribué à votre livre d’une manière ou d’une autre, et peut-être des collègues ou d’autres personnes de votre réseau professionnel. Vous pouvez généralement compter sur l’enthousiasme des gens pour célébrer cette étape importante avec vous, alors n’hésitez pas à envoyer des invitations ! Si vous commencez par la liste d’invités, il vous sera plus facile de trouver un lieu approprié pour accueillir l’événement.',
  'launchparty-findalocation-title': 'Trouvez un lieu et une date',
  'launchparty-findalocation-paragraph1': 'De nombreux endroits peuvent accueillir un événement de lancement de livre. Vous pouvez choisir de l’organiser depuis votre maison, dans votre café préféré ou dans une librairie. Cette dernière option est notre préférée. Être entouré de livres renforce l’atmosphère et donne encore plus de légitimité à votre ouvrage. Essayez de trouver un endroit bon marché (ou gratuit) et faites participer le propriétaire à l’organisation afin qu’il sache à quoi s’attendre.',
  'launchparty-sendinvites-title': 'Envoyez les invitations',
  'launchparty-sendinvites-paragraph1': 'Si une invitation physique n’est pas nécessairement la norme de nos jours, c’est en soi une super raison de l’essayer. Elle peut conférer une certaine légitimité à l’événement et vous permet de faire des choses amusantes, comme fabriquer des invitations avec la couverture de votre livre. Si une invitation physique n’est pas nécessairement la norme de nos jours, c’est en soi une super raison de l’essayer. Elle peut conférer une certaine légitimité à l’événement et vous permet de faire des choses amusantes, comme fabriquer des invitations avec la couverture de votre livre.',
  'launchparty-createagenda-title': 'Programme',
  'launchparty-createagenda-paragraph1': 'Si une invitation physique n’est pas nécessairement la norme de nos jours, c’est en soi une super raison de l’essayer. Elle peut conférer une certaine légitimité à l’événement et vous permet de faire des choses amusantes, comme fabriquer des invitations avec la couverture de votre livre.',
  'launchparty-createagenda-list1': 'Commencez par un verre de bienvenue et laissez les invités se mélanger jusqu’à ce que tout le monde soit arrivé. Un peu de musique serait également une bonne idée.',
  'launchparty-createagenda-list2': 'Une fois que tous les invités sont arrivés, demandez à un ami ou à une autre personne reconnaissable de souhaiter la bienvenue à tous.',
  'launchparty-createagenda-list3': 'Organisez une séance de questions-réponses au cours de laquelle vos invités pourront vous demander, par exemple, pourquoi vous avez choisi d’écrire un livre, de quoi il parle, où vous avez puisé votre inspiration et si vous avez des anecdotes amusantes sur le processus.',
  'launchparty-createagenda-list4': 'Lisez un passage de votre livre à voix haute à vos invités.',
  'launchparty-createagenda-list5': 'Signez les livres que les gens achètent',
  'launchparty-createagenda-list6': 'N’oubliez pas d’offrir quelques en-cas et surtout beaucoup de convivialité',
  'launchparty-contactmedia-title': 'Media',
  'launchparty-contactmedia-paragraph1': 'Nous vous recommandons vivement d’essayer d’entrer en contact avec les médias locaux ou peut-être même avec des influenceurs basés dans votre région. Demandez-leur s’ils seraient intéressés par la soirée de lancement de votre livre et invitez-les à l’événement. Vous seriez surpris du nombre de fois où cela fonctionne, alors n’hésitez pas à tenter votre chance !',
  'launchparty-refreshments-title': 'Prévoyez des rafraîchissements',
  'launchparty-refreshments-paragraph1': 'Nous vous conseillons de rester simple. Choisissez des plats qui plaisent au public et limitez votre offre. Assurez-vous d’obtenir l’accord préalable du propriétaire de l’établissement. Si vous organisez votre lancement dans un endroit qui propose déjà ce service, ils voudront servir leur propre nourriture et leurs propres boissons. Et si c’est un lieu qui ne sert normalement pas de nourriture et de boissons, il se peut qu’il y ait des règles assez strictes sur ce qui est permis ou non.',
  'launchparty-orderbooks-title': 'Livres',
  'launchparty-orderbooks-paragraph1': 'Veillez à disposer d’un nombre suffisant d’exemplaires de votre livre. Vos invités pourront alors en acheter un pendant l’événement, et vous pourrez le dédicacer pour eux sur place afin de rendre l’expérience plus personnelle. N’oubliez pas que si vous organisez le lancement de votre livre dans une librairie, le propriétaire voudra une part des ventes du livre. Veillez à négocier les détails de ce point à l’avance.',
  'launchparty-photos-title': 'Photos et Vidéos',
  'launchparty-photos-paragraph1': 'Le lancement de votre livre est non seulement un bon moyen de sensibiliser le public et de générer des ventes, mais il peut également constituer un futur matériel de marketing pour vous. C’est pourquoi il est important que vous demandiez à quelqu’un de prendre des photos et/ou d’enregistrer l’événement. Il ne doit pas nécessairement s’agir d’un photographe professionnel. Vous avez probablement un ami qui sait se servir d’un appareil photo. Vous pouvez ensuite sélectionner une partie du matériel pour le publier sur les réseaux sociaux afin de promouvoir votre livre.',
  'launchparty-followup-title': 'Suivi',
  'launchparty-followup-paragraph1': 'Veillez à remercier vos invités d’être venus, au plus tard quelques jours après l’événement. Faites-leur savoir que vous appréciez leur soutien et partagez les photos et vidéos de l’événement. Vous pouvez également les partager avec les personnes qui n’ont pas pu venir, et même inclure un lien vers l’endroit où elles peuvent acheter votre livre. Si vous avez réussi à convaincre des médias locaux ou des influenceurs d’assister à l’événement ou de le couvrir, n’oubliez pas de le faire savoir et d’interagir avec eux sur les réseaux sociaux par la suite.',

  // bookstores
  'bookstores-intro-p1': 'Les librairies restent un canal de vente puissant pour les livres, malgré la domination croissante des achats en ligne. Cependant, comme elles sont en déclin depuis un certain temps, les librairies ont tendance à hésiter à stocker des livres d’auteurs inconnus, car elles considèrent généralement que c’est un risque inutile. Néanmoins, nous vous recommandons de prendre contact avec les librairies locales pour savoir si elles accepteraient de stocker votre livre. Le fait que votre livre soit disponible dans une boutique physique renforce considérablement sa légitimité et constitue une excellente opportunité marketing.',
  'bookstores-intro-p2': 'Si vous parvenez à faire en sorte qu’une librairie stocke votre livre, elle voudra une part des bénéfices si et quand votre livre est vendu. Cette part sera généralement de 20 à 40 %. En outre, il est fort probable qu’elle n’acceptera de stocker votre livre que si cela ne présente pas de risques, ce qui signifie qu’elle se réservera le droit de vous renvoyer les exemplaires invendus. Nous avons créé une liste qui vous donnera un aperçu des éléments à garder à l’esprit lorsque vous abordez les librairies et négociez avec elles.',
  'bookstores-checklist-title': 'Checklist pour les librairies',
  'bookstores-selectbookstores-title': 'Choisissez les bonnes librairies',
  'bookstores-selectbookstores-p1': 'La première étape consiste à dresser une liste des librairies que vous souhaitez contacter. Nous vous recommandons de faire des recherches sur celles qui sont près de chez vous, de créer la liste et de les approcher une par une.',
  'bookstores-contactbookstore-title': 'Contactez les librairies',
  'bookstores-contactbookstore-p1': 'Une fois que vous avez déterminé les librairies où vous aimeriez vendre votre livre, il est temps de les contacter et d’organiser une réunion de présentation.',
  'bookstores-contactbookstore-p2': 'Voici nos conseils pour votre première rencontre :',
  'bookstores-contactbookstore-list1': 'Parlez-leur un peu de vous',
  'bookstores-contactbookstore-list2': 'Parlez-leur de votre livre : de quoi il parle, pourquoi vous l’avez écrit et quel est votre public cible',
  'bookstores-contactbookstore-list3': 'Apportez un exemplaire de votre livre et laissez-le leur. Le propriétaire voudra sans doute examiner le livre avant de le mettre en vente',
  'bookstores-agreement-title': 'Passez/signez un accord avec la librairie',
  'bookstores-agreement-p1': 'Si la librairie est disposée à stocker votre livre, vous devez vous accorder sur plusieurs points :',
  'bookstores-agreement-whobuysbooks': 'Qui paie les livres ?',
  'bookstores-agreement-buy-option1': 'Option 1 : Vous payez les livres et la librairie les vend en votre nom. Il s’agit de l’option la plus intéressante pour la librairie, car elle ne court aucun risque financier. Vous pouvez acheter le livre en utilisant la fonction intégrée de Monbeaulivre et convenir d’une marge bénéficiaire avec la librairie. N’oubliez pas de convenir aussi du moment où vous serez payé et de la manière dont vous serez informé des ventes.',
  'bookstores-agreement-buy-option2': 'Option 2 : la librairie vous achète les livres. C’est la solution la plus simple : vous pouvez tous deux percevoir automatiquement vos marges bénéficiaires respectives. Si vous optez pour cette solution, assurez-vous d’avoir réglé la portée sur ‘national’ dans l’étape de tarification de la plateforme.',
  'bookstores-agreement-howlongavailable': 'Combien de temps le livre sera-t-il disponible à la vente dans la librairie ?',
  'bookstores-agreement-positioninstore': 'Où le livre sera-t-il placé/exposé ?',
  'bookstores-beactive-title': 'Soyez actif',
  'bookstores-beactive-p1': 'Une fois que votre livre est disponible dans la librairie, il est impératif que vous en informiez les gens ! Voici quelques façons de le faire :',
  'bookstores-beactive-list1': 'Organisez votre fête de lancement de livre dans la librairie même. Consultez notre guide sur les lancements de livres pour plus d’informations',
  'bookstores-beactive-list2': 'Annoncez la disponibilité de votre livre dans cette librairie sur les réseaux sociaux, sur votre site web ou en ligne pour un prix réduit',
  'bookstores-beactive-list3': 'Faites des apparitions dans la librairie et annoncez-les à vos lecteurs afin d’attirer l’attention et de stimuler les ventes',

  // press release
  'pressrelease-intro-p1': 'Un communiqué de presse est une déclaration officielle que vous faites en votre qualité d’auteur à l’intention des médias. Dans le monde de l’édition, les communiqués de presse sont surtout utilisés pour annoncer la publication d’un livre. En tant qu’auteur auto-édité, vous n’aurez probablement pas les contacts dont dispose une grande maison d’édition. Mais cela ne doit pas vous arrêter. Vous pouvez toujours vous adresser aux médias locaux ; les journaux et sites d’information locaux sont toujours à la recherche de contenu et vous seriez surpris de l’intérêt qu’ils peuvent porter à un auteur en devenir.',
  'pressrelease-intro-p2': 'Notre plateforme génère automatiquement un communiqué de presse pour vous, sur la base des informations que vous remplissez au cours des différentes étapes de la publication. Les informations pertinentes telles que la couverture, le texte de présentation, l’ISBN, le prix et le lien vers la boutique sont incluses.',
  'pressrelease-intro-p3': 'Si vous souhaitez maximiser les chances d’obtenir une critique, envoyez un exemplaire de votre livre avec le communiqué de presse.',
  'pressrelease-identifytarget-title' : 'Examinez votre public cible',
  'pressrelease-identifytarget-p1': 'Il est important que vous gardiez votre public cible à l’esprit lorsque vous sélectionnez les destinataires de votre communiqué de presse. Il serait absurde d’envoyer un communiqué de presse à un média dont le public cible est complètement différent du vôtre.',
  'pressrelease-identifytarget-p2': 'Bien que nous ayons parlé de médias locaux jusqu’à présent, vous ne devez pas vous limiter inutilement. Vous pouvez également envisager de rechercher des sites Web qui traitent des mêmes sujets que ceux abordés dans votre livre. Plus c’est spécifique, mieux c’est.',
  'pressrelease-collectcontact-title': 'Recueillir les coordonnées des personnes à contacter',
  'pressrelease-collectcontact-p1': 'Il est temps de passer à la partie la plus ennuyeuse du processus : dresser une liste structurée de toutes les personnes que vous devez contacter. Une bonne façon de procéder est de créer une feuille de calcul (Excel ou autre) contenant les noms et adresses des points de vente prometteurs, ainsi que le nom, l’email et le numéro de téléphone d’un contact dans ces points de vente. Dans la mesure du possible, essayez d’obtenir les coordonnées de personnes spécifiques, plutôt que des adresses électroniques génériques et des numéros de téléphone d’entreprise. En fonction de la taille de votre liste, vous pouvez également ajouter des colonnes qui vous permettent de suivre les personnes que vous avez contactées et leur réponse.',
  'pressrelease-sendpressrelease-title': 'Envoyez le communiqué de presse',
  'pressrelease-sendpressrelease-p1': 'Lorsque vous envoyez le communiqué de presse par email, veillez à ce que le texte soit court, convaincant et pertinent. Ne réutilisez pas le texte du communiqué de presse, mais essayez de le rendre suffisamment intéressant pour que votre contact soit tenté d’en savoir plus. L’objectif principal est de faire en sorte que le contact ouvre le communiqué de presse. Veillez à envoyer le communiqué de presse au format PDF. Vous pouvez également joindre un extrait de votre livre au format PDF.',
  'pressrelease-sendpressrelease-p2': 'Pour les points de vente hautement prioritaires, vous pouvez envisager d’envoyer un exemplaire imprimé de votre livre. Il s’agit d’un investissement relativement modeste qui peut faire une énorme différence. Enfin, veillez à indiquer dans votre email que vous êtes disponible pour des interviews.',
  'pressrelease-downloadbutton': 'Télécharger le communiqué de presse',
  'pressrelease-followup-title': 'Suivi',
  'pressrelease-followup-p1': 'Ne vous attendez pas à ce que les gens publient votre communiqué de presse ou écrivent quelque chose sur votre livre automatiquement. Effectuez toujours un suivi pour vérifier s’ils ont fait ou envisagent de faire quelque chose avec votre communiqué de presse. Envisagez de renvoyer votre communiqué de presse quelques semaines plus tard si vous ne recevez pas de réponse.',
  'pressrelease-sharelinks-title': 'Partagez les liens',
  'pressrelease-sharelinks-p1': 'Chaque fois que votre livre est mentionné dans les médias, vous devez le partager sur les réseaux sociaux. Il s’agit d’une promotion gratuite pour votre livre, et plus les gens le voient, plus il y a de chances que quelqu’un achète votre livre.',

  // NBD Biblion
  'nbdbiblion-copyandpastetext': 'Cliquer ici pour copier le texte ci-dessus',

  // Business cards
  'businesscards-intro-p1': 'Créer du matériel promotionnel est un excellent moyen de compléter votre stratégie de marketing. Il peut ajouter une touche tangible et personnelle que beaucoup de gens peuvent apprécier à l’ère du numérique. C’est pourquoi nous vous offrons la possibilité d’imprimer vos propres cartes de visite, sur lesquelles vous pouvez placer une photo de votre livre ainsi que des informations sur l’endroit où l’acheter. Les cartes de visite sont imprimées en couleur sur un carton robuste de 400 grammes. Votre commande vous sera livrée dans un délai de deux semaines.',
  'businesscards-blocktitle-order': 'Commander des cartes de visite',
  'businesscards-preview-label-front': 'Recto',
  'businesscards-preview-label-back': 'Verso',
  'businesscards-numberofcards-label': 'Nombre de cartes de visite',
  'businesscards-selectnumberofcards-item-label': '{number} cartes - {price}',

  // whatsapp
  'whatsapp-intro-p1': 'WhatsApp, ou toute autre application de messagerie que vous utilisez, est probablement l’endroit où vous allez réaliser vos premières ventes. Les personnes que vous touchez via WhatsApp constituent votre réseau personnel et seront donc les plus susceptibles de vous soutenir en tant qu’auteur. Veillez à les impliquer dans le processus de création du livre avant d’annoncer la nouvelle de la publication et de leur demander l’ultime faveur d’acheter votre livre ! Vous pouvez, par exemple, leur montrer vos idées de couverture et leur demander leur avis, ou réfléchir avec eux au titre. Vous pouvez même leur envoyer le texte de présentation à l’avance pour les enthousiasmer.',
  'whatsapp-intro-p2': 'Il peut sembler anodin que vos amis et votre famille achètent votre livre, mais ils peuvent être vos plus grands ambassadeurs. Le bouche à oreille est roi, alors ne négligez pas cette méthode de promotion !',
  'whatsapp-promoteviawhatsapp': 'Promouvoir votre livre via WhatsApp',
  'whatsapp-button-sendviawhatsapp': 'Envoyer via WhatsApp',
  'whatsapp-week1-title': 'Semaine 1 – L’annonce',
  'whatsapp-week1-message': 'Salut tout le monde, je voulais juste vous faire savoir que le livre dont je parlais depuis si longtemps est enfin publié ! J’en suis très heureux/se et j’apprécierais vraiment que vous fassiez passer le mot si vous connaissez quelqu’un qui pourrait être intéressé par ce livre\n\nSi vous êtes intéressé par ce livre, ou si vous avez besoin d’un cadeau pour un amoureux des livres, vous pouvez mettre la main sur un exemplaire ici :\n\n',
  'whatsapp-week2-title': 'Semaine 2 – Soirée de lancement du livre',
  'whatsapp-week2-message': 'Salut à vous et merci à tous ceux qui ont déjà acheté mon livre, vous êtes vraiment géniaux !\n\nJe voulais juste vous faire savoir que j’organise une soirée de lancement du livre ! Vous êtes bien sûr tous invités. Il y aura des snacks, des boissons, une courte lecture d’un extrait ! Je signerai même vos livres pour vous si vous le souhaitez ;)\n\nCe sera super amusant et je serais honorée si vous veniez !',
  'whatsapp-week3-title': 'Semaine 3 – Merci d’être venus !',
  'whatsapp-week3-message': 'Salut tout le monde ! Merci à tous ceux qui sont venus à la soirée de lancement de mon livre, c’était fantastique de vous voir tous là ! Voici quelques belles photos que j’ai prises pendant l’événement.\n\nJ’aimerais également remercier tous ceux qui ont acheté un exemplaire, ça me touche beaucoup ! Si vous n’avez pas pu venir et que vous voulez quand même obtenir un exemplaire de mon livre, vous pouvez le faire ici :',
  'whatsapp-week4-title': 'Semaine 4 – Demande d’avis',
  'whatsapp-week4-message': 'Salut !\n\nJ’ai une petite faveur à vous demander: cela m’aiderait énormément si vous pouviez écrire une courte critique du livre sur Goodreads ou Babelio. Ça ferait des merveilles pour la visibilité du livre en ligne et m’aiderait à toucher plus de monde. Je serais plus qu’heureux de récompenser vos efforts avec un café un de ces jours !',
  'whatsapp-week4-goodreadswarning': 'Attention: avant d’envoyer ce message, assurez-vous d’avoir ajouté votre livre à Goodreads!',
  'whatsapp-tips-title': 'Conseils',
  'whatsapp-tips-groups-label': 'Créez des groupes WhatsApp dédiés',
  'whatsapp-tips-groups': 'Il y a de fortes chances que vous ayez une longue liste de contacts sur Whatsapp. Il peut être judicieux de créer plusieurs groupes et d’envoyer à chacun d’entre eux des messages spécialement conçus pour correspondre à votre niveau de familiarité habituel.',
  'whatsapp-tips-readtheroom-label': 'Ne soyez pas trop insistant',
  'whatsapp-tips-readtheroom': 'Les personnes avec lesquelles vous parlez sur Whatsapp sont probablement celles dont vous êtes le plus proche, ce qui signifie qu’elles seront probablement vos lecteurs et clients les plus fidèles. Envisagez de les encourager en remerciant plutôt les personnes qui achètent votre livre. C’est généralement un moyen plus efficace de motiver ceux qui sont à la traîne.',
  'whatsapp-tips-askspread-label': 'Demandez aux gens de faire passer le mot',
  'whatsapp-tips-askspread': 'Le bouche à oreille est l’un des moyens les plus puissants de faire connaître votre livre. C’est aussi l’un des plus difficiles. Par conséquent, profitez de vos groupes Whatsapp et demandez à vos amis et à votre famille de faire passer le mot dans leur propre cercle social.',
  'whatsapp-tips-participation-label': 'Créer un sentiment de participation',
  'whatsapp-tips-participation': 'Les personnes présentes sur Whatsapp sont susceptibles de devenir vos lecteurs et clients les plus proches et les plus fidèles. Encouragez-les en leur faisant savoir à quel point vous appréciez qu’ils achètent votre livre et le fassent connaître. Invitez-les à la fête de lancement de votre livre et à d’autres événements liés au livre, et partagez des photos de ces événements avec le groupe. Rappelez-leur à quel point ils sont importants pour votre succès.',

  // social media
  'socialmedia-intro-p1': 'Comme vous le savez certainement, les réseaux sociaux sont l’un des outils promotionnels les plus importants disponibles aujourd’hui. En tant qu’auteur auto-édité, il ne faut pas manquer l’occasion de communiquer directement avec des lecteurs intéressés par votre sujet. Nous avons compilé ci-dessous quelques conseils et outils pour entretenir plusieurs pages et profils sur les réseaux sociaux, et d’autres sont à venir.',
  'socialmedia-tips-title': 'Conseils',
  'socialmedia-tips-beconsistent-label': 'Soyez cohérent',
  'socialmedia-tips-beconsistent': 'En tant qu’écrivain, vous avez une voix unique, une façon particulière de partager vos pensées, et cela devrait également imprégner votre communication sur les réseaux sociaux. Il est important de partager vos idées de manière active et éloquente, et de projeter votre voix de manière cohérente afin que le public vous reconnaisse.',
  'socialmedia-tips-beshareable-label': 'Soyez ‘partageable’',
  'socialmedia-tips-beshareable': 'Même si vous devez prendre vos marques sur les réseaux sociaux et être original, il est également important de créer du contenu qui puisse être facilement partagé par vos abonnés et followers. Plus vos publications seront partagées, plus votre compte recevra d’attention.',
  'socialmedia-tips-berelatable-label': 'Soyez amical',
  'socialmedia-tips-berelatable': 'Étant donné que vous êtes un auteur qui essaie de promouvoir son livre, vous pourriez vous dire que prendre le temps d’interagir avec les publications des autres et de les partager est contre-productif. Pourtant, c’est essentiel si vous voulez créer des liens sur les réseaux sociaux. Plus vous partagez, aimez et commentez, plus les autres feront de même pour vous. Il est important de faire partie d’une communauté.',
  'socialmedia-tips-hashtaglover-label': 'Soyez un amateur de hashtags',
  'socialmedia-tips-hashtaglover': 'Les hashtags sont toujours extrêmement importants. N’utilisez pas de hashtags génériques ; faites l’effort d’en trouver des originaux afin qu’ils vous aident à trouver les bonnes personnes et vice versa. Faites des recherches sur les principaux hashtags, par exemple dans la communauté des écrivains, avant de faire votre publication. Bien sûr, vous ne devez pas tout hashtaguer. Concentrez-vous sur le contenu que vous voulez vraiment diffuser. Toutes les publications n’ont pas besoin de hashtag.',
  'socialmedia-tips-beflexible-label': 'Soyez flexible',
  'socialmedia-tips-beflexible': 'Chaque plateforme est différente. Instagram est axé sur les images. LinkedIn est axé sur le réseau professionnel. Facebook est axé sur le partage. Twitter est axé sur les mots. Il est important que votre contenu ne soit pas copié-collé ; vous devez vous adapter au style et aux normes de chaque plateforme. Votre voix doit rester la même, mais la façon dont vous la projetez doit être flexible.',
  'socialmedia-tips-beanalytical-label': 'Soyez analytique',
  'socialmedia-tips-beanalytical': 'Chaque plateforme fournit des analyses, alors profitez-en ! Vous devez prêter attention au type de public que vous touchez, aux meilleurs moments pour poster, ainsi qu’aux tendances en matière de likes et de suivi. Vos plateformes vous fourniront ces informations dès que vous transformerez votre profil en compte professionnel.',

  // Instagram
  'instagram-intro-p1': 'Instagram est pratiquement incontournable pour tout auteur de nos jours. La scène du livre sur Instagram, nommée « bookstagram », est une énorme communauté pleine de lecteurs potentiels. Construire une forte présence sur Instagram peut être assez difficile, car cela vous oblige à produire régulièrement du contenu visuel de haute qualité.',
  'instagram-intro-p2': 'Il y a trois choses principales que vous devez privilégier lorsque vous êtes sur Instagram : la construction d’une identité esthétique pour votre feed, l’utilisation de hashtags pertinents et variés (nous en avons ajouté quelques-uns aux modèles, mais n’hésitez pas à les modifier ou à en ajouter d’autres), et la publication de stories.',
  'instagram-promoteoninstagram': 'Promouvoir votre livre sur Instagram',
  'instagram-switchtophone': 'Passer sur votre téléphone',
  'instagram-switchtophone-description': 'Vous devrez utiliser votre téléphone pour publier du contenu Instagram. Vous pouvez scanner le code QR ci-dessous pour accéder à la version mobile de cette page. La plupart des smartphones scannent automatiquement les codes QR si vous ouvrez l’application de l’appareil photo et le pointez vers le code. Si vous ne pouvez pas scanner le code QR, vous pouvez également vous connecter à Mybestseller sur votre téléphone.',
  'instagram-setupinsta-title': 'Installer Instagram',
  'instagram-week1-title': 'Semaine 1 – L’annonce',
  'instagram-week1-message': 'Le voilà enfin : le premier exemplaire imprimé de mon nouveau livre, {title} ! C’est une étape tellement importante pour moi et je ne pourrais pas être plus ravi/e de le partager avec vous ! Si vous voulez me soutenir, le livre est disponible maintenant pour {price}, lien dans la bio !\n\n{keywords} #autoedition #auteur #livrestagram #lancement',
  'instagram-week2-title': 'Semaine 2 - Publication des premières ventes',
  'instagram-week2-chooseanimage-description': 'Lorsque vous créez le visuel de votre premier post de vente, il est important de le rendre personnel ; c’est ce que vos followers Instagram veulent voir. Essayez d’inclure votre visage, votre livre, et de préférence les deux. Par exemple, vous pourriez poster une photo de vous debout devant une librairie, tenant un exemplaire de votre livre.',
  'instagram-week2-message': 'Je suis très heureux/se d’avoir vendu mes 10 premiers livres ! Merci beaucoup à les acheteurs ! Je ne peux pas vous dire à quel point j’apprécie votre soutien. Si vous voulez être aussi heureux que ces superbes personnes, vous pouvez toujours vous rendre sur mon site web et obtenir votre propre exemplaire de {title} ;)\n\nLe lien est dans la bio !\n\n#autoedition #livreaddict',
  'instagram-week3-title': 'Semaine 3 - Concours',
  'instagram-week3-chooseanimage-description': 'Lorsque vous créez le visuel de votre cadeau, il est utile de montrer ce que vous offrez exactement. Dans notre exemple ci-dessous, nous offrons 5 exemplaires signés, et dans ce cas, vous pourriez prendre une photo de 5 exemplaires de votre livre, dont un ouvert pour afficher votre signature. Veillez à ce que votre éclairage soit parfait, afin que les livres soient aussi beaux que possible.',
  'instagram-week3-message': 'Ces deux semaines ont été folles depuis la sortie de mon livre {title}, et je suis submergée par toutes ces réactions incroyablement positives qu’il a reçues jusqu’à présent, sans parler du soutien que j’ai reçu personnellement. Merci à tous pour vos belles paroles !\nSi vous n’avez pas encore réussi à mettre la main sur le livre, ce qui suit pourrait vous intéresser : je vais offrir 5 exemplaires à mes followers ! Les livres seront tous signés, donc si je deviens vraiment célèbre, vous aurez quelque chose de cool sur votre étagère ;)\n\nTout ce que vous avez à faire est de liker ce post et de taguer un ami qui pourrait être intéressé par le livre ! Je sélectionnerai 5 commentaires au hasard et j’annoncerai les gagnants dans mes stories la semaine prochaine !\n\n#concours #bookstagramfrance #auteurindependant',
  'instagram-week4-title': 'Semaine 4 - Demande d’avis',
  'instagram-week4-chooseanimage-description': 'Lorsque vous demandez des avis, vous dépendez de la bonne volonté de vos followers, il est donc important de créer un lien avec eux. Une belle image peut vous aider dans ce domaine. Une photo de vous tenant votre livre est un très bon choix, par exemple. Si vous voulez être excentrique, vous pouvez mettre quelques livres dans un panier et placer un morceau de carton avec les mots ‘Donnez-moi votre avis’ devant. Vous pouvez également opter pour des stories plutôt qu’une simple publication lorsque vous demandez des avis. Cette option touchera probablement plus de personnes.',
  'instagram-week4-message': 'Salut tout le monde ! J’ai une petite faveur à vous demander !\n\nSi vous avez lu mon livre, s’il vous plaît, écrivez une courte critique à son sujet sur Goodreads ! Ce serait incroyablement utile pour renforcer sa visibilité en ligne et toucher davantage de personnes. Même si vous n’avez pas le temps d’écrire une critique, une simple note serait la bienvenue. Je vous en serais très reconnaissant.\n\nMerci aussi à tous ceux qui ont acheté mon livre, vous n’avez pas idée de ce que cela représente pour moi !\n\n#avislecture #autoedition #bookstragramfrance #auteurindependant',
  'instagram-tips-title': 'Conseils',
  'instagram-tips-visuals-label': 'Miser tout sur le visuel',
  'instagram-tips-visuals': 'Comme vous le savez probablement, Instagram fait la part belle aux images. Cela peut sembler être un défi pour vous en tant qu’auteur essayant de promouvoir votre livre, mais ce n’est pas forcément un inconvénient. Les éléments fondamentaux de votre contenu Instagram devraient être la couverture de votre livre, des photos des coulisses de votre processus d’écriture, et quelques photos de votre vie quotidienne en tant qu’auteur.',
  'instagram-tips-quotes-label': 'Citation',
  'instagram-tips-quotes': 'Pour stimuler la curiosité de votre public, une tactique souvent couronnée de succès consiste à partager des citations de votre livre ! Même si Instagram est très visuel, éditer des photos avec une phrase dessus est toujours un bon moyen de partager vos écrits.',
  'instagram-tips-interact-label': 'Intéractions',
  'instagram-tips-interact': 'Vous ne devez pas seulement vous concentrer sur la promotion mais aussi sur l’engagement avec votre public. Plus l’interaction est importante, plus votre compte recevra d’attention. Il est essentiel de partager les publications de vos lecteurs sur vos histoires, ou de commenter leurs posts. Maintenez l’engagement de votre communauté.',
  'instagram-tips-bookstagram-label': 'Bookstagram',
  'instagram-tips-bookstagram': 'Il existe une énorme communauté de lecteurs de livres sur Instagram qui se rassemblent sous le terme générique de "Bookstagram" (ou “Instalivre” pour les plus francophiles). Cette communauté en ligne partage des photos des livres qu’elle lit et de ceux qu’elle prévoit de lire, et elle est extrêmement influente. En tant qu’auteur, vous devez vous rapprocher de ces “bookstagrammeurs” et les suivre, dans le but ultime qu’ils partagent votre livre sur leur profil. Pour commencer, vous devriez viser les comptes ayant entre 1 000 et 20 000 followers, car leur engagement est souvent élevé et il est plus facile pour vous d’entamer une conversation. N’oubliez pas de créer des messages personnalisés !',
  'instagram-tips-addlinks-label': 'Ajoutez des liens',
  'instagram-tips-addlinks': 'Il est important d’ajouter un lien dans votre bio menant à une page web où votre livre est en vente. Vous pouvez ajouter le lien vers notre boutique, ou votre site web d’auteur, et les personnes sur votre Instagram peuvent acheter votre livre en quelques clics !',
  'instagram-tips-balance-label': 'Créez un équilibre',
  'instagram-tips-balance': 'N’oubliez pas qu’Instagram privilégie l’engagement par rapport au partage de contenu. Il n’est donc pas nécessaire de publier tous les jours, cependant il est bon de viser à le faire 2 à 3 fois par semaine. De plus, l’utilisation d’Instagram Stories pour partager des photos moins éditées, avec un contenu plus quotidien peut faire croître l’engagement et attirer l’attention d’Instagram.',
  'instagram-tips-contests-label': 'Organisez des concours',
  'instagram-tips-contests': 'Il n’y a rien que les gens aiment plus que de recevoir des choses gratuites. Organiser un concours est le moyen idéal d’attirer l’attention et de développer votre audience. Vous pouvez offrir un exemplaire dédicacé de votre livre et demander aux gens de vous suivre et de commenter afin de participer au tirage au sort !',
  'instagram-tips-scheduleposts-label': 'Planifiez vos publications',
  'instagram-tips-scheduleposts': 'Être un auteur est un travail difficile, et vous serez souvent pressé par le temps lorsque vous devrez créer et mettre en ligne du nouveau contenu. Une bonne stratégie consiste à utiliser divers outils en ligne pour programmer vos publications. Vous pouvez ainsi créer plusieurs publications en même temps et les programmer pour les semaines à venir. Si vous créez un compte professionnel sur Facebook, vous avez accès à Creator Studio, qui vous permet de créer et de programmer des publications à la fois sur Facebook et Instagram. C’est un bon point de départ.',

  // facebook
  'facebook-copyandpastetext': 'Cliquez ici pour copier le texte ci-dessus. Ensuite, collez ce texte dans votre statut après avoir cliqué sur le bouton de partage.',
  'facebook-button-shareonfb': 'Partagez sur Facebook',
  'facebook-intro-p1': 'La clé pour tirer le meilleur parti de vos efforts marketing sur Facebook est de maximiser votre engagement. Essayez d’obtenir autant de commentaires que possible, et trouvez des groupes pertinents auxquels vous pouvez adhérer et participer activement.',
  'facebook-promoteviafacebook': 'Promouvoir votre livre sur Facebook',
  'facebook-week1-title': 'Semaine 1 - L’annonce',
  'facebook-week1-message': 'C’est le jour ! Je suis maintenant officiellement un auteur publié. La route a été longue et le travail difficile, mais tenir mon livre entre les mains en vaut vraiment la peine. Au cas où vous n’auriez pas suivi mes progrès, et que vous seriez intéressé par le sujet de ce livre, voici le résumé :\n\n{shortdesc}\n\nSi vous pensez que cela vous intéresse, vous pouvez vous rendre sur mon site Web.',
  'facebook-week2-title': 'Semaine 2 - Publication des premières ventes',
  'facebook-week2-message': 'Je suis très heureux/se de vous annoncer que j’ai vendu mes 5 premiers livres  ! J’espère que vous me pardonnerez l’autopromotion que je m’apprête à faire, mais si l’idée de lire un livre que j’ai écrit de mes propres mains vous semble un tant soit peu intéressante, n’hésitez pas à vous rendre sur le mon site Web et à le consulter. Vous pourrez jeter un œil au résumé, admirer la couverture, et pourquoi pas même vous procurer votre propre exemplaire ?',
  'facebook-week3-title': 'Semaine 3 - Concours',
  'facebook-week3-message': 'Jusqu’à présent, être auteur a été un formidable plaisir ; tout le monde a été d’un soutien et d’une gentillesse incroyables, et je ne pourrais vraiment pas être plus heureux/se. Je n’aurais jamais imaginé que mon livre serait aussi bien accueilli, et pour fêter ça, j’organise un tirage au sort ! Donc, si vous n’avez pas encore mis la main sur un exemplaire, aimez et partagez cet article, et laissez un commentaire décrivant l’intrigue de votre livre préféré en aussi peu de mots que possible. Je choisirai ensuite les cinq meilleurs commentaires et enverrai aux commentateurs un exemplaire dédicacé de {title}.',
  'facebook-week4-title': 'Semaine 4 – Demande d’avis',
  'facebook-week4-message': 'Merci beaucoup à tous ceux qui ont acheté mon livre ; vous êtes vraiment fantastiques ! Je suis de retour pour vous demander une autre petite faveur - cela m’aiderait énormément si vous alliez sur Goodreads ou Babelio et y laissiez un avis pour mon livre ! Si vous n’êtes pas ou ne voulez pas être sur Goodreads ou Babelio, un avis sur n’importe quelle plateforme ou dans n’importe quel magasin serait également très apprécié. Merci d’avance !',
  'facebook-tips-title': 'Conseils',
  'facebook-tips-authorpage-label': 'Page d’auteur',
  'facebook-tips-authorpage': 'Il est important de créer des pages pour développer un contenu spécifique sur Facebook. En créant une page d’auteur, votre public peut apprendre à mieux vous connaître et obtenir les informations les plus récentes sur votre livre.',
  'facebook-tips-engagement-label': 'Engagement',
  'facebook-tips-engagement': 'Facebook ne montre pas toujours vos publications à vos propres followers. Son algorithme se concentre sur le degré d’engagement que vous obtenez. Par conséquent, plus vous avez de likes et de commentaires, plus il y a de chances que Facebook donne la priorité à vos publications ! Essayez donc de stimuler votre audience !',
  'facebook-tips-sellyourbook-label': 'Vendre votre livre',
  'facebook-tips-sellyourbook': 'Il est important de créer un lien direct vers les canaux de vente où votre livre est disponible, comme notre boutique en ligne ou votre site web, par exemple. Il est important de faciliter l’accès de votre public à la fois à votre livre et aux informations le concernant et vous concernant.',
  'facebook-tips-promoteothers-label': 'Promouvoir d’autres livres',
  'facebook-tips-promoteothers': 'Ce conseil peut sembler contre-productif, mais il s’agit en fait d’une stratégie couramment utilisée. Plus vous interagissez avec d’autres auteurs, plus ils vous aideront à leur tour. Il est toujours bon de partager des critiques et de promouvoir certains livres pour faire partie d’une communauté active.',
  'facebook-tips-sharewriting-label': 'Partager vos écrits',
  'facebook-tips-sharewriting': 'Pour que votre public apprenne à vous connaître en tant qu’auteur, il est important de partager certains de vos écrits. Donc, créez des publications incluant des citations ou des passages de votre livre ! Ces citations sont importantes pour donner envie aux gens d’acheter votre livre et de suivre votre parcours d’auteur.',
  'facebook-tips-startagroup-label': 'Créez un groupe ou participez à un groupe',
  'facebook-tips-startagroup': 'Les groupes sont un aspect central de Facebook. Beaucoup de gens aiment faire partie de petites communautés dans ce grand monde qu’est Facebook. En tant qu’auteur, vous pouvez trouver des groupes pertinents à votre genre, qui sont d’excellents endroits pour partager des informations sur vous-même et votre livre. Vous pouvez également créer un groupe à partir de zéro pour construire lentement votre propre communauté Facebook !',

  // Twitter
  'twitter-intro-p1': 'Twitter est le lieu de prédilection des personnes qui savent faire parler d’elles à travers leurs écrits. En tant qu’auteur, vous devriez donc vous sentir chez vous.',
  'twitter-intro-p2': 'Pour que Twitter vous soit utile, vous devez tweeter et, ce qui est peut-être encore plus important, retweeter souvent. Gardez un œil sur les hashtags qui concernent les livres, l’édition, votre genre particulier et même vos intérêts personnels. Assurez-vous de contribuer de manière substantielle à tous les discours auxquels vous participez. Le potentiel de vente de Twitter est beaucoup moins direct que celui d’Instagram mais il peut néanmoins s’avérer utile pour gagner un public et, par extension, des ambassadeurs pour votre livre.',
  'twitter-promoteviatwitter': 'Promouvoir votre livre sur Twitter',
  'twitter-button-shareviatwitter': 'Partagez sur Twitter',
  'twitter-charcount': 'Vous avez utilisé {chars} sur 280 caractères.',
  'twitter-week1-title': 'Semaine 1 – L’annonce',
  'twitter-week1-message': 'Mon livre, {title}, est enfin sorti ! Rendez-vous sur mon site web pour commander votre exemplaire, ou venez lire quelques chapitres pour vous faire une idée ! #auteurindependant',
  'twitter-week2-title': 'Semaine 2 - Publication des premières ventes',
  'twitter-week2-message': 'C’est parti ! Les premiers livres sont vendus ! Merci beaucoup à vous pour votre soutien ! Si quelqu’un d’autre est intéressé par mon livre, {title}, vous pouvez le commander sur mon site web',
  'twitter-week3-title': 'Semaine 3 - Concours',
  'twitter-week3-message': 'Attention #lecteurs ! C’est l’heure du #giveaway ! Si vous voulez gagner un exemplaire signé de mon livre, suivez-moi, aimez et retweetez ce tweet, et laissez un commentaire expliquant pourquoi vous aimeriez avoir un exemplaire du livre !',
  'twitter-week4-title': 'Week 4 – Demande d’avis',
  'twitter-week4-message': 'Attention à mes chers lecteurs ! Si vous avez lu mon livre, {title}, et que vous l’avez apprécié, pensez à vous rendre sur Goodreads et à laisser un avis ! Non seulement j’adorerais lire vos avis, mais cela ferait des merveilles pour la visibilité du livre ! #autoedition',
  'twitter-tips-title': 'Conseils',
  'twitter-tips-sharequotes-label': 'Partagez des citations',
  'twitter-tips-sharequotes': 'Comme vous connaissez sans doute déjà Twitter, nous ne nous attarderons pas sur les principes de base. Comme il s’agit d’une plateforme axée sur des messages concis, basés sur du texte, elle est très bien adaptée aux écrivains. Une façon évidente d’utiliser Twitter est de partager des citations de votre livre. Essayez de montrer à votre public qui vous êtes en tant qu’écrivain.',
  'twitter-tips-interaction-label': 'L’interaction avant tout',
  'twitter-tips-interaction': 'Twitter permet non seulement de partager ses propres idées, mais aussi de participer à des discussions en cours, ce qui est probablement encore plus vrai lorsqu’on débute. Twitter offre la possibilité d’interagir avec des lecteurs potentiels, d’autres auteurs et, bien sûr, vos propres fans. Plus il y a d’interaction, mieux c’est !',
  'twitter-tips-balance-label': 'Trouvez l’équilibre',
  'twitter-tips-balance': 'La vente de vos livres étant votre principal objectif, il est facile de se perdre dans le marketing constant. Cependant, il est important de montrer un côté personnel, et encore mieux, une facette plus légère et drôle de vous-même. Les gens ne veulent pas constamment lire des tweets qui se résument à ‘achetez mon livre’ ; ils veulent aussi entendre vos histoires et avoir un aperçu de votre vie !',
  'twitter-tips-pinapost-label': 'Épingler un message',
  'twitter-tips-pinapost': 'Si vous publiez régulièrement des messages sur Twitter et que vous craignez que des informations spécifiques sur votre livre ne soient perdues ou négligées, une bonne idée serait d’épingler un message ! Vous pouvez épingler un message attrayant qui renvoie à l’achat de votre livre et que tout le monde peut voir une fois qu’il a accédé à votre profil. Il ne vous reste plus qu’à les y amener.',
  'twitter-tips-bio-label': 'Biographie',
  'twitter-tips-bio': 'Une grande partie de votre temps sur Twitter est consacrée à tweeter, mais ne négligez pas votre biographie ! Elle sera visible par tous, c’est donc l’endroit idéal pour glisser quelques mots pour vous décrire et inclure le titre de votre livre. Donnez l’impression d’être intéressant !',

  // LinkedIn
  'linkedin-intro-p1': 'LinkedIn a plus de potentiel que vous ne le pensez pour les auteurs autoédités. Cette plateforme est pratiquement taillé sur mesure pour ceux qui ont écrit des ouvrages de non fiction. Toutefois, même si vous avez écrit un livre de fiction, vous pouvez trouver un public qui vous soutient sur LinkedIn. Il s’agit d’une plateforme bien adaptée au partage de votre parcours d’ecriture et de publication, dans une perspective que nous appelons ‘auteurpreneuriale’. Présentez votre livre comme un projet et parlez des défis et des récompenses liés à sa réalisation. Mais n’oubliez pas de rester authentique et humain dans votre démarche !',
  'linkedin-intro-p2': 'Publier sur LinkedIn demande un peu plus de travail préparatoire que sur les autres plateformes. Vous devrez éviter d’être trop vendeur et vous concentrer plutôt sur le partage de connaissances et d’expériences. La rédaction d’un article, par exemple, vous demandera un certain effort, mais il sera bien mieux accueilli sur la plateforme qu’un post ouvertement commercial.',
  'linkedin-promotevialinkedin': 'Promouvoir votre livre sur LinkedIn',
  'linkedin-copyandpastetext': 'Cliquez ici pour copier le texte ci-dessus. Ensuite, collez ce texte dans votre statut après avoir cliqué sur le bouton de partage.',
  'linkedin-button-shareonlinkedin': 'Partagez sur LinkedIn',
  'linkedin-week1-title': 'Semaine 1 – L’annonce',
  'linkedin-week1-message': 'Je suis immensément fier/fière d’annoncer que mon livre, {title}, est enfin publié ! Devenir écrivain a toujours été un rêve pour moi, et ce fut une expérience incroyable de réaliser ce rêve pour moi-même. Si vous avez des commentaires sur le livre ou si vous voulez discuter de son contenu en profondeur, j’aimerais beaucoup entrer en contact avec vous et échanger des idées ! Voici un lien qui vous permettra d’en savoir plus !\n\n#auteurpreneur #auteurindépendant',
  'linkedin-week2-title': 'Semaine 2 - Publication des premières ventes',
  'linkedin-week2-message': 'J’ai récemment organisé mon tout premier lancement de livre et c’était génial ! Voir tout ce monde réuni pour célébrer la publication de mon livre avec moi était un vrai bonheur. Un grand merci à tous ceux qui étaient présents, et pour ceux qui n’ont pas pu venir, voici quelques belles photos de l’événement !\n\n#ventes #livre',
  'linkedin-week3-title': 'Semaine 3 – Atelier',
  'linkedin-week3-message': 'Mon livre se vend vraiment bien en ce moment, et j’ai remarqué un vif intérêt non seulement pour le sujet, mais aussi pour l’écriture et l’auto-édition. J’aimerais vraiment discuter du sujet de mon livre, d’écriture et d’auto-édition avec mon réseau ! J’ai donc décidé d’organiser un atelier pour tous ceux qui sont intéressés. Nous pourrons y échanger des idées et partager nos points de vue les uns avec les autres. Faites-moi savoir si vous êtes intéressés, et j’organiserai l’événement !\n\n#autoedition',
  'linkedin-week4-title': 'Semaine 4 – Concours',
  'linkedin-week4-message': 'Publier mon livre a été l’une des choses les plus difficiles que j’ai jamais faites, mais cela a vraiment porté ses fruits ! Je n’avais jamais imaginé que le livre finirait par se vendre aussi bien, et je veux fêter cela en offrant 5 exemplaires ! Si vous voulez participer au tirage au sort, il vous suffit de réagir à cet article et de laisser un commentaire décrivant pourquoi vous pensez que le sujet de mon livre est important aujourd’hui ! Les 5 commentaires les plus inspirants recevront un exemplaire de {title}\n\n#auteurpreneur #autoedition',
  'linkedin-tips-title': 'Conseils',
  'linkedin-tips-finishprofile-label': 'Finissez votre profil... et finissez-le vraiment.',
  'linkedin-tips-finishprofile': 'Cela peut sembler évident, mais beaucoup de personnes ne finissent jamais de compléter toutes les étapes de leur profil Linkedin. Si vous ne terminez pas votre profil, il y a moins de chances qu’on puisse vous trouver ou s’identifier à vous.',
  'linkedin-tips-connect-label': 'Connectez-vous',
  'linkedin-tips-connect': 'Linkedin est un outil de mise en réseau. La plateforme est basée sur la création de liens avec d’autres professionnels. Vous devez donc vous assurer que vous ajoutez d’autres auteurs, des lecteurs potentiels, etc. Créer du contenu pour un petit réseau ne vous mènera nulle part.',
  'linkedin-tips-publisharticles-label': 'Publiez des articles',
  'linkedin-tips-publisharticles': 'Linkedin offre la possibilité de publier des articles et c’est extrêmement utile pour un auteur en herbe ! Vous pouvez utiliser cette plateforme pour partager des extraits de votre roman, ou peut-être écrire des articles dans lesquels vous partagez des conseils et des expériences que d’autres auteurs en herbe pourraient apprécier. Les gens ont tendance à s’engager et à lire beaucoup sur Linkedin, et les articles sont parfaits pour un auteur qui a quelque chose à dire ! De plus, les articles sont un excellent moyen de canaliser le trafic vers votre site Web.',
  'linkedin-tips-jobposition-label': 'Listez votre livre comme un emploi',
  'linkedin-tips-jobposition': 'Vous pouvez faire de votre livre un travail à part entière ! Le titre ferait référence à votre nom et l’entreprise serait le nom de votre livre. De cette façon, les gens peuvent clairement voir votre livre sur votre profil Linkedin. Vous pouvez ajouter les dates auxquelles vous avez travaillé sur votre roman ainsi qu’une belle description. C’est également un bon moyen d’annoncer d’éventuelles suites.',
  'linkedin-tips-behuman-label': 'Soyez humain',
  'linkedin-tips-behuman': 'Étant donné que Linkedin est principalement connu pour être une plateforme d’affaires, les gens pensent souvent qu’ils doivent être formels lorsqu’ils y postent. Cependant, si vous êtes un auteur qui essaie de vendre son livre, vous gagnerez des points en développant un profil personnalisé, et vous devriez vous concentrer là-dessus ! N’essayez pas d’être ce que vous n’êtes pas. Sur LinkedIn, les gens raffolent des histoires de développement personnel et de défis surmontés, alors utilisez cela à votre avantage.',

  // Libraries
  'libraries-intro-p1': 'Tout écrivain sérieux se doit d’avoir son livre dans une bibliothèque. La Bibliothèque nationale de France (BnF) requiert un exemplaire ; c’est ce qu’on appelle le dépôt légal. Au-delà des formalités, le dépôt de votre livre à la bibliothèque nationale est un excellent moyen d’apporter de la légitimité à votre livre et à vous, en tant qu’auteur. Il s’agit également d’une excellente opportunité de marketing, et si vous parvenez à convaincre suffisamment de personnes de demander votre livre à leur bibliothèque locale, il est fort probable que vous vendiez quelques exemplaires de plus !',

  // Upload zone
  'uploadzone-default-title': 'Insérer',
  'uploadzone-default-title-progress': 'En train d’insérer...',
  'uploadzone-filerequirements': 'Critères du fichier: max. {filesize}, formats: {filetypes}',
  'uploadzone-button-selectfile': 'Choisir un fichier',
  'uploadzone-ordragfilehere': 'ou le déposer ici',
  'uploadzone-dropfiletoupload': 'Déposez un fichier ici pour téléchargement',

  // Book problem translations
  'bookproblem-link-moreinfo': 'Plus d’information',
  'bookproblem-stepScriptSizeModifiedUploadAgain': 'Vous avez modifié la mise en page de votre livre. Veuillez réimporter le fichier de votre manuscrit.',
  'bookproblem-stepScriptColorModifiedUploadAgain': 'Vous avez modifié les paramètres de couleurs de votre manuscrit. Veuillez réimporter le fichier de votre manuscrit.',
  'bookproblem-stepScriptBindingModifiedUploadAgain': 'Vous avez changé votre type de livre. Veuillez réimporter le fichier de votre manuscrit.',
  'bookproblem-stepScriptReaddirectionModifiedUploadAgain': 'Vous avez changé la langue de votre livre en un langage qui a une autre directionnalité. Veuillez importer à nouveau votre manuscrit pour que la directionnalité de l’écrit corresponde à la langue que vous avez choisie.',
  'bookproblem-stepScriptManuscriptConversionFailed': 'Nous n’avons pas pu convertir votre fichier. Votre fichier est probablement endommagé. Essayez de le restaurer et importez-le à nouveau. ',
  'bookproblem-stepScriptManuscriptColorpagesNotCalculated': 'Nous n’avons pas pu calculer le nombre exact de pages en couleurs dans votre livre. Veuillez nous contacter pour nous aider à résoudre ce problème, ou importez une nouvelle version de votre manuscrit.',
  'bookproblem-stepScriptManuscriptConversionTechnicalError': 'Une erreur technique s’est produite lors de la conversion de votre manuscrit. Code d’erreur : {lasterror}',
  'bookproblem-stepScriptFontsNotEmbedded': 'Certaines polices ne sont pas intégrées correctement dans ce document. Consultez notre guide sur la création d’un PDF pour plus d’informations.',
  'bookproblem-stepScriptEbookConversionHasErrors': 'Nous avons détecté plusieurs erreurs lors de la conversion de votre eBook. Veuillez les résoudre avant de continuer.',
  'bookproblem-printerConstraintNumberpagesLessThanMinimum': 'Votre livre n’a pas assez de pages. Assurez-vous qu’il contienne au moins {min} pages (le nombre actuel de pages est de {pagecount}).',
  'bookproblem-printerConstraintNumberpagesMoreThanMaximum': 'Votre livre a trop de pages. Assurez-vous qu’il contienne au maximum {max} pages (le nombre actuel de pages est de {pagecount}).',
  'bookproblem-printerConstraintNumberpagesMoreThanCoverwidth': 'Votre livre contient trop de pages pour le format et le type de papier que vous avez choisis. Assurez-vous que votre livre contienne au maximum {max} pages, ou choisissez un autre type de papier et/ou un format plus étroit.',

  // Bookstep: manuscript
  'bookstep-Manuscript': 'Manuscrit',
  'bookstep-Manuscript-intro-p1-1': 'Il est essentiel que votre mise en page soit bien soignée. C’est pourquoi nous vous recommandons de prendre votre temps pour cette étape. Prenez donc le temps de bien vous l’approprier : vous gagnerez du temps pour vos prochaines œuvres. Nous serons à vos côtés pour vous aider tout au long du processus de rédaction. N’hésitez pas à faire appel à nos services si vous souhaitez qu’un professionnel jette un œil à votre travail. Si votre manuscrit est déjà terminé, vous pouvez simplement le déposer sous forme de document Word ou PDF. Une fois inséré, nous convertirons ce fichier en un PDF prêt à être imprimé. Veillez à bien vérifier ce fichier et commandez toujours un exemplaire test avant de lancer la publication générale. Il n’y a rien de pire que de trouver des fautes de frappe et autres erreurs dans votre livre officiel.',
  'bookstep-Manuscript-intro-p1-3': 'Il est essentiel que votre mise en page soit bien soignée. C’est pourquoi nous vous recommandons de prendre votre temps pour cette étape. Prenez donc le temps de bien vous l’approprier : vous gagnerez du temps pour vos prochaines œuvres. Nous serons à vos côtés pour vous aider tout au long du processus de rédaction. N’hésitez pas à faire appel à nos services si vous souhaitez qu’un professionnel jette un œil à votre travail. Si votre manuscrit est déjà terminé, vous pouvez simplement le déposer sous forme de document Word ou PDF. Une fois inséré, nous convertirons ce fichier en un PDF prêt à être imprimé. Veillez à bien vérifier ce fichier et commandez toujours un exemplaire test avant de lancer la publication générale. Il n’y a rien de pire que de trouver des fautes de frappe et autres erreurs dans votre livre officiel.',
  'bookstep-Manuscript-intro-p1-6': 'Il est essentiel que votre mise en page soit bien soignée. C’est pourquoi nous vous recommandons de prendre votre temps pour cette étape. Prenez donc le temps de bien vous l’approprier : vous gagnerez du temps pour vos prochaines œuvres. Nous serons à vos côtés pour vous aider tout au long du processus de rédaction. Pour créer un eBook, vous pouvez déposer un document Word ou un fichier ePub. Notre plateforme traitera votre fichier (en le convertissant en ePub si nécessaire), et créera une version prête à être publiée. Vérifiez que ce fichier vous convienne avant de continuer.  Si vous prévoyez d’insérer un document Word, il est important d’utiliser un format adapté à l’ePub, pour s’assurer que l’eBook qui en résulte soit lisible. Si vous souhaitez déposer un ePub, nous vous recommandons d’utiliser Calibre pour le créer.',
  'bookstep-Manuscript-intro-p1-7': 'Une mise en page bien soignée est un élément essentiel qui assurera la qualité de votre livre. C’est pourquoi nous vous recommandons de prendre votre temps pour cette étape. Nous resterons à votre disposition tout le long si vous avez besoin d’assistance. Vous pouvez directement importer votre manuscrit fin prêt sous fichier PDF. Une fois votre manuscrit envoyé, nous vous montrerons un aperçu de la version finale. Vérifiez bien ce fichier ! Il n’y a rien de pire que de repérer des coquilles dans un livre déjà publié. ',
  'bookstep-Manuscript-button-nextstep': 'Prochaine étape',
  'bookstep-Manuscript-resources-toolstoimprovescript': 'Support avec votre manuscrit',
  'bookstep-Manuscript-resources-wordtemplate': 'Modèle Word et paramètres',
  'bookstep-Manuscript-resources-wordtemplate-description': 'Utilisez ce modèle Word et paramètres pour la mise en page de votre manuscrit',
  'bookstep-Manuscript-resources-bookspecs': 'Spécifications pour les professionnels',
  'bookstep-Manuscript-resources-bookspecs-description': 'Paramètres pour InDesign, QuarkXPress, etc',
  'bookstep-Manuscript-resources-bookformatting': 'Mise en page de votre livre',
  'bookstep-Manuscript-resources-bookformatting-description': 'Un petit guide sur la meilleure mise en page à adopter',
  'bookstep-Manuscript-resources-services-needhelp': 'Besoin d’aide ?',
  'bookstep-Manuscript-resources-services-needhelp-description': 'Information sur nos services payants',
  'bookstep-Manuscript-UploadBlock-title-0': 'Mon manuscrit',
  'bookstep-Manuscript-UploadBlock-title-1': 'Nous convertons votre manuscrit',
  'bookstep-Manuscript-UploadBlock-title-2': 'Vérifiez votre manuscrit',
  'bookstep-Manuscript-uploadblock-stillwriting-title': 'Je suis en train d’écrire',
  'bookstep-Manuscript-uploadblock-stillwriting-description': 'Vous travaillez toujours sur votre manuscrit ? Si vous avez besoin d’inspiration, vous pouvez consulter certains de nos conseils pratiques ou jeter un coup d’œil aux différents services d’édition que nous proposons. ',
  'bookstep-Manuscript-uploadblock-stillwriting-button': 'Aide à l’écriture',
  'bookstep-Manuscript-uploadblock-stillformatting-title': 'Mise en page',
  'bookstep-Manuscript-uploadblock-stillformatting-description': 'Vous devez encore adapter la mise en page de votre manuscrit ? Utilisez nos modèles ou consultez nos services de mise en page pour vous assurer que votre manuscrit soit picobello.',
  'bookstep-Manuscript-uploadblock-stillformatting-button': 'Mise en page',
  'bookstep-Manuscript-uploadblock-conversioninprogress-title': 'Veuillez attendre s’il vous plaît, nous vérifions et convertons votre manuscrit',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-1': 'Vérifiez votre aperçu avant l’impression',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-1-desc': 'Nous avons converti votre manuscrit en un PDF prêt à être imprimé. Votre livre sera imprimé fidèlement au document, alors vérifiez-le bien ! Vous pouvez déposer une nouvelle version autant de fois que nécessaire.',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-1-previewbutton': 'Vérifier le fichier d’impression',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-3': 'Vérifiez votre aperçu avant l’impression',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-3-desc': 'Nous avons converti votre manuscrit en un PDF prêt à être imprimé. Votre livre sera imprimé fidèlement au document, alors vérifiez-le bien ! Vous pouvez déposer une nouvelle version autant de fois que nécessaire.',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-3-previewbutton': 'Vérifier le fichier d’impression',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-6': 'Vérifiez votre eBook',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-6-desc': 'Nous avons converti votre fichier en eBook. Il s’agit de la version finale de votre manuscrit, que les lecteurs recevront lorsqu’ils achèteront votre livre, alors assurez-vous bien qu’elle vous convienne ! Vous pouvez déposer une nouvelle version de votre manuscrit autant de fois que nécessaire.',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-6-previewbutton': 'Vérifier votre eBook',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-7': 'Vérifiez votre PDF eBook',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-7-desc': 'Nous avons converti votre fichier en PDF. Il s’agit de la version finale de votre manuscrit, que les lecteurs recevront lorsqu’ils achèteront votre livre, alors assurez-vous bien qu’elle vous convienne ! Vous pouvez déposer une nouvelle version de votre manuscrit autant de fois que nécessaire.',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-7-previewbutton': 'Vérifier votre PDF eBook',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-uploadnewbutton': 'Déposer',
  'bookstep-Manuscript-tips-title': 'Conseils pour un beau manuscrit',
  'bookstep-Manuscript-tips-print-sizeinterior-label': 'Mise en page intérieure / Spécifications',
  'bookstep-Manuscript-tips-print-sizeinterior-word': 'Si vous utilisez Word, nous vous recommandons de télécharger un de nos modèles de manuscrit en cliquant sur le bouton ci-dessus. Ce modèle est spécialement créé pour le type de livre que vous avez sélectionné, ce qui signifie que vous n’aurez pas à vous soucier de la mise en page, et vous pourrez simplement y ajouter votre texte. C’est le moyen le plus simple de s’assurer que la mise en page de votre livre corresponde à celle de votre manuscrit.',
  'bookstep-Manuscript-tips-print-sizeinterior-indesign': 'Si vous utilisez InDesign ou un autre programme de mise en page professionnelle, cliquez sur le bouton intitulé “Caractéristiques de livre”. Vous y trouverez toutes les informations dont vous avez besoin pour une mise en page parfaitement réussie.',
  'bookstep-Manuscript-tips-print-images-label': 'Comment ajouter des images à votre manuscrit',
  'bookstep-Manuscript-tips-print-images': 'Vous pouvez bien sûr ajouter des images à votre manuscrit si vous le souhaitez. Une image d’une résolution de minimum 250 dpi est suffisante pour garantir une impression de haute qualité. Placez simplement les images là où vous voulez qu’elles apparaissent dans votre manuscrit (tenez compte du débordement si vous placez des images près des bords). Si vous voulez faire une page double (une image qui couvre deux pages), vous pouvez couper l’image en deux et placer la partie la plus à gauche sur une page paire, et la partie la plus à droite sur la page impaire suivante.',
  'bookstep-Manuscript-tips-print-colophon-label': 'Page de colophon',
  'bookstep-Manuscript-tips-print-colophon-p1': 'Il est de coutume que votre livre commence par une page de titre et une page de colophon. En général, l’ordre est le suivant:',
  'bookstep-Manuscript-tips-print-colophon-firstpage': 'Première page: Expose le titre du livre',
  'bookstep-Manuscript-tips-print-colophon-secondpage': 'Deuxième page: Vierge',
  'bookstep-Manuscript-tips-print-colophon-thirdpage': 'Troisième page: Titre du livre, suivi par le nom d’auteur',
  'bookstep-Manuscript-tips-print-colophon-fourthpage': 'Quatrième page: C’est la page de colophon, qui inclut le nom d’auteur, l’ISBN (si vous en avez un), le nom du créateur de la couverture, le copyright, l’année de la publication, et la mention “publié via : {publicbrand}”',
  'bookstep-Manuscript-tips-print-colophon-p2': 'Ces pages sont communément appelées “pages liminaires” d’un livre. Les pages énumérées ci-dessus ne sont pas numérotées. Cela signifie que la numérotation de vos pages commence à la page 5. Nos modèles sont déjà configurés conformément à cette norme.',
  'bookstep-Manuscript-tips-print-formattinginterior-label': 'Mise en page de votre manuscrit',
  'bookstep-Manuscript-tips-print-formattinginterior': 'Si la mise en page de votre manuscrit n’est pas correctement établie, votre livre perdra de sa valeur. Pour vous éviter de passer à côté de potentiels lecteurs, nous avons rédigé un guide complet sur comment adopter une mise en page professionnelle.',
  'bookstep-Manuscript-tips-print-formattinginterior-link': 'Faire la mise en page de votre manuscrit',
  'bookstep-Manuscript-tips-print-minimumpagecount-label': 'Nombre minimum de pages',
  'bookstep-Manuscript-tips-print-minimumpagecount': 'Pour garantir une impression de qualité, les livres doivent contenir un certain nombre de pages. Le nombre de pages requis varie en fonction du type de livre. Le type de couverture, le type de papier ainsi que les dimensions influencent le nombre minimal de pages ; ces éléments influencent le grammage d’un livre. Par exemple, le nombre de pages requis pour un livre à couverture souple est inférieur à celui d’un livre à couverture rigide. À la première étape du processus de création d’un livre, quand vous choisissez vos critères, nous affichons le nombre minimum de pages requis pour le livre que vous avez sélectionné',
  'bookstep-Manuscript-tips-print-pageorientation-label': 'Orientation des pages',
  'bookstep-Manuscript-tips-print-pageorientation': 'Même si cela peut sembler trivial ou évident, il est bon de le mentionner : la première page de votre manuscrit est toujours à droite.  En effet, l’intérieur de la couverture, page vierge, ne compte pas comme première page. Les pages de gauche sont toujours en nombre pair. Ainsi, lorsque deux pages sont adjacentes, il y aura toujours une page paire à gauche et un numéro de page impair à droite. Gardez cela à l’esprit si vous envisagez la création d’un livre photo, pour construire une bonne répartition. Notez que nous n’ajouterons pas les numéros de page à votre place: c’est un point dont vous devez vous charger avant de nous envoyer votre manuscrit. Nos modèles comprennent quant à eux des numéros de page. Si la numérotation des pages vous pose problème, téléchargez un de nos modèles et travaillez à partir de cela.',
  'bookstep-Manuscript-tips-epub-epubexplain-label': 'À propos des ePubs',
  'bookstep-Manuscript-tips-epub-epubexplain': 'Un fichier pour eBook (appelé ePub) peut fonctionner un peu différemment de ce à quoi vous vous attendez. Par exemple, un ePub n’a pas de numéro de page préétabli. Il est "façonnable", ce qui signifie que le texte s’adapte à la taille de l’écran sur lequel il est lu. Sur un écran plus petit, les phrases seront raccourcies et le nombre total de pages augmentera. L’inverse se produira sur un appareil doté d’un écran plus grand. En résumé, la mise en page d’un livre électronique dépend de l’appareil utilisé par le lecteur.',
  'bookstep-Manuscript-tips-epub-usingword-label': 'En utilisant Word',
  'bookstep-Manuscript-tips-epub-usingword': 'Vous pouvez créer des ePubs à l’aide de Word, mais il y a quelques règles que vous devez respecter pour vous assurer qu’ils soient bien conçus. Par exemple, lorsque vous souhaitez ajouter un saut de page, n’appuyez pas simplement sur la touche “enter” ! Si vous le faites, vous créerez des lignes vides dans votre ePub, et vos lecteurs recevront de grands espaces dans le texte. Nous vous recommandons de lire notre guide sur la création d’un ePub avec Word avant de commencer. Vous pouvez le lire en cliquant sur “Mise en page de manuscrit” en haut de cette page.',
  'bookstep-Manuscript-tips-epub-images-label': 'Ajout d’images',
  'bookstep-Manuscript-tips-epub-images': 'Vous voulez ajouter des images à votre eBook ? Aucun problème ! Gardez simplement à l’esprit que si vous créez votre ePub dans Word, vous devez vous assurer que vos images soient “alignées sur le texte” et non “avec habillage du texte”. Vous pouvez vérifier et modifier cela en utilisant “l’option de disposition” sous l’onglet de mise en page des images dans Word.',
  'bookstep-Manuscript-tips-epub-cover-label': 'Inclure la couverture dans l’ePub VS la créer séparément',
  'bookstep-Manuscript-tips-epub-cover': 'Lorsque vous déposez un fichier ePub, vous avez la possibilité d’inclure une couverture, mais ce n’est pas obligatoire. Si votre couverture répond à nos critères, nous l’intégrerons automatiquement et l’afficherons lors de l’étape de conception de la couverture. En revanche, si le manuscrit que vous déposez est sous forme de fichier Word, il ne peut pas contenir votre couverture: vous devrez alors insérer ou créer votre couverture à l’étape “Conception de la couverture”.',
  'bookstep-Manuscript-tips-epub-calibre-label': 'Calibre: réalisez votre ePub parfait',
  'bookstep-Manuscript-tips-epub-calibre': 'Si vous voulez créer votre propre ePub à partir de zéro, nous vous recommandons Calibre. Il s’agit d’un programme facile d’utilisation qui vous permet de créer un fichier ePub. Vous pouvez même importer des documents Word et les utiliser comme base pour votre ePub. Pour plus d’informations, consultez nos guides sur la création d’un ePub avec Calibre.',
  'bookstep-Manuscript-tips-epub-checkepub-label': 'Vérifiez votre ePub!',
  'bookstep-Manuscript-tips-epub-checkepub': 'Le fichier ePub que nous convertissons à partir du manuscrit que vous aurez déposé est la version finale. Autrement dit: ce fichier correspond à ce que vos lecteurs recevront en achetant votre eBook. Il est donc important que vous preniez bien le temps de le vérifier attentivement.  Pour ce faire, utilisez un logiciel ePub fiable, tel que Calibre, Adobe Digital Editions, Aldiko (pour Android) ou Apple Books (pour iOS). Vous pouvez également utiliser une liseuse (par exemple un Kindle).',

  'bookstep-Manuscript-popup-bookspecs-bookspecs': 'Spécifications du livre',
  'bookstep-Manuscript-popup-bookspecs-selectboard-word': 'Microsoft Word',
  'bookstep-Manuscript-popup-bookspecs-selectboard-word-description': 'Utilisez ce modèle Word et paramètres pour la mise en page de votre manuscrit',
  'bookstep-Manuscript-popup-bookspecs-selectboard-prof': 'Spécifications pour les professionnels',
  'bookstep-Manuscript-popup-bookspecs-selectboard-prof-description': 'Paramètres pour InDesign, QuarkXPress, etc.',
  'bookstep-Manuscript-popup-bookspecs-manuscriptspecs': 'Spécifications du manuscrit',
  'bookstep-Manuscript-popup-bookspecs-pagesize': 'Taille de la page (hors fond perdu) : {sizes}',
  'bookstep-Manuscript-popup-bookspecs-pagesize-inclbleed': 'Taille de la page, fond perdu inclus : {sizes}',
  'bookstep-Manuscript-popup-bookspecs-pagesize-nospecificreq': 'aucun critère spécifique',
  'bookstep-Manuscript-popup-bookspecs-bleed': 'Fond perdu : {bleeds}',
  'bookstep-Manuscript-popup-bookspecs-bleed-nobleedreq': 'pas besoin de fond perdu',
  'bookstep-Manuscript-popup-bookspecs-bleed-top': 'Haut',
  'bookstep-Manuscript-popup-bookspecs-bleed-bottom': 'Bas',
  'bookstep-Manuscript-popup-bookspecs-bleed-outside': 'Grand fond',
  'bookstep-Manuscript-popup-bookspecs-bleed-inside': 'Petit fond',
  'bookstep-Manuscript-popup-bookspecs-nopagenumheadfoot': 'Ne pas ajouter numéro de la page, l’en tête et le pied de la page.',
  'bookstep-Manuscript-popup-bookspecs-imagesinline': 'Vérifiez que vous avez mis les images “alignées avec le texte”. N’utilisez pas des images flottantes',
  'bookstep-Manuscript-popup-bookspecs-uploadspecs': 'Spécifications d’importer',
  'bookstep-Manuscript-popup-bookspecs-fileformats': 'Format de fichier : {formats}',
  'bookstep-Manuscript-popup-bookspecs-noprintmarks': 'Pas de marques d’impression, de découpage ou de fond perdu',
  'bookstep-Manuscript-popup-bookspecs-nospreads': 'Ne pas utiliser des ecarts',
  'bookstep-Manuscript-popup-bookspecs-imagesdpi': 'Images : min. {dpinum} dpi',
  'bookstep-Manuscript-popup-bookspecs-word-wordtemplate': 'Modèle Word',
  'bookstep-Manuscript-popup-bookspecs-word-wordtemplate-p1': 'Pour la mise en page de votre manuscrit depuis Microsoft Word, nous vous recommandons vivement d’utiliser notre modèle. C’est un modèle créé spécifiquement pour correspondre au format de votre livre ; tous les paramètres nécessaires sont déjà enregistrés. Cliquez sur le bouton ci-dessous pour télécharger votre modèle.',
  'bookstep-Manuscript-popup-bookspecs-word-downloadtemplate': 'Télécharger le modèle',
  'bookstep-Manuscript-popup-bookspecs-word-therightsizemargins': 'Régler les marges et les dimensions de la page',
  'bookstep-Manuscript-popup-bookspecs-word-therightsizemargins-p1': 'Si vous utilisez notre modèle, vous n’avez pas à vous tracasser des marges et fonds perdus. Les paramètres adéquats ont déjà été appliqués au document.',
  'bookstep-Manuscript-popup-bookspecs-word-therightsizemargins-p2': 'Si vous n’utilisez pas notre modèle, vous devrez vous assurer que la dimension des pages de votre manuscrit corresponde au format de livre que vous avez sélectionné. Si ce n’est pas le cas, la taille des pages de votre fichier importé sera automatiquement adaptée aux dimensions du format choisi ; ce qui peut potentiellement modifier votre mise en page. En plus de cela, vous devrez tenir compte du fond perdu et l’ajouter à vos marges.',
  'bookstep-Manuscript-popup-bookspecs-word-exampleimage-representsbleed': 'représente le fond perdu',
  'bookstep-Manuscript-popup-bookspecs-word-whatisbleed': 'Qu’est-ce que le fond perdu ?',
  'bookstep-Manuscript-popup-bookspecs-word-whatisbleed-p1': 'Pour éviter tout espace blanc indésirable sur le bord des pages au moment de la découpe, il est nécessaire que la couleur ou l’image en arrière plan soit légèrement plus grande que la taille réelle de la page. Cette marge supplémentaire est ce que nous appelons le “fond perdu”. Si ladite couleur ou image originale ne s’étend pas jusqu’au bord de la page, ignorer le fond perdu ne vous donnera pas d’espace blanc indésirable. Cependant, de légers changements de mise en page peuvent survenir, lors d’une potentielle redimension automatique de votre document, afin d’obtenir ce fond perdu. Vous pourrez constater les potentielles modifications dans l’aperçu avant impression, après avoir importé le fichier de votre manuscrit.',
  'bookstep-Manuscript-popup-bookspecs-exampleimage-leftpage-odd': 'Page de gauche (impair)',
  'bookstep-Manuscript-popup-bookspecs-exampleimage-leftpage-even': 'Page de gauche (pair)',
  'bookstep-Manuscript-popup-bookspecs-exampleimage-rightpage-odd': 'Page de droite (impair)',
  'bookstep-Manuscript-popup-bookspecs-exampleimage-rightpage-even': 'Page de droite (pair)',
  'bookstep-Manuscript-popup-services-needhelp': 'Besoin d’aide ?',
  'bookstep-Manuscript-popup-services-doyouneedsupport': 'Besoin d’aide avec votre livre ?',
  'bookstep-Manuscript-popup-services-weofferpaidservices': 'Nous disposons d’une série de services éditoriaux abordables pour vous aider à réaliser un (très) beau livre. Notre équipe de professionnels peut vous aider dans les domaines suivants : la mise en page, relecture, vérification de l’orthographe, les corrections, la création d’un fichier ePub professionnel, etc. ',
  'bookstep-Manuscript-popup-services-areyouinterested': 'Contactez-nous pour plus d’informations en cliquant sur le bouton ci-dessous. Nous reviendrons vers vous dans un délai de 2 jours ouvrables.',
  'bookstep-Manuscript-popup-services-contactme': 'Ça m’intéresse',
  'bookstep-Manuscript-popup-services-requestsent': 'Nous avons reçu votre demande',
  'bookstep-Manuscript-popup-services-wehavesentrequest': 'Nous avons reçu votre demande',
  'bookstep-Manuscript-popup-services-wewillcontactyousoon': 'Nous avons bien reçu votre demande d’informations supplémentaires sur nos services éditoriaux. Notre équipe vous apportera plus de précisions dans les deux jours ouvrables.',
  
  'bookstep-Manuscript-guidepopup-wordtips': 'Conseils pour Microsoft Word',
  'bookstep-Manuscript-guidepopup-wordtips-description': 'Nos conseils pour créer un excellent livre en utilisant Microsoft Word',
  'bookstep-Manuscript-guidepopup-pdffontsincl': 'Créer un PDF',
  'bookstep-Manuscript-guidepopup-pdffontsincl-description': 'Un petit guide sur la création d’un fichier PDF impeccable',
  'bookstep-Manuscript-guidepopup-createepub': 'Créer un ePub',
  'bookstep-Manuscript-guidepopup-createepub-description': 'Un petit guide sur la création d’un fichier ePub impeccable',
  'bookstep-Manuscript-guidepopup-colorpages': 'Guide de pages en couleurs',
  'bookstep-Manuscript-guidepopup-colorpages-description': 'Nos conseils pour bien organiser vos pages en couleurs',

  // Bookstep: Writing
  'bookstep-Writing': 'Écriture',
  'bookstep-Writing-intro-p1': 'Écrire un livre est l’une des activités les plus gratifiantes qui soit, mais on vous l’accorde, ce n’est pas toujours la plus facile. Sans pour autant pouvoir écrire votre livre à votre place, nous pouvons vous soutenir tout au long du processus. Que vous écriviez de la fiction ou de la non-fiction, il est impératif que vous structuriez votre processus d’écriture. La plupart des écrivains qui ne le font pas démarrent sur une note positive et se retrouvent bloqués en cours de route. Alors rendez-vous service : dressez un plan et respectez-le. Vous ne savez pas comment faire ? Consultez notre guide.',
  'bookstep-Writing-helpwithwriting-title': 'Aide à la rédaction',
  'bookstep-Writing-createwritingplan-title': 'Guide de rédaction',
  'bookstep-Writing-writeblurb-title': 'Rédigez le texte de présentation',
  'bookstep-Writing-writeblurb-p1': 'Notre conseil : commencez par rédiger le texte de présentation. De cette façon, vous serez obligé de considérer la trame dans son ensemble. Vous allez sans doute écrire plus d’une version de votre texte de présentation. Partagez vos essais avec d’autres personnes et demandez-leur leur avis.',
  'bookstep-Writing-writeblurb-p2': 'Bien qu’un texte de présentation ne soit pas très long, distiller l’essence de votre livre en quelques phrases est un exercice incomparable. Ne le négligez pas : il vous aidera à créer un plan d’écriture solide et servira de base au processus d’écriture lui-même. Soyons clairs : ce texte bref détient le pouvoir de favoriser ou de freiner la vente d’un livre. C’est l’un des éléments principaux que les lecteurs prennent en compte avant de décider de s’intéresser à un livre.',
  'bookstep-Writing-writeblurb-howtowriteblurb': 'Comment écrire un bon texte de présentation',
  'bookstep-Writing-writeblurb-howtowriteblurb-p1': 'Comme toute chose, un bon moyen de se renseigner sur la façon d’écrire un bon texte de présentation est de consulter ce qui a déjà été fait. Parcourez donc différents textes de présentation. Cela vous donnera une idée plus précise des meilleures pratiques pour un texte de présentation correspondant au genre que vous avez choisi. Notez que quel que soit le genre de livre que vous écrivez, il existe des critères généraux que tout texte de présentation doit remplir :',
  'bookstep-Writing-writeblurb-howtowriteblurb-list1': 'Une première phrase captivante : Vous devez intriguer le lecteur dès le début.  Vous pouvez vous aider d’une citation prometteuse, d’une question percutante, ou encore d’une solution à un problème.',
  'bookstep-Writing-writeblurb-howtowriteblurb-list2': 'Un résumé : Vous devez donner au lecteur un aperçu de l’histoire sans pour autant dévoiler les principaux éléments de l’intrigue. ',
  'bookstep-Writing-writeblurb-howtowriteblurb-list3': 'Une promesse : Le lecteur veut savoir ce qu’il peut attendre de votre livre. Si vous écrivez une fiction, créez un suspense ou laissez entendre un drame. S’il s’agit de non-fiction, avancez des solutions à des problèmes ou des connaissances sur un sujet.',
  'bookstep-Writing-writeblurb-howtowriteblurb-list4': 'À propos de l’auteur : Particulièrement pertinent pour les ouvrages non fictionnels. Veillez à inclure des informations sur vous-même en rapport avec le livre : expériences de vie, autres livres que vous avez écrits, etc.',
  'bookstep-Writing-writeblurb-howtowriteblurb-list5': 'Photo de l’auteur : C’est un élément facultatif, mais si vous l’incluez, choisissez une photo d’une résolution suffisante (300 dpi) pour un aspect professionnel.',
  'bookstep-Writing-createplan-title': 'Préparez un plan d’écriture',
  'bookstep-Writing-createplan-p1': 'Certes, l’écriture, comme tout processus créatif, ne peut pas toujours être forcée. Néanmoins, nous vous conseillons vivement de créer un plan d’écriture. Divisez ce plan en 2 parties :',
  'bookstep-Writing-createplan-list1': 'Planifier : Quand allez-vous écrire ? Quelle sera la durée de chaque session d’écriture ? Ces choix vous appartiennent. Du calme du petit matin au silence de la nuit : à vous de choisir le moment qui vous inspire le plus. Créez un plan pour votre livre, divisez-le en chapitres et faites un plan pour chaque chapitre. Ne vous sentez pas obligé de commencer par le début : commencez à n’importe quel moment de l’histoire, du moment que cela vous appelle à rédiger.',
  'bookstep-Writing-createplan-list2': 'La trame : définissez d’abord les grandes lignes de votre récit ; quels personnages allez-vous présenter et comment vont-ils évoluer ? Comment allez-vous parvenir aux principaux points de l’intrigue ? La formule la plus populaire pour une intrigue est probablement "le périple du héros". C’est tout aussi vrai pour les livres de non-fiction. Les gens ont tendance à aimer suivre un protagoniste qui surmonte des obstacles et grandit en tant que personne.',
  'bookstep-Writing-writefeedbackrewrite-title': 'Écrire, écrire, feed-back, réécrire',
  'bookstep-Writing-writefeedbackrewrite-p1': 'Maintenant que vous avez tracé les grandes lignes de l’intrigue principale, le temps est venu d’entrer dans les détails. C’est là que vous commencez le réel processus d’écriture. Ne vous découragez pas: vous ne devez pas forcément développer votre récit du premier chapitre jusqu’au dernier. Vous pouvez tout aussi bien commencer au début, au milieu ou à la fin: choisissez simplement l’endroit qui vous inspire le plus sur le moment. C’est généralement une bien meilleure méthode que de se forcer à tout écrire dans l’ordre.',
  'bookstep-Writing-writefeedbackrewrite-p2': 'Dès que vous avez terminé quelques chapitres, demandez à vos amis et/ou votre famille de lire votre travail et de vous donner un avis honnête. Veillez à choisir des personnes qui n’hésiteront pas à formuler des critiques constructives ; la flatterie ne vous servira à rien. Il y a quelques points que vous devez garder à l’esprit en ce qui concerne votre écriture :',
  'bookstep-Writing-writefeedbackrewrite-list1': 'Allez-vous écrire à la première ou troisième personne ?',
  'bookstep-Writing-writefeedbackrewrite-list2': 'Au présent ou au passé ?',
  'bookstep-Writing-writefeedbackrewrite-list3': 'La constance est clé.',
  'bookstep-Writing-writefeedbackrewrite-list4': 'Montrez plutôt que raconter.',
  'bookstep-Writing-writefeedbackrewrite-list5': 'Créez une tension et une certaine atmosphère qui attire l’attention.',
  'bookstep-Writing-feedbackediting-title': 'Feedback et corrections',
  'bookstep-Writing-feedbackediting-p1': 'L’écriture est une forme d’art, et les artistes ont tendance à ne pas être sûrs de leur propre travail. Ne vous laissez pas décourager par le doute, c’est un élément naturel du processus. Utilisez plutôt ce sentiment en demandant à vos réviseurs des critiques constructives ; votre histoire n’en sera que meilleure.',
  'bookstep-Writing-feedbackediting-p2': 'Une fois votre manuscrit terminé, faites-le relire par le plus de personnes possible (pourquoi pas en échange d’un café !). N’oubliez pas que la plupart des gens préfèrent encore lire sur papier. Il peut donc être judicieux d’imprimer quelques copies de votre manuscrit pour le confort de vos relecteurs. La plupart des gens sont flattés lorsqu’on leur demande de relire un travail ; ils seront heureux de vous aider.',
  'bookstep-Writing-feedbackediting-p3': 'Assurez-vous bien que votre manuscrit soit exempt de fautes d’orthographe et de grammaire. Rien ne dévalorise un récit autant qu’une faute d’orthographe. En fonction de vos ambitions et de vos ressources, vous pouvez opter pour nos services de relecture ou de quick scan. Un expert lira alors soit l’ensemble de votre manuscrit, soit une partie de celui-ci, et vous donnera son avis sur le style, la cohérence et constance, l’intrigue, le développement des personnages, etc.',
  'bookstep-Writing-nextstep': 'Prochaine étape: mise en page',
  'bookstep-Writing-uploadnow': 'Importer directement',
  'bookstep-Writing-tips-title': 'Conseils de rédaction',
  'bookstep-Writing-tips-titlesubtitle-label': 'Titre et sous-titre',
  'bookstep-Writing-tips-titlesubtitle': 'Vous avez déjà un titre et un sous-titre pour votre livre ? Dans ce cas, vous pouvez sauter cette étape, mais la plupart des gens ont du mal à se décider pour un titre. Nous vous recommandons de noter toutes vos idées de titres en brainstorming. En ayant toutes vos idées sous les yeux, vous parviendrez à identifier plus facilement les meilleures, et peut-être même qu’une idée triomphante sortira du lot. Un conseil : montrez vos trois meilleurs titres potentiels à vos amis ou à ceux qui vous suivent sur les réseaux sociaux et leur demandez lequel ils préfèrent. En définitive, les gens tiendront compte de trois éléments principaux pour déterminer s’ils sont prêts à acheter votre livre : la couverture (y compris le titre), le texte de présentation, et la description. Vous l’aurez compris : consulter l’opinion publique sur ces éléments en vaut la peine.',
  'bookstep-Writing-tips-blurb-label': 'Texte de présentation',
  'bookstep-Writing-tips-blurb': 'Si vous parvenez à susciter l’intérêt d’un lecteur avec votre couverture, il y a de fortes chances qu’il retourne alors le livre pour lire le texte de présentation. C’est le moment clé dans la prise de décision des lecteurs, et c’est ce qui conclura ou non une vente. Qu’il s’agisse d’un livre de fiction ou non, il y a quelques éléments qui intéresseront vos lecteurs potentiels : le texte est-il attrayant, le livre résout-il un problème ou pose-t-il une question intéressante qui éveille leur curiosité ? Le texte de présentation doit laisser le lecteur sur sa faim. Si vous y parvenez, il y a de bonnes chances que votre livre s’achète. Demandez à différentes personnes de relire votre texte de présentation et demandez-leur leur avis : achèteraient-elles votre livre sur base du texte de présentation ?',
  'bookstep-Writing-tips-howdoyoufeelabouit-label': 'Qu’en pensez-vous?',
  'bookstep-Writing-tips-howdoyoufeelabouit': 'Demandez à certains de relire votre travail dès le commencement. Votre manuscrit ne doit pas nécessairement être achevé pour que vous en retiriez des commentaires avisés. Que vous fassiez lire une seule page ou plusieurs chapitres, demandez des commentaires honnêtes. En général, les gens apprécieront de vous aider et de lire votre travail. Vous pouvez également publier un chapitre sur une plateforme de réseaux sociaux de votre choix et demander des commentaires de cette manière. Cette dernière méthode vous permet de faire d’une pierre deux coups : vous recevez un feedback et vous faites la promotion de votre livre simultanément.',
  'bookstep-Writing-tips-marketing-label': 'Marketing',
  'bookstep-Writing-tips-marketing': '“Marketing? J’ai même pas encore fini d’écrire mon livre!” Précisément. C’est le moment de penser à comment vous allez promouvoir votre livre. À ce stade précoce, vous pouvez commencer modestement en suscitant un certain intérêt. Partagez votre parcours d’écrivain sur les réseaux sociaux, expliquez comment ça se passe, quelles sont les difficultés que vous rencontrez. Partagez votre couverture lorsqu’elle est prête. Publiez des extraits de vos chapitres terminés. Vous recevrez sûrement de nombreux encouragements ! Ce ne sont là que quelques idées que vous pouvez inclure dans votre plan marketing. Ne négligez pas cet aspect : c’est comme ça que vous pourrez vous constituer un réseau fidèle. Cela vous aidera à faire connaître votre livre une fois qu’il sera publié.',
  'bookstep-Writing-tips-support-label': 'Aide',
  'bookstep-Writing-tips-support': 'Vous vous trouvez dans une impasse et avez besoin d’aide, ou vous aimeriez un avis professionnel sur votre manuscrit ? Nous proposons plusieurs formules de services abordables, qui vous permettent d’envoyer votre manuscrit à un écrivain, un relecteur ou un coach, et de lui demander d’améliorer votre manuscrit. Vous pouvez opter pour un quick scan,  une vérification orthographique, une évaluation complète et même des ateliers workshop. Si vous êtes vraiment sérieux dans la préparation de votre livre, assurez-vous d’impliquer votre réseau et/ou d’engager l’un de nos relecteurs pour vous aider à réaliser le meilleur livre possible.',
  'bookstep-Writing-tips-dontgiveup-label': 'Ne baissez pas les bras !',
  'bookstep-Writing-tips-dontgiveup': 'Tout auteur se heurte de temps en temps à un obstacle : désillusion, syndrome de la page blanche, etc. N’abandonnez pas ! Si nécessaire, faites une pause et allez faire autre chose pendant un moment. Allez vous promener, socialisez un peu, ou distrayez-vous d’une autre manière. Votre motivation reviendra en temps voulu.',

  // Bookstep: Formatting
  'bookstep-Formatting': 'Mise en page',
  'bookstep-Formatting-intro-p1': 'Le texte est de toute évidence la partie la plus importante de votre manuscrit, mais sans une bonne mise en page, il y a de fortes chances que personne ne veuille le lire, quelle qu’en soit la qualité. Un manuscrit bien mis en page permet une lecture sans effort, permettant au lecteur de se perdre dans votre histoire sans être distrait ou confus. Vous pouvez bien sûr faire la mise en page vous-même, mais vous pouvez aussi demander à un professionnel de la faire pour vous. Si vous optez pour cette dernière solution, nous vous invitons à consulter notre service de mise en page. Vous souhaitez la configurer vous-même ? Consultez nos directives pour vous assurer d’obtenir le meilleur résultat possible.',
  'bookstep-Formatting-helpwithformatting-title': 'Aide pour la mise en page',
  'bookstep-Formatting-guide-title': 'Guide de mise en page',
  'bookstep-Formatting-guide-print-template-title': 'Modèle',
  'bookstep-Formatting-guide-print-template-p1': 'Si vous prévoyez de faire la mise en page de votre manuscrit vous-même, nous vous recommandons de travailler sur base d’un document qui a été préalablement configuré à cet égard. Cela vous épargnera du temps et des efforts par la suite. Si vous utilisez Word, nous vous proposons d’utiliser nos modèles : ils sont configurés pour correspondre aux caractéristiques du modèle de livre que vous avez choisi. Ils tiennent aussi compte des pages de titre et de colophon, des numéros de page et des marges. Si vous avez déjà terminé votre manuscrit, vous pouvez simplement copier le texte sur le modèle ; mais ne copiez pas tout en même temps ! Procéder de la sorte écraserait les paramètres du modèle, ce qui va à l’encontre du but-même de son utilité. Copiez-collez votre texte section par section, en adaptant les caractéristiques de votre copie originale à la version du modèle.',
  'bookstep-Formatting-guide-print-template-p2': 'Si vous concevez votre manuscrit à l’aide d’un programme de mise en page professionnel comme InDesign, configurez votre document en fonction des caractéristiques du format de livre que vous avez choisi. Assurez-vous de disposer d’une marge de 3 mm autour des bords extérieurs de chaque page (sans compter la "gouttière", c’est-à-dire les marges intérieures). Vous pouvez obtenir les caractéristiques exactes de votre livre en cliquant sur le bouton "Caractéristiques de livre" ci-dessus.',
  'bookstep-Formatting-guide-print-template-p3': 'N’oubliez pas : une page impaire est toujours à droite, et une page paire est toujours à gauche.',
  'bookstep-Formatting-guide-print-fonts-title': 'Polices, tailles de police et interlignes',
  'bookstep-Formatting-guide-print-fonts-p1': 'Nous n’allons pas vous dire quelle police utiliser. C’est un choix personnel qui varie en fonction du type de livre. La première décision à prendre est la suivante : une police avec ou sans empattement ? Les empattements sont les petites lignes attachées aux extrémités des lettres. C’est un élément qui contribue largement à la bonne lisibilité d’un texte imprimé. Les polices avec empattement sont le choix classique pour les livres et les journaux imprimés. Les plus populaires sont Cambria, Garamond, Georgia et Times New Roman.',
  'bookstep-Formatting-guide-print-fonts-p2': 'Les polices sans empattement n’ont pas ces lignes, ce qui donne une esthétique plus moderne et minimaliste. On retrouve fréquemment ces polices sur l’écran : elles favorisent la lisibilité du texte en ligne. Les polices sans empattement les plus populaires sont Arial, Calibri, Helvetica et Verdana.',
  'bookstep-Formatting-guide-print-fonts-p3': 'En plus de cela, vous devrez choisir une taille de police et un interligne. Là encore, il n’y a pas de bon choix, tout dépend de vos préférences personnelles. En général, les tailles de police les plus courantes sont 10, 11 et 12, selon la police utilisée. Si votre livre s’adresse à un public plus âgé, utilisez une taille de police plus grande et un interligne plus important (la norme pour l’interligne se situe entre 1,1 et 1,3). Imprimer quelques pages de votre manuscrit est un bon moyen de vérifier si vous êtes satisfait de votre police et de votre interligne.',
  'bookstep-Formatting-guide-print-moreoptions-title': 'Encore plus d’options de mise en page',
  'bookstep-Formatting-guide-print-moreoptions-p1': 'Nous n’en avons pas encore fini ; il y a encore quelques choix à faire avant de vous lancer. Voici une liste des plus importants :',
  'bookstep-Formatting-guide-print-moreoptions-list1': 'Disposition de la page : La principale chose à considérer ici, ce sont les marges. Veillez à ce qu’elles ne soient pas trop petites : environ 2 cm, avec un peu plus pour la gouttière (les côtés des pages tournés vers l’intérieur où le livre est relié). Une partie des marges intérieures des pages disparaîtra, car elles seront utilisées pour relier le livre. Nos modèles sont conçus pour s’y adapter, vous n’aurez donc pas à vous soucier des marges. Si vous ne souhaitez pas utiliser nos modèles, utilisez les marges suivantes :',
  'bookstep-Formatting-guide-print-moreoptions-list1-topmargin': 'Marge supérieure: {size}',
  'bookstep-Formatting-guide-print-moreoptions-list1-bottommargin': 'Marge inférieure: {size}',
  'bookstep-Formatting-guide-print-moreoptions-list1-innermargin': 'Marge intérieure: {size}',
  'bookstep-Formatting-guide-print-moreoptions-list1-outermargin': 'Marge extérieure: {size}',
  'bookstep-Formatting-guide-print-moreoptions-list2': 'Nouveaux chapitres : Commencez chaque nouveau chapitre sur une nouvelle page. Vous pouvez donner à vos chapitres des noms, des numéros, ou les deux. Si vous utilisez Word, veillez à utiliser le style “Titre 1” pour le titre/numéro de votre chapitre. De cette façon, vous vous assurez que le format est le même pour chaque chapitre.',
  'bookstep-Formatting-guide-print-moreoptions-list3': 'Paragraphes : Vous pouvez séparer les paragraphes par des espaces, ce qui améliore le confort de lecture. Vous pouvez aussi choisir de mettre en retrait (alinéa) le premier mot de chaque paragraphe. Quel que soit votre choix, veillez à ce que vos paragraphes ne soient pas trop longs, pour ne pas compromettre la lisibilité de votre texte.',
  'bookstep-Formatting-guide-print-moreoptions-list4': 'Images et texte : Si le contenu de votre livre alterne entre texte et images, comme un livre de recettes, placez vos images sur les pages paires (à gauche) et votre texte sur les pages impaires (à droite).',
  'bookstep-Formatting-guide-print-uploadscript-title': 'Importer votre manuscrit',
  'bookstep-Formatting-guide-print-uploadscript-p1': 'Vous pouvez travailler sur votre manuscrit en utilisant le programme de votre choix, mais sachez que nous n’accepterons que les fichiers Word (.doc ou .docx) et PDF lors de l’envoi de votre fichier. Si vous utilisez un programme autre que Word (par exemple : Pages), vous devrez alors convertir votre fichier en PDF avant de nous l’envoyer.',
  'bookstep-Formatting-guide-print-uploadscript-p2': 'Même si votre manuscrit a été réalisé sur Word, nous vous recommandons de le convertir en PDF avant de nous l’envoyer. Cela réduira les risques d’erreurs de mise en page au moment de la conversion. Veillez à ce que chaque page de votre manuscrit corresponde à une page distincte dans votre PDF converti, sinon le résultat ne sera pas concluant. Une fois que vous avez déposé votre manuscrit, nous le convertissons en un fichier PDF adapté pour l’impression. Examinez attentivement ce fichier converti pour vous assurer que chaque page soit correctement agencée.',
  'bookstep-Formatting-guide-print-uploadscript-p3': 'Vous n’êtes pas satisfait ? Aucun problème. Vous pouvez envoyer une nouvelle version de votre manuscrit autant de fois que nécessaire. Cette démarche remplacera toutes les versions précédentes que vous avez envoyées.',
  'bookstep-Formatting-guide-epub-howepubswork-title': 'Un ePub : comment ça marche ?',
  'bookstep-Formatting-guide-epub-howepubswork-p1': 'Avant de vous lancer dans la création de votre eBook, assurez-vous que vous comprenez vraiment comment ça fonctionne. Un eBook n’est pas comme tout autre document digital : ce que vous avez créé sur votre logiciel de traitement de texte risque de ne pas correspondre à un format de livre électronique. ',
  'bookstep-Formatting-guide-epub-howepubswork-p2': 'Tout d’abord, les eBooks publiés sur notre plateforme seront toujours au format ePub.  C’est le format standard des eBooks de par le monde : il peut donc être lu sur tous les e-readers. L’une des principales caractéristiques des fichiers ePub est qu’ils sont “façonnables”. Autrement dit, le texte du fichier s’adapte à la taille de l’écran sur lequel il est lu. Sur un écran plus petit, les lignes seront plus courtes et le livre aura donc plus de pages qu’il n’en aurait eu sur un écran plus grand. Ainsi, la mise en page d’un eBook dépend de l’appareil utilisé pour le lire.',
  'bookstep-Formatting-guide-epub-howepubswork-p3': 'Par conséquent, un segment de texte peut apparaître sur une seule page dans Word, mais être réparti sur plusieurs pages sur une liseuse. Alors ne vous préoccupez pas de la position exacte des lignes et des paragraphes ; assurez-vous plutôt que le texte suit un cheminement logique et que l’espacement entre les paragraphes soit régulier. Vous obtiendrez ainsi un résultat optimal.',
  'bookstep-Formatting-guide-epub-templatefonts-title': 'Modèles et polices',
  'bookstep-Formatting-guide-epub-templatefonts-p1': 'Pour la création de votre eBook sur Word, nous vous recommandons d’utiliser un de nos modèles. Ils sont conçus pour assurer une bonne mise en page d’eBook avec un minimum de complications, ce qui réduira votre charge de travail. Vous pouvez télécharger nos modèles en cliquant sur le bouton “Modèle Word” ci-dessus.',
  'bookstep-Formatting-guide-epub-templatefonts-p2': 'En ce qui concerne les différentes polices, vous ne devrez pas non plus consacrer beaucoup de temps et de réflexion pour choisir celle qui vous convient. La grande majorité des liseuses offre aux utilisateurs la possibilité de sélectionner leurs propres polices de prédilection. La police que vous avez utilisée risque donc de toute façon d’être remplacée. Nous vous conseillons donc de choisir simplement une police que vous trouvez belle et avec laquelle vous aimez travailler. Georgia et Times New Roman sont des choix classiques de police avec empattement, tandis qu’Arial, Helvetica ou Verdana feront l’affaire si vous préférez les polices sans empattement.',
  'bookstep-Formatting-guide-epub-chapterstoc-title': 'Chapitres et table des matières',
  'bookstep-Formatting-guide-epub-chapterstoc-p1': 'En créant votre eBook à partir de Word, servez-vous des différents styles qui vous sont proposés sur la barre affichant “Normal”, “Titre 1”, “Titre 2”, etc. Cela vous permettra de structurer votre eBook et votre table des matières sera ainsi générée automatiquement sur les liseuses. Vous trouverez ci-dessous une sélection des principaux styles qui vous seront utiles :',
  'bookstep-Formatting-guide-epub-chapterstoc-l1-normal': 'Normal : Utilisez-le pour le corps de texte normal de votre manuscrit.',
  'bookstep-Formatting-guide-epub-chapterstoc-l1-title': 'Titre : Utilisez-le pour écrire le titre de votre livre (page de titre).',
  'bookstep-Formatting-guide-epub-chapterstoc-l1-heading1': 'Titre 1: Sélectionnez ce style pour les titres de chapitre. Dans la table des matières, ce titre apparaîtra au sommet de la hiérarchie. Nous avons configuré nos modèles de sorte que ces titres apparaissent toujours sur une nouvelle page.',
  'bookstep-Formatting-guide-epub-chapterstoc-l1-heading2': 'Titre 2: Utilisez ce style pour les sous-titres, par exemple si vous souhaitez diviser les chapitres en sections. Dans la table des matières, ils sont directement subordonnés à au “Titre 1”.',
  'bookstep-Formatting-guide-epub-chapterstoc-l1-heading3': 'Titre 3 : Vous pouvez utiliser ce titre dans le cas peu probable où vous voudriez diviser vos sous-sections (“Titre 2”) en des sections encore plus petites. Vous verrez rarement ce type d’utilisation dans les romans, mais souvent dans les livres académiques.',
  'bookstep-Formatting-guide-epub-chapterstoc-p2': 'Si vous prévoyez de créer votre ePub manuellement, les chapitres et les titres fonctionneront un peu différemment. Il est donc important que vous procédiez ainsi :',
  'bookstep-Formatting-guide-epub-chapterstoc-l2-separatefiles': 'Assurez-vous que chaque chapitre de votre ePub se trouve dans un fichier HTML distinct. Ce fichier doit ensuite être enregistré correctement dans le fichier OPF où l’ordre des fichiers HTML est établi.',
  'bookstep-Formatting-guide-epub-chapterstoc-l2-useh1tags': 'Les chapitres doivent toujours commencer par un tag HTML “h1” dans votre fichier HTML. Ce tag h1 doit contenir le titre et/ou le numéro du chapitre.',
  'bookstep-Formatting-guide-epub-chapterstoc-l2-useh2h3tags': 'Pour les sous-titres, utilisez les tags h2, h3 ou h4 (etc). Celles-ci fonctionnent de la même manière que les Titres 1, 2, 3 (etc) dans Word. ',
  'bookstep-Formatting-guide-epub-chapterstoc-l2-generatetoc': 'N’oubliez pas de prévoir une table des matières pour votre fichier ePub. La plupart des programmes ePub peuvent le faire automatiquement au moyen d‘un fichier “NCX” qui sera ajouté à votre ePub.',
  'bookstep-Formatting-guide-epub-moreformattips-title': 'Autres astuces pour vos formats',
  'bookstep-Formatting-guide-epub-moreformattips-p1': 'En plus des conseils déjà mentionnés, il y a quelques autres éléments à prendre en compte pour la mise en page de votre eBook :',
  'bookstep-Formatting-guide-epub-moreformattips-list1': 'Numéros de page : assurez-vous que votre fichier Word ou ePub ne contienne pas de numéros de page. Comme indiqué précédemment, les paramètres individuels de chaque e-reader rendent les numéros de page superflus. Les inclure ne fera que causer des problèmes.',
  'bookstep-Formatting-guide-epub-moreformattips-list2': 'Listes numérotées : si vous souhaitez inclure une liste numérotée dans votre livre électronique, sachez que cela est possible, mais qu’il existe certaines limites. Assurez-vous que votre liste ne soit pas interrompue par un paragraphe pour reprendre plus loin dans le texte (par exemple, si les éléments de votre liste sont particulièrement longs). Les ePubs ne permettent pas cela. Les éléments de votre liste doivent donc toujours être reliés.',
  'bookstep-Formatting-guide-epub-moreformattips-list3': 'Images : vous pouvez tout à fait ajouter des images à votre fichier ePub. Assurez-vous que vos images soient au format JPG ou PNG et que leur résolution ne soit pas trop élevée (nous recommandons une limite maximum de 1000x1000 pixels).',
  'bookstep-Formatting-guide-epub-moreformattips-list4': 'Couverture : si vous importez un document Word, assurez-vous que la couverture n’y soit PAS inclue. La couverture sera créée et/ou importée à l’étape “Couverture”, plus tard dans le processus de publication. Si vous créez votre propre fichier ePub, vous pouvez, si vous voulez, avoir votre couverture déjà inclue dans le fichier. Si la résolution est suffisamment bonne, notre système exportera automatiquement la couverture à l’étape suivante.',
  'bookstep-Formatting-guide-epub-uploadscript-title': 'Importer votre manuscrit',
  'bookstep-Formatting-guide-epub-uploadscript-p1': 'Vous pouvez rédiger votre manuscrit à partir du programme de votre choix, mais nous n’acceptons que les documents Word (doc ou docx) ou ePub au moment de l’importation. Par conséquent, si vous travaillez avec un logiciel autre que Microsoft Word, vous devrez d’abord convertir votre fichier en un fichier Word ou ePub avant de l’importer sur notre plateforme.',
  'bookstep-Formatting-guide-epub-uploadscript-p2': 'Un mot d’avertissement : d’après notre expérience, la conversion de fichiers créés dans LibreOffice, Apple Pages ou d’autres applications de traitement de texte en fichiers doc ou docx ne se fait pas toujours sans heurts, ce qui peut entraîner des messages d’erreur lorsque vous tentez de déposer ces fichiers convertis.',
  'bookstep-Formatting-guide-epub-uploadscript-p3': 'Une fois votre fichier déposé, nous le convertirons en ePub. Vous pourrez ensuite consulter ce ePub à l’aide d’un e-reader ou d’une application qui lit les ePubs. Vérifiez attentivement ce fichier converti ; il doit être impeccable, car c’est ce que vos lecteurs recevront.',
  'bookstep-Formatting-guide-epub-uploadscript-p4': 'Si vous rencontrez des difficultés, vous pouvez toujours faire appel à notre service de mise en page des livres électroniques. Veuillez toutefois noter que ce service est payant.',
  'bookstep-Formatting-guide-pdfebook-template-title': 'Modèle',
  'bookstep-Formatting-guide-pdfebook-template-p1': 'Si vous prévoyez de mettre en page votre manuscrit vous-même, nous vous recommandons vivement de commencer à travailler dans un document correctement configuré le plus tôt possible ; de préférence dès le commencement. Si vous travaillez avec Microsoft Word, utilisez nos modèles. Si vous avez déjà terminé votre manuscrit, vous pouvez en copier le contenu dans le modèle, mais veillez à ne pas copier et coller l’ensemble du manuscrit en une seule fois ! Vous risqueriez de modifier la mise en page, ce qui entraînerait des problèmes lors de l’importation du fichier.',
  'bookstep-Formatting-guide-pdfebook-template-p2': 'Si vous êtes déjà satisfait de la mise en page de votre document Word, vous pouvez aussi simplement le convertir en PDF et l’importer sur la plateforme. C’est généralement une méthode fiable pour créer un livre électronique au format PDF.',
  'bookstep-Formatting-guide-pdfebook-template-p3': 'Si vous utilisez un logiciel professionnel comme InDesign, vous pouvez simplement transposer les spécifications du format de livre que vous avez choisi dans le document.',
  'bookstep-Formatting-guide-pdfebook-fonts-title': 'Polices, taille des caractères et espacement des lignes',
  'bookstep-Formatting-guide-pdfebook-fonts-p1': 'Nous n’allons pas vous dire quelle police utiliser, car c’est très subjectif et cela dépend en partie du type de livre que vous écrivez. La première décision à prendre est la suivante : utiliser une police avec ou sans empattement ? Les polices avec empattement ont de petits “pieds” à l’extrémité des traits. On considère généralement que ces pieds aident à guider les yeux du lecteur le long de la page. Les polices avec empattement sont, de loin, le type de police le plus utilisé dans les livres. Cambria, Garamond, Georgia et Times New Roman sont quelques-unes des polices à empattement les plus populaires.',
  'bookstep-Formatting-guide-pdfebook-fonts-p2': 'Les polices sans empattement sont plus simples et ont tendance à paraître plus “modernes”. Elles sont couramment utilisées en ligne et sur les couvertures de livres. Arial, Calibri, Helvetica et Verdana sont quelques-unes des polices sans empattement les plus populaires.',
  'bookstep-Formatting-guide-pdfebook-fonts-p3': 'Vous devez également choisir une taille de police et un interligne. Là encore, il n’y a pas de réponse correcte ; tout est subjectif et dépend aussi de la police que vous avez choisie. Les tailles 10, 11 et 12 sont les plus couramment utilisées. En ce qui concerne l’interligne, un espacement compris entre 1,1 et 1,3 est conventionnel.',
  'bookstep-Formatting-guide-pdfebook-fonts-p4': 'Si votre livre s’adresse à un public dont l’âge moyen est élevé, envisagez une taille de police et un interlignage plus grands. Il peut être utile d’imprimer quelques pages de votre livre pour vous faire une idée de la configuration que vous préférez.',
  'bookstep-Formatting-guide-pdfebook-moreoptions-title': 'Encore plus d’options',
  'bookstep-Formatting-guide-pdfebook-moreoptions-p1': 'Nous n’avons pas encore fini les amis, il y a encore des choix à faire. Voici les plus importants :',
  'bookstep-Formatting-guide-pdfebook-moreoptions-list1': 'Marges : Assurez-vous qu’elles soient suffisamment grandes ; 2 cm est une bonne règle générale. Si vous n’utilisez pas nos modèles, vous pouvez recréer une mise en page similaire en définissant les marges comme suit :',
  'bookstep-Formatting-guide-pdfebook-moreoptions-list1-topmargin': 'Marge supérieure: {size}',
  'bookstep-Formatting-guide-pdfebook-moreoptions-list1-bottommargin': 'Marge inférieure: {size}',
  'bookstep-Formatting-guide-pdfebook-moreoptions-list1-leftmargin': 'Marge gauche: {size}',
  'bookstep-Formatting-guide-pdfebook-moreoptions-list1-rightmargin': 'Marge droite: {size}',
  'bookstep-Formatting-guide-pdfebook-moreoptions-list2': 'Nouveaux chapitres : commencez les nouveaux chapitres sur de nouvelles pages. Cela favorise la qualité de la lecture. Vous pouvez donner un titre à vos chapitres, leur attribuer un numéro, ou les deux. Quel que soit votre choix, veillez à appliquer le style “Titre 1” à vos titres de chapitre dans Word pour garantir une mise en page cohérente.',
  'bookstep-Formatting-guide-pdfebook-moreoptions-list3': 'Paragraphes : Vous pouvez soit séparer les paragraphes par une ligne d’espace, soit par un alinéa. Veillez surtout à ce que vos paragraphes ne soient pas trop longs, car cela compromettrait la lisibilité du texte.',
  'bookstep-Formatting-guide-pdfebook-uploadscript-title': 'Importer votre manuscrit',
  'bookstep-Formatting-guide-pdfebook-uploadscript-p1': 'Vous êtes bien sûr libre d’utiliser le logiciel de de votre choix pour rédiger votre manuscrit, mais pour l’importation de votre manuscrit en eBook,  nous n’acceptons que les PDF. Ainsi, quelle que soit la façon dont vous avez créé votre manuscrit, vous devrez le convertir en PDF avant de pouvoir le déposer sur notre plateforme. La plupart des programmes intègrent un outil de conversion, soit sous “Enregistrer sous”, soit sous “Exporter”. En général, il vous suffit de sélectionner l’une ou l’autre de ces options, puis de choisir le format de fichier PDF.',
  'bookstep-Formatting-guide-pdfebook-uploadscript-p2': 'Une fois que vous aurez déposé votre manuscrit, nous le convertirons en un PDF prêt à être publié ; il apparaîtra sur votre écran. Vérifiez soigneusement ce fichier converti et assurez-vous que la mise en page de chaque page soit parfaite.',
  'bookstep-Formatting-guide-pdfebook-uploadscript-p3': 'Vous n’êtes pas satisfait du résultat ? Pas de problème : à cette étape, vous pouvez modifier et réimporter votre manuscrit autant de fois que nécessaire. Lorsque vous importez une nouvelle version de votre manuscrit, l’ancienne version est automatiquement perdue.',
  'bookstep-Formatting-nextstep': 'Prochaine étape: importer',
  'bookstep-Formatting-tips-title': 'Conseils pour la mise en page',
  'bookstep-Formatting-tips-print-newpage-label' : 'Nouvelle page',
  'bookstep-Formatting-tips-print-newpage': 'Quand un chapitre se termine en milieu de page et que vous aimeriez commencer le chapitre suivant sur une nouvelle page, n’utilisez jamais la touche “enter” toute seule pour y parvenir. Sinon, d’énormes espaces blancs apparaîtront lors de la conversion du document en PDF, déplaçant l’ensemble du texte. Vous devez donc toujours utiliser la combinaison “ctrl+enter” (“cmd+enter” sur mac) pour créer un saut de page dans Word. Cela évitera tout problème. Il en va de même lorsque vous insérez des images dans votre manuscrit : n’appuyez pas simplement sur “enter” pour libérer de l’espace !',
  'bookstep-Formatting-tips-print-colophon-label': 'Page de colophon ',
  'bookstep-Formatting-tips-print-colophon': 'Il est de coutume que votre livre commence par une page de titre et une page de colophon. En général, l’ordre est le suivant:',
  'bookstep-Formatting-tips-print-colophon-page1': 'Première page: Expose le titre du livre',
  'bookstep-Formatting-tips-print-colophon-page2': 'Deuxième page: Vierge',
  'bookstep-Formatting-tips-print-colophon-page3': 'Troisième page: Titre du livre, suivi par le nom d’auteur',
  'bookstep-Formatting-tips-print-colophon-page4': 'Quatrième page: C’est la page de colophon, qui inclut le nom d’auteur, l’ISBN (si vous en avez un), le nom du créateur de la couverture, le copyright, l’année de la publication, et la mention “publié via : {publicbrand}”',
  'bookstep-Formatting-tips-print-colophon-rest': 'Ces pages sont communément appelées “pages liminaires” d’un livre. Les pages énumérées ci-dessus ne sont pas numérotées. Cela signifie que la numérotation de vos pages commence à la page 5. Nos modèles sont déjà configurés conformément à cette norme.',
  'bookstep-Formatting-tips-print-images-label': 'Images',
  'bookstep-Formatting-tips-print-images': 'Vous pouvez bien sûr ajouter des images à votre manuscrit si vous le souhaitez. Une image d’une résolution de 250 dpi est suffisante pour garantir une impression de haute qualité. Placez simplement les images là où vous voulez qu’elles apparaissent dans votre manuscrit (tenez compte du débordement si vous placez des images près des bords). Si vous voulez faire une page double, c’est-à-dire une image qui couvre deux pages, vous pouvez couper l’image en deux et placer la section gauche sur une page paire, et la moitié droite sur la page impaire suivante.',
  'bookstep-Formatting-tips-print-wrongformat-label': 'Mauvais format ?',
  'bookstep-Formatting-tips-print-wrongformat': 'Si vous déposez un document dont le format ne correspond pas au type de livre que vous avez sélectionné, nous redimensionnons votre document pour qu’il corresponde. Cela peut fonctionner sans problème (surtout s’il n’y a que de légères différences), mais vous risquez aussi de vous retrouver avec des problèmes de mise en page. En somme, vérifiez toujours minutieusement le PDF converti que vous recevez ! Autant ne pas attendre de recevoir l’épreuve en main pour se rendre compte que la mise en page pose problème.',
  'bookstep-Formatting-tips-print-colorpages-label': 'En couleurs et Noir & Blanc',
  'bookstep-Formatting-tips-print-colorpages': 'Si votre livre contient à la fois des pages en noir et blanc et des pages en couleurs, il est important que vous suiviez une certaine procédure pour ne pas faire imprimer toutes vos pages en couleurs. Cliquez ici pour un résumé complet de la procédure à suivre pour ajouter des pages en couleurs à votre livre. {link}',
  'bookstep-Formatting-tips-print-colorpages-link': 'Cliquez ici pour connaître tous les tenants et aboutissants des pages en couleurs',
  'bookstep-Formatting-tips-print-proofcopy-label': 'L’épreuve',
  'bookstep-Formatting-tips-print-proofcopy': 'Vous n’êtes pas encore tout à fait prêt à commander une épreuve, mais il est bon de le rappeler : achetez toujours un premier exemplaire pour vous-même avant de publier ou de passer des commandes d’impression en grand nombre. Quelle que soit la rigueur avec laquelle vous avez vérifié votre manuscrit numériquement, vous remarquerez toujours quelques erreurs dans la version imprimée. Les coquilles, les détails esthétiques et les couleurs deviendront à un moment donné difficiles à distinguer sur un écran, et la copie imprimée aura toujours un aspect légèrement différent ; vous serez surpris de ce que vous trouverez. Il est extrêmement frustrant de relever ces petites erreurs après la publication ou l’impression en nombre d’un livre. Les achats groupés sont aussi possibles pour les épreuves ; ce qui vous permet d’en commander plusieurs et de demander à d’autres personnes de vous aider à relire votre brouillon final. ',
  'bookstep-Formatting-tips-epub-chapterheadings-label': 'Titres de chapitre',
  'bookstep-Formatting-tips-epub-chapterheadings': 'Pour créer un eBook de qualité, il est important d’utiliser les bons types de titres. Vous les trouverez sur Microsoft Word en tant que différents “Styles”, comme “Titre 1”. Si vous ne procédez pas ainsi, votre eBook ne sera qu’un long texte sans titres, sans possibilité de naviguer entre les chapitres.',
  'bookstep-Formatting-tips-epub-colophon-label': 'Page de colophon',
  'bookstep-Formatting-tips-epub-colophon': 'Il est d’usage qu’un livre commence par des pages de titre et une page de colophon. En général, les quelques premières pages d’un livre suivent l’ordre suivant :',
  'bookstep-Formatting-tips-epub-colophon-page1': 'Première page : titre du livre suivi du nom d’auteur',
  'bookstep-Formatting-tips-epub-colophon-page2': 'Deuxième page : la page de colophon, où figurent généralement le nom d’auteur, l’ISBN (si vous l’avez commandé), le nom de la personne qui a créé et/ou illustré la couverture, le copyright, l’année de publication et la mention “publié via : {publicbrand}”',
  'bookstep-Formatting-tips-epub-colophon-rest': 'Si vous utilisez Microsoft Word, veillez à introduire un saut de page entre ces deux pages, afin qu’elles restent séparées.',
  'bookstep-Formatting-tips-epub-pagenumbers-label': 'Pas de numéros de page',
  'bookstep-Formatting-tips-epub-pagenumbers': 'Assurez-vous que votre manuscrit ne contienne ni numéro de page, ni texte en en-tête ou pied de page. Si vous les laissez, ils apparaîtront à des endroits aléatoires du eBook, et non aux endroits prévus.',
  'bookstep-Formatting-tips-epub-images-label': 'Images',
  'bookstep-Formatting-tips-epub-images': 'Vous pouvez bien sûr ajouter des images à votre eBook. Dans ce cas, n’oubliez pas que l’image doit être placée “en ligne avec le texte”. Vous ne pouvez pas ajouter d’images dites “flottantes” avec du texte sur les côtés ; les images doivent toujours occuper la ligne entière, et le texte les accompagne au-dessus et/ou en dessous.',
  'bookstep-Formatting-tips-pdfebook-newpage-label': 'Nouvelle page',
  'bookstep-Formatting-tips-pdfebook-newpage': 'Quand un chapitre se termine au milieu d’une page et que vous voulez commencer le chapitre suivant sur une nouvelle page, ne procédez pas en appuyant sur “enter” plusieurs fois. Sinon, au moment de convertir votre fichier en PDF, vous vous retrouverez avec de grands espaces blancs et des titres de chapitre à des endroits improbables. Sur Word, utilisez toujours CTRL + enter (ou CMD + enter si vous utilisez un Mac) pour commencer sur une nouvelle page. Même procédure si vous voulez dédier une page entière à l’ajout d’une image : n’utilisez pas la touche “enter” pour créer son espace.',
  'bookstep-Formatting-tips-pdfebook-colophon-label': 'Page de colophon',
  'bookstep-Formatting-tips-pdfebook-colophon': 'Il est de coutume que votre livre commence par une page de titre et une page de colophon. En général, l’ordre est le suivant:',
  'bookstep-Formatting-tips-pdfebook-colophon-page1': 'Première page: Expose le titre du livre',
  'bookstep-Formatting-tips-pdfebook-colophon-page2': 'Deuxième page: Vierge',
  'bookstep-Formatting-tips-pdfebook-colophon-page3': 'Troisième page: Titre du livre, suivi par le nom d’auteur',
  'bookstep-Formatting-tips-pdfebook-colophon-page4': 'Quatrième page: C’est la page de colophon, qui inclut le nom d’auteur, l’ISBN (si vous en avez un), le nom du créateur de la couverture, le copyright, l’année de la publication, et la mention “publié via : {publicbrand}”',
  'bookstep-Formatting-tips-pdfebook-colophon-rest': 'Si vous utilisez Microsoft Word, veillez à introduire un saut de page entre ces deux pages, afin qu’elles restent séparées.',
  'bookstep-Formatting-tips-pdfebook-images-label': 'Images',
  'bookstep-Formatting-tips-pdfebook-images': 'Vous pouvez bien sûr ajouter des images à votre manuscrit. Une résolution de 96 dpi est suffisante pour les eBooks. Vous devrez insérer vous-même vos images. Veillez donc à ce qu’il y ait un espace correct entre le texte et l’image.',
  'bookstep-Formatting-tips-pdfebook-dimensions-label': 'Dimensions',
  'bookstep-Formatting-tips-pdfebook-dimensions': 'Il n’y a aucune restriction quant aux dimensions de votre PDF. Toutefois, il est conseillé d’utiliser un format assez standard, de préférence entre A5 et A4. Un format particulièrement grand ou petit peut nuire à la lisibilité de votre eBook.',

  // Account settings
  'AccountSettings-Security-button-2FA-title': 'Activer l’identification à deux facteurs',
  'AccountSettings-Security-button-2FA-subtitle-enabled': 'Activé',
  'AccountSettings-Security-button-2FA-subtitle-disabled': 'Désactivé',
  'AccountSettings-Security-button-2FA-subtitle': '{enableddisabled} • Nous vous demandons un code quand vous vous connectez depuis un nouvel appareil.',

  // Checkout
  'Checkout-Cart-title-Yourcart': 'Votre panier',
  'Checkout-Cart-RemoveFromCart': 'Supprimer du panier',
  'Checkout-Cart-CartItem-Title-Buscards': 'Cartes de visite',
  'Checkout-Cart-CartItem-Title-Isbn': 'ISBN',
  'Checkout-Cart-CartItem-Originally': 'À l’origine : <span>{price}</span>',
  'Checkout-Cart-CartItem-Forbook': 'Pour livre : {bookTitle}',
  'Checkout-Cart-CartIsEmpty': 'Votre panier est vide.',
  'Checkout-Cart-title-Total': 'Totaal',
  'Checkout-Cart-total-Subtotal': 'Sous-total <span>{price}</span>',
  'Checkout-Cart-total-Discount': 'Réduction <span>{price}</span>',
  'Checkout-Cart-total-Total': 'Total <span>{price}</span>',
  'Checkout-Cart-Button-ProceedCheckout': 'Passer la commande',
  'Checkout-Cart-ErrorPopup-title': 'Quelques products de votre panier ne peuvent pas être commandés.',
  'Checkout-Cart-ErrorPopup-p1': 'Si vous vliquez le bouton ‘Passer la commande’, nous supprimons les produits indisponibles de votre panier et vous pouvez passer la commade.',
  'Checkout-Cart-ErrorPopup-unavailableprods': 'Les produits indisponibles :',
  'Checkout-Cart-ErrorPopup-Button-Cancel': 'Annuler',
  'Checkout-Cart-ErrorPopup-Button-ContinueOrder': 'Passer la commande',
  'Checkout-Cart-WarningPopup-title': 'Vous êtes sur le point de commander simultanément un ISBN et le livre associé.',
  'Checkout-Cart-WarningPopup-p1': 'Si vous commandez simultanément un ISBN en le livre associé, le livre <b>n’aura pas</b> l’ISBN. Nous vous recommandons de commander d’abord l’ISBN et commander le livre quand l’ISBN a été attaché a votre livre.',
  'Checkout-Cart-WarningPopup-p2': 'Si vous cliquez le bouton ‘Commande l’ISBN’ klikt, nous supprimons les livres suivants de votre panier :',
  'Checkout-Cart-WarningPopup-Button-IgnoreContinue': 'Ignorer et continuer',
  'Checkout-Cart-WarningPopup-Button-OrderIsbnFirst': 'Commande l’ISBN',
  'Checkout-Error-CheckoutSpecApiError': 'Une erreur inattendue s’est produite lors de preparer de la commande. Contactez le support et joignez une capture d’écran de cette page.',
  'Checkout-CheckoutDetails-Button-Save': 'Sauvegarder',
  'Checkout-CheckoutDetails-Button-Next': 'Continuer',
  'Checkout-CheckoutDetails-Button-Cancel': 'Annuler',
  'Checkout-CheckoutDetails-Button-NextStep': 'Étape suivante',
  'Checkout-CheckoutDetails-title-Email': 'L’adresse e-mail',
  'Checkout-CheckoutDetails-title-ShippingAddress': 'Adresse de livraison',
  'Checkout-CheckoutDetails-title-BillingAddress': 'Adresse de facturation',
  'Checkout-CheckoutDetails-title-ShippingMethod': 'Mode de livraison',
  'Checkout-CheckoutDetails-Email-label-Email': 'L’adresse e-mail',
  'Checkout-CheckoutDetails-Email-Button-EditEmail': 'Modifier l’e-mail',
  'Checkout-CheckoutDetails-ShippingAddress-label-YourShippingAddress': 'Votre adresse de livraison',
  'Checkout-CheckoutDetails-ShippingAddress-Button-EnterDifferentAddress': 'Modifer adresse de livraison',
  'Checkout-CheckoutDetails-BillingAddress-SameAsShipping-label': 'Identique à l’adresse de livraison',
  'Checkout-CheckoutDetails-BillingAddress-label-YourBillingAddress': 'Votre adresse de facturation',
  'Checkout-CheckoutDetails-BillingAddress-Button-EnterDifferentAddress': 'Modifier adresse de facturation',
  'Checkout-CheckoutDetails-BillingAddress-CustomerType-label': 'Type de client',
  'Checkout-CheckoutDetails-BillingAddress-CustomerType-labelDesc': 'Sélectionnez quel type de client vous êtes. Les clients professionnels ont besoin d’un numéro de TVA UE valide.',
  'Checkout-CheckoutDetails-BillingAddress-CustomerType-Private': 'Personne privée',
  'Checkout-CheckoutDetails-BillingAddress-CustomerType-Private-desc': 'Client sans numéro de TVA UE',
  'Checkout-CheckoutDetails-BillingAddress-CustomerType-Company': 'Entreprise',
  'Checkout-CheckoutDetails-BillingAddress-CustomerType-Company-desc': 'Client avec numéro de TVA UE',
  'Checkout-CheckoutDetails-ShippingMethod-SelectMethod-label': 'Sélectionnez votre méthode d’expédition préférée',
  'Checkout-Address-Phoneline': 'Tel: {phone}',
  'Checkout-Address-Vatline': 'Numéro de TVA: {taxno}',
  'Checkout-Address-Input-Fn': 'Prénom',
  'Checkout-Address-Input-Ln': 'Nom',
  'Checkout-Address-Input-L': 'Pays',
  'Checkout-Address-GenericAddress-S': 'Rue et numéro de la maison',
  'Checkout-Address-GenericAddress-P': 'Code postal',
  'Checkout-Address-GenericAddress-Nr': 'Numéro',
  'Checkout-Address-GenericAddress-NrA': 'Numéro + addition',
  'Checkout-Address-GenericAddress-C': 'Ville',
  'Checkout-Address-GenericAddress-R': 'Région',
  'Checkout-Address-Input-T': 'Numéro de téléphone',
  'Checkout-Address-Input-Shipping-Cp': 'Nom d’entreprise (sur l’étiquette d’adresse)',
  'Checkout-Address-Input-Cp': 'Nom d’entreprise',
  'Checkout-Address-Input-Shipping-V': 'Numéro fiscal',
  'Checkout-Address-Input-V': 'Numéro de TVA',
  'Checkout-Address-Button-Save': 'Sauvegarder',
  'Checkout-AddressStreetWarningNoNumber': 'Attention: ne contient pas de numéro de maison',
  'Checkout-PostalcodecheckError-ADDRESS_NOT_FOUND': 'Nous ne pouvons pas trouver une adresse avec ce code postal et numéro de la maison (+ addition). Veuillez vérifier si vous avez tout rempli correctement.',
  'Checkout-PostalcodecheckError-INVALID_API_CALL': 'Une erreur inattendue s’est produite lors de la recherde de l’adresse. Contectez le support.',
  'Checkout-CheckoutOverview-title-YourDeliveryDetails': 'Détails de votre commande',
  'Checkout-CheckoutOverview-title-Paymentmethod': 'Mode de paiement',
  'Checkout-CheckoutOverview-YourDeliveryDetails-label-Email': 'L’adresse e-mail',
  'Checkout-CheckoutOverview-YourDeliveryDetails-label-YourAddress': 'Votre adresse',
  'Checkout-CheckoutOverview-YourDeliveryDetails-label-ShippingAddress': 'Adresse de livraison',
  'Checkout-CheckoutOverview-YourDeliveryDetails-label-BillingAddress': 'Adresse de facturation',
  'Checkout-CheckoutOverview-YourDeliveryDetails-label-ShippingMethod': 'Mode de livraison',
  'Checkout-OrderSidebar-title-YourOrder': 'Votre commande',
  'Checkout-OrderSidebar-ProductItem-Quantity': 'Qté: {quantity}',
  'Checkout-OrderSidebar-totals-Shipping': 'Livraison: <span>{price}</span>',
  'Checkout-OrderSidebar-totals-Total': 'Total: <span>{price}</span>',
  'Checkout-OrderSidebar-totals-IncludedVAT': 'Y compris la TVA: <span>{price}</span>',
  'CheckoutSuccess-title-Thankyou': 'Merci pour votre commande !',
  'CheckoutSuccess-YourOrderNoIs': 'Votre numéro de commande est : <b>{orderId}</b>',
  'CheckoutSuccess-IntroWhatToExpect': 'Vous venez de passer une commande. En fonction de ce que vous avez commandé, vous pouvez lire ci-dessous ce à quoi vous devez vous attendre.',
  'CheckoutSuccess-title-PaperbackHardcover': 'Livre broché ou livre relié',
  'CheckoutSuccess-title-Ebook': 'eBook',
  'CheckoutSuccess-title-Isbn': 'ISBN',
  'CheckoutSuccess-title-Buscards': 'Cartes de visite',
  'CheckoutSuccess-li1-OrderConfEmail': 'Vous recevrez une confirmation par e-mail',
  'CheckoutSuccess-li1-OrderNumberIs': 'Votre numéro de commande est : <b>{orderId}</b>.',
  'CheckoutSuccess-li1-OrderConfSentTo': 'Nous enverrons une confirmation de commande à {email}. Remarque : l’email pourrait se retrouver dans vos spams.',
  'CheckoutSuccess-PaperbackHardcover-li2-WeMakePOD': 'Nous utilisons un système d’impression à la demande pour nos livres',
  'CheckoutSuccess-PaperbackHardcover-li2-WePrintRightAway': 'Nous imprimons votre commande immédiatement mais n’oubliez pas que cela prendra quelques jours.',
  'CheckoutSuccess-PaperbackHardcover-li3-OrderWillShipped': 'Votre commande sera expédiée',
  'CheckoutSuccess-PaperbackHardcover-li3-ShipConfEMail': 'Vous recevrez une confirmation d’expédition avec un numéro de suivi par e-mail.',
  'CheckoutSuccess-PaperbackHardcover-ExpectedShippingDate': 'Date d’expédition prévue',
  'CheckoutSuccess-PaperbackHardcover-ExpectedShippingDate-BetweenDates': 'Entre {startdate} et {enddate}',
  'CheckoutSuccess-Ebook-li2-dlLinkEmail': 'Votre lien de téléchargement se trouve dans l’e-mail de confirmation',
  'CheckoutSuccess-Ebook-li2-dlLinkEmailOrClickBelow': 'Le lien pour télécharger votre eBook se trouve dans l’e-mail de confirmation ou vous pouvez cliquer sur le bouton ci-dessous.',
  'CheckoutSuccess-Ebook-DownloadYourEbook': 'Téléchargez votre eBook',
  'CheckoutSuccess-Isbn-li2-IsbnReady': 'Votre ISBN est prêt dans une heure',
  'CheckoutSuccess-Isbn-li2-IsbnReadyInStep': 'Vous pouvez trouver le numéro ISBN dans l’étape ISBN de votre livre. Nous ajoutons automatiquement le numéro ISBN au dos de votre couverture.',
  'CheckoutSuccess-Isbn-ContinueWithBook': 'Continuez votre livre',
  'CheckoutSuccess-Isbn-ContinueWithBookClickHere': 'Cliquez ici pour continuer votre livre.',
  'CheckoutSuccess-Buscards-li2-WeMakeCards': 'Nous sommes en train de créer votre carte de visite',
  'CheckoutSuccess-Buscards-li2-WePrintRightAway': 'Nous imprimons vos cartes de visite immédiatement. Il faudra environ une semaine pour les livrer.',
  'CheckoutSuccess-Buscards-li2-NoShipmentMail': 'Remarque : vous ne recevrez pas de confirmation d’expédition séparée pour les cartes de visite.',
  'CheckoutFailed-title-PaymentFailed': 'Votre paiement a échoué',
  'CheckoutFailed-SomethingWentWrongTryAgain': 'Quelque chose s’est mal passé lors du paiement de votre commande. Veuillez réessayer.',
  'CheckoutFailed-button-CheckoutAgain': 'Repasser la commande',
  'CheckoutError-title-SomethingWrong': 'Quelque chose s’est mal passé',
  'CheckoutError-SomethingWentWrongContactUs': 'Une erreur s’est produite lors de la finalisation du paiement de votre commande. Essayez à nouveau ou contactez le support. Veuillez inclure la référence suivante lorsque vous contactez support: {orderId}',
  'CheckoutError-button-TryAgain': 'Essayer à nouveau',
  'CheckoutPending-title-PaymentInProgress': 'Votre payement est en cours de vérification',
  'CheckoutPending-PaymentProcessingTakesLonger': 'Le traitement de votre paiement prend un peu plus de temps que prévu. Pour le moment, nous ne pouvons pas vous informer définitive si votre paiement a réussi.',
  'CheckoutPending-CheckAgainInFewHours': 'Vérifiez votre e-mail pendant les prochaines heures. Quand nous avons reçu la confirmation de votre paiement, nous vous envoyons une confirmation par e-mail. Si vous ne recevez pas cette confirmation, Veuillez vérifier auprès de votre banque ou votre carte de crédit si le paiement a été correctement débité.',
  'CheckoutPending-OrderReference': 'Votre numéro de commande : {orderId}',
  'CheckoutCancelled-title-PaymentCancelled': 'Vous avez annulé votre paiement',
  'CheckoutCancelled-TryAgainIfYouWant': 'Vous avez annulé le paiement. Si ce n’était pas l’intention, cliquez sur le bouton ci-dessous pour payer.',
  'CheckoutCancelled-button-CheckoutAgain': 'Payer à nouveau',

  // All ISO-3166-1 alpha 2 countries
  'Country-AD': 'Andorre',
  'Country-AE': 'Émirats arabes unis',
  'Country-AF': 'Afghanistan',
  'Country-AG': 'Antigua-et-Barbuda',
  'Country-AI': 'Anguilla',
  'Country-AL': 'Albanie',
  'Country-AM': 'Arménie',
  'Country-AO': 'Angola',
  'Country-AQ': 'Antarctique',
  'Country-AR': 'Argentine',
  'Country-AS': 'Samoa américaines',
  'Country-AT': 'Autriche',
  'Country-AU': 'Australie',
  'Country-AW': 'Aruba',
  'Country-AX': 'Îles Åland',
  'Country-AZ': 'Azerbaïdjan',
  'Country-BA': 'Bosnie-Herzégovine',
  'Country-BB': 'Barbade',
  'Country-BD': 'Bangladesh',
  'Country-BE': 'Belgique',
  'Country-BF': 'Burkina Faso',
  'Country-BG': 'Bulgarie',
  'Country-BH': 'Bahreïn',
  'Country-BI': 'Burundi',
  'Country-BJ': 'Bénin',
  'Country-BL': 'Saint-Barthélemy',
  'Country-BM': 'Bermudes',
  'Country-BN': 'Brunéi Darussalam',
  'Country-BO': 'Bolivie',
  'Country-BQ': 'Pays-Bas caribéens',
  'Country-BR': 'Brésil',
  'Country-BS': 'Bahamas',
  'Country-BT': 'Bhoutan',
  'Country-BV': 'Île Bouvet',
  'Country-BW': 'Botswana',
  'Country-BY': 'Biélorussie',
  'Country-BZ': 'Belize',
  'Country-CA': 'Canada',
  'Country-CC': 'Îles Cocos',
  'Country-CD': 'Congo-Kinshasa',
  'Country-CF': 'République centrafricaine',
  'Country-CG': 'Congo-Brazzaville',
  'Country-CH': 'Suisse',
  'Country-CI': 'Côte d’Ivoire',
  'Country-CK': 'Îles Cook',
  'Country-CL': 'Chili',
  'Country-CM': 'Cameroun',
  'Country-CN': 'Chine',
  'Country-CO': 'Colombie',
  'Country-CR': 'Costa Rica',
  'Country-CU': 'Cuba',
  'Country-CV': 'Cap-Vert',
  'Country-CW': 'Curaçao',
  'Country-CX': 'Île Christmas',
  'Country-CY': 'Chypre',
  'Country-CZ': 'République tchèque',
  'Country-DE': 'Allemagne',
  'Country-DJ': 'Djibouti',
  'Country-DK': 'Danemark',
  'Country-DM': 'Dominique',
  'Country-DO': 'République dominicaine',
  'Country-DZ': 'Algérie',
  'Country-EC': 'Équateur',
  'Country-EE': 'Estonie',
  'Country-EG': 'Égypte',
  'Country-EH': 'Sahara occidental',
  'Country-ER': 'Érythrée',
  'Country-ES': 'Espagne',
  'Country-ET': 'Éthiopie',
  'Country-FI': 'Finlande',
  'Country-FJ': 'Fidji',
  'Country-FK': 'Îles Falkland',
  'Country-FM': 'Micronésie',
  'Country-FO': 'Îles Féroé',
  'Country-FR': 'France',
  'Country-GA': 'Gabon',
  'Country-GB': 'Royaume-Uni',
  'Country-GD': 'Grenade',
  'Country-GE': 'Géorgie',
  'Country-GF': 'Guyane française',
  'Country-GG': 'Guernesey',
  'Country-GH': 'Ghana',
  'Country-GI': 'Gibraltar',
  'Country-GL': 'Groenland',
  'Country-GM': 'Gambie',
  'Country-GN': 'Guinée',
  'Country-GP': 'Guadeloupe',
  'Country-GQ': 'Guinée équatoriale',
  'Country-GR': 'Grèce',
  'Country-GS': 'Géorgie du Sud-et-les îles Sandwich du Sud',
  'Country-GT': 'Guatemala',
  'Country-GU': 'Guam',
  'Country-GW': 'Guinée-Bissau',
  'Country-GY': 'Guyana',
  'Country-HK': 'R.A.S. chinoise de Hong Kong',
  'Country-HM': 'Îles Heard-et-MacDonald',
  'Country-HN': 'Honduras',
  'Country-HR': 'Croatie',
  'Country-HT': 'Haïti',
  'Country-HU': 'Hongrie',
  'Country-ID': 'Indonésie',
  'Country-IE': 'Irlande',
  'Country-IL': 'Israël',
  'Country-IM': 'Île de Man',
  'Country-IN': 'Inde',
  'Country-IO': 'Territoire britannique de l’océan Indien',
  'Country-IQ': 'Irak',
  'Country-IR': 'Iran',
  'Country-IS': 'Islande',
  'Country-IT': 'Italie',
  'Country-JE': 'Jersey',
  'Country-JM': 'Jamaïque',
  'Country-JO': 'Jordanie',
  'Country-JP': 'Japon',
  'Country-KE': 'Kenya',
  'Country-KG': 'Kirghizistan',
  'Country-KH': 'Cambodge',
  'Country-KI': 'Kiribati',
  'Country-KM': 'Comores',
  'Country-KN': 'Saint-Kitts-et-Nevis',
  'Country-KP': 'Corée du Nord',
  'Country-KR': 'Corée du Sud',
  'Country-KW': 'Koweït',
  'Country-KY': 'Îles Caïmans',
  'Country-KZ': 'Kazakhstan',
  'Country-LA': 'Laos',
  'Country-LB': 'Liban',
  'Country-LC': 'Sainte-Lucie',
  'Country-LI': 'Liechtenstein',
  'Country-LK': 'Sri Lanka',
  'Country-LR': 'Libéria',
  'Country-LS': 'Lesotho',
  'Country-LT': 'Lituanie',
  'Country-LU': 'Luxembourg',
  'Country-LV': 'Lettonie',
  'Country-LY': 'Libye',
  'Country-MA': 'Maroc',
  'Country-MC': 'Monaco',
  'Country-MD': 'Moldavie',
  'Country-ME': 'Monténégro',
  'Country-MF': 'Saint-Martin',
  'Country-MG': 'Madagascar',
  'Country-MH': 'Îles Marshall',
  'Country-MK': 'Macédoine du Nord',
  'Country-ML': 'Mali',
  'Country-MM': 'Myanmar (Birmanie)',
  'Country-MN': 'Mongolie',
  'Country-MO': 'R.A.S. chinoise de Macao',
  'Country-MP': 'Îles Mariannes du Nord',
  'Country-MQ': 'Martinique',
  'Country-MR': 'Mauritanie',
  'Country-MS': 'Montserrat',
  'Country-MT': 'Malte',
  'Country-MU': 'Maurice',
  'Country-MV': 'Maldives',
  'Country-MW': 'Malawi',
  'Country-MX': 'Mexique',
  'Country-MY': 'Malaisie',
  'Country-MZ': 'Mozambique',
  'Country-NA': 'Namibie',
  'Country-NC': 'Nouvelle-Calédonie',
  'Country-NE': 'Niger',
  'Country-NF': 'Île Norfolk',
  'Country-NG': 'Nigéria',
  'Country-NI': 'Nicaragua',
  'Country-NL': 'Pays-Bas',
  'Country-NO': 'Norvège',
  'Country-NP': 'Népal',
  'Country-NR': 'Nauru',
  'Country-NU': 'Niue',
  'Country-NZ': 'Nouvelle-Zélande',
  'Country-OM': 'Oman',
  'Country-PA': 'Panama',
  'Country-PE': 'Pérou',
  'Country-PF': 'Polynésie française',
  'Country-PG': 'Papouasie-Nouvelle-Guinée',
  'Country-PH': 'Philippines',
  'Country-PK': 'Pakistan',
  'Country-PL': 'Pologne',
  'Country-PM': 'Saint-Pierre-et-Miquelon',
  'Country-PN': 'Pitcairn',
  'Country-PR': 'Porto Rico',
  'Country-PS': 'Territoires palestiniens',
  'Country-PT': 'Portugal',
  'Country-PW': 'Palaos',
  'Country-PY': 'Paraguay',
  'Country-QA': 'Qatar',
  'Country-RE': 'La Réunion',
  'Country-RO': 'Roumanie',
  'Country-RS': 'Serbie',
  'Country-RU': 'Russie',
  'Country-RW': 'Rwanda',
  'Country-SA': 'Arabie saoudite',
  'Country-SB': 'Îles Salomon',
  'Country-SC': 'Seychelles',
  'Country-SD': 'Soudan',
  'Country-SE': 'Suède',
  'Country-SG': 'Singapour',
  'Country-SH': 'Sainte-Hélène',
  'Country-SI': 'Slovénie',
  'Country-SJ': 'Svalbard et Jan Mayen',
  'Country-SK': 'Slovaquie',
  'Country-SL': 'Sierra Leone',
  'Country-SM': 'Saint-Marin',
  'Country-SN': 'Sénégal',
  'Country-SO': 'Somalie',
  'Country-SR': 'Suriname',
  'Country-SS': 'Soudan du Sud',
  'Country-ST': 'Sao Tomé-et-Principe',
  'Country-SV': 'Salvador',
  'Country-SX': 'Saint-Martin (partie néerlandaise)',
  'Country-SY': 'Syrie',
  'Country-SZ': 'Eswatini',
  'Country-TC': 'Îles Turks et Caïques',
  'Country-TD': 'Tchad',
  'Country-TF': 'Terres australes françaises',
  'Country-TG': 'Togo',
  'Country-TH': 'Thaïlande',
  'Country-TJ': 'Tadjikistan',
  'Country-TK': 'Tokelau',
  'Country-TL': 'Timor oriental',
  'Country-TM': 'Turkménistan',
  'Country-TN': 'Tunisie',
  'Country-TO': 'Tonga',
  'Country-TR': 'Turquie',
  'Country-TT': 'Trinité-et-Tobago',
  'Country-TV': 'Tuvalu',
  'Country-TW': 'Taïwan',
  'Country-TZ': 'Tanzanie',
  'Country-UA': 'Ukraine',
  'Country-UG': 'Ouganda',
  'Country-UM': 'Îles mineures éloignées des États-Unis',
  'Country-US': 'États-Unis',
  'Country-UY': 'Uruguay',
  'Country-UZ': 'Ouzbékistan',
  'Country-VA': 'Cité du Vatican',
  'Country-VC': 'Saint-Vincent-et-les Grenadines',
  'Country-VE': 'Venezuela',
  'Country-VG': 'Îles Vierges britanniques',
  'Country-VI': 'Îles Vierges des États-Unis',
  'Country-VN': 'Viêt Nam',
  'Country-VU': 'Vanuatu',
  'Country-WF': 'Wallis-et-Futuna',
  'Country-WS': 'Samoa',
  'Country-XK': 'Kosovo',
  'Country-YE': 'Yémen',
  'Country-YT': 'Mayotte',
  'Country-ZA': 'Afrique du Sud',
  'Country-ZM': 'Zambie',
  'Country-ZW': 'Zimbabwe',
};

export default messages;
