import React from 'react';
import EmailButton from '../EmailButton';

function FooterSendMessage(props) {
	return(
		<div className="cw-footer-sendmessage-container">
			<h3>{props.translations.titleSendmemessage}</h3>
			<p>{props.translations.textSendmemessage}</p>
			<EmailButton {...props} />&nbsp;&nbsp;{props.email}
		</div>
	);
}

export default FooterSendMessage;