import React from 'react';
import EmailIcon from './icons/EmailIcon';

function EmailButton(props) {
	function handleClick() {
		window.location.href = 'mailto:'+props.email;
	}

	return(<button className="app-button-cta" onClick={handleClick} title={props.translations.buttonSendemail}><EmailIcon />{props.translations.buttonSendemail}</button>);
}

export default EmailButton;