import React, { useRef, useEffect } from 'react';
import { useHistory } from "react-router-dom";

function Backbutton(props) {
	let history = useHistory();

	const prevPathRef = useRef();
	useEffect(() => {
		prevPathRef.current = props.pagePath ? props.pagePath : '';
	});
	const previousPath = prevPathRef.current;

	function handleBack() {
		if(previousPath && previousPath !== '') {
			history.goBack();
		} else {
			history.push('/');
		}
	}
	return(
		<div className="cw-headerbar-back-container">
			<button onClick={handleBack}>Back</button>
		</div>
	);
}

export default Backbutton;