import React from 'react';
import FooterSocialButton from './FooterSocialButton';

function FooterSocial(props) {

	const socialButtons = Object.keys(props.socialmedia).map((keyName,i) => (
			<FooterSocialButton key={keyName} brand={keyName} url={props.socialmedia[keyName]}/>
	));
	return(
		<div className="cw-footer-social-container">
			<h3>{props.translations.titleGetsocial}</h3>
			<p>{props.translations.textGetsocial}</p>
			<div className="cw-footer-social-buttons">
				{socialButtons}
			</div>
		</div>
	);
}

export default FooterSocial;