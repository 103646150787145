import { Link, useHistory, useParams } from 'react-router-dom';
import useLocalStorageState from 'use-local-storage-state';
import { CheckoutResultRouteParams } from '..';
import { CheckoutState, setFullCheckoutState } from '../../checkoutSlice';
import { SVGGraphic, SectionIntroduction } from 'mbs-common';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch } from '../../../hooks';

export default function CheckoutError() {
  let { id: orderId } = useParams<CheckoutResultRouteParams>();
  const [localorder, setLocalOrder] = useLocalStorageState<CheckoutState>(`order-${orderId}`);
  const history = useHistory();
  const dispatch = useAppDispatch();

  const handleClick = () => {
    if (localorder) {
      dispatch(setFullCheckoutState(localorder));
      history.push('/checkout');
    } else {
      history.push('/cart');
    }
  };
  return (
    <>
      <SectionIntroduction>
        <SVGGraphic name="Bookbuddy/Warningsign" className="introsvg" />
        <h2>
          <FormattedMessage
            id="CheckoutError-title-SomethingWrong"
            defaultMessage="Something unexpected went wrong during your order payment"
          />
        </h2>
        <p>
          <FormattedMessage
            id="CheckoutError-SomethingWentWrongContactUs"
            defaultMessage="Something went wrong during the payment of your order. Please contact support with reference {orderId}"
            values={{ orderId }}
          />
        </p>
        <p>
          <button onClick={handleClick} className="app-button-cta">
            <FormattedMessage
              id="CheckoutError-button-TryAgain"
              defaultMessage="Try ordering again"
            />
          </button>
        </p>
      </SectionIntroduction>
    </>
  );
}
