import { ProductTypes } from '../Cart/cartSlice';
import { apiSlice } from '../api/apiSlice';
import { useAppDispatch } from '../hooks';
import { store } from '../store';
import ApiCall from './ApiCall';
import isEUCountry from './isEUCountry';

export interface VATRatesObject extends Record<ProductTypes, number> {}

export enum VATTypes {
  Export = 'VAT_EXPORT',
  ShippingCountry = 'VAT_SHIPPINGCOUNTRY',
  OriginCountry = 'VAT_ORIGINCOUNTRY',
  Intracommunity = 'VAT_INTRACOMMUNITY'
}

export enum VATRateReturnType {
  Percentage = 'VATRETURN_PERC',
  Ratio = 'VATRETURN_RATIO',
  IncreaseRatio = 'VATRETURN_INCREASERATIO'
}
class VATRATE {
  originCountry: string;
  shippingCountry: string;
  billingCountry: string;
  isEUCompany: boolean;
  rates?: Record<string, VATRatesObject>;

  constructor(
    originCountry: string,
    shippingCountry: string,
    billingCountry: string,
    isEUCompany: boolean,
    rates?: Record<string, VATRatesObject>
  ) {
    this.originCountry = originCountry;
    this.shippingCountry = shippingCountry;
    this.billingCountry = billingCountry;
    this.isEUCompany = isEUCompany;
    if (rates) {
      this.rates = rates;
    }
  }
/*
  async loadRates() {
    if (!this.rates) {
      const api = new ApiCall();
      let success = false;
      await api
        .getData('checkoutAPI/vatrates')
        .then((response: any) => {
          this.rates = response.data;
          success = true;
        })
        .catch((error: Record<string, string>) => {
          success = false;
        });
      return success;
    }
    return true;
  }
  */
  async loadRates() {
    const result = await store.dispatch(apiSlice.endpoints.getVATRates.initiate());
    const data = result.data;
    //const { data } = await apiSlice.endpoints.getVATRates.useQuery();
    if (data) {
      this.rates = data;
    }
  }

  getVATType(): VATTypes {
    // first check if we're originating from somewhere in the EU. If not, different rules might apply
    if (isEUCountry(this.originCountry)) {
      if (!isEUCountry(this.shippingCountry)) {
        // not shipping to the EU, so Export delivery
        return VATTypes.Export;
      } else {
        // if we're shipping to a private person in the EU, always the shipping country rate
        if (!this.isEUCompany) {
          return VATTypes.ShippingCountry;
        } else {
          // if the company is in the same country as the origin, we'll use the origin rate instead of 0%
          if (this.originCountry === this.billingCountry) {
            return VATTypes.OriginCountry;
          } else {
            return VATTypes.Intracommunity;
          }
        }
      }
    } else {
      // this will currently only happen for GB since we don't originate orders any other country outside the EU
      if (this.originCountry === 'GB' && this.shippingCountry === 'GB') {
        return VATTypes.OriginCountry;
      } else {
        return VATTypes.Export;
      }
    }
  }

  getVatRateForCountry(country: string, productType: ProductTypes): number | false {
    if (!this.rates) {
      return false;
    }
    if (this.rates[country]) {
      return this.rates[country][productType];
    } else {
      return false;
    }
  }

  getVatRatesForCountry(country: string) {
    if (!this.rates) {
      return false;
    }
    if (this.rates[country]) {
      return this.rates[country];
    } else {
      return false;
    }
  }

  getRate(
    productType: ProductTypes,
    returnType: VATRateReturnType = VATRateReturnType.Percentage
  ): number | false {
    if (!this.rates) {
      return false;
    }
    let vatRate: number | false = false;
    const vatType = this.getVATType();

    switch (vatType) {
      case VATTypes.Export:
        vatRate = 0;
        break;
      case VATTypes.Intracommunity:
        vatRate = 0;
        break;
      case VATTypes.OriginCountry:
        vatRate = this.getVatRateForCountry(this.originCountry, productType);
        break;
      case VATTypes.ShippingCountry:
        vatRate = this.getVatRateForCountry(this.shippingCountry, productType);
        break;
    }

    if (vatRate === false) {
      return vatRate;
    }

    switch (returnType) {
      case VATRateReturnType.Percentage:
        return vatRate;
      case VATRateReturnType.Ratio:
        return vatRate / 100;
      case VATRateReturnType.IncreaseRatio:
        return 1 + vatRate / 100;
    }
  }

  getRates(returnType: VATRateReturnType = VATRateReturnType.Percentage) {
    if (!this.rates) {
      return false;
    }
    let vatRatesObject: VATRatesObject | false = false;
    const vatType = this.getVATType();

    switch (vatType) {
      case VATTypes.Export:
        vatRatesObject = {
          PRINTBOOK: 0,
          PRIVATEPRINTBOOK: 0,
          EBOOK: 0,
          ISBN: 0,
          ISBNDIGITAL: 0,
          BUSCARDS: 0,
          SERVICE: 0,
          SHIPPING: 0,
        };
        break;
      case VATTypes.Intracommunity:
        vatRatesObject = {
          PRINTBOOK: 0,
          PRIVATEPRINTBOOK: 0,
          EBOOK: 0,
          ISBN: 0,
          ISBNDIGITAL: 0,
          BUSCARDS: 0,
          SERVICE: 0,
          SHIPPING: 0
        };
        break;
      case VATTypes.OriginCountry:
        vatRatesObject = this.getVatRatesForCountry(this.originCountry);
        break;
      case VATTypes.ShippingCountry:
        vatRatesObject = this.getVatRatesForCountry(this.shippingCountry);
        break;
    }

    if (vatRatesObject === false) {
      return vatRatesObject;
    }

    switch (returnType) {
      case VATRateReturnType.Percentage:
        return vatRatesObject;
      case VATRateReturnType.Ratio:
        const returnVatRatesObjectRatio = { ...vatRatesObject };
        for (const [key, value] of Object.entries<number>(returnVatRatesObjectRatio)) {
          returnVatRatesObjectRatio[key as ProductTypes] = value / 100;
        }
        return returnVatRatesObjectRatio;
      case VATRateReturnType.IncreaseRatio:
        const returnVatRatesObjectIncreaseRatio = { ...vatRatesObject };
        for (const [key, value] of Object.entries<number>(returnVatRatesObjectIncreaseRatio)) {
          returnVatRatesObjectIncreaseRatio[key as ProductTypes] = 1 + value / 100;
        }
        return returnVatRatesObjectIncreaseRatio;
    }
  }
}

export default VATRATE;
