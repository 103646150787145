import { useHistory, useParams } from 'react-router-dom';
import useLocalStorageState from 'use-local-storage-state';
import { CheckoutResultRouteParams } from '..';
import { CheckoutState, setFullCheckoutState } from '../../checkoutSlice';
import { SectionIntroduction } from 'mbs-common';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch } from '../../../hooks';

export default function CheckoutCancelled() {
  let { id: orderId } = useParams<CheckoutResultRouteParams>();
  const [localorder, setLocalOrder] = useLocalStorageState<CheckoutState>(`order-${orderId}`);
  const history = useHistory();
  const dispatch = useAppDispatch();

  const handleClick = () => {
    if (localorder) {
      dispatch(setFullCheckoutState(localorder));
      history.push('/checkout');
    } else {
      history.push('/cart');
    }
  };
  return (
    <>
      <SectionIntroduction>
        <h2>
          <FormattedMessage
            id="CheckoutCancelled-title-PaymentCancelled"
            defaultMessage="You have cancelled the payment"
          />
        </h2>
        <p>
          <FormattedMessage
            id="CheckoutCancelled-TryAgainIfYouWant"
            defaultMessage="You have cancelled the payment. If this wasn’t your intention, click the button below to pay."
          />
        </p>
        <p>
          <button onClick={handleClick} className="app-button-cta">
            <FormattedMessage
              id="CheckoutCancelled-button-CheckoutAgain"
              defaultMessage="Checkout again"
            />
          </button>
        </p>
      </SectionIntroduction>
    </>
  );
}
