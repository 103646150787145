import { Dialog } from 'mbs-common';
import { CartItem, deleteItems } from './cartSlice';
import { useAppDispatch } from '../hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { useState } from 'react';

export default function WarningPopup({
  warningBooks,
  clearWarnings
}: {
  warningBooks: CartItem[];
  clearWarnings: () => void;
}) {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const [isVisible, setIsVisible] = useState<boolean>(true);

  const handleClickDeleteContinue = () => {
    const idsToDelete = warningBooks.map((item) => item.id);
    dispatch(deleteItems(idsToDelete));
    if (clearWarnings) {
      clearWarnings();
    }
  };

  const renderWarningProducts = warningBooks.map((item, index) => {
    if (item.bookSpecs) {
      return (
        <li key={`warningpopup-key-${index}`}>
          {item.bookSpecs.title} (
          {intl.formatMessage({
            id: `binding-${item.bookSpecs.binding}`,
            defaultMessage: 'Book'
          })}
          )
        </li>
      );
    } else {
      return <li key={`warningpopup-key-${index}`}>{item.id}</li>;
    }
  });

  const handleClickForceContinue = () => {
    if (clearWarnings) {
      clearWarnings();
    }
  };

  const buttons = [
    {
      label: intl.formatMessage({
        id: 'Checkout-Cart-WarningPopup-Button-IgnoreContinue',
        defaultMessage: 'Ignore and continue'
      }),
      class: 'app-button-standard',
      onClick: handleClickForceContinue
    },
    {
      label: intl.formatMessage({
        id: 'Checkout-Cart-WarningPopup-Button-OrderIsbnFirst',
        defaultMessage: 'Order ISBNs first'
      }),
      class: 'app-button-cta',
      onClick: handleClickDeleteContinue
    }
  ];

  return (
    <Dialog
      buttons={buttons}
      title={intl.formatMessage({
        id: 'Checkout-Cart-WarningPopup-title',
        defaultMessage: 'You are about to order an ISBN and a the associated book simultaneously.'
      })}
      isVisible={isVisible}
      onClose={() => setIsVisible(false)}
      id="cart-warningpopup"
    >
      <p>
        <FormattedMessage
          id="Checkout-Cart-WarningPopup-p1"
          defaultMessage="If you order the ISBN and book(s) at the same time, the book(s) will <b>NOT</b> contain the ISBN. We strongly recommend first ordering the ISBN, then ordering the book after the ISBN is assigned."
          values={{
            b: (chunks: any) => <b>{chunks}</b>
          }}
        />
      </p>
      <p>
        <FormattedMessage
          id="Checkout-Cart-WarningPopup-p2"
          defaultMessage="If you click the ‘Order ISBNs first’ button, we will remove the following book(s) from your shopping cart:"
          values={{
            b: (chunks: any) => <b>{chunks}</b>
          }}
        />
      </p>
      <ul>{renderWarningProducts}</ul>
    </Dialog>
  );
}
