import React from 'react';
import { Link } from 'react-router-dom';

function TopbookImage(props) {

	return(
		<div className="cw-topbook-image-container">
			<Link to={props.url}>
				<img src={props.imageUrl} alt={props.bookTitle}/>
			</Link>
		</div>
	)
}

export default TopbookImage;