import React from 'react';
import { Link } from 'react-router-dom';
import Buybutton from './Buybutton';

function BookItem(props) {
	const bookdetailsLink = "/bookdetails/"+props.book.id;

	let buybuttonType = "short";
	let buybuttonLabel = props.translations.buybuttonFull;
	if(props.book.binding === 6) {
		buybuttonType = "ebook";
		buybuttonLabel = props.translations.buybuttonEbook;
	}
	return(
		<div className="cw-bookitem">
			<Link to={bookdetailsLink} className="cw-bookitem-imagelink"><img src={props.book.imageUrl} alt={props.book.title} /></Link>
			<div className="cw-bookitem-titleblock">
				<div className="cw-bookitem-title">{props.book.title}</div>
				<div className="cw-bookitem-subtitle">{props.book.subtitle}</div>
			</div>
			<div className="cw-bookitem-buttonprice">
				<div className="cw-bookitem-buybutton">
					<Buybutton url={props.book.url} bookData={props.book} buttonType={buybuttonType} label={buybuttonLabel}/>
				</div>
				<div className="cw-bookitem-bindingprice">
					<div className="cw-bookitem-binding">{props.book.bindingText}</div>
					<div className="cw-bookitem-price">{props.book.price}</div>
				</div>
			</div>
		</div>
	);
}

export default BookItem;