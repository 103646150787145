import React, { useEffect } from 'react';
import ContentContainer from '../ContentContainer';
import BookImage from './BookImage';
import BookBuyBlock from './BookBuyBlock';
import BookDescription from './BookDescription';
import BookColumn from './BookColumn';
import BookSpecs from './BookSpecs';
import {useHistory} from 'react-router-dom';

function BookdetailsComponent(props) {
	const history = useHistory();
	useEffect(() => {
		props.setShowBackbutton(true)
		props.setPagePath('/bookdetails/'+props.match.params.id);
	});
	const book = props.authorData.books.find(obj => obj.id === parseInt(props.match.params.id));
	
	if(book) {
		return(
			<main className="cw-main-container">
				<ContentContainer>
					<div className="cw-bookdetails-imagebuyblock-container">
						<BookImage imageUrl={book.imageUrl} bookTitle={book.bookTitle}/>
						<BookBuyBlock book={book} translations={props.authorData.translations}/>
					</div>
					<div className="cw-bookdetails-descspecs-container">
						<BookColumn title={props.authorData.translations.blocktitleDescription}>
							<BookDescription description={book.description} />
						</BookColumn>
						<BookColumn title={props.authorData.translations.blocktitleSpecifications}>
							<BookSpecs book={book} translations={props.authorData.translations}/>
						</BookColumn>
					</div>
				</ContentContainer>
			</main>
		);
	} else {
		history.push('/');
		return (
			<main/>
		);
	}
	
}

export default BookdetailsComponent;