import React from 'react';
import TopbookImage from './TopbookImage';
import TopbookContent from './TopbookContent';

function Topbook(props) {
	const bookdetailsLink = "/bookdetails/"+props.bookData.id;

	return(
		<div className="cw-topbook-container">
			<TopbookImage imageUrl={props.bookData.imageUrl} bookTitle={props.bookData.bookTitle} url={bookdetailsLink}/>
			<TopbookContent bookData={props.bookData} translations={props.translations}/>
		</div>
	);
}

export default Topbook;