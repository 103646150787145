import { Block, BlockContent, FormItem, Popup, PopupBoard, PopupContent, ToggleSwitch } from 'mbs-common';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import BillingAddressInput from './BillingAddressInput';
import CurrentAddressBlock from '../../AddressInput/CurrentAddressBlock';
import { setBillingAddressToSameAsShipping } from '../../checkoutSlice';

export default function BillingAddress({
  billingAddressSameAsShipping
}: {
  billingAddressSameAsShipping: boolean;
}) {
  const checkout = useAppSelector((state) => state.checkout);
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [billingSameCheckbox, setBillingSameCheckbox] = useState(billingAddressSameAsShipping);
  const [showAddressInputPopup, setShowAddressInputPopup] = useState<boolean>(false);

  const handleShowAddressInputPopup = () => {
    setShowAddressInputPopup(true);
  };

  const handleHideAddressInputPopup = () => {
    setShowAddressInputPopup(false);
  };

  useEffect(() => {
    setBillingSameCheckbox(billingAddressSameAsShipping);
  }, [billingAddressSameAsShipping]);

  const handleBillingSameCheckboxSwitch = () => {
    setBillingSameCheckbox(!billingSameCheckbox);
    if (!billingSameCheckbox) {
      // billingsameasshipping is getting turned on, so dispatch that to redux'
      // when we do this, make sure we also set the proper customerType for this customer, so we don't get COMPANY orders without valid vat numbers
      // we do NOT dispatch turning it off, because definitively turning it off requires the full billing address to be set
      dispatch(setBillingAddressToSameAsShipping(true));
    }
  };

  return (
    <Block
      blockTitle={intl.formatMessage({
        id: 'Checkout-CheckoutDetails-title-BillingAddress',
        defaultMessage: 'Billing Address'
      })}
    >
      <BlockContent>
        <FormItem style={{ marginBottom: '20px' }}>
          <ToggleSwitch
            id="billingSameAsShipping"
            checked={billingSameCheckbox}
            onChange={handleBillingSameCheckboxSwitch}
            labelText={intl.formatMessage({
              id: 'Checkout-CheckoutDetails-BillingAddress-SameAsShipping-label',
              defaultMessage: 'Same as shipping address?'
            })}
          />
        </FormItem>
        {!billingSameCheckbox &&
        'billingAddress' in checkout &&
        checkout.billingAddress !== undefined ? (
          <>
            <CurrentAddressBlock
              blockId="shippingAddress"
              currentAddress={checkout.billingAddress}
              // handleClickOtherAddress={handleShowAddressInputPopup}
              blockTitle={intl.formatMessage({
                id: 'Checkout-CheckoutDetails-BillingAddress-label-YourBillingAddress',
                defaultMessage: 'Your billing address'
              })}
              handleClickOtherAddress={handleShowAddressInputPopup}
              showOtherAddressButton={true}
              otherAddressButtonLabel={intl.formatMessage({
                id: 'Checkout-CheckoutDetails-BillingAddress-Button-EnterDifferentAddress',
                defaultMessage: 'Enter other billing address'
              })}
            />
            {showAddressInputPopup && (
              <Popup
                isVisible={showAddressInputPopup}
                id="billingAddressPopup"
                handleClose={handleHideAddressInputPopup}
                activeBoard="billingAddressPopup-board1"
              >
                <PopupBoard
                  id="billingAddressPopup-board1"
                  headerTitle={intl.formatMessage({
                    id: 'Checkout-CheckoutDetails-title-BillingAddress',
                    defaultMessage: 'Billing address'
                  })}
                  headerType="fixed"
                  showClose={true}
                >
                  <PopupContent>
                    <BillingAddressInput
                      customerType={checkout.customerType}
                      onComplete={handleHideAddressInputPopup}
                    />
                  </PopupContent>
                </PopupBoard>
              </Popup>
            )}
          </>
        ) : (
          !billingSameCheckbox && <BillingAddressInput customerType={checkout.customerType} />
        )}
      </BlockContent>
    </Block>
  );
}
