import React from 'react';

const getPaths = (name) => {
	switch(name) {
		case "right-dark":
			return(
				<g>
					<path className="svg-path-darkfill" d="M1,16c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4L6.6,8L0.3,1.7c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0
						L9.4,8l-7.7,7.7C1.5,15.9,1.3,16,1,16z"/>
				</g>
			);
		default:
			return(
				<g>
					<path className="svg-path-darkfill" d="M1,16c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4L6.6,8L0.3,1.7c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0
						L9.4,8l-7.7,7.7C1.5,15.9,1.3,16,1,16z"/>
				</g>
			);
	}
}
const mySvg = ({ name = "", height = "20px"}) => (
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 20 18" xmlSpace="preserve" height={height}>
	{getPaths(name)}
</svg>
);

export default mySvg;