import React from 'react';
import FooterSendMessage from './FooterSendMessage';
import FooterSocial from './FooterSocial';

function FooterComponent(props) {
	return(
		<div>
			{(props.email || Object.keys(props.socialmedia).length > 0) &&
				<footer className="cw-footer-container">
					<div className="cw-footer-content">
						<h2>{props.translations.titleStayintouch}</h2>
						<div className="cw-footer-blocks">
							{props.email && <FooterSendMessage {...props} />}
							{Object.keys(props.socialmedia).length>0 && <FooterSocial {...props} />}
						</div>
					</div>
				</footer>
			}
		</div>
		
	);
}

export default FooterComponent;