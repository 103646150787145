import React from 'react';

const styles = ".st0{fill:#FFFFFF;}";

const mySvg = ({ width = "20px"}) => (
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 20 21" xmlSpace="preserve" width={width}>
<style type="text/css">
	{styles}
</style>
<g>
	<path className="st0" d="M17.5,14h-14v-1h13.6l1.8-8H3.5V4h16c0.2,0,0.3,0.1,0.4,0.2C20,4.3,20,4.5,20,4.6l-2,9
		C17.9,13.8,17.7,14,17.5,14z"/>
	<rect x="4" y="7" className="st0" width="15" height="1"/>
	<rect x="4" y="10" className="st0" width="14" height="1"/>
	<g>
		<path className="st0" d="M4,21c-1.1,0-2-0.9-2-2s0.9-2,2-2c1.1,0,2,0.9,2,2S5.1,21,4,21z"/>
	</g>
	<g>
		<path className="st0" d="M15,21c-1.1,0-2-0.9-2-2s0.9-2,2-2c1.1,0,2,0.9,2,2S16.1,21,15,21z"/>
	</g>
	<path className="st0" d="M14.5,18h-9C4.2,18,3,16.8,3,15.5v-13C3,1.8,2.2,1,1.5,1h-1C0.2,1,0,0.8,0,0.5S0.2,0,0.5,0h1
		C2.8,0,4,1.2,4,2.5v13C4,16.2,4.8,17,5.5,17h9c0.3,0,0.5,0.2,0.5,0.5S14.8,18,14.5,18z"/>
</g>
</svg>
);

export default mySvg;