/**
 * Function that sorts an array by a specific key in an object in the array.
 * WARNING: DOES NOT CLONE THE ARRAY, SO DO NOT USE IT DIRECTLY ON A REDUX STORE ARRAY
 * @param {*} array the array to be sorted
 * @param {*} key the specific key that you want to sort
 * @param {*} order the order (asc or desc)
 * @returns sorted array
 */
export default function sortByKey(array, key, order = 'asc') {
  return array.sort(function (a, b) {
    var x = a[key];
    var y = b[key];
    if (order === 'asc') {
      return x < y ? -1 : x > y ? 1 : 0;
    } else if (order === 'desc') {
      return x < y ? 1 : x > y ? -1 : 0;
    }
  });
}
