import { Block, BlockContent } from 'mbs-common';
import { useIntl } from 'react-intl';
import ProductsBlock from './ProductsBlock';
import TotalsBlock from './TotalsBlock';

export default function OrderSidebar() {
  const intl = useIntl();
  return (
    <Block
      blockTitle={intl.formatMessage({
        id: 'Checkout-OrderSidebar-title-YourOrder',
        defaultMessage: 'Your order'
      })}
      style={{ position: 'sticky', top: '100px'}}
    >
      <BlockContent type="small">
        <ProductsBlock />
        <TotalsBlock />
      </BlockContent>
    </Block>
  );
}
