import { combineReducers } from '@reduxjs/toolkit';
import twoFA from './twoFA';
import * as z from 'zod';
import { getCanariasValidator, getPostalCodeValidator, postalCodeValidators } from '../helper/addressSupport';

export default combineReducers({ twoFA });

export enum CustomerType {
  'PRIVATE' = 'PRIVATE',
  'COMPANY' = 'COMPANY',
  'BOOKSTORE' = 'BOOKSTORE'
}

// const userAddressNLSchema = z.object({
//   l: z.literal('NL'),
//   p: z.string().regex(postalCodeValidators['NL'], { message: 'NLPostalcode Wrong'})
// });

export const userAddressSchema = z
  .object({
    fn: z
      .string({ required_error: 'ZodValidationError-AddressFnRequired' })
      .min(1, { message: 'ZodValidationError-AddressFnRequired' })
      .max(30, { message: 'ZodValidationError-AddressFnLongerThan30' }),
    ln: z
      .string({ required_error: 'ZodValidationError-AddressLnRequired' })
      .min(1, { message: 'ZodValidationError-AddressLnRequired' })
      .max(30, { message: 'ZodValidationError-AddressLnLongerThan30' }),
    s: z
      .string({ required_error: 'ZodValidationError-AddressSRequired' })
      .min(1, { message: 'ZodValidationError-AddressSRequired' })
      .max(50, { message: 'ZodValidationError-AddressSLongerThan50' }),
    nr: z
      .string()
      .regex(/^[0-9]+$/, { message: 'ZodValidationError-AddressNrMustBeNumber' })
      .optional(),
    nra: z.string().optional(),
    s2: z.string().max(50, { message: 'ZodValidationError-AddressS2LongerThan50' }).optional(),
    p: z
      .string({ required_error: 'ZodValidationError-AddressPRequired' })
      .min(1, { message: 'ZodValidationError-AddressPRequired' })
      .max(20, { message: 'ZodValidationError-AddressPLongerThan20' }),
    c: z
      .string({ required_error: 'ZodValidationError-AddressCRequired' })
      .min(1, { message: 'ZodValidationError-AddressCRequired' })
      .max(35, { message: 'ZodValidationError-AddressCLongerThan35' }),
    r: z.string().max(35, { message: 'ZodValidationError-AddressRLongerThan35' }).optional(),
    l: z
      .string({ required_error: 'ZodValidationError-AdressLRequired' })
      .refine((value) => /^[A-Z]{2}$/.test(value), {
        message: 'ZodValidationError-AddressCountryNotTwoLetterCode'
      }),
    cp: z.string().max(35, { message: 'ZodValidationError-AddressCpLongerThan35' }).optional(),
    t: z.string().max(20, { message: 'ZodValidationError-AddressTLongerThan20' }).optional(),
    v: z.string().optional()
  })
  .refine((data) => `${data.fn} ${data.ln}`.length < 40, {
    message: 'ZodValidationError-AddressFullNameLongerThan40',
    path: ['fn']
  })
  .refine(
    (data) => {
      const country = data.l;

      // no postcode errors needed when there is no country
      if (!country) {
        return true;
      }

      const validator = getPostalCodeValidator(country);

      // no postalcode errors needed when there is no validator
      if (validator) {
        return validator.test(data.p);
      } else {
        return true;
      }
    },
    { message: 'ZodValidationError-AddressPostalCodeIncorrect', path: ['p'] }
  )
  .refine((data) => data.s.length === 0 || data.s2?.length === 0 || data.s !== data.s2, {
    message: 'ZodValidationError-AddressS2SameAsS',
    path: ['s2']
  })
  .refine(
    (data) => {
      if (data.l === 'ES' && getCanariasValidator().test(data.p) && !data.v) {
        return false;
      }
      return true;
    },
    { message: 'ZodValidationError-AddressVRequiredForCanarias', path: ['v'] }
  );


export type UserAddr = z.infer<typeof userAddressSchema>;

export interface UserAddress extends z.infer<typeof userAddressSchema> {
  n: string;
}
/*
export interface UserAddress {
  n: string; // full name
  fn: string; // first name
  ln: string; // last name
  s: string; // street
  nr?: string; // building number (deprecated for now)
  s2?: string; // street line 2
  p: string; // postal code
  c: string; // city
  r?: string; // region
  l: string; // 2 letter country code
  cp?: string; // company name for address label
  t?: string; // phone number
  v?: string; // vat number (could also be used for Canarias ID number)
}*/

export interface IEUCompany {
  companyName: string;
  cocNo?: string;
  taxNo: string;
  iban?: string;
  bic?: string;
  bankaccountName?: string;
  website?: string;
}

interface UserBaseProfile {
  email: string;
  customerType: CustomerType;
  shippingAddress?: UserAddress;
  firstName?: string;
  lastName?: string;
}
interface UserProfileBillingAddressSameAsShipping {
  billingAddressSameAsShipping: true;
}

interface UserProfileBillingAddressNotSameAsShipping {
  billingAddressSameAsShipping: false;
  billingAddress: UserAddress;
}

interface UserProfilePrivate extends UserBaseProfile {
  customerType: CustomerType.PRIVATE;
  hasCompany: false;
}

interface UserProfileEUCompany extends UserBaseProfile {
  customerType: CustomerType.COMPANY | CustomerType.BOOKSTORE;
  hasCompany: true;
  euCompany: IEUCompany;
}

export type UserProfile =
  | (UserProfilePrivate &
      (UserProfileBillingAddressSameAsShipping | UserProfileBillingAddressNotSameAsShipping))
  | (UserProfileEUCompany &
      (UserProfileBillingAddressSameAsShipping | UserProfileBillingAddressNotSameAsShipping));
