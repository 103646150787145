import { useIntl } from 'react-intl';
import ProductItem from '.';
import { CartItemEbookSpecs } from '../../../../Cart/cartSlice';

export default function EbookItem({
  bookSpecs,
  quantity,
  id
}: {
  bookSpecs: CartItemEbookSpecs;
  quantity: number;
  id: string;
}) {
  const intl = useIntl();
  const theImage = <img src={bookSpecs.imageUrl} alt={bookSpecs.title} />;
  const specifications = `${intl.formatMessage({ id: `binding-${bookSpecs.binding}` })} ${
    bookSpecs.isbn ? `(${bookSpecs.isbn})` : ''
  }`;

  return (
    <ProductItem
      title={bookSpecs.title}
      subtitle={bookSpecs.subtitle}
      image={theImage}
      quantity={quantity}
      specifications={specifications}
    />
  );
}