import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Cart from './Cart';
import Checkout from './Checkout';
import CheckoutSuccess from './Checkout/CheckoutResult/CheckoutSuccess';
import CheckoutResult from './Checkout/CheckoutResult';
import CheckoutFailed from './Checkout/CheckoutResult/CheckoutFailed';
import CheckoutError from './Checkout/CheckoutResult/CheckoutError';
import CheckoutPending from './Checkout/CheckoutResult/CheckoutPending';
import CheckoutCancelled from './Checkout/CheckoutResult/CheckoutCancelled';
import React, { useEffect } from 'react';
// import AppPageLayout from './Layout/AppPageLayout';

export default function CheckoutApp({ setShowBackbutton, setPagePath }: {setShowBackbutton: React.Dispatch<React.SetStateAction<boolean>>, setPagePath: React.Dispatch<React.SetStateAction<string>>}) {
  let { path } = useRouteMatch();

  useEffect(() => {
    if(typeof(setShowBackbutton) == 'function' && typeof(setPagePath) == 'function')
    {
      setShowBackbutton(true);
      setPagePath('/cart')
    }
  }, []);

  return (
    <>
      <section className="app-context-container">
        <Switch>
          <Route path={`${path}/result/orderId/:id`} children={<CheckoutResult />} />
          <Route path={`${path}/success/orderId/:id`} children={<CheckoutSuccess />} />
          <Route path={`${path}/failed/orderId/:id`} children={<CheckoutFailed />} />
          <Route path={`${path}/error/orderId/:id`} children={<CheckoutError />} />
          <Route path={`${path}/pending/orderId/:id`} children={<CheckoutPending />} />
          <Route path={`${path}/cancelled/orderId/:id`} children={<CheckoutCancelled />} />
          <Checkout />
        </Switch>
      </section>
    </>
  );
}
