import { useIntl } from 'react-intl';
import { useAppSelector, useGetCartQuery } from '../../../hooks';
import { Loading } from 'mbs-common';
import { CartItemBuscards, CartItemEbook, CartItemIsbn, CartItemPrintBook, productTypeIsBuscards, productTypeIsEBook, productTypeIsIsbn, productTypeIsPrintBook } from '../../../Cart/cartSlice';
import PrintBookItem from './ProductItem/PrintBookItem';
import EbookItem from './ProductItem/EbookItem';
import IsbnItem from './ProductItem/IsbnItem';
import BuscardsItem from './ProductItem/BuscardsItem';

export default function ProductsBlock() {
  const intl = useIntl();
  const checkout = useAppSelector((state) => state.checkout);
  const { data: cart, isSuccess, isLoading } = useGetCartQuery();

  return (
    <div className="chkt-ordsdbr-prods">
      {checkout.specification && isSuccess ? (
        checkout.specification.items.map((item, index) => {
          const cartItem = cart.items.find((value) => item.id === value.id);
          if (cartItem) {
            if (productTypeIsPrintBook(item.type)) {
              const cartItemTyped = cartItem as CartItemPrintBook;
              return (
                <PrintBookItem
                  bookSpecs={cartItemTyped.bookSpecs}
                  quantity={item.quantity}
                  id={item.id}
                  key={`checkoutsidebar-item-${index}`}
                />
              );
            } else if (productTypeIsEBook(item.type)) {
              const cartItemTyped = cartItem as CartItemEbook;
              return (
                <EbookItem
                  bookSpecs={cartItemTyped.bookSpecs}
                  quantity={item.quantity}
                  id={item.id}
                  key={`checkoutsidebar-item-${index}`}
                />
              );
            } else if (productTypeIsIsbn(item.type)) {
              const cartItemTyped = cartItem as CartItemIsbn;
              return (
                <IsbnItem
                  isbnSpecs={cartItemTyped.isbnSpecs}
                  quantity={item.quantity}
                  id={item.id}
                  key={`checkoutsidebar-item-${index}`}
                />
              );
            } else if (productTypeIsBuscards(item.type)) {
              const cartItemTyped = cartItem as CartItemBuscards;
              return (
                <BuscardsItem
                  buscardsSpecs={cartItemTyped.buscardsSpecs}
                  quantity={item.quantity}
                  id={item.id}
                  key={`checkoutsidebar-item-${index}`}
                />
              );
            }
          }
          return <div className="chkt-ordsdbr-prods-proditem">Item: {item.id}</div>;
        })
      ) : isSuccess ? (
        cart.items.map((item, index) => {
          if (productTypeIsPrintBook(item.type)) {
            const cartItem = item as CartItemPrintBook;
            return (
              <PrintBookItem
                bookSpecs={cartItem.bookSpecs}
                quantity={cartItem.quantity}
                id={cartItem.id}
                key={`checkoutsidebar-item-${index}`}
              />
            );
          } else if (productTypeIsEBook(item.type)) {
            const cartItem = item as CartItemEbook;
            return (
              <EbookItem
                bookSpecs={cartItem.bookSpecs}
                quantity={cartItem.quantity}
                id={cartItem.id}
                key={`checkoutsidebar-item-${index}`}
              />
            );
          } else if (productTypeIsIsbn(item.type)) {
            const cartItem = item as CartItemIsbn;
            return (
              <IsbnItem
                isbnSpecs={cartItem.isbnSpecs}
                quantity={cartItem.quantity}
                id={cartItem.id}
                key={`checkoutsidebar-item-${index}`}
              />
            );
          } else if (productTypeIsBuscards(item.type)) {
            const cartItem = item as CartItemBuscards;
            return (
              <BuscardsItem
                buscardsSpecs={cartItem.buscardsSpecs}
                quantity={cartItem.quantity}
                id={cartItem.id}
                key={`checkoutsidebar-item-${index}`}
              />
            );
          }
          return <div className="chkt-ordsdbr-prods-proditem">Item: {item.id}</div>;
        })
      ) : (
        <Loading container={true} size="20px" />
      )}
    </div>
  );
}
