import { Block, BlockContent, FormItem, MBSInput } from 'mbs-common';
import { useAppDispatch } from '../../../hooks';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { setEmail } from '../../checkoutSlice';
import { FormattedMessage, useIntl } from 'react-intl';

const emailSchema = z.object({
  email: z
    .string()
    .min(1, { message: 'ZodValidationError-EmailRequired' })
    .email({ message: 'ZodValidationError-EmailNotValidEmail' })
});

type EmailForm = z.infer<typeof emailSchema>;

export default function Email({ currentEmail }: { currentEmail?: string }) {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [showEditField, setShowEditField] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<EmailForm>({
    resolver: zodResolver(emailSchema),
    mode: 'onBlur',
    shouldFocusError: true
  });

  const handeClickShowInput = () => {
    setShowEditField(true);
    if (currentEmail) {
      setValue('email', currentEmail);
    }
  };

  const handeClickCancelInput = () => {
    setShowEditField(false);
  };

  const onSubmit: SubmitHandler<EmailForm> = (data, event) => {
    event?.preventDefault();
    dispatch(setEmail(data.email));
    setShowEditField(false);
  };

  return (
    <Block
      blockTitle={intl.formatMessage({
        id: 'Checkout-CheckoutDetails-title-Email',
        defaultMessage: 'E-mail address'
      })}
    >
      <BlockContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormItem
            label={intl.formatMessage({
              id: 'Checkout-CheckoutDetails-Email-label-Email',
              defaultMessage: 'E-mail'
            })}
            inputType="flex"
          >
            {currentEmail && !showEditField ? (
              <>
                {currentEmail}{' '}
                <button className="app-button-inlinelink" onClick={handeClickShowInput}>
                  <FormattedMessage
                    id="Checkout-CheckoutDetails-Email-Button-EditEmail"
                    defaultMessage="Edit"
                  />
                </button>
              </>
            ) : (
              <>
                <MBSInput
                  {...register('email')}
                  onBlur={!currentEmail ? handleSubmit(onSubmit) : undefined}
                  error={errors.email?.message && intl.formatMessage({ id: errors.email?.message })}
                />
                <div className="form-buttons">
                  <div>
                    <button type="submit" className="app-button-cta">
                      {currentEmail ? (
                        <FormattedMessage
                          id="Checkout-CheckoutDetails-Button-Save"
                          defaultMessage="Save"
                        />
                      ) : (
                        <FormattedMessage
                          id="Checkout-CheckoutDetails-Button-Next"
                          defaultMessage="Next"
                        />
                      )}
                    </button>
                  </div>
                  {currentEmail && (
                    <div>
                      <button onClick={handeClickCancelInput}>
                        <FormattedMessage
                          id="Checkout-CheckoutDetails-Button-Cancel"
                          defaultMessage="Cancel"
                        />
                      </button>
                    </div>
                  )}
                </div>
              </>
            )}
          </FormItem>
        </form>
      </BlockContent>
    </Block>
  );
}
