import * as axios from 'axios';
import getCookie from './getCookie';

export default class ApiCall {
  constructor() {
    this.apiToken = null;
    this.client = null;
    this.apiBaseURL = window.location.origin + '/site';
    //this.apiBaseURL = 'http://www.site1.nl/site/index.php'; //TODO: fix this for production
  }

  init = () => {
    //this.apiToken = getCookie('jwt');

    let headers = {};
/*
    if (this.apiToken) {
      headers.Authorization = `Bearer ${this.apiToken}`;
    }
*/
    this.client = axios.create({
      baseURL: this.apiBaseURL,
      timeout: 31000,
      headers: headers,
      withCredentials: true
    });

    return this.client;
  };

  getData = (path, params = {}) => {
    return this.init().get(path, { params });
  };

  postData = (path, params = {}) => {
    return this.init().post(path, params);
  };

  patchData = (path, params = {}) => {
    return this.init().patch(path, params);
  };

  putData = (path, params = {}) => {
    return this.init().put(path, params);
  };

  uploadFile = (path, file, onUploadProgress) => {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      timeout: 0,
      onUploadProgress
    };
    let formData = new FormData();
    formData.append('qqfile', file);

    return this.init().post(path, formData, config);
  };
}
