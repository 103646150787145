import { FormattedMessage, useIntl } from 'react-intl';
import {
  CartStateSucceeded,
  getPricePerUnitForCartItem,
  getPricePerUnitForQuantity
} from '../cartSlice';
import { Block, BlockContent, Loading } from 'mbs-common';
import { VATRateReturnType } from '../../helper/VATRate';
import { useAppSelector, useGetLanguage, useGetVATRatesForUserProfile } from '../../hooks';
import { minorUnitsToCurrency } from '../../helper/localize';

export default function TotalsBlock({
  cart,
  onOrderSubmit
}: {
  cart: CartStateSucceeded;
  onOrderSubmit?: () => void;
}) {
  const intl = useIntl();
  const language = useGetLanguage();
  const { data, isSuccess } = useGetVATRatesForUserProfile(
    cart.config.sourceCountry,
    VATRateReturnType.IncreaseRatio
  );
  const minorSubtotal = cart.items.reduce((total, item) => {
    const minorPrice = getPricePerUnitForQuantity(item.pricing, 1);
    const vatIncreaseRatio = isSuccess ? data[item.type] : 1;
    const price = minorPrice * item.quantity * vatIncreaseRatio;
    return total + price;
  }, 0);

  const minorTotal = cart.items.reduce((total, item) => {
    const minorPrice = getPricePerUnitForCartItem(item);
    const vatIncreaseRatio = isSuccess ? data[item.type] : 1;
    const price = minorPrice * item.quantity * vatIncreaseRatio;
    return total + price;
  }, 0);

  const discount = minorSubtotal - minorTotal;

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (onOrderSubmit) {
      onOrderSubmit();
    }
  };

  return (
    <Block
      blockTitle={intl.formatMessage({ id: 'Checkout-Cart-title-Total', defaultMessage: 'Total' })}
      style={{ position: 'sticky', top: '100px' }}
    >
      <BlockContent type="small">
        {isSuccess ? (
          <div className="cart-totals">
            <div className="form-item">
              <FormattedMessage
                id="Checkout-Cart-total-Subtotal"
                defaultMessage="Subtotal <span>{price}</span>"
                values={{
                  span: (chunks: any) => <span>{chunks}</span>,
                  price: minorUnitsToCurrency({
                    language,
                    amount: minorSubtotal,
                    currency: cart.config.currency
                  })
                }}
              />
            </div>
            {discount > 0 && (
              <div className="form-item cart-totals-discount">
                <FormattedMessage
                  id="Checkout-Cart-total-Discount"
                  defaultMessage="Discount <span>{price}</span>"
                  values={{
                    span: (chunks: any) => <span>{chunks}</span>,
                    price: minorUnitsToCurrency({
                      language,
                      amount: discount,
                      currency: cart.config.currency
                    })
                  }}
                />
              </div>
            )}
            <div className="form-item cart-totals-total">
              <FormattedMessage
                id="Checkout-Cart-total-Total"
                defaultMessage="Total <span>{price}</span>"
                values={{
                  span: (chunks: any) => <span>{chunks}</span>,
                  price: minorUnitsToCurrency({
                    language,
                    amount: minorTotal,
                    currency: cart.config.currency
                  })
                }}
              />
            </div>
            {onOrderSubmit && (
              <div className="form-item">
                <button className="app-button-cta" style={{ width: '100%' }} onClick={handleClick}>
                  <FormattedMessage
                    id="Checkout-Cart-Button-ProceedCheckout"
                    defaultMessage="Proceed to checkout"
                  />
                </button>
              </div>
            )}
          </div>
        ) : (
          <Loading container={true} size="20px" />
        )}
      </BlockContent>
    </Block>
  );
}
