import React from 'react';

export default function BookSpecs(props) {
	let tableRows = [
		{
			label: props.translations.labelAuthor,
			value: props.book.author
		},
		{
			label: props.translations.labelLanguage,
			value: props.book.languageText
		},
		{
			label: props.translations.labelBinding,
			value: props.book.bindingText
		},
		{
			label: props.translations.labelSize,
			value: props.book.sizeText
		},
		{
			label: props.translations.labelColor,
			value: props.book.colorText
		}];

		if(props.book.noPages > 0) {
			tableRows = tableRows.concat(
				{
					label: props.translations.labelNoOfPages,
					value: props.book.noPages
				}
			);
		}

		tableRows = tableRows.concat(
			{
				label: props.translations.labelISBN,
				value: (props.book.isbn ? props.book.isbn : '-')
			},
			{
				label: props.translations.labelPublishdate,
				value: props.book.publishDate
			},
			{
				label: props.translations.labelGenre,
				value: props.book.genreText
			}
		);

	const tableRender = tableRows.map((item, index) => <tr key={index} className={index % 2 === 0 ? "reducedheight even" : "reducedheight"}>
		<td>{item.label}</td>
		<td>{item.value}</td>
	</tr>);
	return(
		<table className="data-table">
			<tbody>
				{tableRender}
			</tbody>
		</table>
	);
}